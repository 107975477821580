//
// Mixins
// --------------------------------------------------


// Font smoothing

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Social buttons hover

@mixin social-btn-hover($color) {
  &:hover {
    border-color: rgba($color, .3);
    color: $color;
  }
}

@mixin screen-1920() {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin screen-1800() {
  @media (max-width: 1800px) {
    @content;
  }
}

@mixin screen-1600() {
  @media (max-width: 1600px) {
    @content;
  }
}

@mixin screen-1440() {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin screen-1400() {
  @media (max-width: 1399.98px) {
    @content;
  }
}

@mixin screen-1200 {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin screen-992() {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin screen-768() {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin screen-576() {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin screen-460() {
  @media (max-width: 460px) {
    @content;
  }
}