/*!
 * Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa {
  font-family: 'Font Awesome 6 Pro';
  font-family: var(--fa-style-family, 'Font Awesome 6 Pro');
  font-weight: 900;
  font-weight: var(--fa-style, 900);
}
.fa,
.fa-brands,
.fa-duotone,
.fa-light,
.fa-regular,
.fa-solid,
.fa-thin,
.fab,
.fad,
.fal,
.far,
.fas,
.fat {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  color: inherit;
  color: var(--fa-color, inherit);
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}
.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em;
}
.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em;
}
.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}
.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}
.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: 2em;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border-radius: var(--fa-border-radius, 0.1em);
  border: 0.08em solid #eee;
  border: var(--fa-border-width, 0.08em) var(--fa-border-style, solid)
    var(--fa-border-color, #eee);
  padding: 0.2em 0.25em 0.15em;
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}
.fa-pull-left {
  float: left;
  margin-right: 0.3em;
  margin-right: var(--fa-pull-margin, 0.3em);
}
.fa-pull-right {
  float: right;
  margin-left: 0.3em;
  margin-left: var(--fa-pull-margin, 0.3em);
}
.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-spin-direction, normal);
  animation-direction: var(--fa-spin-direction, normal);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-duration: var(--fa-spin-duration, 2s);
  animation-duration: var(--fa-spin-duration, 2s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-spin-iteration-count, infinite);
  animation-iteration-count: var(--fa-spin-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-timing-function: var(--fa-spin-timing, linear);
  animation-timing-function: var(--fa-spin-timing, linear);
}
.fa-spin-reverse {
  --fa-spin-direction: reverse;
}
.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-direction: var(--fa-spin-direction, normal);
  animation-direction: var(--fa-spin-direction, normal);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--fa-spin-duration, 1s);
  animation-duration: var(--fa-spin-duration, 1s);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-spin-iteration-count, infinite);
  animation-iteration-count: var(--fa-spin-iteration-count, infinite);
  -webkit-animation-timing-function: steps(8);
  animation-timing-function: steps(8);
  -webkit-animation-timing-function: var(--fa-spin-timing, steps(8));
  animation-timing-function: var(--fa-spin-timing, steps(8));
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.fa-flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}
.fa-rotate-by {
  -webkit-transform: rotate(none);
  transform: rotate(none);
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: auto;
  z-index: var(--fa-stack-z-index, auto);
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff);
}
.fa-0:before {
  content: '\e089';
}
.fa-1:before {
  content: '\e08a';
}
.fa-2:before {
  content: '\e08b';
}
.fa-3:before {
  content: '\e08c';
}
.fa-4:before {
  content: '\e08d';
}
.fa-5:before {
  content: '\e08e';
}
.fa-6:before {
  content: '\e08f';
}
.fa-7:before {
  content: '\e090';
}
.fa-8:before {
  content: '\e091';
}
.fa-9:before {
  content: '\e092';
}
.fa-a:before {
  content: '\e093';
}
.fa-abacus:before {
  content: '\f640';
}
.fa-acorn:before {
  content: '\f6ae';
}
.fa-address-book:before,
.fa-contact-book:before {
  content: '\f2b9';
}
.fa-address-card:before,
.fa-contact-card:before,
.fa-vcard:before {
  content: '\f2bb';
}
.fa-air-conditioner:before {
  content: '\f8f4';
}
.fa-air-freshener:before {
  content: '\f5d0';
}
.fa-airplay:before {
  content: '\e094';
}
.fa-alarm-clock:before {
  content: '\f34e';
}
.fa-alarm-exclamation:before {
  content: '\f843';
}
.fa-alarm-plus:before {
  content: '\f844';
}
.fa-alarm-snooze:before {
  content: '\f845';
}
.fa-album:before {
  content: '\f89f';
}
.fa-album-collection:before {
  content: '\f8a0';
}
.fa-alicorn:before {
  content: '\f6b0';
}
.fa-alien:before {
  content: '\f8f5';
}
.fa-alien-8bit:before,
.fa-alien-monster:before {
  content: '\f8f6';
}
.fa-align-center:before {
  content: '\f037';
}
.fa-align-justify:before {
  content: '\f039';
}
.fa-align-left:before {
  content: '\f036';
}
.fa-align-right:before {
  content: '\f038';
}
.fa-align-slash:before {
  content: '\f846';
}
.fa-alt:before {
  content: '\e095';
}
.fa-amp-guitar:before {
  content: '\f8a1';
}
.fa-ampersand:before {
  content: '\e096';
}
.fa-anchor:before {
  content: '\f13d';
}
.fa-angel:before {
  content: '\f779';
}
.fa-angle:before {
  content: '\e097';
}
.fa-angle-90:before {
  content: '\e098';
}
.fa-angle-down:before {
  content: '\f107';
}
.fa-angle-left:before {
  content: '\f104';
}
.fa-angle-right:before {
  content: '\f105';
}
.fa-angle-up:before {
  content: '\f106';
}
.fa-angle-double-down:before,
.fa-angles-down:before {
  content: '\f103';
}
.fa-angle-double-left:before,
.fa-angles-left:before {
  content: '\f100';
}
.fa-angle-double-right:before,
.fa-angles-right:before {
  content: '\f101';
}
.fa-angle-double-up:before,
.fa-angles-up:before {
  content: '\f102';
}
.fa-ankh:before {
  content: '\f644';
}
.fa-aperture:before {
  content: '\e099';
}
.fa-apple-core:before {
  content: '\e09a';
}
.fa-apple-crate:before {
  content: '\f6b1';
}
.fa-apple-alt:before,
.fa-apple-whole:before {
  content: '\f5d1';
}
.fa-archway:before {
  content: '\f557';
}
.fa-arrow-down:before {
  content: '\f063';
}
.fa-arrow-down-1-9:before,
.fa-sort-numeric-asc:before,
.fa-sort-numeric-down:before {
  content: '\f162';
}
.fa-arrow-down-9-1:before,
.fa-sort-numeric-desc:before,
.fa-sort-numeric-down-alt:before {
  content: '\f886';
}
.fa-arrow-down-a-z:before,
.fa-sort-alpha-asc:before,
.fa-sort-alpha-down:before {
  content: '\f15d';
}
.fa-arrow-down-arrow-up:before,
.fa-sort-alt:before {
  content: '\f883';
}
.fa-arrow-down-big-small:before,
.fa-sort-size-down:before {
  content: '\f88c';
}
.fa-arrow-down-from-dotted-line:before {
  content: '\e09b';
}
.fa-arrow-down-from-line:before,
.fa-arrow-from-top:before {
  content: '\f345';
}
.fa-arrow-down-left:before {
  content: '\e09c';
}
.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: '\e09d';
}
.fa-arrow-down-long:before,
.fa-long-arrow-down:before {
  content: '\f175';
}
.fa-arrow-down-right:before {
  content: '\e09e';
}
.fa-arrow-down-short-wide:before,
.fa-sort-amount-desc:before,
.fa-sort-amount-down-alt:before {
  content: '\f884';
}
.fa-arrow-down-small-big:before,
.fa-sort-size-down-alt:before {
  content: '\f88d';
}
.fa-arrow-down-square-triangle:before,
.fa-sort-shapes-down-alt:before {
  content: '\f889';
}
.fa-arrow-down-to-bracket:before {
  content: '\e09f';
}
.fa-arrow-down-to-dotted-line:before {
  content: '\e0a0';
}
.fa-arrow-down-to-line:before,
.fa-arrow-to-bottom:before {
  content: '\f33d';
}
.fa-arrow-down-to-square:before {
  content: '\e0a1';
}
.fa-arrow-down-triangle-square:before,
.fa-sort-shapes-down:before {
  content: '\f888';
}
.fa-arrow-down-wide-short:before,
.fa-sort-amount-asc:before,
.fa-sort-amount-down:before {
  content: '\f160';
}
.fa-arrow-down-z-a:before,
.fa-sort-alpha-desc:before,
.fa-sort-alpha-down-alt:before {
  content: '\f881';
}
.fa-arrow-left:before {
  content: '\f060';
}
.fa-arrow-from-right:before,
.fa-arrow-left-from-line:before {
  content: '\f344';
}
.fa-arrow-left-long:before,
.fa-long-arrow-left:before {
  content: '\f177';
}
.fa-arrow-left-rotate:before,
.fa-arrow-rotate-left:before,
.fa-undo:before {
  content: '\f0e2';
}
.fa-arrow-left-to-line:before,
.fa-arrow-to-left:before {
  content: '\f33e';
}
.fa-arrow-pointer:before,
.fa-mouse-pointer:before {
  content: '\f245';
}
.fa-arrow-right:before {
  content: '\f061';
}
.fa-arrow-right-arrow-left:before,
.fa-exchange:before {
  content: '\f0ec';
}
.fa-arrow-right-from-bracket:before,
.fa-sign-out:before {
  content: '\f08b';
}
.fa-arrow-from-left:before,
.fa-arrow-right-from-line:before {
  content: '\f343';
}
.fa-arrow-right-long:before,
.fa-long-arrow-right:before {
  content: '\f178';
}
.fa-arrow-right-to-bracket:before,
.fa-sign-in:before {
  content: '\f090';
}
.fa-arrow-right-to-line:before,
.fa-arrow-to-right:before {
  content: '\f340';
}
.fa-arrow-rotate-forward:before,
.fa-redo:before,
.fa-rotate-right:before {
  content: '\f01e';
}
.fa-arrow-trend-down:before {
  content: '\e0a2';
}
.fa-arrow-trend-up:before {
  content: '\e0a3';
}
.fa-arrow-turn-down:before,
.fa-level-down:before {
  content: '\f149';
}
.fa-arrow-turn-up:before,
.fa-level-up:before {
  content: '\f148';
}
.fa-arrow-up:before {
  content: '\f062';
}
.fa-arrow-up-1-9:before,
.fa-sort-numeric-up:before {
  content: '\f163';
}
.fa-arrow-up-9-1:before,
.fa-sort-numeric-up-alt:before {
  content: '\f887';
}
.fa-arrow-up-a-z:before,
.fa-sort-alpha-up:before {
  content: '\f15e';
}
.fa-arrow-up-arrow-down:before,
.fa-sort-up-down:before {
  content: '\e0a4';
}
.fa-arrow-up-big-small:before,
.fa-sort-size-up:before {
  content: '\f88e';
}
.fa-arrow-up-from-bracket:before {
  content: '\e0a5';
}
.fa-arrow-up-from-dotted-line:before {
  content: '\e0a6';
}
.fa-arrow-from-bottom:before,
.fa-arrow-up-from-line:before {
  content: '\f342';
}
.fa-arrow-up-from-square:before {
  content: '\e0a7';
}
.fa-arrow-up-left:before {
  content: '\e0a8';
}
.fa-arrow-up-left-from-circle:before {
  content: '\e0a9';
}
.fa-arrow-up-long:before,
.fa-long-arrow-up:before {
  content: '\f176';
}
.fa-arrow-up-right:before {
  content: '\e0aa';
}
.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: '\e0ab';
}
.fa-arrow-up-right-from-square:before,
.fa-external-link:before {
  content: '\f08e';
}
.fa-arrow-up-short-wide:before,
.fa-sort-amount-up-alt:before {
  content: '\f885';
}
.fa-arrow-up-small-big:before,
.fa-sort-size-up-alt:before {
  content: '\f88f';
}
.fa-arrow-up-square-triangle:before,
.fa-sort-shapes-up-alt:before {
  content: '\f88b';
}
.fa-arrow-up-to-dotted-line:before {
  content: '\e0ac';
}
.fa-arrow-to-top:before,
.fa-arrow-up-to-line:before {
  content: '\f341';
}
.fa-arrow-up-triangle-square:before,
.fa-sort-shapes-up:before {
  content: '\f88a';
}
.fa-arrow-up-wide-short:before,
.fa-sort-amount-up:before {
  content: '\f161';
}
.fa-arrow-up-z-a:before,
.fa-sort-alpha-up-alt:before {
  content: '\f882';
}
.fa-arrows-cross:before {
  content: '\e0ad';
}
.fa-arrows-from-dotted-line:before {
  content: '\e0ae';
}
.fa-arrows-from-line:before {
  content: '\e0af';
}
.fa-arrows-h:before,
.fa-arrows-left-right:before {
  content: '\f07e';
}
.fa-arrows-maximize:before,
.fa-expand-arrows:before {
  content: '\f31d';
}
.fa-arrows-minimize:before,
.fa-compress-arrows:before {
  content: '\e0b0';
}
.fa-arrows-repeat:before,
.fa-repeat-alt:before {
  content: '\f364';
}
.fa-arrows-repeat-1:before,
.fa-repeat-1-alt:before {
  content: '\f366';
}
.fa-arrows-retweet:before,
.fa-retweet-alt:before {
  content: '\f361';
}
.fa-arrows-rotate:before,
.fa-refresh:before,
.fa-sync:before {
  content: '\f021';
}
.fa-arrows-to-dotted-line:before {
  content: '\e0b1';
}
.fa-arrows-to-line:before {
  content: '\e0b2';
}
.fa-arrows-up-down:before,
.fa-arrows-v:before {
  content: '\f07d';
}
.fa-arrows-up-down-left-right:before,
.fa-arrows:before {
  content: '\f047';
}
.fa-asterisk:before {
  content: '\f069';
}
.fa-at:before {
  content: '\f1fa';
}
.fa-atom:before {
  content: '\f5d2';
}
.fa-atom-alt:before,
.fa-atom-simple:before {
  content: '\f5d3';
}
.fa-audio-description:before {
  content: '\f29e';
}
.fa-audio-description-slash:before {
  content: '\e0b3';
}
.fa-austral-sign:before {
  content: '\e0b4';
}
.fa-avocado:before {
  content: '\e0b5';
}
.fa-award:before {
  content: '\f559';
}
.fa-award-simple:before {
  content: '\e0b6';
}
.fa-axe:before {
  content: '\f6b2';
}
.fa-axe-battle:before {
  content: '\f6b3';
}
.fa-b:before {
  content: '\e0b7';
}
.fa-baby:before {
  content: '\f77c';
}
.fa-baby-carriage:before,
.fa-carriage-baby:before {
  content: '\f77d';
}
.fa-backpack:before {
  content: '\f5d4';
}
.fa-backward:before {
  content: '\f04a';
}
.fa-backward-fast:before,
.fa-fast-backward:before {
  content: '\f049';
}
.fa-backward-step:before,
.fa-step-backward:before {
  content: '\f048';
}
.fa-bacon:before {
  content: '\f7e5';
}
.fa-bacteria:before {
  content: '\e059';
}
.fa-bacterium:before {
  content: '\e05a';
}
.fa-badge:before {
  content: '\f335';
}
.fa-badge-check:before {
  content: '\f336';
}
.fa-badge-dollar:before {
  content: '\f645';
}
.fa-badge-percent:before {
  content: '\f646';
}
.fa-badge-sheriff:before {
  content: '\f8a2';
}
.fa-badger-honey:before {
  content: '\f6b4';
}
.fa-bag-shopping:before,
.fa-shopping-bag:before {
  content: '\f290';
}
.fa-bags-shopping:before {
  content: '\f847';
}
.fa-bahai:before {
  content: '\f666';
}
.fa-baht-sign:before {
  content: '\e0b8';
}
.fa-ball-pile:before {
  content: '\f77e';
}
.fa-ballot:before {
  content: '\f732';
}
.fa-ballot-check:before {
  content: '\f733';
}
.fa-ban:before,
.fa-cancel:before {
  content: '\f05e';
}
.fa-ban-bug:before,
.fa-debug:before {
  content: '\f7f9';
}
.fa-ban-parking:before,
.fa-parking-circle-slash:before {
  content: '\f616';
}
.fa-ban-smoking:before,
.fa-smoking-ban:before {
  content: '\f54d';
}
.fa-band-aid:before,
.fa-bandage:before {
  content: '\f462';
}
.fa-banjo:before {
  content: '\f8a3';
}
.fa-bank:before,
.fa-institution:before,
.fa-university:before {
  content: '\f19c';
}
.fa-barcode:before {
  content: '\f02a';
}
.fa-barcode-read:before {
  content: '\f464';
}
.fa-barcode-scan:before {
  content: '\f465';
}
.fa-bars:before,
.fa-navicon:before {
  content: '\f0c9';
}
.fa-bars-filter:before {
  content: '\e0b9';
}
.fa-bars-progress:before,
.fa-tasks-alt:before {
  content: '\f828';
}
.fa-bars-sort:before {
  content: '\e0ba';
}
.fa-bars-staggered:before,
.fa-reorder:before,
.fa-stream:before {
  content: '\f550';
}
.fa-baseball-ball:before {
  content: '\f433';
}
.fa-baseball-bat-ball:before,
.fa-baseball:before {
  content: '\f432';
}
.fa-basket-shopping:before,
.fa-shopping-basket:before {
  content: '\f291';
}
.fa-basket-shopping-simple:before,
.fa-shopping-basket-alt:before {
  content: '\e0bb';
}
.fa-basketball-ball:before {
  content: '\f434';
}
.fa-basketball-hoop:before {
  content: '\f435';
}
.fa-bat:before {
  content: '\f6b5';
}
.fa-bath:before,
.fa-bathtub:before {
  content: '\f2cd';
}
.fa-battery-bolt:before {
  content: '\f376';
}
.fa-battery-0:before,
.fa-battery-empty:before {
  content: '\f244';
}
.fa-battery-exclamation:before {
  content: '\e0bc';
}
.fa-battery-5:before,
.fa-battery-full:before,
.fa-battery:before {
  content: '\f240';
}
.fa-battery-3:before,
.fa-battery-half:before {
  content: '\f242';
}
.fa-battery-1:before,
.fa-battery-low:before {
  content: '\e0bd';
}
.fa-battery-2:before,
.fa-battery-quarter:before {
  content: '\f243';
}
.fa-battery-slash:before {
  content: '\f377';
}
.fa-battery-4:before,
.fa-battery-three-quarters:before {
  content: '\f241';
}
.fa-bed:before {
  content: '\f236';
}
.fa-bed-bunk:before {
  content: '\f8f8';
}
.fa-bed-empty:before {
  content: '\f8f9';
}
.fa-bed-alt:before,
.fa-bed-front:before {
  content: '\f8f7';
}
.fa-bed-pulse:before,
.fa-procedures:before {
  content: '\f487';
}
.fa-bee:before {
  content: '\e0be';
}
.fa-beer-foam:before,
.fa-beer-mug:before {
  content: '\e0bf';
}
.fa-beer-mug-empty:before,
.fa-beer:before {
  content: '\f0fc';
}
.fa-bell:before {
  content: '\f0f3';
}
.fa-bell-concierge:before,
.fa-concierge-bell:before {
  content: '\f562';
}
.fa-bell-exclamation:before {
  content: '\f848';
}
.fa-bell-on:before {
  content: '\f8fa';
}
.fa-bell-plus:before {
  content: '\f849';
}
.fa-bell-school:before {
  content: '\f5d5';
}
.fa-bell-school-slash:before {
  content: '\f5d6';
}
.fa-bell-slash:before {
  content: '\f1f6';
}
.fa-bells:before {
  content: '\f77f';
}
.fa-bezier-curve:before {
  content: '\f55b';
}
.fa-bicycle:before {
  content: '\f206';
}
.fa-binoculars:before {
  content: '\f1e5';
}
.fa-biohazard:before {
  content: '\f780';
}
.fa-bitcoin-sign:before {
  content: '\e0c0';
}
.fa-blanket:before {
  content: '\f498';
}
.fa-blender:before {
  content: '\f517';
}
.fa-blender-phone:before {
  content: '\f6b6';
}
.fa-blinds:before {
  content: '\f8fb';
}
.fa-blinds-open:before {
  content: '\f8fc';
}
.fa-blinds-raised:before {
  content: '\f8fd';
}
.fa-block-quote:before {
  content: '\e0c1';
}
.fa-blog:before {
  content: '\f781';
}
.fa-bold:before {
  content: '\f032';
}
.fa-bolt:before,
.fa-flash:before {
  content: '\f0e7';
}
.fa-bolt-auto:before {
  content: '\e0c2';
}
.fa-bolt-lightning:before {
  content: '\e0c3';
}
.fa-bolt-slash:before {
  content: '\e0c4';
}
.fa-bomb:before {
  content: '\f1e2';
}
.fa-bone:before {
  content: '\f5d7';
}
.fa-bone-break:before {
  content: '\f5d8';
}
.fa-bong:before {
  content: '\f55c';
}
.fa-book:before {
  content: '\f02d';
}
.fa-book-arrow-right:before {
  content: '\e0c5';
}
.fa-book-arrow-up:before {
  content: '\e0c6';
}
.fa-atlas:before,
.fa-book-atlas:before {
  content: '\f558';
}
.fa-bible:before,
.fa-book-bible:before {
  content: '\f647';
}
.fa-book-alt:before,
.fa-book-blank:before {
  content: '\f5d9';
}
.fa-book-bookmark:before {
  content: '\e0c7';
}
.fa-book-circle-arrow-right:before {
  content: '\e0c8';
}
.fa-book-circle-arrow-up:before {
  content: '\e0c9';
}
.fa-book-copy:before {
  content: '\e0ca';
}
.fa-book-font:before {
  content: '\e0cb';
}
.fa-book-heart:before {
  content: '\f499';
}
.fa-book-journal-whills:before,
.fa-journal-whills:before {
  content: '\f66a';
}
.fa-book-law:before {
  content: '\e0cc';
}
.fa-book-medical:before {
  content: '\f7e6';
}
.fa-book-open:before {
  content: '\f518';
}
.fa-book-open-alt:before,
.fa-book-open-cover:before {
  content: '\e0cd';
}
.fa-book-open-reader:before,
.fa-book-reader:before {
  content: '\f5da';
}
.fa-book-quran:before,
.fa-quran:before {
  content: '\f687';
}
.fa-book-section:before {
  content: '\e0ce';
}
.fa-book-dead:before,
.fa-book-skull:before {
  content: '\f6b7';
}
.fa-book-sparkles:before,
.fa-book-spells:before {
  content: '\f6b8';
}
.fa-book-tanakh:before,
.fa-tanakh:before {
  content: '\f827';
}
.fa-book-user:before {
  content: '\f7e7';
}
.fa-bookmark:before {
  content: '\f02e';
}
.fa-bookmark-slash:before {
  content: '\e0cf';
}
.fa-books:before {
  content: '\f5db';
}
.fa-books-medical:before {
  content: '\f7e8';
}
.fa-boombox:before {
  content: '\f8a5';
}
.fa-boot:before {
  content: '\f782';
}
.fa-booth-curtain:before {
  content: '\f734';
}
.fa-border-all:before {
  content: '\f84c';
}
.fa-border-bottom:before {
  content: '\f84d';
}
.fa-border-bottom-right:before,
.fa-border-style-alt:before {
  content: '\f854';
}
.fa-border-center-h:before {
  content: '\f89c';
}
.fa-border-center-v:before {
  content: '\f89d';
}
.fa-border-inner:before {
  content: '\f84e';
}
.fa-border-left:before {
  content: '\f84f';
}
.fa-border-none:before {
  content: '\f850';
}
.fa-border-outer:before {
  content: '\f851';
}
.fa-border-right:before {
  content: '\f852';
}
.fa-border-top:before {
  content: '\f855';
}
.fa-border-style:before,
.fa-border-top-left:before {
  content: '\f853';
}
.fa-bow-arrow:before {
  content: '\f6b9';
}
.fa-bowl-hot:before,
.fa-soup:before {
  content: '\f823';
}
.fa-bowling-ball:before {
  content: '\f436';
}
.fa-bowling-ball-pin:before {
  content: '\e0d0';
}
.fa-bowling-pins:before {
  content: '\f437';
}
.fa-box:before {
  content: '\f466';
}
.fa-archive:before,
.fa-box-archive:before {
  content: '\f187';
}
.fa-box-ballot:before {
  content: '\f735';
}
.fa-box-check:before {
  content: '\f467';
}
.fa-box-circle-check:before {
  content: '\e0d1';
}
.fa-box-dollar:before,
.fa-box-usd:before {
  content: '\f4a0';
}
.fa-box-heart:before {
  content: '\f49d';
}
.fa-box-open:before {
  content: '\f49e';
}
.fa-box-full:before,
.fa-box-open-full:before {
  content: '\f49c';
}
.fa-box-alt:before,
.fa-box-taped:before {
  content: '\f49a';
}
.fa-box-tissue:before {
  content: '\e05b';
}
.fa-boxes-alt:before,
.fa-boxes-stacked:before,
.fa-boxes:before {
  content: '\f468';
}
.fa-boxing-glove:before,
.fa-glove-boxing:before {
  content: '\f438';
}
.fa-bracket-curly-left:before,
.fa-bracket-curly:before {
  content: '\e0d2';
}
.fa-bracket-curly-right:before {
  content: '\e0d3';
}
.fa-bracket-round:before,
.fa-parenthesis:before {
  content: '\e0d4';
}
.fa-bracket-round-right:before {
  content: '\e0d5';
}
.fa-bracket-left:before,
.fa-bracket-square:before,
.fa-bracket:before {
  content: '\e0d6';
}
.fa-bracket-square-right:before {
  content: '\e0d7';
}
.fa-brackets-curly:before {
  content: '\f7ea';
}
.fa-brackets-round:before,
.fa-parentheses:before {
  content: '\e0d8';
}
.fa-brackets-square:before,
.fa-brackets:before {
  content: '\f7e9';
}
.fa-braille:before {
  content: '\f2a1';
}
.fa-brain:before {
  content: '\f5dc';
}
.fa-brain-arrow-curved-right:before,
.fa-mind-share:before {
  content: '\f677';
}
.fa-brain-circuit:before {
  content: '\e0d9';
}
.fa-brake-warning:before {
  content: '\e0da';
}
.fa-bread-loaf:before {
  content: '\f7eb';
}
.fa-bread-slice:before {
  content: '\f7ec';
}
.fa-briefcase:before {
  content: '\f0b1';
}
.fa-briefcase-arrow-right:before {
  content: '\e0db';
}
.fa-briefcase-clock:before,
.fa-business-time:before {
  content: '\f64a';
}
.fa-briefcase-medical:before {
  content: '\f469';
}
.fa-bring-forward:before {
  content: '\f856';
}
.fa-bring-front:before {
  content: '\f857';
}
.fa-broom:before {
  content: '\f51a';
}
.fa-browser:before {
  content: '\f37e';
}
.fa-browsers:before {
  content: '\e0dc';
}
.fa-brush:before {
  content: '\f55d';
}
.fa-bug:before {
  content: '\f188';
}
.fa-building:before {
  content: '\f1ad';
}
.fa-buildings:before {
  content: '\e0dd';
}
.fa-bullhorn:before {
  content: '\f0a1';
}
.fa-bullseye:before {
  content: '\f140';
}
.fa-bullseye-arrow:before {
  content: '\f648';
}
.fa-bullseye-pointer:before {
  content: '\f649';
}
.fa-burger:before,
.fa-hamburger:before {
  content: '\f805';
}
.fa-burger-cheese:before,
.fa-cheeseburger:before {
  content: '\f7f1';
}
.fa-burger-fries:before {
  content: '\e0de';
}
.fa-burger-glass:before {
  content: '\e0df';
}
.fa-burger-soda:before {
  content: '\f858';
}
.fa-burrito:before {
  content: '\f7ed';
}
.fa-bus:before {
  content: '\f207';
}
.fa-bus-school:before {
  content: '\f5dd';
}
.fa-bus-alt:before,
.fa-bus-simple:before {
  content: '\f55e';
}
.fa-c:before {
  content: '\e0e0';
}
.fa-cabinet-filing:before {
  content: '\f64b';
}
.fa-cable-car:before {
  content: '\e0e1';
}
.fa-cactus:before {
  content: '\f8a7';
}
.fa-birthday-cake:before,
.fa-cake-candles:before {
  content: '\f1fd';
}
.fa-calculator:before {
  content: '\f1ec';
}
.fa-calculator-alt:before,
.fa-calculator-simple:before {
  content: '\f64c';
}
.fa-calendar:before {
  content: '\f133';
}
.fa-calendar-arrow-down:before,
.fa-calendar-download:before {
  content: '\e0e2';
}
.fa-calendar-arrow-up:before,
.fa-calendar-upload:before {
  content: '\e0e3';
}
.fa-calendar-check:before {
  content: '\f274';
}
.fa-calendar-clock:before,
.fa-calendar-time:before {
  content: '\e0e4';
}
.fa-calendar-day:before {
  content: '\f783';
}
.fa-calendar-alt:before,
.fa-calendar-days:before {
  content: '\f073';
}
.fa-calendar-exclamation:before {
  content: '\f334';
}
.fa-calendar-heart:before {
  content: '\e0e5';
}
.fa-calendar-image:before {
  content: '\e0e6';
}
.fa-calendar-lines:before,
.fa-calendar-note:before {
  content: '\e0e7';
}
.fa-calendar-minus:before {
  content: '\f272';
}
.fa-calendar-edit:before,
.fa-calendar-pen:before {
  content: '\f333';
}
.fa-calendar-plus:before {
  content: '\f271';
}
.fa-calendar-range:before {
  content: '\e0e8';
}
.fa-calendar-star:before {
  content: '\f736';
}
.fa-calendar-week:before {
  content: '\f784';
}
.fa-calendar-times:before,
.fa-calendar-xmark:before {
  content: '\f273';
}
.fa-calendars:before {
  content: '\e0e9';
}
.fa-camcorder:before,
.fa-video-handheld:before {
  content: '\f8a8';
}
.fa-camera-alt:before,
.fa-camera:before {
  content: '\f030';
}
.fa-camera-cctv:before,
.fa-cctv:before {
  content: '\f8ac';
}
.fa-camera-movie:before {
  content: '\f8a9';
}
.fa-camera-polaroid:before {
  content: '\f8aa';
}
.fa-camera-retro:before {
  content: '\f083';
}
.fa-camera-rotate:before {
  content: '\e0ea';
}
.fa-camera-home:before,
.fa-camera-security:before {
  content: '\f8fe';
}
.fa-camera-slash:before {
  content: '\e0eb';
}
.fa-camera-viewfinder:before {
  content: '\e0ec';
}
.fa-camera-web:before,
.fa-webcam:before {
  content: '\f832';
}
.fa-camera-web-slash:before,
.fa-webcam-slash:before {
  content: '\f833';
}
.fa-campfire:before {
  content: '\f6ba';
}
.fa-campground:before {
  content: '\f6bb';
}
.fa-candle-holder:before {
  content: '\f6bc';
}
.fa-candy-cane:before {
  content: '\f786';
}
.fa-candy-corn:before {
  content: '\f6bd';
}
.fa-cannabis:before {
  content: '\f55f';
}
.fa-capsules:before {
  content: '\f46b';
}
.fa-automobile:before,
.fa-car:before {
  content: '\f1b9';
}
.fa-battery-car:before,
.fa-car-battery:before {
  content: '\f5df';
}
.fa-car-building:before {
  content: '\f859';
}
.fa-car-bump:before {
  content: '\f5e0';
}
.fa-car-bus:before {
  content: '\f85a';
}
.fa-car-crash:before {
  content: '\f5e1';
}
.fa-car-garage:before {
  content: '\f5e2';
}
.fa-car-alt:before,
.fa-car-rear:before {
  content: '\f5de';
}
.fa-car-side:before {
  content: '\f5e4';
}
.fa-car-tilt:before {
  content: '\f5e5';
}
.fa-car-wash:before {
  content: '\f5e6';
}
.fa-car-mechanic:before,
.fa-car-wrench:before {
  content: '\f5e3';
}
.fa-caravan:before {
  content: '\f8ff';
}
.fa-caravan-alt:before,
.fa-caravan-simple:before {
  content: '\e000';
}
.fa-caret-down:before {
  content: '\f0d7';
}
.fa-caret-left:before {
  content: '\f0d9';
}
.fa-caret-right:before {
  content: '\f0da';
}
.fa-caret-up:before {
  content: '\f0d8';
}
.fa-carrot:before {
  content: '\f787';
}
.fa-cars:before {
  content: '\f85b';
}
.fa-cart-arrow-down:before {
  content: '\f218';
}
.fa-cart-flatbed:before,
.fa-dolly-flatbed:before {
  content: '\f474';
}
.fa-cart-flatbed-boxes:before,
.fa-dolly-flatbed-alt:before {
  content: '\f475';
}
.fa-cart-flatbed-empty:before,
.fa-dolly-flatbed-empty:before {
  content: '\f476';
}
.fa-cart-flatbed-suitcase:before,
.fa-luggage-cart:before {
  content: '\f59d';
}
.fa-cart-plus:before {
  content: '\f217';
}
.fa-cart-shopping:before,
.fa-shopping-cart:before {
  content: '\f07a';
}
.fa-cart-shopping-fast:before {
  content: '\e0ed';
}
.fa-betamax:before,
.fa-casette-betamax:before {
  content: '\f8a4';
}
.fa-casette-vhs:before,
.fa-vhs:before {
  content: '\f8ec';
}
.fa-cash-register:before {
  content: '\f788';
}
.fa-cassette-tape:before {
  content: '\f8ab';
}
.fa-castle:before {
  content: '\e0ee';
}
.fa-cat:before {
  content: '\f6be';
}
.fa-cat-space:before {
  content: '\e001';
}
.fa-cauldron:before {
  content: '\f6bf';
}
.fa-cedi-sign:before {
  content: '\e0ef';
}
.fa-cent-sign:before {
  content: '\e0f0';
}
.fa-certificate:before {
  content: '\f0a3';
}
.fa-chair:before {
  content: '\f6c0';
}
.fa-chair-office:before {
  content: '\f6c1';
}
.fa-blackboard:before,
.fa-chalkboard:before {
  content: '\f51b';
}
.fa-chalkboard-teacher:before,
.fa-chalkboard-user:before {
  content: '\f51c';
}
.fa-champagne-glass:before,
.fa-glass-champagne:before {
  content: '\f79e';
}
.fa-champagne-glasses:before,
.fa-glass-cheers:before {
  content: '\f79f';
}
.fa-charging-station:before {
  content: '\f5e7';
}
.fa-area-chart:before,
.fa-chart-area:before {
  content: '\f1fe';
}
.fa-bar-chart:before,
.fa-chart-bar:before {
  content: '\f080';
}
.fa-chart-bullet:before {
  content: '\e0f1';
}
.fa-chart-candlestick:before {
  content: '\e0f2';
}
.fa-chart-column:before {
  content: '\e0f3';
}
.fa-chart-gantt:before {
  content: '\e0f4';
}
.fa-chart-line:before,
.fa-line-chart:before {
  content: '\f201';
}
.fa-chart-line-down:before {
  content: '\f64d';
}
.fa-chart-line-up:before {
  content: '\e0f5';
}
.fa-analytics:before,
.fa-chart-mixed:before {
  content: '\f643';
}
.fa-chart-network:before {
  content: '\f78a';
}
.fa-chart-pie:before,
.fa-pie-chart:before {
  content: '\f200';
}
.fa-chart-pie-alt:before,
.fa-chart-pie-simple:before {
  content: '\f64e';
}
.fa-chart-pyramid:before {
  content: '\e0f6';
}
.fa-chart-radar:before {
  content: '\e0f7';
}
.fa-chart-scatter:before {
  content: '\f7ee';
}
.fa-chart-scatter-3d:before {
  content: '\e0f8';
}
.fa-chart-scatter-bubble:before {
  content: '\e0f9';
}
.fa-chart-tree-map:before {
  content: '\e0fa';
}
.fa-chart-user:before,
.fa-user-chart:before {
  content: '\f6a3';
}
.fa-chart-waterfall:before {
  content: '\e0fb';
}
.fa-check:before {
  content: '\f00c';
}
.fa-check-double:before {
  content: '\f560';
}
.fa-check-to-slot:before,
.fa-vote-yea:before {
  content: '\f772';
}
.fa-cheese:before {
  content: '\f7ef';
}
.fa-cheese-swiss:before {
  content: '\f7f0';
}
.fa-cherries:before {
  content: '\e0fc';
}
.fa-chess:before {
  content: '\f439';
}
.fa-chess-bishop:before {
  content: '\f43a';
}
.fa-chess-bishop-alt:before,
.fa-chess-bishop-piece:before {
  content: '\f43b';
}
.fa-chess-board:before {
  content: '\f43c';
}
.fa-chess-clock:before {
  content: '\f43d';
}
.fa-chess-clock-alt:before,
.fa-chess-clock-flip:before {
  content: '\f43e';
}
.fa-chess-king:before {
  content: '\f43f';
}
.fa-chess-king-alt:before,
.fa-chess-king-piece:before {
  content: '\f440';
}
.fa-chess-knight:before {
  content: '\f441';
}
.fa-chess-knight-alt:before,
.fa-chess-knight-piece:before {
  content: '\f442';
}
.fa-chess-pawn:before {
  content: '\f443';
}
.fa-chess-pawn-alt:before,
.fa-chess-pawn-piece:before {
  content: '\f444';
}
.fa-chess-queen:before {
  content: '\f445';
}
.fa-chess-queen-alt:before,
.fa-chess-queen-piece:before {
  content: '\f446';
}
.fa-chess-rook:before {
  content: '\f447';
}
.fa-chess-rook-alt:before,
.fa-chess-rook-piece:before {
  content: '\f448';
}
.fa-chevron-down:before {
  content: '\f078';
}
.fa-chevron-left:before {
  content: '\f053';
}
.fa-chevron-right:before {
  content: '\f054';
}
.fa-chevron-up:before {
  content: '\f077';
}
.fa-chevron-double-down:before,
.fa-chevrons-down:before {
  content: '\f322';
}
.fa-chevron-double-left:before,
.fa-chevrons-left:before {
  content: '\f323';
}
.fa-chevron-double-right:before,
.fa-chevrons-right:before {
  content: '\f324';
}
.fa-chevron-double-up:before,
.fa-chevrons-up:before {
  content: '\f325';
}
.fa-child:before {
  content: '\f1ae';
}
.fa-chimney:before {
  content: '\f78b';
}
.fa-church:before {
  content: '\f51d';
}
.fa-circle:before {
  content: '\f111';
}
.fa-circle-0:before {
  content: '\e0fd';
}
.fa-circle-1:before {
  content: '\e0fe';
}
.fa-circle-2:before {
  content: '\e0ff';
}
.fa-circle-3:before {
  content: '\e100';
}
.fa-circle-4:before {
  content: '\e101';
}
.fa-circle-5:before {
  content: '\e102';
}
.fa-circle-6:before {
  content: '\e103';
}
.fa-circle-7:before {
  content: '\e104';
}
.fa-circle-8:before {
  content: '\e105';
}
.fa-circle-9:before {
  content: '\e106';
}
.fa-circle-a:before {
  content: '\e107';
}
.fa-circle-ampersand:before {
  content: '\e108';
}
.fa-arrow-circle-down:before,
.fa-circle-arrow-down:before {
  content: '\f0ab';
}
.fa-circle-arrow-down-left:before {
  content: '\e109';
}
.fa-circle-arrow-down-right:before {
  content: '\e10a';
}
.fa-arrow-circle-left:before,
.fa-circle-arrow-left:before {
  content: '\f0a8';
}
.fa-arrow-circle-right:before,
.fa-circle-arrow-right:before {
  content: '\f0a9';
}
.fa-arrow-circle-up:before,
.fa-circle-arrow-up:before {
  content: '\f0aa';
}
.fa-circle-arrow-up-left:before {
  content: '\e10b';
}
.fa-circle-arrow-up-right:before {
  content: '\e10c';
}
.fa-circle-b:before {
  content: '\e10d';
}
.fa-circle-bolt:before {
  content: '\e10e';
}
.fa-book-circle:before,
.fa-circle-book-open:before {
  content: '\e10f';
}
.fa-bookmark-circle:before,
.fa-circle-bookmark:before {
  content: '\e110';
}
.fa-circle-c:before {
  content: '\e111';
}
.fa-calendar-circle:before,
.fa-circle-calendar:before {
  content: '\e112';
}
.fa-camera-circle:before,
.fa-circle-camera:before {
  content: '\e113';
}
.fa-caret-circle-down:before,
.fa-circle-caret-down:before {
  content: '\f32d';
}
.fa-caret-circle-left:before,
.fa-circle-caret-left:before {
  content: '\f32e';
}
.fa-caret-circle-right:before,
.fa-circle-caret-right:before {
  content: '\f330';
}
.fa-caret-circle-up:before,
.fa-circle-caret-up:before {
  content: '\f331';
}
.fa-check-circle:before,
.fa-circle-check:before {
  content: '\f058';
}
.fa-chevron-circle-down:before,
.fa-circle-chevron-down:before {
  content: '\f13a';
}
.fa-chevron-circle-left:before,
.fa-circle-chevron-left:before {
  content: '\f137';
}
.fa-chevron-circle-right:before,
.fa-circle-chevron-right:before {
  content: '\f138';
}
.fa-chevron-circle-up:before,
.fa-circle-chevron-up:before {
  content: '\f139';
}
.fa-circle-d:before {
  content: '\e114';
}
.fa-circle-dashed:before {
  content: '\e115';
}
.fa-circle-divide:before {
  content: '\e116';
}
.fa-circle-dollar:before,
.fa-dollar-circle:before,
.fa-usd-circle:before {
  content: '\f2e8';
}
.fa-circle-dollar-to-slot:before,
.fa-donate:before {
  content: '\f4b9';
}
.fa-circle-dot:before,
.fa-dot-circle:before {
  content: '\f192';
}
.fa-arrow-alt-circle-down:before,
.fa-circle-down:before {
  content: '\f358';
}
.fa-circle-down-left:before {
  content: '\e117';
}
.fa-circle-down-right:before {
  content: '\e118';
}
.fa-circle-e:before {
  content: '\e119';
}
.fa-circle-envelope:before,
.fa-envelope-circle:before {
  content: '\e11a';
}
.fa-circle-exclamation:before,
.fa-exclamation-circle:before {
  content: '\f06a';
}
.fa-circle-exclamation-check:before {
  content: '\e11b';
}
.fa-circle-f:before {
  content: '\e11c';
}
.fa-circle-g:before {
  content: '\e11d';
}
.fa-circle-h:before,
.fa-hospital-symbol:before {
  content: '\f47e';
}
.fa-circle-half:before {
  content: '\e11e';
}
.fa-adjust:before,
.fa-circle-half-stroke:before {
  content: '\f042';
}
.fa-circle-heart:before,
.fa-heart-circle:before {
  content: '\f4c7';
}
.fa-circle-i:before {
  content: '\e11f';
}
.fa-circle-info:before,
.fa-info-circle:before {
  content: '\f05a';
}
.fa-circle-j:before {
  content: '\e120';
}
.fa-circle-k:before {
  content: '\e121';
}
.fa-circle-l:before {
  content: '\e122';
}
.fa-arrow-alt-circle-left:before,
.fa-circle-left:before {
  content: '\f359';
}
.fa-circle-location-arrow:before,
.fa-location-circle:before {
  content: '\f602';
}
.fa-circle-m:before {
  content: '\e123';
}
.fa-circle-microphone:before,
.fa-microphone-circle:before {
  content: '\e124';
}
.fa-circle-microphone-lines:before,
.fa-microphone-circle-alt:before {
  content: '\e125';
}
.fa-circle-minus:before,
.fa-minus-circle:before {
  content: '\f056';
}
.fa-circle-n:before {
  content: '\e126';
}
.fa-circle-notch:before {
  content: '\f1ce';
}
.fa-circle-o:before {
  content: '\e127';
}
.fa-circle-p:before {
  content: '\e128';
}
.fa-circle-parking:before,
.fa-parking-circle:before {
  content: '\f615';
}
.fa-circle-pause:before,
.fa-pause-circle:before {
  content: '\f28b';
}
.fa-circle-phone:before,
.fa-phone-circle:before {
  content: '\e129';
}
.fa-circle-phone-flip:before,
.fa-phone-circle-alt:before {
  content: '\e12a';
}
.fa-circle-phone-hangup:before,
.fa-phone-circle-down:before {
  content: '\e12b';
}
.fa-circle-play:before,
.fa-play-circle:before {
  content: '\f144';
}
.fa-circle-plus:before,
.fa-plus-circle:before {
  content: '\f055';
}
.fa-circle-q:before {
  content: '\e12c';
}
.fa-circle-quarter:before {
  content: '\e12d';
}
.fa-circle-question:before,
.fa-question-circle:before {
  content: '\f059';
}
.fa-circle-r:before {
  content: '\e12e';
}
.fa-circle-radiation:before,
.fa-radiation-alt:before {
  content: '\f7ba';
}
.fa-arrow-alt-circle-right:before,
.fa-circle-right:before {
  content: '\f35a';
}
.fa-circle-s:before {
  content: '\e12f';
}
.fa-circle-small:before {
  content: '\e130';
}
.fa-circle-sort:before,
.fa-sort-circle:before {
  content: '\e030';
}
.fa-circle-sort-down:before,
.fa-sort-circle-down:before {
  content: '\e031';
}
.fa-circle-sort-up:before,
.fa-sort-circle-up:before {
  content: '\e032';
}
.fa-circle-star:before,
.fa-star-circle:before {
  content: '\e131';
}
.fa-circle-stop:before,
.fa-stop-circle:before {
  content: '\f28d';
}
.fa-circle-t:before {
  content: '\e132';
}
.fa-circle-three-quarters:before {
  content: '\e133';
}
.fa-circle-trash:before,
.fa-trash-circle:before {
  content: '\e134';
}
.fa-circle-u:before {
  content: '\e135';
}
.fa-arrow-alt-circle-up:before,
.fa-circle-up:before {
  content: '\f35b';
}
.fa-circle-up-left:before {
  content: '\e136';
}
.fa-circle-up-right:before {
  content: '\e137';
}
.fa-circle-user:before,
.fa-user-circle:before {
  content: '\f2bd';
}
.fa-circle-v:before {
  content: '\e138';
}
.fa-circle-video:before,
.fa-video-circle:before {
  content: '\e139';
}
.fa-circle-w:before {
  content: '\e13a';
}
.fa-circle-waveform-lines:before,
.fa-waveform-circle:before {
  content: '\e13b';
}
.fa-circle-x:before {
  content: '\e13c';
}
.fa-circle-xmark:before,
.fa-times-circle:before,
.fa-xmark-circle:before {
  content: '\f057';
}
.fa-circle-y:before {
  content: '\e13d';
}
.fa-circle-z:before {
  content: '\e13e';
}
.fa-city:before {
  content: '\f64f';
}
.fa-clapperboard:before {
  content: '\e13f';
}
.fa-clapperboard-play:before {
  content: '\e140';
}
.fa-clarinet:before {
  content: '\f8ad';
}
.fa-claw-marks:before {
  content: '\f6c2';
}
.fa-clipboard:before {
  content: '\f328';
}
.fa-clipboard-check:before {
  content: '\f46c';
}
.fa-clipboard-list:before {
  content: '\f46d';
}
.fa-clipboard-list-check:before {
  content: '\f737';
}
.fa-clipboard-medical:before {
  content: '\e141';
}
.fa-clipboard-prescription:before {
  content: '\f5e8';
}
.fa-clipboard-user:before {
  content: '\f7f3';
}
.fa-clock:before {
  content: '\f017';
}
.fa-clock-desk:before {
  content: '\e142';
}
.fa-clock-rotate-left:before,
.fa-history:before {
  content: '\f1da';
}
.fa-clone:before {
  content: '\f24d';
}
.fa-closed-captioning:before {
  content: '\f20a';
}
.fa-closed-captioning-slash:before {
  content: '\e143';
}
.fa-clothes-hanger:before {
  content: '\e144';
}
.fa-cloud:before {
  content: '\f0c2';
}
.fa-cloud-arrow-down:before,
.fa-cloud-download-alt:before,
.fa-cloud-download:before {
  content: '\f0ed';
}
.fa-cloud-arrow-up:before,
.fa-cloud-upload-alt:before,
.fa-cloud-upload:before {
  content: '\f0ee';
}
.fa-cloud-bolt:before,
.fa-thunderstorm:before {
  content: '\f76c';
}
.fa-cloud-bolt-moon:before,
.fa-thunderstorm-moon:before {
  content: '\f76d';
}
.fa-cloud-bolt-sun:before,
.fa-thunderstorm-sun:before {
  content: '\f76e';
}
.fa-cloud-drizzle:before {
  content: '\f738';
}
.fa-cloud-fog:before,
.fa-fog:before {
  content: '\f74e';
}
.fa-cloud-hail:before {
  content: '\f739';
}
.fa-cloud-hail-mixed:before {
  content: '\f73a';
}
.fa-cloud-meatball:before {
  content: '\f73b';
}
.fa-cloud-moon:before {
  content: '\f6c3';
}
.fa-cloud-moon-rain:before {
  content: '\f73c';
}
.fa-cloud-music:before {
  content: '\f8ae';
}
.fa-cloud-rain:before {
  content: '\f73d';
}
.fa-cloud-rainbow:before {
  content: '\f73e';
}
.fa-cloud-showers:before {
  content: '\f73f';
}
.fa-cloud-showers-heavy:before {
  content: '\f740';
}
.fa-cloud-slash:before {
  content: '\e145';
}
.fa-cloud-sleet:before {
  content: '\f741';
}
.fa-cloud-snow:before {
  content: '\f742';
}
.fa-cloud-sun:before {
  content: '\f6c4';
}
.fa-cloud-sun-rain:before {
  content: '\f743';
}
.fa-cloud-word:before {
  content: '\e146';
}
.fa-clouds:before {
  content: '\f744';
}
.fa-clouds-moon:before {
  content: '\f745';
}
.fa-clouds-sun:before {
  content: '\f746';
}
.fa-clover:before {
  content: '\e147';
}
.fa-club:before {
  content: '\f327';
}
.fa-code:before {
  content: '\f121';
}
.fa-code-branch:before {
  content: '\f126';
}
.fa-code-commit:before {
  content: '\f386';
}
.fa-code-compare:before {
  content: '\e148';
}
.fa-code-fork:before {
  content: '\e149';
}
.fa-code-merge:before {
  content: '\f387';
}
.fa-code-pull-request:before {
  content: '\e14a';
}
.fa-code-simple:before {
  content: '\e14b';
}
.fa-coffee-bean:before {
  content: '\e14c';
}
.fa-coffee-beans:before {
  content: '\e14d';
}
.fa-coffee-pot:before {
  content: '\e002';
}
.fa-coffin:before {
  content: '\f6c6';
}
.fa-coffin-cross:before {
  content: '\e051';
}
.fa-coin:before {
  content: '\f85c';
}
.fa-coins:before {
  content: '\f51e';
}
.fa-colon-sign:before {
  content: '\e14e';
}
.fa-comet:before {
  content: '\e003';
}
.fa-command:before {
  content: '\e14f';
}
.fa-comment:before {
  content: '\f075';
}
.fa-comment-arrow-down:before {
  content: '\e150';
}
.fa-comment-arrow-up:before {
  content: '\e151';
}
.fa-comment-arrow-up-right:before {
  content: '\e152';
}
.fa-comment-captions:before {
  content: '\e153';
}
.fa-comment-check:before {
  content: '\f4ac';
}
.fa-comment-code:before {
  content: '\e154';
}
.fa-comment-dollar:before {
  content: '\f651';
}
.fa-comment-dots:before,
.fa-commenting:before {
  content: '\f4ad';
}
.fa-comment-exclamation:before {
  content: '\f4af';
}
.fa-comment-image:before {
  content: '\e155';
}
.fa-comment-lines:before {
  content: '\f4b0';
}
.fa-comment-medical:before {
  content: '\f7f5';
}
.fa-comment-middle:before {
  content: '\e156';
}
.fa-comment-middle-top:before {
  content: '\e157';
}
.fa-comment-minus:before {
  content: '\f4b1';
}
.fa-comment-music:before {
  content: '\f8b0';
}
.fa-comment-edit:before,
.fa-comment-pen:before {
  content: '\f4ae';
}
.fa-comment-plus:before {
  content: '\f4b2';
}
.fa-comment-question:before {
  content: '\e158';
}
.fa-comment-quote:before {
  content: '\e159';
}
.fa-comment-slash:before {
  content: '\f4b3';
}
.fa-comment-smile:before {
  content: '\f4b4';
}
.fa-comment-sms:before,
.fa-sms:before {
  content: '\f7cd';
}
.fa-comment-text:before {
  content: '\e15a';
}
.fa-comment-times:before,
.fa-comment-xmark:before {
  content: '\f4b5';
}
.fa-comments:before {
  content: '\f086';
}
.fa-comments-dollar:before {
  content: '\f653';
}
.fa-comments-question:before {
  content: '\e15b';
}
.fa-comments-question-check:before {
  content: '\e15c';
}
.fa-compact-disc:before {
  content: '\f51f';
}
.fa-compass:before {
  content: '\f14e';
}
.fa-compass-drafting:before,
.fa-drafting-compass:before {
  content: '\f568';
}
.fa-compass-slash:before {
  content: '\f5e9';
}
.fa-compress:before {
  content: '\f066';
}
.fa-compress-wide:before {
  content: '\f326';
}
.fa-computer-classic:before {
  content: '\f8b1';
}
.fa-computer-mouse:before,
.fa-mouse:before {
  content: '\f8cc';
}
.fa-computer-mouse-scrollwheel:before,
.fa-mouse-alt:before {
  content: '\f8cd';
}
.fa-computer-speaker:before {
  content: '\f8b2';
}
.fa-container-storage:before {
  content: '\f4b7';
}
.fa-conveyor-belt:before {
  content: '\f46e';
}
.fa-conveyor-belt-alt:before,
.fa-conveyor-belt-boxes:before {
  content: '\f46f';
}
.fa-conveyor-belt-empty:before {
  content: '\e15d';
}
.fa-cookie:before {
  content: '\f563';
}
.fa-cookie-bite:before {
  content: '\f564';
}
.fa-copy:before {
  content: '\f0c5';
}
.fa-copyright:before {
  content: '\f1f9';
}
.fa-corn:before {
  content: '\f6c7';
}
.fa-couch:before {
  content: '\f4b8';
}
.fa-cow:before {
  content: '\f6c8';
}
.fa-cowbell:before {
  content: '\f8b3';
}
.fa-cowbell-circle-plus:before,
.fa-cowbell-more:before {
  content: '\f8b4';
}
.fa-credit-card-alt:before,
.fa-credit-card:before {
  content: '\f09d';
}
.fa-credit-card-blank:before {
  content: '\f389';
}
.fa-credit-card-front:before {
  content: '\f38a';
}
.fa-cricket-bat-ball:before,
.fa-cricket:before {
  content: '\f449';
}
.fa-croissant:before {
  content: '\f7f6';
}
.fa-crop:before {
  content: '\f125';
}
.fa-crop-alt:before,
.fa-crop-simple:before {
  content: '\f565';
}
.fa-cross:before {
  content: '\f654';
}
.fa-crosshairs:before {
  content: '\f05b';
}
.fa-crow:before {
  content: '\f520';
}
.fa-crown:before {
  content: '\f521';
}
.fa-crutch:before {
  content: '\f7f7';
}
.fa-crutches:before {
  content: '\f7f8';
}
.fa-cruzeiro-sign:before {
  content: '\e15e';
}
.fa-cube:before {
  content: '\f1b2';
}
.fa-cubes:before {
  content: '\f1b3';
}
.fa-coffee-togo:before,
.fa-cup-togo:before {
  content: '\f6c5';
}
.fa-curling-stone:before,
.fa-curling:before {
  content: '\f44a';
}
.fa-d:before {
  content: '\e15f';
}
.fa-dagger:before {
  content: '\f6cb';
}
.fa-database:before {
  content: '\f1c0';
}
.fa-deer:before {
  content: '\f78e';
}
.fa-deer-rudolph:before {
  content: '\f78f';
}
.fa-backspace:before,
.fa-delete-left:before {
  content: '\f55a';
}
.fa-delete-right:before {
  content: '\e160';
}
.fa-democrat:before {
  content: '\f747';
}
.fa-desktop-alt:before,
.fa-desktop:before {
  content: '\f108';
}
.fa-desktop-arrow-down:before {
  content: '\e161';
}
.fa-dharmachakra:before {
  content: '\f655';
}
.fa-diagram-lean-canvas:before {
  content: '\e162';
}
.fa-diagram-nested:before {
  content: '\e163';
}
.fa-diagram-project:before,
.fa-project-diagram:before {
  content: '\f542';
}
.fa-diagram-sankey:before {
  content: '\e164';
}
.fa-diagram-stankey:before {
  content: '\e165';
}
.fa-diagram-venn:before {
  content: '\e166';
}
.fa-dial-med-high:before,
.fa-dial:before {
  content: '\e167';
}
.fa-dial-high:before {
  content: '\e168';
}
.fa-dial-low:before {
  content: '\e169';
}
.fa-dial-max:before {
  content: '\e16a';
}
.fa-dial-med:before {
  content: '\e16b';
}
.fa-dial-med-low:before {
  content: '\e16c';
}
.fa-dial-min:before {
  content: '\e16d';
}
.fa-diamond:before {
  content: '\f219';
}
.fa-diamond-turn-right:before,
.fa-directions:before {
  content: '\f5eb';
}
.fa-dice:before {
  content: '\f522';
}
.fa-dice-d10:before {
  content: '\f6cd';
}
.fa-dice-d12:before {
  content: '\f6ce';
}
.fa-dice-d20:before {
  content: '\f6cf';
}
.fa-dice-d4:before {
  content: '\f6d0';
}
.fa-dice-d6:before {
  content: '\f6d1';
}
.fa-dice-d8:before {
  content: '\f6d2';
}
.fa-dice-five:before {
  content: '\f523';
}
.fa-dice-four:before {
  content: '\f524';
}
.fa-dice-one:before {
  content: '\f525';
}
.fa-dice-six:before {
  content: '\f526';
}
.fa-dice-three:before {
  content: '\f527';
}
.fa-dice-two:before {
  content: '\f528';
}
.fa-diploma:before,
.fa-scroll-ribbon:before {
  content: '\f5ea';
}
.fa-disc-drive:before {
  content: '\f8b5';
}
.fa-disease:before {
  content: '\f7fa';
}
.fa-display:before {
  content: '\e16e';
}
.fa-display-arrow-down:before {
  content: '\e16f';
}
.fa-desktop-code:before,
.fa-display-code:before {
  content: '\e170';
}
.fa-desktop-medical:before,
.fa-display-medical:before {
  content: '\e171';
}
.fa-desktop-slash:before,
.fa-display-slash:before {
  content: '\e172';
}
.fa-divide:before {
  content: '\f529';
}
.fa-dna:before {
  content: '\f471';
}
.fa-do-not-enter:before {
  content: '\f5ec';
}
.fa-dog:before {
  content: '\f6d3';
}
.fa-dog-leashed:before {
  content: '\f6d4';
}
.fa-dollar-sign:before,
.fa-dollar:before,
.fa-usd:before {
  content: '\f155';
}
.fa-dolly-box:before,
.fa-dolly:before {
  content: '\f472';
}
.fa-dolly-empty:before {
  content: '\f473';
}
.fa-dolphin:before {
  content: '\e173';
}
.fa-dong-sign:before {
  content: '\e174';
}
.fa-door-closed:before {
  content: '\f52a';
}
.fa-door-open:before {
  content: '\f52b';
}
.fa-dove:before {
  content: '\f4ba';
}
.fa-arrow-alt-down:before,
.fa-down:before {
  content: '\f354';
}
.fa-arrow-alt-from-top:before,
.fa-down-from-line:before {
  content: '\f349';
}
.fa-down-left:before {
  content: '\e175';
}
.fa-compress-alt:before,
.fa-down-left-and-up-right-to-center:before {
  content: '\f422';
}
.fa-down-long:before,
.fa-long-arrow-alt-down:before {
  content: '\f309';
}
.fa-down-right:before {
  content: '\e176';
}
.fa-arrow-alt-to-bottom:before,
.fa-down-to-line:before {
  content: '\f34a';
}
.fa-download:before {
  content: '\f019';
}
.fa-dragon:before {
  content: '\f6d5';
}
.fa-draw-circle:before {
  content: '\f5ed';
}
.fa-draw-polygon:before {
  content: '\f5ee';
}
.fa-draw-square:before {
  content: '\f5ef';
}
.fa-dreidel:before {
  content: '\f792';
}
.fa-drone:before {
  content: '\f85f';
}
.fa-drone-alt:before,
.fa-drone-front:before {
  content: '\f860';
}
.fa-droplet:before,
.fa-tint:before {
  content: '\f043';
}
.fa-dewpoint:before,
.fa-droplet-degree:before {
  content: '\f748';
}
.fa-droplet-percent:before,
.fa-humidity:before {
  content: '\f750';
}
.fa-droplet-slash:before,
.fa-tint-slash:before {
  content: '\f5c7';
}
.fa-drum:before {
  content: '\f569';
}
.fa-drum-steelpan:before {
  content: '\f56a';
}
.fa-drumstick:before {
  content: '\f6d6';
}
.fa-drumstick-bite:before {
  content: '\f6d7';
}
.fa-dryer:before {
  content: '\f861';
}
.fa-dryer-alt:before,
.fa-dryer-heat:before {
  content: '\f862';
}
.fa-duck:before {
  content: '\f6d8';
}
.fa-dumbbell:before {
  content: '\f44b';
}
.fa-dumpster:before {
  content: '\f793';
}
.fa-dumpster-fire:before {
  content: '\f794';
}
.fa-dungeon:before {
  content: '\f6d9';
}
.fa-e:before {
  content: '\e177';
}
.fa-ear:before {
  content: '\f5f0';
}
.fa-deaf:before,
.fa-deafness:before,
.fa-ear-deaf:before,
.fa-hard-of-hearing:before {
  content: '\f2a4';
}
.fa-assistive-listening-systems:before,
.fa-ear-listen:before {
  content: '\f2a2';
}
.fa-ear-muffs:before {
  content: '\f795';
}
.fa-earth-africa:before,
.fa-globe-africa:before {
  content: '\f57c';
}
.fa-earth-americas:before,
.fa-earth:before,
.fa-globe-americas:before {
  content: '\f57d';
}
.fa-earth-asia:before,
.fa-globe-asia:before {
  content: '\f57e';
}
.fa-earth-europa:before,
.fa-globe-europe:before {
  content: '\f7a2';
}
.fa-eclipse:before {
  content: '\f749';
}
.fa-egg:before {
  content: '\f7fb';
}
.fa-egg-fried:before {
  content: '\f7fc';
}
.fa-eggplant:before {
  content: '\e178';
}
.fa-eject:before {
  content: '\f052';
}
.fa-elephant:before {
  content: '\f6da';
}
.fa-elevator:before {
  content: '\e179';
}
.fa-ellipsis-h:before,
.fa-ellipsis:before {
  content: '\f141';
}
.fa-ellipsis-h-alt:before,
.fa-ellipsis-stroke:before {
  content: '\f39b';
}
.fa-ellipsis-stroke-vertical:before,
.fa-ellipsis-v-alt:before {
  content: '\f39c';
}
.fa-ellipsis-v:before,
.fa-ellipsis-vertical:before {
  content: '\f142';
}
.fa-empty-set:before {
  content: '\f656';
}
.fa-engine:before {
  content: '\e17a';
}
.fa-engine-exclamation:before,
.fa-engine-warning:before {
  content: '\f5f2';
}
.fa-envelope:before {
  content: '\f0e0';
}
.fa-envelope-badge:before,
.fa-envelope-dot:before {
  content: '\e17b';
}
.fa-envelope-open:before {
  content: '\f2b6';
}
.fa-envelope-open-dollar:before {
  content: '\f657';
}
.fa-envelope-open-text:before {
  content: '\f658';
}
.fa-envelopes:before {
  content: '\e17c';
}
.fa-envelopes-bulk:before,
.fa-mail-bulk:before {
  content: '\f674';
}
.fa-equals:before {
  content: '\f52c';
}
.fa-eraser:before {
  content: '\f12d';
}
.fa-escalator:before {
  content: '\e17d';
}
.fa-ethernet:before {
  content: '\f796';
}
.fa-eur:before,
.fa-euro-sign:before,
.fa-euro:before {
  content: '\f153';
}
.fa-exclamation:before {
  content: '\f12a';
}
.fa-expand:before {
  content: '\f065';
}
.fa-expand-wide:before {
  content: '\f320';
}
.fa-eye:before {
  content: '\f06e';
}
.fa-eye-dropper-empty:before,
.fa-eye-dropper:before,
.fa-eyedropper:before {
  content: '\f1fb';
}
.fa-eye-dropper-full:before {
  content: '\e17e';
}
.fa-eye-dropper-half:before {
  content: '\e17f';
}
.fa-eye-evil:before {
  content: '\f6db';
}
.fa-eye-low-vision:before,
.fa-low-vision:before {
  content: '\f2a8';
}
.fa-eye-slash:before {
  content: '\f070';
}
.fa-f:before {
  content: '\e180';
}
.fa-angry:before,
.fa-face-angry:before {
  content: '\f556';
}
.fa-dizzy:before,
.fa-face-dizzy:before {
  content: '\f567';
}
.fa-face-flushed:before,
.fa-flushed:before {
  content: '\f579';
}
.fa-face-frown:before,
.fa-frown:before {
  content: '\f119';
}
.fa-face-frown-open:before,
.fa-frown-open:before {
  content: '\f57a';
}
.fa-face-grimace:before,
.fa-grimace:before {
  content: '\f57f';
}
.fa-face-grin:before,
.fa-grin:before {
  content: '\f580';
}
.fa-face-grin-beam:before,
.fa-grin-beam:before {
  content: '\f582';
}
.fa-face-grin-beam-sweat:before,
.fa-grin-beam-sweat:before {
  content: '\f583';
}
.fa-face-grin-hearts:before,
.fa-grin-hearts:before {
  content: '\f584';
}
.fa-face-grin-squint:before,
.fa-grin-squint:before {
  content: '\f585';
}
.fa-face-grin-squint-tears:before,
.fa-grin-squint-tears:before {
  content: '\f586';
}
.fa-face-grin-stars:before,
.fa-grin-stars:before {
  content: '\f587';
}
.fa-face-grin-tears:before,
.fa-grin-tears:before {
  content: '\f588';
}
.fa-face-grin-tongue:before,
.fa-grin-tongue:before {
  content: '\f589';
}
.fa-face-grin-tongue-squint:before,
.fa-grin-tongue-squint:before {
  content: '\f58a';
}
.fa-face-grin-tongue-wink:before,
.fa-grin-tongue-wink:before {
  content: '\f58b';
}
.fa-face-grin-wide:before,
.fa-grin-alt:before {
  content: '\f581';
}
.fa-face-grin-wink:before,
.fa-grin-wink:before {
  content: '\f58c';
}
.fa-face-kiss:before,
.fa-kiss:before {
  content: '\f596';
}
.fa-face-kiss-beam:before,
.fa-kiss-beam:before {
  content: '\f597';
}
.fa-face-kiss-wink-heart:before,
.fa-kiss-wink-heart:before {
  content: '\f598';
}
.fa-face-laugh:before,
.fa-laugh:before {
  content: '\f599';
}
.fa-face-laugh-beam:before,
.fa-laugh-beam:before {
  content: '\f59a';
}
.fa-face-laugh-squint:before,
.fa-laugh-squint:before {
  content: '\f59b';
}
.fa-face-laugh-wink:before,
.fa-laugh-wink:before {
  content: '\f59c';
}
.fa-face-meh:before,
.fa-meh:before {
  content: '\f11a';
}
.fa-face-meh-blank:before,
.fa-meh-blank:before {
  content: '\f5a4';
}
.fa-face-rolling-eyes:before,
.fa-meh-rolling-eyes:before {
  content: '\f5a5';
}
.fa-face-sad-cry:before,
.fa-sad-cry:before {
  content: '\f5b3';
}
.fa-face-sad-tear:before,
.fa-sad-tear:before {
  content: '\f5b4';
}
.fa-face-smile:before,
.fa-smile:before {
  content: '\f118';
}
.fa-face-smile-beam:before,
.fa-smile-beam:before {
  content: '\f5b8';
}
.fa-face-smile-plus:before,
.fa-smile-plus:before {
  content: '\f5b9';
}
.fa-face-smile-wink:before,
.fa-smile-wink:before {
  content: '\f4da';
}
.fa-face-surprise:before,
.fa-surprise:before {
  content: '\f5c2';
}
.fa-face-tired:before,
.fa-tired:before {
  content: '\f5c8';
}
.fa-fan:before {
  content: '\f863';
}
.fa-fan-table:before {
  content: '\e004';
}
.fa-barn-silo:before,
.fa-farm:before {
  content: '\f864';
}
.fa-faucet:before {
  content: '\e005';
}
.fa-faucet-drip:before {
  content: '\e006';
}
.fa-fax:before {
  content: '\f1ac';
}
.fa-feather:before {
  content: '\f52d';
}
.fa-feather-alt:before,
.fa-feather-pointed:before {
  content: '\f56b';
}
.fa-fence:before {
  content: '\e181';
}
.fa-field-hockey-stick-ball:before,
.fa-field-hockey:before {
  content: '\f44c';
}
.fa-file:before {
  content: '\f15b';
}
.fa-file-arrow-down:before,
.fa-file-download:before {
  content: '\f56d';
}
.fa-file-arrow-up:before,
.fa-file-upload:before {
  content: '\f574';
}
.fa-file-audio:before {
  content: '\f1c7';
}
.fa-file-binary:before {
  content: '\e182';
}
.fa-file-award:before,
.fa-file-certificate:before {
  content: '\f5f3';
}
.fa-file-chart-column:before,
.fa-file-chart-line:before {
  content: '\f659';
}
.fa-file-chart-pie:before {
  content: '\f65a';
}
.fa-file-check:before {
  content: '\f316';
}
.fa-file-code:before {
  content: '\f1c9';
}
.fa-file-contract:before {
  content: '\f56c';
}
.fa-file-csv:before {
  content: '\f6dd';
}
.fa-file-dashed-line:before,
.fa-page-break:before {
  content: '\f877';
}
.fa-file-excel:before {
  content: '\f1c3';
}
.fa-file-exclamation:before {
  content: '\f31a';
}
.fa-arrow-right-from-file:before,
.fa-file-export:before {
  content: '\f56e';
}
.fa-file-heart:before {
  content: '\e183';
}
.fa-file-image:before {
  content: '\f1c5';
}
.fa-arrow-right-to-file:before,
.fa-file-import:before {
  content: '\f56f';
}
.fa-file-invoice:before {
  content: '\f570';
}
.fa-file-invoice-dollar:before {
  content: '\f571';
}
.fa-file-alt:before,
.fa-file-lines:before,
.fa-file-text:before {
  content: '\f15c';
}
.fa-file-magnifying-glass:before,
.fa-file-search:before {
  content: '\f865';
}
.fa-file-medical:before {
  content: '\f477';
}
.fa-file-minus:before {
  content: '\f318';
}
.fa-file-music:before {
  content: '\f8b6';
}
.fa-file-pdf:before {
  content: '\f1c1';
}
.fa-file-edit:before,
.fa-file-pen:before {
  content: '\f31c';
}
.fa-file-plus:before {
  content: '\f319';
}
.fa-file-plus-minus:before {
  content: '\e184';
}
.fa-file-powerpoint:before {
  content: '\f1c4';
}
.fa-file-prescription:before {
  content: '\f572';
}
.fa-file-signature:before {
  content: '\f573';
}
.fa-file-spreadsheet:before {
  content: '\f65b';
}
.fa-file-user:before {
  content: '\f65c';
}
.fa-file-video:before {
  content: '\f1c8';
}
.fa-file-medical-alt:before,
.fa-file-waveform:before {
  content: '\f478';
}
.fa-file-word:before {
  content: '\f1c2';
}
.fa-file-times:before,
.fa-file-xmark:before {
  content: '\f317';
}
.fa-file-archive:before,
.fa-file-zipper:before {
  content: '\f1c6';
}
.fa-files:before {
  content: '\e185';
}
.fa-files-medical:before {
  content: '\f7fd';
}
.fa-fill:before {
  content: '\f575';
}
.fa-fill-drip:before {
  content: '\f576';
}
.fa-film:before {
  content: '\f008';
}
.fa-film-canister:before {
  content: '\f8b7';
}
.fa-film-alt:before,
.fa-film-simple:before {
  content: '\f3a0';
}
.fa-film-slash:before {
  content: '\e186';
}
.fa-films:before {
  content: '\e187';
}
.fa-filter:before {
  content: '\f0b0';
}
.fa-filter-circle-dollar:before,
.fa-funnel-dollar:before {
  content: '\f662';
}
.fa-filter-circle-xmark:before {
  content: '\e188';
}
.fa-filter-list:before {
  content: '\e189';
}
.fa-filter-slash:before {
  content: '\e18a';
}
.fa-filters:before {
  content: '\e18b';
}
.fa-fingerprint:before {
  content: '\f577';
}
.fa-fire:before {
  content: '\f06d';
}
.fa-fire-extinguisher:before {
  content: '\f134';
}
.fa-fire-flame:before,
.fa-flame:before {
  content: '\f6df';
}
.fa-fire-alt:before,
.fa-fire-flame-curved:before {
  content: '\f7e4';
}
.fa-burn:before,
.fa-fire-flame-simple:before {
  content: '\f46a';
}
.fa-fire-hydrant:before {
  content: '\e18c';
}
.fa-fire-smoke:before {
  content: '\f74b';
}
.fa-fireplace:before {
  content: '\f79a';
}
.fa-fish:before {
  content: '\f578';
}
.fa-fish-cooked:before {
  content: '\f7fe';
}
.fa-flag:before {
  content: '\f024';
}
.fa-flag-checkered:before {
  content: '\f11e';
}
.fa-flag-pennant:before,
.fa-pennant:before {
  content: '\f456';
}
.fa-flag-alt:before,
.fa-flag-swallowtail:before {
  content: '\f74c';
}
.fa-flag-usa:before {
  content: '\f74d';
}
.fa-flashlight:before {
  content: '\f8b8';
}
.fa-flask:before {
  content: '\f0c3';
}
.fa-flask-poison:before,
.fa-flask-round-poison:before {
  content: '\f6e0';
}
.fa-flask-potion:before,
.fa-flask-round-potion:before {
  content: '\f6e1';
}
.fa-floppy-disk:before,
.fa-save:before {
  content: '\f0c7';
}
.fa-floppy-disk-circle-arrow-right:before,
.fa-save-circle-arrow-right:before {
  content: '\e18d';
}
.fa-floppy-disk-circle-xmark:before,
.fa-floppy-disk-times:before,
.fa-save-circle-xmark:before,
.fa-save-times:before {
  content: '\e18e';
}
.fa-floppy-disk-pen:before {
  content: '\e18f';
}
.fa-floppy-disks:before {
  content: '\e190';
}
.fa-florin-sign:before {
  content: '\e191';
}
.fa-flower:before {
  content: '\f7ff';
}
.fa-flower-daffodil:before {
  content: '\f800';
}
.fa-flower-tulip:before {
  content: '\f801';
}
.fa-flute:before {
  content: '\f8b9';
}
.fa-flux-capacitor:before {
  content: '\f8ba';
}
.fa-folder:before {
  content: '\f07b';
}
.fa-folder-arrow-down:before,
.fa-folder-download:before {
  content: '\e053';
}
.fa-folder-arrow-up:before,
.fa-folder-upload:before {
  content: '\e054';
}
.fa-folder-blank:before {
  content: '\e192';
}
.fa-folder-bookmark:before {
  content: '\e193';
}
.fa-folder-cog:before,
.fa-folder-gear:before {
  content: '\e194';
}
.fa-folder-grid:before {
  content: '\e195';
}
.fa-folder-heart:before {
  content: '\e196';
}
.fa-folder-image:before {
  content: '\e197';
}
.fa-folder-magnifying-glass:before,
.fa-folder-search:before {
  content: '\e198';
}
.fa-folder-medical:before {
  content: '\e199';
}
.fa-folder-minus:before {
  content: '\f65d';
}
.fa-folder-music:before {
  content: '\e19a';
}
.fa-folder-open:before {
  content: '\f07c';
}
.fa-folder-plus:before {
  content: '\f65e';
}
.fa-folder-tree:before {
  content: '\f802';
}
.fa-folder-user:before {
  content: '\e19b';
}
.fa-folder-times:before,
.fa-folder-xmark:before {
  content: '\f65f';
}
.fa-folders:before {
  content: '\f660';
}
.fa-font:before {
  content: '\f031';
}
.fa-font-case:before {
  content: '\f866';
}
.fa-football-ball:before {
  content: '\f44e';
}
.fa-football-helmet:before {
  content: '\f44f';
}
.fa-fork:before,
.fa-utensil-fork:before {
  content: '\f2e3';
}
.fa-fork-knife:before,
.fa-utensils-alt:before {
  content: '\f2e6';
}
.fa-forklift:before {
  content: '\f47a';
}
.fa-forward:before {
  content: '\f04e';
}
.fa-fast-forward:before,
.fa-forward-fast:before {
  content: '\f050';
}
.fa-forward-step:before,
.fa-step-forward:before {
  content: '\f051';
}
.fa-franc-sign:before {
  content: '\e19c';
}
.fa-french-fries:before {
  content: '\f803';
}
.fa-frog:before {
  content: '\f52e';
}
.fa-function:before {
  content: '\f661';
}
.fa-futbol-ball:before,
.fa-futbol:before,
.fa-soccer-ball:before {
  content: '\f1e3';
}
.fa-g:before {
  content: '\e19d';
}
.fa-galaxy:before {
  content: '\e008';
}
.fa-game-board:before {
  content: '\f867';
}
.fa-game-board-alt:before,
.fa-game-board-simple:before {
  content: '\f868';
}
.fa-game-console-handheld:before {
  content: '\f8bb';
}
.fa-gamepad:before {
  content: '\f11b';
}
.fa-gamepad-alt:before,
.fa-gamepad-modern:before {
  content: '\f8bc';
}
.fa-garage:before {
  content: '\e009';
}
.fa-garage-car:before {
  content: '\e00a';
}
.fa-garage-open:before {
  content: '\e00b';
}
.fa-gas-pump:before {
  content: '\f52f';
}
.fa-gas-pump-slash:before {
  content: '\f5f4';
}
.fa-dashboard:before,
.fa-gauge-high:before,
.fa-gauge:before,
.fa-tachometer-alt-fast:before,
.fa-tachometer-alt:before {
  content: '\f625';
}
.fa-gauge-low:before,
.fa-tachometer-alt-slow:before {
  content: '\f627';
}
.fa-gauge-max:before,
.fa-tachometer-alt-fastest:before {
  content: '\f626';
}
.fa-gauge-med:before,
.fa-tachometer-alt-average:before {
  content: '\f624';
}
.fa-gauge-min:before,
.fa-tachometer-alt-slowest:before {
  content: '\f628';
}
.fa-gauge-simple-high:before,
.fa-gauge-simple:before,
.fa-tachometer:before {
  content: '\f62a';
}
.fa-gauge-simple-low:before,
.fa-tachometer-slow:before {
  content: '\f62c';
}
.fa-gauge-simple-max:before,
.fa-tachometer-fastest:before {
  content: '\f62b';
}
.fa-gauge-simple-med:before,
.fa-tachometer-average:before {
  content: '\f629';
}
.fa-gauge-simple-min:before,
.fa-tachometer-slowest:before {
  content: '\f62d';
}
.fa-gavel:before,
.fa-legal:before {
  content: '\f0e3';
}
.fa-cog:before,
.fa-gear:before {
  content: '\f013';
}
.fa-cogs:before,
.fa-gears:before {
  content: '\f085';
}
.fa-gem:before {
  content: '\f3a5';
}
.fa-genderless:before {
  content: '\f22d';
}
.fa-ghost:before {
  content: '\f6e2';
}
.fa-gif:before {
  content: '\e19e';
}
.fa-gift:before {
  content: '\f06b';
}
.fa-gift-card:before {
  content: '\f663';
}
.fa-gifts:before {
  content: '\f79c';
}
.fa-gingerbread-man:before {
  content: '\f79d';
}
.fa-glass:before {
  content: '\f804';
}
.fa-glass-citrus:before {
  content: '\f869';
}
.fa-glass-empty:before {
  content: '\e19f';
}
.fa-glass-half-empty:before,
.fa-glass-half-full:before,
.fa-glass-half:before {
  content: '\e1a0';
}
.fa-glasses:before {
  content: '\f530';
}
.fa-glasses-alt:before,
.fa-glasses-round:before {
  content: '\f5f5';
}
.fa-globe:before {
  content: '\f0ac';
}
.fa-globe-snow:before {
  content: '\f7a3';
}
.fa-globe-stand:before {
  content: '\f5f6';
}
.fa-golf-ball-tee:before,
.fa-golf-ball:before {
  content: '\f450';
}
.fa-golf-club:before {
  content: '\f451';
}
.fa-gopuram:before {
  content: '\f664';
}
.fa-graduation-cap:before,
.fa-mortar-board:before {
  content: '\f19d';
}
.fa-gramophone:before {
  content: '\f8bd';
}
.fa-grate:before {
  content: '\e1a1';
}
.fa-grate-droplet:before {
  content: '\e1a2';
}
.fa-greater-than:before {
  content: '\f531';
}
.fa-greater-than-equal:before {
  content: '\f532';
}
.fa-grid-3:before,
.fa-grid:before {
  content: '\e1a3';
}
.fa-grid-2:before {
  content: '\e1a4';
}
.fa-grid-2-plus:before {
  content: '\e1a5';
}
.fa-grid-4:before {
  content: '\e1a6';
}
.fa-grid-5:before {
  content: '\e1a7';
}
.fa-grip-horizontal:before,
.fa-grip:before {
  content: '\f58d';
}
.fa-grip-lines:before {
  content: '\f7a4';
}
.fa-grip-lines-vertical:before {
  content: '\f7a5';
}
.fa-grip-vertical:before {
  content: '\f58e';
}
.fa-guarani-sign:before {
  content: '\e1a8';
}
.fa-guitar:before {
  content: '\f7a6';
}
.fa-guitar-electric:before {
  content: '\f8be';
}
.fa-guitars:before {
  content: '\f8bf';
}
.fa-gun:before {
  content: '\e1a9';
}
.fa-gun-slash:before {
  content: '\e1aa';
}
.fa-gun-squirt:before {
  content: '\e1ab';
}
.fa-h:before {
  content: '\e1ac';
}
.fa-h1:before {
  content: '\f313';
}
.fa-h2:before {
  content: '\f314';
}
.fa-h3:before {
  content: '\f315';
}
.fa-h4:before {
  content: '\f86a';
}
.fa-hammer:before {
  content: '\f6e3';
}
.fa-hammer-war:before {
  content: '\f6e4';
}
.fa-hamsa:before {
  content: '\f665';
}
.fa-hand-back-point-down:before {
  content: '\e1ad';
}
.fa-hand-back-point-left:before {
  content: '\e1ae';
}
.fa-hand-back-point-right:before {
  content: '\e1af';
}
.fa-hand-back-point-up:before {
  content: '\e1b0';
}
.fa-allergies:before,
.fa-hand-dots:before {
  content: '\f461';
}
.fa-hand-fingers-crossed:before {
  content: '\e1b1';
}
.fa-fist-raised:before,
.fa-hand-fist:before {
  content: '\f6de';
}
.fa-hand-heart:before {
  content: '\f4bc';
}
.fa-hand-holding:before {
  content: '\f4bd';
}
.fa-hand-holding-box:before {
  content: '\f47b';
}
.fa-hand-holding-dollar:before,
.fa-hand-holding-usd:before {
  content: '\f4c0';
}
.fa-hand-holding-droplet:before,
.fa-hand-holding-water:before {
  content: '\f4c1';
}
.fa-hand-holding-heart:before {
  content: '\f4be';
}
.fa-hand-holding-magic:before {
  content: '\f6e5';
}
.fa-hand-holding-medical:before {
  content: '\e05c';
}
.fa-hand-holding-seedling:before {
  content: '\f4bf';
}
.fa-hand-holding-skull:before {
  content: '\e1b2';
}
.fa-hand-lizard:before {
  content: '\f258';
}
.fa-hand-love:before {
  content: '\e1b3';
}
.fa-hand-middle-finger:before {
  content: '\f806';
}
.fa-hand-paper:before {
  content: '\f256';
}
.fa-hand-peace:before {
  content: '\f25b';
}
.fa-hand-point-down:before {
  content: '\f0a7';
}
.fa-hand-point-left:before {
  content: '\f0a5';
}
.fa-hand-point-right:before {
  content: '\f0a4';
}
.fa-hand-point-up:before {
  content: '\f0a6';
}
.fa-hand-pointer:before {
  content: '\f25a';
}
.fa-hand-rock:before {
  content: '\f255';
}
.fa-hand-scissors:before {
  content: '\f257';
}
.fa-hand-sparkles:before {
  content: '\e05d';
}
.fa-hand-spock:before {
  content: '\f259';
}
.fa-hand-wave:before {
  content: '\e1b4';
}
.fa-hands:before,
.fa-sign-language:before,
.fa-signing:before {
  content: '\f2a7';
}
.fa-american-sign-language-interpreting:before,
.fa-asl-interpreting:before,
.fa-hands-american-sign-language-interpreting:before,
.fa-hands-asl-interpreting:before {
  content: '\f2a3';
}
.fa-hands-bubbles:before,
.fa-hands-wash:before {
  content: '\e05e';
}
.fa-hands-clapping:before {
  content: '\e1b5';
}
.fa-hands-holding:before {
  content: '\f4c2';
}
.fa-hand-receiving:before,
.fa-hands-holding-diamond:before {
  content: '\f47c';
}
.fa-hands-holding-dollar:before,
.fa-hands-usd:before {
  content: '\f4c5';
}
.fa-hands-heart:before,
.fa-hands-holding-heart:before {
  content: '\f4c3';
}
.fa-hands-horns:before {
  content: '\e1b6';
}
.fa-hands-praying:before,
.fa-praying-hands:before {
  content: '\f684';
}
.fa-handshake:before {
  content: '\f2b5';
}
.fa-hands-helping:before,
.fa-handshake-angle:before {
  content: '\f4c4';
}
.fa-handshake-alt:before,
.fa-handshake-simple:before {
  content: '\f4c6';
}
.fa-handshake-alt-slash:before,
.fa-handshake-simple-slash:before {
  content: '\e05f';
}
.fa-handshake-slash:before {
  content: '\e060';
}
.fa-hanukiah:before {
  content: '\f6e6';
}
.fa-hard-drive:before,
.fa-hdd:before {
  content: '\f0a0';
}
.fa-hashtag:before {
  content: '\f292';
}
.fa-hat-chef:before {
  content: '\f86b';
}
.fa-hat-cowboy:before {
  content: '\f8c0';
}
.fa-hat-cowboy-side:before {
  content: '\f8c1';
}
.fa-hat-santa:before {
  content: '\f7a7';
}
.fa-hat-winter:before {
  content: '\f7a8';
}
.fa-hat-witch:before {
  content: '\f6e7';
}
.fa-hat-wizard:before {
  content: '\f6e8';
}
.fa-head-side:before {
  content: '\f6e9';
}
.fa-head-side-brain:before {
  content: '\f808';
}
.fa-head-side-cough:before {
  content: '\e061';
}
.fa-head-side-cough-slash:before {
  content: '\e062';
}
.fa-head-side-goggles:before,
.fa-head-vr:before {
  content: '\f6ea';
}
.fa-head-side-headphones:before {
  content: '\f8c2';
}
.fa-head-side-heart:before {
  content: '\e1b7';
}
.fa-head-side-mask:before {
  content: '\e063';
}
.fa-head-side-medical:before {
  content: '\f809';
}
.fa-head-side-virus:before {
  content: '\e064';
}
.fa-header:before,
.fa-heading:before {
  content: '\f1dc';
}
.fa-headphones:before {
  content: '\f025';
}
.fa-headphones-alt:before,
.fa-headphones-simple:before {
  content: '\f58f';
}
.fa-headset:before {
  content: '\f590';
}
.fa-heart:before {
  content: '\f004';
}
.fa-heart-broken:before,
.fa-heart-crack:before {
  content: '\f7a9';
}
.fa-heart-half:before {
  content: '\e1b8';
}
.fa-heart-half-alt:before,
.fa-heart-half-stroke:before {
  content: '\e1b9';
}
.fa-heart-pulse:before,
.fa-heartbeat:before {
  content: '\f21e';
}
.fa-heat:before {
  content: '\e00c';
}
.fa-helicopter:before {
  content: '\f533';
}
.fa-helmet-battle:before {
  content: '\f6eb';
}
.fa-hard-hat:before,
.fa-hat-hard:before,
.fa-helmet-safety:before {
  content: '\f807';
}
.fa-hexagon:before {
  content: '\f312';
}
.fa-hexagon-divide:before {
  content: '\e1ba';
}
.fa-hexagon-minus:before,
.fa-minus-hexagon:before {
  content: '\f307';
}
.fa-hexagon-plus:before,
.fa-plus-hexagon:before {
  content: '\f300';
}
.fa-hexagon-xmark:before,
.fa-times-hexagon:before,
.fa-xmark-hexagon:before {
  content: '\f2ee';
}
.fa-high-definition:before {
  content: '\e1bb';
}
.fa-highlighter:before {
  content: '\f591';
}
.fa-highlighter-line:before {
  content: '\e1bc';
}
.fa-hippo:before {
  content: '\f6ed';
}
.fa-hockey-mask:before {
  content: '\f6ee';
}
.fa-hockey-puck:before {
  content: '\f453';
}
.fa-hockey-sticks:before {
  content: '\f454';
}
.fa-holly-berry:before {
  content: '\f7aa';
}
.fa-home-lg:before,
.fa-home:before {
  content: '\f015';
}
.fa-home-heart:before {
  content: '\f4c9';
}
.fa-home-lg-alt:before,
.fa-home-simple:before {
  content: '\f80a';
}
.fa-home-user:before {
  content: '\e1bd';
}
.fa-hood-cloak:before {
  content: '\f6ef';
}
.fa-horizontal-rule:before {
  content: '\f86c';
}
.fa-horse:before {
  content: '\f6f0';
}
.fa-horse-head:before {
  content: '\f7ab';
}
.fa-horse-saddle:before {
  content: '\f8c3';
}
.fa-hospital:before {
  content: '\f0f8';
}
.fa-hospital-user:before {
  content: '\f80d';
}
.fa-hospital-alt:before,
.fa-hospital-wide:before {
  content: '\f47d';
}
.fa-hospitals:before {
  content: '\f80e';
}
.fa-hot-tub-person:before,
.fa-hot-tub:before {
  content: '\f593';
}
.fa-hotdog:before {
  content: '\f80f';
}
.fa-hotel:before {
  content: '\f594';
}
.fa-hourglass-2:before,
.fa-hourglass-half:before,
.fa-hourglass:before {
  content: '\f254';
}
.fa-hourglass-empty:before {
  content: '\f252';
}
.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: '\f253';
}
.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: '\f251';
}
.fa-house:before {
  content: '\e00d';
}
.fa-house-building:before {
  content: '\e1be';
}
.fa-house-crack:before,
.fa-house-damage:before {
  content: '\f6f1';
}
.fa-house-day:before {
  content: '\e00e';
}
.fa-house-flood:before {
  content: '\f74f';
}
.fa-house-heart:before {
  content: '\e1bf';
}
.fa-house-laptop:before,
.fa-laptop-house:before {
  content: '\e066';
}
.fa-clinic-medical:before,
.fa-house-medical:before {
  content: '\f7f2';
}
.fa-house-night:before {
  content: '\e010';
}
.fa-house-person-depart:before,
.fa-house-person-leave:before {
  content: '\e00f';
}
.fa-house-person-arrive:before,
.fa-house-person-return:before {
  content: '\e011';
}
.fa-house-signal:before {
  content: '\e012';
}
.fa-house-tree:before {
  content: '\e1c0';
}
.fa-house-turret:before {
  content: '\e1c1';
}
.fa-house-user:before {
  content: '\e065';
}
.fa-hryvnia-sign:before,
.fa-hryvnia:before {
  content: '\f6f2';
}
.fa-hurricane:before {
  content: '\f751';
}
.fa-i:before {
  content: '\e1c2';
}
.fa-i-cursor:before {
  content: '\f246';
}
.fa-ice-cream:before {
  content: '\f810';
}
.fa-ice-skate:before {
  content: '\f7ac';
}
.fa-icicles:before {
  content: '\f7ad';
}
.fa-heart-music-camera-bolt:before,
.fa-icons:before {
  content: '\f86d';
}
.fa-id-badge:before {
  content: '\f2c1';
}
.fa-drivers-license:before,
.fa-id-card:before {
  content: '\f2c2';
}
.fa-id-card-alt:before,
.fa-id-card-clip:before {
  content: '\f47f';
}
.fa-igloo:before {
  content: '\f7ae';
}
.fa-image:before {
  content: '\f03e';
}
.fa-image-landscape:before,
.fa-landscape:before {
  content: '\e1c3';
}
.fa-image-polaroid:before {
  content: '\f8c4';
}
.fa-image-polaroid-user:before {
  content: '\e1c4';
}
.fa-image-portrait:before,
.fa-portrait:before {
  content: '\f3e0';
}
.fa-image-slash:before {
  content: '\e1c5';
}
.fa-image-user:before {
  content: '\e1c6';
}
.fa-images:before {
  content: '\f302';
}
.fa-images-user:before {
  content: '\e1c7';
}
.fa-inbox:before {
  content: '\f01c';
}
.fa-inbox-full:before {
  content: '\e1c8';
}
.fa-inbox-arrow-down:before,
.fa-inbox-in:before {
  content: '\f310';
}
.fa-inbox-arrow-up:before,
.fa-inbox-out:before {
  content: '\f311';
}
.fa-inboxes:before {
  content: '\e1c9';
}
.fa-indent:before {
  content: '\f03c';
}
.fa-indian-rupee-sign:before,
.fa-indian-rupee:before,
.fa-inr:before {
  content: '\e1ca';
}
.fa-industry:before {
  content: '\f275';
}
.fa-industry-alt:before,
.fa-industry-windows:before {
  content: '\f3b3';
}
.fa-infinity:before {
  content: '\f534';
}
.fa-info:before {
  content: '\f129';
}
.fa-inhaler:before {
  content: '\f5f9';
}
.fa-input-numeric:before {
  content: '\e1cb';
}
.fa-input-pipe:before {
  content: '\e1cc';
}
.fa-input-text:before {
  content: '\e1cd';
}
.fa-integral:before {
  content: '\f667';
}
.fa-intersection:before {
  content: '\f668';
}
.fa-island-tree-palm:before,
.fa-island-tropical:before {
  content: '\f811';
}
.fa-italic:before {
  content: '\f033';
}
.fa-j:before {
  content: '\e1ce';
}
.fa-jack-o-lantern:before {
  content: '\f30e';
}
.fa-jedi:before {
  content: '\f669';
}
.fa-fighter-jet:before,
.fa-jet-fighter:before {
  content: '\f0fb';
}
.fa-joint:before {
  content: '\f595';
}
.fa-joystick:before {
  content: '\f8c5';
}
.fa-jug:before {
  content: '\f8c6';
}
.fa-k:before {
  content: '\e1cf';
}
.fa-kaaba:before {
  content: '\f66b';
}
.fa-kazoo:before {
  content: '\f8c7';
}
.fa-kerning:before {
  content: '\f86f';
}
.fa-key:before {
  content: '\f084';
}
.fa-key-skeleton:before {
  content: '\f6f3';
}
.fa-keyboard:before {
  content: '\f11c';
}
.fa-keyboard-down:before {
  content: '\e1d0';
}
.fa-keyboard-left:before {
  content: '\e1d1';
}
.fa-keynote:before {
  content: '\f66c';
}
.fa-khanda:before {
  content: '\f66d';
}
.fa-kidneys:before {
  content: '\f5fb';
}
.fa-kip-sign:before {
  content: '\e1d2';
}
.fa-first-aid:before,
.fa-kit-medical:before {
  content: '\f479';
}
.fa-kite:before {
  content: '\f6f4';
}
.fa-kiwi-bird:before {
  content: '\f535';
}
.fa-knife:before,
.fa-utensil-knife:before {
  content: '\f2e4';
}
.fa-knife-kitchen:before {
  content: '\f6f5';
}
.fa-l:before {
  content: '\e1d3';
}
.fa-lambda:before {
  content: '\f66e';
}
.fa-lamp:before {
  content: '\f4ca';
}
.fa-lamp-desk:before {
  content: '\e014';
}
.fa-lamp-floor:before {
  content: '\e015';
}
.fa-lamp-street:before {
  content: '\e1d4';
}
.fa-landmark:before {
  content: '\f66f';
}
.fa-landmark-alt:before,
.fa-landmark-dome:before {
  content: '\f752';
}
.fa-language:before {
  content: '\f1ab';
}
.fa-laptop:before {
  content: '\f109';
}
.fa-laptop-arrow-down:before {
  content: '\e1d5';
}
.fa-laptop-code:before {
  content: '\f5fc';
}
.fa-laptop-medical:before {
  content: '\f812';
}
.fa-laptop-mobile:before,
.fa-phone-laptop:before {
  content: '\f87a';
}
.fa-laptop-slash:before {
  content: '\e1d6';
}
.fa-lari-sign:before {
  content: '\e1d7';
}
.fa-lasso:before {
  content: '\f8c8';
}
.fa-lasso-sparkles:before {
  content: '\e1d8';
}
.fa-layer-group:before,
.fa-layers:before {
  content: '\f5fd';
}
.fa-layer-minus:before,
.fa-layers-minus:before {
  content: '\f5fe';
}
.fa-layer-plus:before,
.fa-layers-plus:before {
  content: '\f5ff';
}
.fa-leaf:before {
  content: '\f06c';
}
.fa-leaf-heart:before {
  content: '\f4cb';
}
.fa-leaf-maple:before {
  content: '\f6f6';
}
.fa-leaf-oak:before {
  content: '\f6f7';
}
.fa-arrow-alt-left:before,
.fa-left:before {
  content: '\f355';
}
.fa-arrow-alt-from-right:before,
.fa-left-from-line:before {
  content: '\f348';
}
.fa-left-long:before,
.fa-long-arrow-alt-left:before {
  content: '\f30a';
}
.fa-arrows-alt-h:before,
.fa-left-right:before {
  content: '\f337';
}
.fa-arrow-alt-to-left:before,
.fa-left-to-line:before {
  content: '\f34b';
}
.fa-lemon:before {
  content: '\f094';
}
.fa-less-than:before {
  content: '\f536';
}
.fa-less-than-equal:before {
  content: '\f537';
}
.fa-life-ring:before {
  content: '\f1cd';
}
.fa-light-ceiling:before {
  content: '\e016';
}
.fa-light-switch:before {
  content: '\e017';
}
.fa-light-switch-off:before {
  content: '\e018';
}
.fa-light-switch-on:before {
  content: '\e019';
}
.fa-lightbulb:before {
  content: '\f0eb';
}
.fa-lightbulb-dollar:before {
  content: '\f670';
}
.fa-lightbulb-exclamation:before {
  content: '\f671';
}
.fa-lightbulb-on:before {
  content: '\f672';
}
.fa-lightbulb-slash:before {
  content: '\f673';
}
.fa-lights-holiday:before {
  content: '\f7b2';
}
.fa-line-columns:before {
  content: '\f870';
}
.fa-line-height:before {
  content: '\f871';
}
.fa-chain:before,
.fa-link:before {
  content: '\f0c1';
}
.fa-chain-horizontal:before,
.fa-link-horizontal:before {
  content: '\e1d9';
}
.fa-chain-horizontal-slash:before,
.fa-link-horizontal-slash:before {
  content: '\e1da';
}
.fa-link-simple:before {
  content: '\e1db';
}
.fa-link-simple-slash:before {
  content: '\e1dc';
}
.fa-chain-broken:before,
.fa-chain-slash:before,
.fa-link-slash:before,
.fa-unlink:before {
  content: '\f127';
}
.fa-lips:before {
  content: '\f600';
}
.fa-lira-sign:before {
  content: '\f195';
}
.fa-list-squares:before,
.fa-list:before {
  content: '\f03a';
}
.fa-list-check:before,
.fa-tasks:before {
  content: '\f0ae';
}
.fa-list-dropdown:before {
  content: '\e1dd';
}
.fa-list-music:before {
  content: '\f8c9';
}
.fa-list-1-2:before,
.fa-list-numeric:before,
.fa-list-ol:before {
  content: '\f0cb';
}
.fa-list-radio:before {
  content: '\e1de';
}
.fa-list-timeline:before {
  content: '\e1df';
}
.fa-list-tree:before {
  content: '\e1e0';
}
.fa-list-dots:before,
.fa-list-ul:before {
  content: '\f0ca';
}
.fa-litecoin-sign:before {
  content: '\e1e1';
}
.fa-loader:before {
  content: '\e1e2';
}
.fa-location:before,
.fa-map-marker:before {
  content: '\f041';
}
.fa-location-arrow:before {
  content: '\f124';
}
.fa-location-check:before,
.fa-map-marker-check:before {
  content: '\f606';
}
.fa-location-crosshairs:before {
  content: '\f601';
}
.fa-location-crosshairs-slash:before {
  content: '\f603';
}
.fa-location-dot:before,
.fa-map-marker-alt:before {
  content: '\f3c5';
}
.fa-location-dot-slash:before,
.fa-map-marker-alt-slash:before {
  content: '\f605';
}
.fa-location-exclamation:before,
.fa-map-marker-exclamation:before {
  content: '\f608';
}
.fa-location-minus:before,
.fa-map-marker-minus:before {
  content: '\f609';
}
.fa-location-pen:before,
.fa-map-marker-edit:before {
  content: '\f607';
}
.fa-location-plus:before,
.fa-map-marker-plus:before {
  content: '\f60a';
}
.fa-location-question:before,
.fa-map-marker-question:before {
  content: '\f60b';
}
.fa-location-slash:before,
.fa-map-marker-slash:before {
  content: '\f60c';
}
.fa-location-smile:before,
.fa-map-marker-smile:before {
  content: '\f60d';
}
.fa-location-xmark:before,
.fa-map-marker-times:before,
.fa-map-marker-xmark:before {
  content: '\f60e';
}
.fa-lock:before {
  content: '\f023';
}
.fa-lock-alt:before,
.fa-lock-keyhole:before {
  content: '\f30d';
}
.fa-lock-keyhole-open:before,
.fa-lock-open-alt:before {
  content: '\f3c2';
}
.fa-lock-open:before {
  content: '\f3c1';
}
.fa-couch-small:before,
.fa-loveseat:before {
  content: '\f4cc';
}
.fa-luchador-mask:before,
.fa-luchador:before,
.fa-mask-luchador:before {
  content: '\f455';
}
.fa-lungs:before {
  content: '\f604';
}
.fa-lungs-virus:before {
  content: '\e067';
}
.fa-m:before {
  content: '\e1e3';
}
.fa-mace:before {
  content: '\f6f8';
}
.fa-magnet:before {
  content: '\f076';
}
.fa-magnifying-glass:before,
.fa-search:before {
  content: '\f002';
}
.fa-magnifying-glass-dollar:before,
.fa-search-dollar:before {
  content: '\f688';
}
.fa-magnifying-glass-location:before,
.fa-search-location:before {
  content: '\f689';
}
.fa-magnifying-glass-minus:before,
.fa-search-minus:before {
  content: '\f010';
}
.fa-magnifying-glass-plus:before,
.fa-search-plus:before {
  content: '\f00e';
}
.fa-mailbox:before {
  content: '\f813';
}
.fa-manat-sign:before {
  content: '\e1e4';
}
.fa-mandolin:before {
  content: '\f6f9';
}
.fa-manhole:before {
  content: '\e1e5';
}
.fa-map:before {
  content: '\f279';
}
.fa-map-location:before,
.fa-map-marked:before {
  content: '\f59f';
}
.fa-map-location-dot:before,
.fa-map-marked-alt:before {
  content: '\f5a0';
}
.fa-map-pin:before {
  content: '\f276';
}
.fa-marker:before {
  content: '\f5a1';
}
.fa-mars:before {
  content: '\f222';
}
.fa-mars-double:before {
  content: '\f227';
}
.fa-mars-stroke:before {
  content: '\f229';
}
.fa-mars-stroke-h:before,
.fa-mars-stroke-right:before {
  content: '\f22b';
}
.fa-mars-stroke-up:before,
.fa-mars-stroke-v:before {
  content: '\f22a';
}
.fa-glass-martini-alt:before,
.fa-martini-glass:before {
  content: '\f57b';
}
.fa-cocktail:before,
.fa-martini-glass-citrus:before {
  content: '\f561';
}
.fa-glass-martini:before,
.fa-martini-glass-empty:before {
  content: '\f000';
}
.fa-mask:before {
  content: '\f6fa';
}
.fa-mask-face:before {
  content: '\e1e6';
}
.fa-masks-theater:before,
.fa-theater-masks:before {
  content: '\f630';
}
.fa-expand-arrows-alt:before,
.fa-maximize:before {
  content: '\f31e';
}
.fa-meat:before {
  content: '\f814';
}
.fa-medal:before {
  content: '\f5a2';
}
.fa-megaphone:before {
  content: '\f675';
}
.fa-memo:before {
  content: '\e1e7';
}
.fa-memo-circle-check:before {
  content: '\e1e8';
}
.fa-memory:before {
  content: '\f538';
}
.fa-menorah:before {
  content: '\f676';
}
.fa-mercury:before {
  content: '\f223';
}
.fa-comment-alt:before,
.fa-message:before {
  content: '\f27a';
}
.fa-comment-alt-arrow-down:before,
.fa-message-arrow-down:before {
  content: '\e1e9';
}
.fa-comment-alt-arrow-up:before,
.fa-message-arrow-up:before {
  content: '\e1ea';
}
.fa-message-arrow-up-right:before {
  content: '\e1eb';
}
.fa-comment-alt-captions:before,
.fa-message-captions:before {
  content: '\e1ec';
}
.fa-comment-alt-check:before,
.fa-message-check:before {
  content: '\f4a2';
}
.fa-message-code:before {
  content: '\e1ed';
}
.fa-comment-alt-dollar:before,
.fa-message-dollar:before {
  content: '\f650';
}
.fa-comment-alt-dots:before,
.fa-message-dots:before,
.fa-messaging:before {
  content: '\f4a3';
}
.fa-comment-alt-exclamation:before,
.fa-message-exclamation:before {
  content: '\f4a5';
}
.fa-comment-alt-image:before,
.fa-message-image:before {
  content: '\e1ee';
}
.fa-comment-alt-lines:before,
.fa-message-lines:before {
  content: '\f4a6';
}
.fa-comment-alt-medical:before,
.fa-message-medical:before {
  content: '\f7f4';
}
.fa-comment-middle-alt:before,
.fa-message-middle:before {
  content: '\e1ef';
}
.fa-comment-middle-top-alt:before,
.fa-message-middle-top:before {
  content: '\e1f0';
}
.fa-comment-alt-minus:before,
.fa-message-minus:before {
  content: '\f4a7';
}
.fa-comment-alt-music:before,
.fa-message-music:before {
  content: '\f8af';
}
.fa-comment-alt-edit:before,
.fa-message-edit:before,
.fa-message-pen:before {
  content: '\f4a4';
}
.fa-comment-alt-plus:before,
.fa-message-plus:before {
  content: '\f4a8';
}
.fa-message-question:before {
  content: '\e1f1';
}
.fa-comment-alt-quote:before,
.fa-message-quote:before {
  content: '\e1f2';
}
.fa-comment-alt-slash:before,
.fa-message-slash:before {
  content: '\f4a9';
}
.fa-comment-alt-smile:before,
.fa-message-smile:before {
  content: '\f4aa';
}
.fa-message-sms:before {
  content: '\e1f3';
}
.fa-comment-alt-text:before,
.fa-message-text:before {
  content: '\e1f4';
}
.fa-comment-alt-times:before,
.fa-message-times:before,
.fa-message-xmark:before {
  content: '\f4ab';
}
.fa-comments-alt:before,
.fa-messages:before {
  content: '\f4b6';
}
.fa-comments-alt-dollar:before,
.fa-messages-dollar:before {
  content: '\f652';
}
.fa-messages-question:before {
  content: '\e1f5';
}
.fa-meteor:before {
  content: '\f753';
}
.fa-meter:before {
  content: '\e1f6';
}
.fa-meter-bolt:before {
  content: '\e1f7';
}
.fa-meter-droplet:before {
  content: '\e1f8';
}
.fa-meter-fire:before {
  content: '\e1f9';
}
.fa-microchip:before {
  content: '\f2db';
}
.fa-microchip-ai:before {
  content: '\e1fa';
}
.fa-microphone:before {
  content: '\f130';
}
.fa-microphone-alt:before,
.fa-microphone-lines:before {
  content: '\f3c9';
}
.fa-microphone-alt-slash:before,
.fa-microphone-lines-slash:before {
  content: '\f539';
}
.fa-microphone-slash:before {
  content: '\f131';
}
.fa-microphone-stand:before {
  content: '\f8cb';
}
.fa-microscope:before {
  content: '\f610';
}
.fa-microwave:before {
  content: '\e01b';
}
.fa-mill-sign:before {
  content: '\e1fb';
}
.fa-compress-arrows-alt:before,
.fa-minimize:before {
  content: '\f78c';
}
.fa-minus:before,
.fa-subtract:before {
  content: '\f068';
}
.fa-mistletoe:before {
  content: '\f7b4';
}
.fa-mitten:before {
  content: '\f7b5';
}
.fa-mobile-android:before,
.fa-mobile-phone:before,
.fa-mobile:before {
  content: '\f3ce';
}
.fa-mobile-button:before {
  content: '\f10b';
}
.fa-mobile-iphone:before,
.fa-mobile-notch:before {
  content: '\e1fc';
}
.fa-mobile-android-alt:before,
.fa-mobile-screen:before {
  content: '\f3cf';
}
.fa-mobile-alt:before,
.fa-mobile-screen-button:before {
  content: '\f3cd';
}
.fa-money-bill:before {
  content: '\f0d6';
}
.fa-money-bill-1:before,
.fa-money-bill-alt:before {
  content: '\f3d1';
}
.fa-money-bill-1-wave:before,
.fa-money-bill-wave-alt:before {
  content: '\f53b';
}
.fa-money-bill-simple:before {
  content: '\e1fd';
}
.fa-money-bill-simple-wave:before {
  content: '\e1fe';
}
.fa-money-bill-wave:before {
  content: '\f53a';
}
.fa-money-bills:before {
  content: '\e1ff';
}
.fa-money-bills-alt:before,
.fa-money-bills-simple:before {
  content: '\e200';
}
.fa-money-check:before {
  content: '\f53c';
}
.fa-money-check-alt:before,
.fa-money-check-dollar:before {
  content: '\f53d';
}
.fa-money-check-dollar-pen:before,
.fa-money-check-edit-alt:before {
  content: '\f873';
}
.fa-money-check-edit:before,
.fa-money-check-pen:before {
  content: '\f872';
}
.fa-monitor-heart-rate:before,
.fa-monitor-waveform:before {
  content: '\f611';
}
.fa-monkey:before {
  content: '\f6fb';
}
.fa-monument:before {
  content: '\f5a6';
}
.fa-moon:before {
  content: '\f186';
}
.fa-moon-cloud:before {
  content: '\f754';
}
.fa-eclipse-alt:before,
.fa-moon-over-sun:before {
  content: '\f74a';
}
.fa-moon-stars:before {
  content: '\f755';
}
.fa-mortar-pestle:before {
  content: '\f5a7';
}
.fa-mosque:before {
  content: '\f678';
}
.fa-motorcycle:before {
  content: '\f21c';
}
.fa-mountain:before {
  content: '\f6fc';
}
.fa-mountains:before {
  content: '\f6fd';
}
.fa-mp3-player:before {
  content: '\f8ce';
}
.fa-mug:before {
  content: '\f874';
}
.fa-mug-hot:before {
  content: '\f7b6';
}
.fa-mug-marshmallows:before {
  content: '\f7b7';
}
.fa-coffee:before,
.fa-mug-saucer:before {
  content: '\f0f4';
}
.fa-mug-tea:before {
  content: '\f875';
}
.fa-mug-tea-saucer:before {
  content: '\e201';
}
.fa-music:before {
  content: '\f001';
}
.fa-music-alt:before,
.fa-music-note:before {
  content: '\f8cf';
}
.fa-music-alt-slash:before,
.fa-music-note-slash:before {
  content: '\f8d0';
}
.fa-music-slash:before {
  content: '\f8d1';
}
.fa-n:before {
  content: '\e202';
}
.fa-naira-sign:before {
  content: '\e203';
}
.fa-narwhal:before {
  content: '\f6fe';
}
.fa-network-wired:before {
  content: '\f6ff';
}
.fa-neuter:before {
  content: '\f22c';
}
.fa-newspaper:before {
  content: '\f1ea';
}
.fa-not-equal:before {
  content: '\f53e';
}
.fa-notdef:before {
  content: '\e204';
}
.fa-note:before {
  content: '\e205';
}
.fa-note-medical:before {
  content: '\e206';
}
.fa-note-sticky:before,
.fa-sticky-note:before {
  content: '\f249';
}
.fa-notes:before {
  content: '\e207';
}
.fa-notes-medical:before {
  content: '\f481';
}
.fa-o:before {
  content: '\e208';
}
.fa-object-group:before {
  content: '\f247';
}
.fa-object-ungroup:before {
  content: '\f248';
}
.fa-octagon:before {
  content: '\f306';
}
.fa-octagon-divide:before {
  content: '\e209';
}
.fa-octagon-exclamation:before {
  content: '\e20a';
}
.fa-minus-octagon:before,
.fa-octagon-minus:before {
  content: '\f308';
}
.fa-octagon-plus:before,
.fa-plus-octagon:before {
  content: '\f301';
}
.fa-octagon-xmark:before,
.fa-times-octagon:before,
.fa-xmark-octagon:before {
  content: '\f2f0';
}
.fa-oil-can:before {
  content: '\f613';
}
.fa-oil-can-drip:before {
  content: '\e20b';
}
.fa-oil-temp:before,
.fa-oil-temperature:before {
  content: '\f614';
}
.fa-om:before {
  content: '\f679';
}
.fa-omega:before {
  content: '\f67a';
}
.fa-option:before {
  content: '\e20c';
}
.fa-ornament:before {
  content: '\f7b8';
}
.fa-otter:before {
  content: '\f700';
}
.fa-dedent:before,
.fa-outdent:before {
  content: '\f03b';
}
.fa-outlet:before {
  content: '\e01c';
}
.fa-oven:before {
  content: '\e01d';
}
.fa-overline:before {
  content: '\f876';
}
.fa-p:before {
  content: '\e20d';
}
.fa-pager:before {
  content: '\f815';
}
.fa-paint-brush:before {
  content: '\f1fc';
}
.fa-paint-brush-alt:before,
.fa-paint-brush-fine:before {
  content: '\f5a9';
}
.fa-paint-roller:before {
  content: '\f5aa';
}
.fa-paintbrush-pencil:before {
  content: '\e20e';
}
.fa-palette:before {
  content: '\f53f';
}
.fa-palette-boxes:before {
  content: '\e20f';
}
.fa-pallet:before {
  content: '\f482';
}
.fa-pallet-box:before {
  content: '\e210';
}
.fa-pallet-alt:before,
.fa-pallet-boxes:before {
  content: '\f483';
}
.fa-panorama:before {
  content: '\e211';
}
.fa-paper-plane:before {
  content: '\f1d8';
}
.fa-paper-plane-alt:before,
.fa-paper-plane-top:before,
.fa-send:before {
  content: '\e212';
}
.fa-paperclip:before {
  content: '\f0c6';
}
.fa-parachute-box:before {
  content: '\f4cd';
}
.fa-paragraph:before {
  content: '\f1dd';
}
.fa-paragraph-left:before,
.fa-paragraph-rtl:before {
  content: '\f878';
}
.fa-passport:before {
  content: '\f5ab';
}
.fa-file-clipboard:before,
.fa-paste:before {
  content: '\f0ea';
}
.fa-pause:before {
  content: '\f04c';
}
.fa-paw:before {
  content: '\f1b0';
}
.fa-paw-claws:before {
  content: '\f702';
}
.fa-paw-alt:before,
.fa-paw-simple:before {
  content: '\f701';
}
.fa-peace:before {
  content: '\f67c';
}
.fa-peach:before {
  content: '\e213';
}
.fa-pear:before {
  content: '\e214';
}
.fa-pedestal:before {
  content: '\e215';
}
.fa-pegasus:before {
  content: '\f703';
}
.fa-pen:before {
  content: '\f304';
}
.fa-pen-circle:before {
  content: '\e216';
}
.fa-pen-alt:before,
.fa-pen-clip:before {
  content: '\f305';
}
.fa-pen-alt-slash:before,
.fa-pen-clip-slash:before {
  content: '\e217';
}
.fa-pen-fancy:before {
  content: '\f5ac';
}
.fa-pen-fancy-slash:before {
  content: '\e218';
}
.fa-pen-field:before {
  content: '\e219';
}
.fa-pen-line:before {
  content: '\e21a';
}
.fa-pen-nib:before {
  content: '\f5ad';
}
.fa-pen-paintbrush:before,
.fa-pencil-paintbrush:before {
  content: '\f618';
}
.fa-pen-ruler:before,
.fa-pencil-ruler:before {
  content: '\f5ae';
}
.fa-pen-slash:before {
  content: '\e21b';
}
.fa-pen-swirl:before {
  content: '\e21c';
}
.fa-edit:before,
.fa-pen-to-square:before {
  content: '\f044';
}
.fa-pencil-alt:before,
.fa-pencil:before {
  content: '\f040';
}
.fa-pencil-slash:before {
  content: '\e21d';
}
.fa-people-arrows-left-right:before,
.fa-people-arrows:before {
  content: '\e068';
}
.fa-people-carry-box:before,
.fa-people-carry:before {
  content: '\f4ce';
}
.fa-pepper-hot:before {
  content: '\f816';
}
.fa-percent:before,
.fa-percentage:before {
  content: '\f295';
}
.fa-male:before,
.fa-person:before {
  content: '\f183';
}
.fa-biking:before,
.fa-person-biking:before {
  content: '\f84a';
}
.fa-biking-mountain:before,
.fa-person-biking-mountain:before {
  content: '\f84b';
}
.fa-person-booth:before {
  content: '\f756';
}
.fa-person-carry-box:before,
.fa-person-carry:before {
  content: '\f4cf';
}
.fa-digging:before,
.fa-person-digging:before {
  content: '\f85e';
}
.fa-person-dolly:before {
  content: '\f4d0';
}
.fa-person-dolly-empty:before {
  content: '\f4d1';
}
.fa-diagnoses:before,
.fa-person-dots-from-line:before {
  content: '\f470';
}
.fa-female:before,
.fa-person-dress:before {
  content: '\f182';
}
.fa-person-dress-simple:before {
  content: '\e21e';
}
.fa-person-from-portal:before,
.fa-portal-exit:before {
  content: '\e023';
}
.fa-hiking:before,
.fa-person-hiking:before {
  content: '\f6ec';
}
.fa-person-pinball:before {
  content: '\e21f';
}
.fa-person-praying:before,
.fa-pray:before {
  content: '\f683';
}
.fa-person-running:before,
.fa-running:before {
  content: '\f70c';
}
.fa-person-seat:before {
  content: '\e220';
}
.fa-person-seat-reclined:before {
  content: '\e221';
}
.fa-person-sign:before {
  content: '\f757';
}
.fa-person-simple:before {
  content: '\e222';
}
.fa-person-skating:before,
.fa-skating:before {
  content: '\f7c5';
}
.fa-person-ski-jumping:before,
.fa-ski-jump:before {
  content: '\f7c7';
}
.fa-person-ski-lift:before,
.fa-ski-lift:before {
  content: '\f7c8';
}
.fa-person-skiing:before,
.fa-skiing:before {
  content: '\f7c9';
}
.fa-person-skiing-nordic:before,
.fa-skiing-nordic:before {
  content: '\f7ca';
}
.fa-person-sledding:before,
.fa-sledding:before {
  content: '\f7cb';
}
.fa-person-snowboarding:before,
.fa-snowboarding:before {
  content: '\f7ce';
}
.fa-person-snowmobiling:before,
.fa-snowmobile:before {
  content: '\f7d1';
}
.fa-person-swimming:before,
.fa-swimmer:before {
  content: '\f5c4';
}
.fa-person-to-portal:before,
.fa-portal-enter:before {
  content: '\e022';
}
.fa-person-walking:before,
.fa-walking:before {
  content: '\f554';
}
.fa-blind:before,
.fa-person-walking-with-cane:before {
  content: '\f29d';
}
.fa-peseta-sign:before {
  content: '\e223';
}
.fa-peso-sign:before {
  content: '\e224';
}
.fa-phone:before {
  content: '\f095';
}
.fa-phone-arrow-down-left:before,
.fa-phone-arrow-down:before,
.fa-phone-incoming:before {
  content: '\e225';
}
.fa-phone-arrow-up-right:before,
.fa-phone-arrow-up:before,
.fa-phone-outgoing:before {
  content: '\e226';
}
.fa-phone-alt:before,
.fa-phone-flip:before {
  content: '\f879';
}
.fa-phone-hangup:before {
  content: '\e227';
}
.fa-phone-missed:before {
  content: '\e228';
}
.fa-phone-office:before {
  content: '\f67d';
}
.fa-phone-plus:before {
  content: '\f4d2';
}
.fa-phone-rotary:before {
  content: '\f8d3';
}
.fa-phone-slash:before {
  content: '\f3dd';
}
.fa-phone-volume:before,
.fa-volume-control-phone:before {
  content: '\f2a0';
}
.fa-phone-xmark:before {
  content: '\e229';
}
.fa-photo-film:before,
.fa-photo-video:before {
  content: '\f87c';
}
.fa-photo-film-music:before {
  content: '\e22a';
}
.fa-pi:before {
  content: '\f67e';
}
.fa-piano:before {
  content: '\f8d4';
}
.fa-piano-keyboard:before {
  content: '\f8d5';
}
.fa-pie:before {
  content: '\f705';
}
.fa-pig:before {
  content: '\f706';
}
.fa-piggy-bank:before {
  content: '\f4d3';
}
.fa-pills:before {
  content: '\f484';
}
.fa-pinball:before {
  content: '\e22b';
}
.fa-pizza:before {
  content: '\f817';
}
.fa-pizza-slice:before {
  content: '\f818';
}
.fa-place-of-worship:before {
  content: '\f67f';
}
.fa-plane:before {
  content: '\f072';
}
.fa-plane-arrival:before {
  content: '\f5af';
}
.fa-plane-departure:before {
  content: '\f5b0';
}
.fa-plane-alt:before,
.fa-plane-engines:before {
  content: '\f3de';
}
.fa-plane-prop:before {
  content: '\e22c';
}
.fa-plane-slash:before {
  content: '\e069';
}
.fa-plane-tail:before {
  content: '\e22d';
}
.fa-plane-up:before {
  content: '\e22e';
}
.fa-plane-up-slash:before {
  content: '\e22f';
}
.fa-planet-moon:before {
  content: '\e01f';
}
.fa-planet-ringed:before {
  content: '\e020';
}
.fa-play:before {
  content: '\f04b';
}
.fa-play-pause:before {
  content: '\e230';
}
.fa-plug:before {
  content: '\f1e6';
}
.fa-add:before,
.fa-plus:before {
  content: '\f067';
}
.fa-plus-minus:before {
  content: '\e231';
}
.fa-podcast:before {
  content: '\f2ce';
}
.fa-podium:before {
  content: '\f680';
}
.fa-podium-star:before {
  content: '\f758';
}
.fa-police-box:before {
  content: '\e021';
}
.fa-poll-people:before {
  content: '\f759';
}
.fa-poo:before {
  content: '\f2fe';
}
.fa-poo-bolt:before,
.fa-poo-storm:before {
  content: '\f75a';
}
.fa-poop:before {
  content: '\f619';
}
.fa-popcorn:before {
  content: '\f819';
}
.fa-power-off:before {
  content: '\f011';
}
.fa-prescription:before {
  content: '\f5b1';
}
.fa-prescription-bottle:before {
  content: '\f485';
}
.fa-prescription-bottle-alt:before,
.fa-prescription-bottle-medical:before {
  content: '\f486';
}
.fa-presentation-screen:before,
.fa-presentation:before {
  content: '\f685';
}
.fa-print:before {
  content: '\f02f';
}
.fa-print-magnifying-glass:before,
.fa-print-search:before {
  content: '\f81a';
}
.fa-print-slash:before {
  content: '\f686';
}
.fa-projector:before {
  content: '\f8d6';
}
.fa-pump-medical:before {
  content: '\e06a';
}
.fa-pump-soap:before {
  content: '\e06b';
}
.fa-pumpkin:before {
  content: '\f707';
}
.fa-puzzle-piece:before {
  content: '\f12e';
}
.fa-puzzle-piece-alt:before,
.fa-puzzle-piece-simple:before {
  content: '\e232';
}
.fa-q:before {
  content: '\e233';
}
.fa-qrcode:before {
  content: '\f029';
}
.fa-question:before {
  content: '\f128';
}
.fa-broom-ball:before,
.fa-quidditch-broom-ball:before,
.fa-quidditch:before {
  content: '\f458';
}
.fa-quote-left:before {
  content: '\f10d';
}
.fa-quote-left-alt:before,
.fa-quote-left-round:before {
  content: '\e234';
}
.fa-quote-right:before {
  content: '\f10e';
}
.fa-quote-right-alt:before,
.fa-quote-right-round:before {
  content: '\e235';
}
.fa-r:before {
  content: '\e236';
}
.fa-rabbit:before {
  content: '\f708';
}
.fa-rabbit-fast:before,
.fa-rabbit-running:before {
  content: '\f709';
}
.fa-racquet:before {
  content: '\f45a';
}
.fa-radar:before {
  content: '\e024';
}
.fa-radiation:before {
  content: '\f7b9';
}
.fa-radio:before {
  content: '\f8d7';
}
.fa-radio-alt:before,
.fa-radio-tuner:before {
  content: '\f8d8';
}
.fa-rainbow:before {
  content: '\f75b';
}
.fa-raindrops:before {
  content: '\f75c';
}
.fa-ram:before {
  content: '\f70a';
}
.fa-ramp-loading:before {
  content: '\f4d4';
}
.fa-raygun:before {
  content: '\e025';
}
.fa-receipt:before {
  content: '\f543';
}
.fa-record-vinyl:before {
  content: '\f8d9';
}
.fa-rectangle-landscape:before,
.fa-rectangle:before {
  content: '\f2fa';
}
.fa-ad:before,
.fa-rectangle-ad:before {
  content: '\f641';
}
.fa-barcode-alt:before,
.fa-rectangle-barcode:before {
  content: '\f463';
}
.fa-list-alt:before,
.fa-rectangle-list:before {
  content: '\f022';
}
.fa-rectangle-terminal:before {
  content: '\e237';
}
.fa-rectangle-portrait:before,
.fa-rectangle-vertical:before {
  content: '\f2fb';
}
.fa-rectangle-vertical-history:before {
  content: '\e238';
}
.fa-rectangle-wide:before {
  content: '\f2fc';
}
.fa-rectangle-times:before,
.fa-rectangle-xmark:before,
.fa-times-rectangle:before,
.fa-window-close:before {
  content: '\f410';
}
.fa-recycle:before {
  content: '\f1b8';
}
.fa-refrigerator:before {
  content: '\e026';
}
.fa-registered:before {
  content: '\f25d';
}
.fa-repeat:before {
  content: '\f363';
}
.fa-repeat-1:before {
  content: '\f365';
}
.fa-mail-reply:before,
.fa-reply:before {
  content: '\f3e5';
}
.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: '\f122';
}
.fa-reply-clock:before,
.fa-reply-time:before {
  content: '\e239';
}
.fa-republican:before {
  content: '\f75e';
}
.fa-restroom:before {
  content: '\f7bd';
}
.fa-restroom-simple:before {
  content: '\e23a';
}
.fa-retweet:before {
  content: '\f079';
}
.fa-rhombus:before {
  content: '\e23b';
}
.fa-ribbon:before {
  content: '\f4d6';
}
.fa-arrow-alt-right:before,
.fa-right:before {
  content: '\f356';
}
.fa-right-from-bracket:before,
.fa-sign-out-alt:before {
  content: '\f2f5';
}
.fa-arrow-alt-from-left:before,
.fa-right-from-line:before {
  content: '\f347';
}
.fa-exchange-alt:before,
.fa-right-left:before {
  content: '\f362';
}
.fa-long-arrow-alt-right:before,
.fa-right-long:before {
  content: '\f30b';
}
.fa-right-to-bracket:before,
.fa-sign-in-alt:before {
  content: '\f2f6';
}
.fa-arrow-alt-to-right:before,
.fa-right-to-line:before {
  content: '\f34c';
}
.fa-ring:before {
  content: '\f70b';
}
.fa-rings-wedding:before {
  content: '\f81b';
}
.fa-road:before {
  content: '\f018';
}
.fa-robot:before {
  content: '\f544';
}
.fa-rocket:before {
  content: '\f135';
}
.fa-rocket-launch:before {
  content: '\e027';
}
.fa-rotate:before,
.fa-sync-alt:before {
  content: '\f2f1';
}
.fa-rotate-exclamation:before {
  content: '\e23c';
}
.fa-redo-alt:before,
.fa-rotate-forward:before {
  content: '\f2f9';
}
.fa-rotate-left:before,
.fa-undo-alt:before {
  content: '\f2ea';
}
.fa-route:before {
  content: '\f4d7';
}
.fa-route-highway:before {
  content: '\f61a';
}
.fa-route-interstate:before {
  content: '\f61b';
}
.fa-router:before {
  content: '\f8da';
}
.fa-feed:before,
.fa-rss:before {
  content: '\f09e';
}
.fa-rouble:before,
.fa-rub:before,
.fa-ruble-sign:before,
.fa-ruble:before {
  content: '\f158';
}
.fa-ruler:before {
  content: '\f545';
}
.fa-ruler-combined:before {
  content: '\f546';
}
.fa-ruler-horizontal:before {
  content: '\f547';
}
.fa-ruler-triangle:before {
  content: '\f61c';
}
.fa-ruler-vertical:before {
  content: '\f548';
}
.fa-rupee-sign:before,
.fa-rupee:before {
  content: '\f156';
}
.fa-rupiah-sign:before {
  content: '\e23d';
}
.fa-rv:before {
  content: '\f7be';
}
.fa-s:before {
  content: '\e23e';
}
.fa-sack:before {
  content: '\f81c';
}
.fa-sack-dollar:before {
  content: '\f81d';
}
.fa-bowl-salad:before,
.fa-salad:before {
  content: '\f81e';
}
.fa-sandwich:before {
  content: '\f81f';
}
.fa-satellite:before {
  content: '\f7bf';
}
.fa-satellite-dish:before {
  content: '\f7c0';
}
.fa-sausage:before {
  content: '\f820';
}
.fa-saxophone:before {
  content: '\f8dc';
}
.fa-sax-hot:before,
.fa-saxophone-fire:before {
  content: '\f8db';
}
.fa-balance-scale:before,
.fa-scale-balanced:before {
  content: '\f24e';
}
.fa-balance-scale-left:before,
.fa-scale-unbalanced:before {
  content: '\f515';
}
.fa-balance-scale-right:before,
.fa-scale-unbalanced-flip:before {
  content: '\f516';
}
.fa-scalpel:before {
  content: '\f61d';
}
.fa-scalpel-line-dashed:before,
.fa-scalpel-path:before {
  content: '\f61e';
}
.fa-scanner-image:before,
.fa-scanner:before {
  content: '\f8f3';
}
.fa-scanner-gun:before {
  content: '\f488';
}
.fa-scanner-keyboard:before {
  content: '\f489';
}
.fa-scanner-touchscreen:before {
  content: '\f48a';
}
.fa-scarecrow:before {
  content: '\f70d';
}
.fa-scarf:before {
  content: '\f7c1';
}
.fa-school:before {
  content: '\f549';
}
.fa-cut:before,
.fa-scissors:before {
  content: '\f0c4';
}
.fa-screen-users:before,
.fa-users-class:before {
  content: '\f63d';
}
.fa-screencast:before {
  content: '\e23f';
}
.fa-screwdriver:before {
  content: '\f54a';
}
.fa-screwdriver-wrench:before,
.fa-tools:before {
  content: '\f7d9';
}
.fa-scribble:before {
  content: '\e240';
}
.fa-scroll:before {
  content: '\f70e';
}
.fa-scroll-old:before {
  content: '\f70f';
}
.fa-scroll-torah:before,
.fa-torah:before {
  content: '\f6a0';
}
.fa-scrubber:before {
  content: '\f2f8';
}
.fa-scythe:before {
  content: '\f710';
}
.fa-sd-card:before {
  content: '\f7c2';
}
.fa-sd-cards:before {
  content: '\e241';
}
.fa-seal:before {
  content: '\e242';
}
.fa-seal-exclamation:before {
  content: '\e243';
}
.fa-seal-question:before {
  content: '\e244';
}
.fa-seat-airline:before {
  content: '\e245';
}
.fa-section:before {
  content: '\e246';
}
.fa-seedling:before,
.fa-sprout:before {
  content: '\f4d8';
}
.fa-send-back:before {
  content: '\f87e';
}
.fa-send-backward:before {
  content: '\f87f';
}
.fa-sensor:before {
  content: '\e028';
}
.fa-sensor-cloud:before,
.fa-sensor-smoke:before {
  content: '\e02c';
}
.fa-sensor-fire:before {
  content: '\e02a';
}
.fa-sensor-on:before {
  content: '\e02b';
}
.fa-sensor-alert:before,
.fa-sensor-triangle-exclamation:before {
  content: '\e029';
}
.fa-server:before {
  content: '\f233';
}
.fa-shapes:before,
.fa-triangle-circle-square:before {
  content: '\f61f';
}
.fa-arrow-turn-right:before,
.fa-mail-forward:before,
.fa-share:before {
  content: '\f064';
}
.fa-arrows-turn-right:before,
.fa-share-all:before {
  content: '\f367';
}
.fa-share-from-square:before,
.fa-share-square:before {
  content: '\f14d';
}
.fa-share-alt:before,
.fa-share-nodes:before {
  content: '\f1e0';
}
.fa-sheep:before {
  content: '\f711';
}
.fa-ils:before,
.fa-shekel-sign:before,
.fa-shekel:before,
.fa-sheqel-sign:before,
.fa-sheqel:before {
  content: '\f20b';
}
.fa-inventory:before,
.fa-shelves:before {
  content: '\f480';
}
.fa-shelves-empty:before {
  content: '\e247';
}
.fa-shield:before {
  content: '\f132';
}
.fa-shield-alt:before,
.fa-shield-blank:before {
  content: '\f3ed';
}
.fa-shield-check:before {
  content: '\f2f7';
}
.fa-shield-cross:before {
  content: '\f712';
}
.fa-shield-exclamation:before {
  content: '\e248';
}
.fa-shield-keyhole:before {
  content: '\e249';
}
.fa-shield-minus:before {
  content: '\e24a';
}
.fa-shield-plus:before {
  content: '\e24b';
}
.fa-shield-slash:before {
  content: '\e24c';
}
.fa-shield-virus:before {
  content: '\e06c';
}
.fa-shield-times:before,
.fa-shield-xmark:before {
  content: '\e24d';
}
.fa-ship:before {
  content: '\f21a';
}
.fa-shish-kebab:before {
  content: '\f821';
}
.fa-shoe-prints:before {
  content: '\f54b';
}
.fa-shop:before,
.fa-store-alt:before {
  content: '\f54f';
}
.fa-shop-slash:before,
.fa-store-alt-slash:before {
  content: '\e070';
}
.fa-shovel:before {
  content: '\f713';
}
.fa-shovel-snow:before {
  content: '\f7c3';
}
.fa-shower:before {
  content: '\f2cc';
}
.fa-shower-alt:before,
.fa-shower-down:before {
  content: '\e24e';
}
.fa-shredder:before {
  content: '\f68a';
}
.fa-random:before,
.fa-shuffle:before {
  content: '\f074';
}
.fa-shuttle-space:before,
.fa-space-shuttle:before {
  content: '\f197';
}
.fa-shuttlecock:before {
  content: '\f45b';
}
.fa-sickle:before {
  content: '\f822';
}
.fa-sidebar:before {
  content: '\e24f';
}
.fa-sidebar-flip:before {
  content: '\e250';
}
.fa-sigma:before {
  content: '\f68b';
}
.fa-sign-hanging:before,
.fa-sign:before {
  content: '\f4d9';
}
.fa-signal-5:before,
.fa-signal-perfect:before,
.fa-signal:before {
  content: '\f012';
}
.fa-signal-alt-4:before,
.fa-signal-alt:before,
.fa-signal-bars-strong:before,
.fa-signal-bars:before {
  content: '\f690';
}
.fa-signal-alt-2:before,
.fa-signal-bars-fair:before {
  content: '\f692';
}
.fa-signal-alt-3:before,
.fa-signal-bars-good:before {
  content: '\f693';
}
.fa-signal-alt-slash:before,
.fa-signal-bars-slash:before {
  content: '\f694';
}
.fa-signal-alt-1:before,
.fa-signal-bars-weak:before {
  content: '\f691';
}
.fa-signal-2:before,
.fa-signal-fair:before {
  content: '\f68d';
}
.fa-signal-3:before,
.fa-signal-good:before {
  content: '\f68e';
}
.fa-signal-slash:before {
  content: '\f695';
}
.fa-signal-stream:before {
  content: '\f8dd';
}
.fa-signal-stream-slash:before {
  content: '\e251';
}
.fa-signal-4:before,
.fa-signal-strong:before {
  content: '\f68f';
}
.fa-signal-1:before,
.fa-signal-weak:before {
  content: '\f68c';
}
.fa-signature:before {
  content: '\f5b7';
}
.fa-map-signs:before,
.fa-signs-post:before {
  content: '\f277';
}
.fa-sim-card:before {
  content: '\f7c4';
}
.fa-sim-cards:before {
  content: '\e252';
}
.fa-sink:before {
  content: '\e06d';
}
.fa-siren:before {
  content: '\e02d';
}
.fa-siren-on:before {
  content: '\e02e';
}
.fa-sitemap:before {
  content: '\f0e8';
}
.fa-skeleton:before {
  content: '\f620';
}
.fa-skull:before {
  content: '\f54c';
}
.fa-skull-cow:before {
  content: '\f8de';
}
.fa-skull-crossbones:before {
  content: '\f714';
}
.fa-slash:before {
  content: '\f715';
}
.fa-sleigh:before {
  content: '\f7cc';
}
.fa-slider:before {
  content: '\e253';
}
.fa-sliders-h:before,
.fa-sliders:before {
  content: '\f1de';
}
.fa-sliders-up:before,
.fa-sliders-v:before {
  content: '\f3f1';
}
.fa-smog:before {
  content: '\f75f';
}
.fa-smoke:before {
  content: '\f760';
}
.fa-smoking:before {
  content: '\f48d';
}
.fa-snake:before {
  content: '\f716';
}
.fa-snooze:before,
.fa-zzz:before {
  content: '\f880';
}
.fa-snow-blowing:before {
  content: '\f761';
}
.fa-snowflake:before {
  content: '\f2dc';
}
.fa-snowflakes:before {
  content: '\f7cf';
}
.fa-snowman:before {
  content: '\f7d0';
}
.fa-frosty-head:before,
.fa-snowman-head:before {
  content: '\f79b';
}
.fa-snowplow:before {
  content: '\f7d2';
}
.fa-soap:before {
  content: '\e06e';
}
.fa-socks:before {
  content: '\f696';
}
.fa-solar-panel:before {
  content: '\f5ba';
}
.fa-solar-system:before {
  content: '\e02f';
}
.fa-sort:before,
.fa-unsorted:before {
  content: '\f0dc';
}
.fa-sort-desc:before,
.fa-sort-down:before {
  content: '\f0dd';
}
.fa-sort-asc:before,
.fa-sort-up:before {
  content: '\f0de';
}
.fa-spa:before {
  content: '\f5bb';
}
.fa-space-station-moon:before {
  content: '\e033';
}
.fa-space-station-moon-alt:before,
.fa-space-station-moon-construction:before {
  content: '\e034';
}
.fa-spade:before {
  content: '\f2f4';
}
.fa-pastafarianism:before,
.fa-spaghetti-monster-flying:before {
  content: '\f67b';
}
.fa-sparkles:before {
  content: '\f890';
}
.fa-speaker:before {
  content: '\f8df';
}
.fa-speakers:before {
  content: '\f8e0';
}
.fa-spell-check:before {
  content: '\f891';
}
.fa-spider:before {
  content: '\f717';
}
.fa-spider-black-widow:before {
  content: '\f718';
}
.fa-spider-web:before {
  content: '\f719';
}
.fa-spinner:before {
  content: '\f110';
}
.fa-spinner-third:before {
  content: '\f3f4';
}
.fa-split:before {
  content: '\e254';
}
.fa-splotch:before {
  content: '\f5bc';
}
.fa-spoon:before,
.fa-utensil-spoon:before {
  content: '\f2e5';
}
.fa-spray-can:before {
  content: '\f5bd';
}
.fa-sprinkler:before {
  content: '\e035';
}
.fa-square:before {
  content: '\f0c8';
}
.fa-square-0:before {
  content: '\e255';
}
.fa-square-1:before {
  content: '\e256';
}
.fa-square-2:before {
  content: '\e257';
}
.fa-square-3:before {
  content: '\e258';
}
.fa-square-4:before {
  content: '\e259';
}
.fa-square-5:before {
  content: '\e25a';
}
.fa-square-6:before {
  content: '\e25b';
}
.fa-square-7:before {
  content: '\e25c';
}
.fa-square-8:before {
  content: '\e25d';
}
.fa-square-9:before {
  content: '\e25e';
}
.fa-square-a:before {
  content: '\e25f';
}
.fa-square-ampersand:before {
  content: '\e260';
}
.fa-arrow-square-down:before,
.fa-square-arrow-down:before {
  content: '\f339';
}
.fa-square-arrow-down-left:before {
  content: '\e261';
}
.fa-square-arrow-down-right:before {
  content: '\e262';
}
.fa-arrow-square-left:before,
.fa-square-arrow-left:before {
  content: '\f33a';
}
.fa-arrow-square-right:before,
.fa-square-arrow-right:before {
  content: '\f33b';
}
.fa-arrow-square-up:before,
.fa-square-arrow-up:before {
  content: '\f33c';
}
.fa-square-arrow-up-left:before {
  content: '\e263';
}
.fa-external-link-square:before,
.fa-square-arrow-up-right:before {
  content: '\f14c';
}
.fa-square-b:before {
  content: '\e264';
}
.fa-square-bolt:before {
  content: '\e265';
}
.fa-square-c:before {
  content: '\e266';
}
.fa-caret-square-down:before,
.fa-square-caret-down:before {
  content: '\f150';
}
.fa-caret-square-left:before,
.fa-square-caret-left:before {
  content: '\f191';
}
.fa-caret-square-right:before,
.fa-square-caret-right:before {
  content: '\f152';
}
.fa-caret-square-up:before,
.fa-square-caret-up:before {
  content: '\f151';
}
.fa-check-square:before,
.fa-square-check:before {
  content: '\f14a';
}
.fa-chevron-square-down:before,
.fa-square-chevron-down:before {
  content: '\f329';
}
.fa-chevron-square-left:before,
.fa-square-chevron-left:before {
  content: '\f32a';
}
.fa-chevron-square-right:before,
.fa-square-chevron-right:before {
  content: '\f32b';
}
.fa-chevron-square-up:before,
.fa-square-chevron-up:before {
  content: '\f32c';
}
.fa-square-code:before {
  content: '\e267';
}
.fa-square-d:before {
  content: '\e268';
}
.fa-square-dashed:before {
  content: '\e269';
}
.fa-square-divide:before {
  content: '\e26a';
}
.fa-dollar-square:before,
.fa-square-dollar:before,
.fa-usd-square:before {
  content: '\f2e9';
}
.fa-arrow-alt-square-down:before,
.fa-square-down:before {
  content: '\f350';
}
.fa-square-down-left:before {
  content: '\e26b';
}
.fa-square-down-right:before {
  content: '\e26c';
}
.fa-square-e:before {
  content: '\e26d';
}
.fa-envelope-square:before,
.fa-square-envelope:before {
  content: '\f199';
}
.fa-exclamation-square:before,
.fa-square-exclamation:before {
  content: '\f321';
}
.fa-square-f:before {
  content: '\e26e';
}
.fa-box-fragile:before,
.fa-square-fragile:before,
.fa-square-wine-glass-crack:before {
  content: '\f49b';
}
.fa-square-full:before {
  content: '\f45c';
}
.fa-square-g:before {
  content: '\e26f';
}
.fa-h-square:before,
.fa-square-h:before {
  content: '\f0fd';
}
.fa-heart-square:before,
.fa-square-heart:before {
  content: '\f4c8';
}
.fa-square-i:before {
  content: '\e270';
}
.fa-info-square:before,
.fa-square-info:before {
  content: '\f30f';
}
.fa-square-j:before {
  content: '\e271';
}
.fa-square-k:before {
  content: '\e272';
}
.fa-square-l:before {
  content: '\e273';
}
.fa-arrow-alt-square-left:before,
.fa-square-left:before {
  content: '\f351';
}
.fa-square-m:before {
  content: '\e274';
}
.fa-minus-square:before,
.fa-square-minus:before {
  content: '\f146';
}
.fa-square-n:before {
  content: '\e275';
}
.fa-square-o:before {
  content: '\e276';
}
.fa-square-p:before {
  content: '\e277';
}
.fa-parking:before,
.fa-square-parking:before {
  content: '\f540';
}
.fa-parking-slash:before,
.fa-square-parking-slash:before {
  content: '\f617';
}
.fa-pen-square:before,
.fa-pencil-square:before,
.fa-square-pen:before {
  content: '\f14b';
}
.fa-phone-square:before,
.fa-square-phone:before {
  content: '\f098';
}
.fa-phone-square-alt:before,
.fa-square-phone-flip:before {
  content: '\f87b';
}
.fa-phone-square-down:before,
.fa-square-phone-hangup:before {
  content: '\e278';
}
.fa-plus-square:before,
.fa-square-plus:before {
  content: '\f0fe';
}
.fa-poll-h:before,
.fa-square-poll-horizontal:before {
  content: '\f682';
}
.fa-poll:before,
.fa-square-poll-vertical:before {
  content: '\f681';
}
.fa-square-q:before {
  content: '\e279';
}
.fa-question-square:before,
.fa-square-question:before {
  content: '\f2fd';
}
.fa-square-r:before {
  content: '\e27a';
}
.fa-arrow-alt-square-right:before,
.fa-square-right:before {
  content: '\f352';
}
.fa-square-root:before {
  content: '\f697';
}
.fa-square-root-alt:before,
.fa-square-root-variable:before {
  content: '\f698';
}
.fa-rss-square:before,
.fa-square-rss:before {
  content: '\f143';
}
.fa-square-s:before {
  content: '\e27b';
}
.fa-share-alt-square:before,
.fa-square-share-nodes:before {
  content: '\f1e1';
}
.fa-sliders-h-square:before,
.fa-square-sliders:before {
  content: '\f3f0';
}
.fa-sliders-v-square:before,
.fa-square-sliders-vertical:before {
  content: '\f3f2';
}
.fa-square-small:before {
  content: '\e27c';
}
.fa-square-star:before {
  content: '\e27d';
}
.fa-square-t:before {
  content: '\e27e';
}
.fa-box-up:before,
.fa-square-this-way-up:before {
  content: '\f49f';
}
.fa-square-u:before {
  content: '\e27f';
}
.fa-arrow-alt-square-up:before,
.fa-square-up:before {
  content: '\f353';
}
.fa-square-up-left:before {
  content: '\e280';
}
.fa-external-link-square-alt:before,
.fa-square-up-right:before {
  content: '\f360';
}
.fa-square-user:before {
  content: '\e281';
}
.fa-square-v:before {
  content: '\e282';
}
.fa-square-w:before {
  content: '\e283';
}
.fa-square-x:before {
  content: '\e284';
}
.fa-square-xmark:before,
.fa-times-square:before,
.fa-xmark-square:before {
  content: '\f2d3';
}
.fa-square-y:before {
  content: '\e285';
}
.fa-square-z:before {
  content: '\e286';
}
.fa-squirrel:before {
  content: '\f71a';
}
.fa-staff:before {
  content: '\f71b';
}
.fa-stairs:before {
  content: '\e287';
}
.fa-stamp:before {
  content: '\f5bf';
}
.fa-standard-definition:before {
  content: '\e288';
}
.fa-star:before {
  content: '\f005';
}
.fa-star-and-crescent:before {
  content: '\f699';
}
.fa-star-christmas:before {
  content: '\f7d4';
}
.fa-star-exclamation:before {
  content: '\f2f3';
}
.fa-star-half:before {
  content: '\f089';
}
.fa-star-half-alt:before,
.fa-star-half-stroke:before {
  content: '\f5c0';
}
.fa-star-of-david:before {
  content: '\f69a';
}
.fa-star-of-life:before {
  content: '\f621';
}
.fa-star-sharp:before {
  content: '\e289';
}
.fa-star-sharp-half:before {
  content: '\e28a';
}
.fa-star-sharp-half-alt:before,
.fa-star-sharp-half-stroke:before {
  content: '\e28b';
}
.fa-star-shooting:before {
  content: '\e036';
}
.fa-starfighter:before {
  content: '\e037';
}
.fa-starfighter-alt:before,
.fa-starfighter-twin-ion-engine:before {
  content: '\e038';
}
.fa-starfighter-alt-advanced:before,
.fa-starfighter-twin-ion-engine-advanced:before {
  content: '\e28c';
}
.fa-stars:before {
  content: '\f762';
}
.fa-starship:before {
  content: '\e039';
}
.fa-starship-freighter:before {
  content: '\e03a';
}
.fa-steak:before {
  content: '\f824';
}
.fa-steering-wheel:before {
  content: '\f622';
}
.fa-gbp:before,
.fa-pound-sign:before,
.fa-sterling-sign:before {
  content: '\f154';
}
.fa-stethoscope:before {
  content: '\f0f1';
}
.fa-stocking:before {
  content: '\f7d5';
}
.fa-stomach:before {
  content: '\f623';
}
.fa-stop:before {
  content: '\f04d';
}
.fa-stopwatch:before {
  content: '\f2f2';
}
.fa-stopwatch-20:before {
  content: '\e06f';
}
.fa-store:before {
  content: '\f54e';
}
.fa-store-slash:before {
  content: '\e071';
}
.fa-street-view:before {
  content: '\f21d';
}
.fa-stretcher:before {
  content: '\f825';
}
.fa-strikethrough:before {
  content: '\f0cc';
}
.fa-stroopwafel:before {
  content: '\f551';
}
.fa-subscript:before {
  content: '\f12c';
}
.fa-suitcase:before {
  content: '\f0f2';
}
.fa-medkit:before,
.fa-suitcase-medical:before {
  content: '\f0fa';
}
.fa-suitcase-rolling:before {
  content: '\f5c1';
}
.fa-sun:before {
  content: '\f185';
}
.fa-sun-alt:before,
.fa-sun-bright:before {
  content: '\e28d';
}
.fa-sun-cloud:before {
  content: '\f763';
}
.fa-sun-dust:before {
  content: '\f764';
}
.fa-sun-haze:before {
  content: '\f765';
}
.fa-sunglasses:before {
  content: '\f892';
}
.fa-sunrise:before {
  content: '\f766';
}
.fa-sunset:before {
  content: '\f767';
}
.fa-superscript:before {
  content: '\f12b';
}
.fa-swatchbook:before {
  content: '\f5c3';
}
.fa-sword:before {
  content: '\f71c';
}
.fa-sword-laser:before {
  content: '\e03b';
}
.fa-sword-laser-alt:before {
  content: '\e03c';
}
.fa-swords:before {
  content: '\f71d';
}
.fa-swords-laser:before {
  content: '\e03d';
}
.fa-icons-alt:before,
.fa-symbols:before {
  content: '\f86e';
}
.fa-synagogue:before {
  content: '\f69b';
}
.fa-syringe:before {
  content: '\f48e';
}
.fa-t:before {
  content: '\e28e';
}
.fa-table:before {
  content: '\f0ce';
}
.fa-table-cells:before,
.fa-th:before {
  content: '\f00a';
}
.fa-table-cells-large:before,
.fa-th-large:before {
  content: '\f009';
}
.fa-columns:before,
.fa-table-columns:before {
  content: '\f0db';
}
.fa-table-layout:before {
  content: '\e28f';
}
.fa-table-list:before,
.fa-th-list:before {
  content: '\f00b';
}
.fa-table-pivot:before {
  content: '\e290';
}
.fa-rows:before,
.fa-table-rows:before {
  content: '\e291';
}
.fa-ping-pong-paddle-ball:before,
.fa-table-tennis-paddle-ball:before,
.fa-table-tennis:before {
  content: '\f45d';
}
.fa-table-tree:before {
  content: '\e292';
}
.fa-tablet-android:before,
.fa-tablet:before {
  content: '\f3fb';
}
.fa-tablet-button:before {
  content: '\f10a';
}
.fa-tablet-rugged:before {
  content: '\f48f';
}
.fa-tablet-android-alt:before,
.fa-tablet-screen:before {
  content: '\f3fc';
}
.fa-tablet-alt:before,
.fa-tablet-screen-button:before {
  content: '\f3fa';
}
.fa-tablets:before {
  content: '\f490';
}
.fa-digital-tachograph:before,
.fa-tachograph-digital:before {
  content: '\f566';
}
.fa-taco:before {
  content: '\f826';
}
.fa-tag:before {
  content: '\f02b';
}
.fa-tags:before {
  content: '\f02c';
}
.fa-tally-5:before,
.fa-tally:before {
  content: '\f69c';
}
.fa-tally-1:before {
  content: '\e293';
}
.fa-tally-2:before {
  content: '\e294';
}
.fa-tally-3:before {
  content: '\e295';
}
.fa-tally-4:before {
  content: '\e296';
}
.fa-tape:before {
  content: '\f4db';
}
.fa-cab:before,
.fa-taxi:before {
  content: '\f1ba';
}
.fa-taxi-bus:before {
  content: '\e297';
}
.fa-teeth:before {
  content: '\f62e';
}
.fa-teeth-open:before {
  content: '\f62f';
}
.fa-telescope:before {
  content: '\e03e';
}
.fa-temperature-arrow-down:before,
.fa-temperature-down:before {
  content: '\e03f';
}
.fa-temperature-arrow-up:before,
.fa-temperature-up:before {
  content: '\e040';
}
.fa-temperature-0:before,
.fa-temperature-empty:before,
.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: '\f2cb';
}
.fa-temperature-4:before,
.fa-temperature-full:before,
.fa-thermometer-4:before,
.fa-thermometer-full:before {
  content: '\f2c7';
}
.fa-temperature-2:before,
.fa-temperature-half:before,
.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: '\f2c9';
}
.fa-temperature-high:before {
  content: '\f769';
}
.fa-temperature-list:before {
  content: '\e298';
}
.fa-temperature-low:before {
  content: '\f76b';
}
.fa-temperature-1:before,
.fa-temperature-quarter:before,
.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: '\f2ca';
}
.fa-temperature-frigid:before,
.fa-temperature-snow:before {
  content: '\f768';
}
.fa-temperature-hot:before,
.fa-temperature-sun:before {
  content: '\f76a';
}
.fa-temperature-3:before,
.fa-temperature-three-quarters:before,
.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: '\f2c8';
}
.fa-tenge-sign:before,
.fa-tenge:before {
  content: '\f7d7';
}
.fa-tennis-ball:before {
  content: '\f45e';
}
.fa-terminal:before {
  content: '\f120';
}
.fa-text:before {
  content: '\f893';
}
.fa-text-height:before {
  content: '\f034';
}
.fa-text-size:before {
  content: '\f894';
}
.fa-remove-format:before,
.fa-text-slash:before {
  content: '\f87d';
}
.fa-text-width:before {
  content: '\f035';
}
.fa-thermometer:before {
  content: '\f491';
}
.fa-theta:before {
  content: '\f69e';
}
.fa-thumbs-down:before {
  content: '\f165';
}
.fa-thumbs-up:before {
  content: '\f164';
}
.fa-thumb-tack:before,
.fa-thumbtack:before {
  content: '\f08d';
}
.fa-ticket:before {
  content: '\f145';
}
.fa-ticket-airline:before {
  content: '\e299';
}
.fa-ticket-alt:before,
.fa-ticket-simple:before {
  content: '\f3ff';
}
.fa-tickets-airline:before {
  content: '\e29a';
}
.fa-tilde:before {
  content: '\f69f';
}
.fa-timeline:before {
  content: '\e29b';
}
.fa-timeline-arrow:before {
  content: '\e29c';
}
.fa-timer:before {
  content: '\e29d';
}
.fa-tire:before {
  content: '\f631';
}
.fa-tire-flat:before {
  content: '\f632';
}
.fa-tire-pressure-warning:before {
  content: '\f633';
}
.fa-tire-rugged:before {
  content: '\f634';
}
.fa-toggle-off:before {
  content: '\f204';
}
.fa-toggle-on:before {
  content: '\f205';
}
.fa-toilet:before {
  content: '\f7d8';
}
.fa-toilet-paper:before {
  content: '\f71e';
}
.fa-toilet-paper-alt:before,
.fa-toilet-paper-blank:before {
  content: '\f71f';
}
.fa-toilet-paper-blank-under:before,
.fa-toilet-paper-reverse-alt:before {
  content: '\e29e';
}
.fa-toilet-paper-slash:before {
  content: '\e072';
}
.fa-toilet-paper-reverse:before,
.fa-toilet-paper-under:before {
  content: '\e29f';
}
.fa-toilet-paper-reverse-slash:before,
.fa-toilet-paper-under-slash:before {
  content: '\e2a0';
}
.fa-tombstone:before {
  content: '\f720';
}
.fa-tombstone-alt:before,
.fa-tombstone-blank:before {
  content: '\f721';
}
.fa-toolbox:before {
  content: '\f552';
}
.fa-tooth:before {
  content: '\f5c9';
}
.fa-toothbrush:before {
  content: '\f635';
}
.fa-torii-gate:before {
  content: '\f6a1';
}
.fa-tornado:before {
  content: '\f76f';
}
.fa-broadcast-tower:before,
.fa-tower-broadcast:before {
  content: '\f519';
}
.fa-tower-control:before {
  content: '\e2a1';
}
.fa-tractor:before {
  content: '\f722';
}
.fa-trademark:before {
  content: '\f25c';
}
.fa-traffic-cone:before {
  content: '\f636';
}
.fa-traffic-light:before {
  content: '\f637';
}
.fa-traffic-light-go:before {
  content: '\f638';
}
.fa-traffic-light-slow:before {
  content: '\f639';
}
.fa-traffic-light-stop:before {
  content: '\f63a';
}
.fa-trailer:before {
  content: '\e041';
}
.fa-train:before {
  content: '\f238';
}
.fa-subway:before,
.fa-train-subway:before {
  content: '\f239';
}
.fa-subway-tunnel:before,
.fa-train-subway-tunnel:before {
  content: '\e2a2';
}
.fa-train-tram:before,
.fa-tram:before {
  content: '\f7da';
}
.fa-transformer-bolt:before {
  content: '\e2a3';
}
.fa-transgender:before {
  content: '\f224';
}
.fa-transgender-alt:before {
  content: '\f225';
}
.fa-transporter:before {
  content: '\e042';
}
.fa-transporter-1:before {
  content: '\e043';
}
.fa-transporter-2:before {
  content: '\e044';
}
.fa-transporter-3:before {
  content: '\e045';
}
.fa-transporter-4:before {
  content: '\e2a4';
}
.fa-transporter-5:before {
  content: '\e2a5';
}
.fa-transporter-6:before {
  content: '\e2a6';
}
.fa-transporter-7:before {
  content: '\e2a7';
}
.fa-transporter-empty:before {
  content: '\e046';
}
.fa-trash:before {
  content: '\f1f8';
}
.fa-trash-arrow-up:before,
.fa-trash-restore:before {
  content: '\f829';
}
.fa-trash-alt:before,
.fa-trash-can:before {
  content: '\f2ed';
}
.fa-trash-can-arrow-up:before,
.fa-trash-restore-alt:before {
  content: '\f82a';
}
.fa-trash-can-clock:before {
  content: '\e2a8';
}
.fa-trash-can-list:before {
  content: '\e2a9';
}
.fa-trash-alt-slash:before,
.fa-trash-can-slash:before {
  content: '\e2aa';
}
.fa-trash-can-arrow-turn-left:before,
.fa-trash-can-undo:before,
.fa-trash-undo-alt:before {
  content: '\f896';
}
.fa-trash-clock:before {
  content: '\e2ab';
}
.fa-trash-list:before {
  content: '\e2ac';
}
.fa-trash-slash:before {
  content: '\e2ad';
}
.fa-trash-arrow-turn-left:before,
.fa-trash-undo:before {
  content: '\f895';
}
.fa-treasure-chest:before {
  content: '\f723';
}
.fa-tree:before {
  content: '\f1bb';
}
.fa-tree-christmas:before {
  content: '\f7db';
}
.fa-tree-alt:before,
.fa-tree-deciduous:before {
  content: '\f400';
}
.fa-tree-decorated:before {
  content: '\f7dc';
}
.fa-tree-large:before {
  content: '\f7dd';
}
.fa-tree-palm:before {
  content: '\f82b';
}
.fa-trees:before {
  content: '\f724';
}
.fa-triangle:before {
  content: '\f2ec';
}
.fa-exclamation-triangle:before,
.fa-triangle-exclamation:before,
.fa-warning:before {
  content: '\f071';
}
.fa-triangle-instrument:before,
.fa-triangle-music:before {
  content: '\f8e2';
}
.fa-construction:before,
.fa-triangle-person-digging:before {
  content: '\f85d';
}
.fa-trophy:before {
  content: '\f091';
}
.fa-trophy-alt:before,
.fa-trophy-star:before {
  content: '\f2eb';
}
.fa-truck:before {
  content: '\f0d1';
}
.fa-shipping-timed:before,
.fa-truck-clock:before {
  content: '\f48c';
}
.fa-truck-container:before {
  content: '\f4dc';
}
.fa-truck-container-empty:before {
  content: '\e2ae';
}
.fa-shipping-fast:before,
.fa-truck-fast:before {
  content: '\f48b';
}
.fa-truck-flatbed:before {
  content: '\e2af';
}
.fa-truck-front:before {
  content: '\e2b0';
}
.fa-ambulance:before,
.fa-truck-medical:before {
  content: '\f0f9';
}
.fa-truck-monster:before {
  content: '\f63b';
}
.fa-truck-moving:before {
  content: '\f4df';
}
.fa-truck-pickup:before {
  content: '\f63c';
}
.fa-truck-plow:before {
  content: '\f7de';
}
.fa-truck-ramp:before {
  content: '\f4e0';
}
.fa-truck-loading:before,
.fa-truck-ramp-box:before {
  content: '\f4de';
}
.fa-truck-couch:before,
.fa-truck-ramp-couch:before {
  content: '\f4dd';
}
.fa-truck-tow:before {
  content: '\e2b1';
}
.fa-trumpet:before {
  content: '\f8e3';
}
.fa-tshirt:before {
  content: '\f553';
}
.fa-teletype:before,
.fa-tty:before {
  content: '\f1e4';
}
.fa-teletype-answer:before,
.fa-tty-answer:before {
  content: '\e2b2';
}
.fa-tugrik-sign:before {
  content: '\e2b3';
}
.fa-turkey:before {
  content: '\f725';
}
.fa-try:before,
.fa-turkish-lira-sign:before,
.fa-turkish-lira:before {
  content: '\e2b4';
}
.fa-level-down-alt:before,
.fa-turn-down:before {
  content: '\f3be';
}
.fa-level-up-alt:before,
.fa-turn-up:before {
  content: '\f3bf';
}
.fa-turntable:before {
  content: '\f8e4';
}
.fa-turtle:before {
  content: '\f726';
}
.fa-television:before,
.fa-tv-alt:before,
.fa-tv:before {
  content: '\f26c';
}
.fa-tv-music:before {
  content: '\f8e6';
}
.fa-tv-retro:before {
  content: '\f401';
}
.fa-typewriter:before {
  content: '\f8e7';
}
.fa-u:before {
  content: '\e2b5';
}
.fa-ufo:before {
  content: '\e047';
}
.fa-ufo-beam:before {
  content: '\e048';
}
.fa-umbrella:before {
  content: '\f0e9';
}
.fa-umbrella-beach:before {
  content: '\f5ca';
}
.fa-umbrella-alt:before,
.fa-umbrella-simple:before {
  content: '\e2b6';
}
.fa-underline:before {
  content: '\f0cd';
}
.fa-unicorn:before {
  content: '\f727';
}
.fa-union:before {
  content: '\f6a2';
}
.fa-universal-access:before {
  content: '\f29a';
}
.fa-unlock:before {
  content: '\f09c';
}
.fa-unlock-alt:before,
.fa-unlock-keyhole:before {
  content: '\f13e';
}
.fa-arrow-alt-up:before,
.fa-up:before {
  content: '\f357';
}
.fa-arrows-alt-v:before,
.fa-up-down:before {
  content: '\f338';
}
.fa-arrows-alt:before,
.fa-up-down-left-right:before {
  content: '\f0b2';
}
.fa-arrow-alt-from-bottom:before,
.fa-up-from-line:before {
  content: '\f346';
}
.fa-up-left:before {
  content: '\e2b7';
}
.fa-long-arrow-alt-up:before,
.fa-up-long:before {
  content: '\f30c';
}
.fa-up-right:before {
  content: '\e2b8';
}
.fa-expand-alt:before,
.fa-up-right-and-down-left-from-center:before {
  content: '\f424';
}
.fa-external-link-alt:before,
.fa-up-right-from-square:before {
  content: '\f35d';
}
.fa-arrow-alt-to-top:before,
.fa-up-to-line:before {
  content: '\f34d';
}
.fa-upload:before {
  content: '\f093';
}
.fa-usb-drive:before {
  content: '\f8e9';
}
.fa-user:before {
  content: '\f007';
}
.fa-user-alien:before {
  content: '\e04a';
}
.fa-user-astronaut:before {
  content: '\f4fb';
}
.fa-user-bounty-hunter:before {
  content: '\e2b9';
}
.fa-user-check:before {
  content: '\f4fc';
}
.fa-user-clock:before {
  content: '\f4fd';
}
.fa-user-cowboy:before {
  content: '\f8ea';
}
.fa-user-crown:before {
  content: '\f6a4';
}
.fa-user-doctor:before,
.fa-user-md:before {
  content: '\f0f0';
}
.fa-user-doctor-message:before,
.fa-user-md-chat:before {
  content: '\f82e';
}
.fa-user-cog:before,
.fa-user-gear:before {
  content: '\f4fe';
}
.fa-user-graduate:before {
  content: '\f501';
}
.fa-user-friends:before,
.fa-user-group:before {
  content: '\f500';
}
.fa-user-group-crown:before,
.fa-users-crown:before {
  content: '\f6a5';
}
.fa-user-headset:before {
  content: '\f82d';
}
.fa-user-construction:before,
.fa-user-hard-hat:before,
.fa-user-helmet-safety:before {
  content: '\f82c';
}
.fa-user-injured:before {
  content: '\f728';
}
.fa-user-alt:before,
.fa-user-large:before {
  content: '\f406';
}
.fa-user-alt-slash:before,
.fa-user-large-slash:before {
  content: '\f4fa';
}
.fa-user-lock:before {
  content: '\f502';
}
.fa-user-minus:before {
  content: '\f503';
}
.fa-user-music:before {
  content: '\f8eb';
}
.fa-user-ninja:before {
  content: '\f504';
}
.fa-user-nurse:before {
  content: '\f82f';
}
.fa-user-edit:before,
.fa-user-pen:before {
  content: '\f4ff';
}
.fa-user-pilot:before {
  content: '\e2ba';
}
.fa-user-pilot-tie:before {
  content: '\e2bb';
}
.fa-user-plus:before {
  content: '\f234';
}
.fa-user-robot:before {
  content: '\e04b';
}
.fa-user-secret:before {
  content: '\f21b';
}
.fa-user-shakespeare:before {
  content: '\e2bc';
}
.fa-user-shield:before {
  content: '\f505';
}
.fa-user-slash:before {
  content: '\f506';
}
.fa-user-tag:before {
  content: '\f507';
}
.fa-user-tie:before {
  content: '\f508';
}
.fa-user-unlock:before {
  content: '\e058';
}
.fa-user-visor:before {
  content: '\e04c';
}
.fa-user-times:before,
.fa-user-xmark:before {
  content: '\f235';
}
.fa-group:before,
.fa-users:before {
  content: '\f0c0';
}
.fa-users-cog:before,
.fa-users-gear:before {
  content: '\f509';
}
.fa-users-medical:before {
  content: '\f830';
}
.fa-users-slash:before {
  content: '\e073';
}
.fa-cutlery:before,
.fa-utensils:before {
  content: '\f2e7';
}
.fa-utility-pole:before {
  content: '\e2bd';
}
.fa-utility-pole-double:before {
  content: '\e2be';
}
.fa-v:before {
  content: '\e2bf';
}
.fa-vacuum:before {
  content: '\e04d';
}
.fa-vacuum-robot:before {
  content: '\e04e';
}
.fa-value-absolute:before {
  content: '\f6a6';
}
.fa-shuttle-van:before,
.fa-van-shuttle:before {
  content: '\f5b6';
}
.fa-vault:before {
  content: '\e2c0';
}
.fa-vector-circle:before {
  content: '\e2c1';
}
.fa-vector-polygon:before {
  content: '\e2c2';
}
.fa-vector-square:before {
  content: '\f5cb';
}
.fa-venus:before {
  content: '\f221';
}
.fa-venus-double:before {
  content: '\f226';
}
.fa-venus-mars:before {
  content: '\f228';
}
.fa-vest:before {
  content: '\e085';
}
.fa-vest-patches:before {
  content: '\e086';
}
.fa-vial:before {
  content: '\f492';
}
.fa-vials:before {
  content: '\f493';
}
.fa-video-camera:before,
.fa-video:before {
  content: '\f03d';
}
.fa-video-arrow-down-left:before {
  content: '\e2c3';
}
.fa-video-arrow-up-right:before {
  content: '\e2c4';
}
.fa-video-plus:before {
  content: '\f4e1';
}
.fa-video-slash:before {
  content: '\f4e2';
}
.fa-vihara:before {
  content: '\f6a7';
}
.fa-violin:before {
  content: '\f8ed';
}
.fa-virus:before {
  content: '\e074';
}
.fa-virus-slash:before {
  content: '\e075';
}
.fa-viruses:before {
  content: '\e076';
}
.fa-voicemail:before {
  content: '\f897';
}
.fa-volcano:before {
  content: '\f770';
}
.fa-volleyball-ball:before {
  content: '\f45f';
}
.fa-volume-medium:before,
.fa-volume:before {
  content: '\f6a8';
}
.fa-volume-high:before,
.fa-volume-up:before {
  content: '\f028';
}
.fa-volume-down:before,
.fa-volume-low:before {
  content: '\f027';
}
.fa-volume-off:before {
  content: '\f026';
}
.fa-volume-slash:before {
  content: '\f2e2';
}
.fa-volume-mute:before,
.fa-volume-times:before,
.fa-volume-xmark:before {
  content: '\f6a9';
}
.fa-vr-cardboard:before {
  content: '\f729';
}
.fa-w:before {
  content: '\e2c5';
}
.fa-wagon-covered:before {
  content: '\f8ee';
}
.fa-walker:before {
  content: '\f831';
}
.fa-walkie-talkie:before {
  content: '\f8ef';
}
.fa-wallet:before {
  content: '\f555';
}
.fa-wand:before {
  content: '\f72a';
}
.fa-magic:before,
.fa-wand-magic:before {
  content: '\f0d0';
}
.fa-magic-wand-sparkles:before,
.fa-wand-magic-sparkles:before {
  content: '\e2c6';
}
.fa-wand-sparkles:before {
  content: '\f72b';
}
.fa-warehouse:before {
  content: '\f494';
}
.fa-warehouse-alt:before,
.fa-warehouse-full:before {
  content: '\f495';
}
.fa-washer:before,
.fa-washing-machine:before {
  content: '\f898';
}
.fa-watch:before {
  content: '\f2e1';
}
.fa-watch-apple:before {
  content: '\e2c7';
}
.fa-watch-calculator:before {
  content: '\f8f0';
}
.fa-watch-fitness:before {
  content: '\f63e';
}
.fa-watch-smart:before {
  content: '\e2c8';
}
.fa-water:before {
  content: '\f773';
}
.fa-water-arrow-down:before,
.fa-water-lower:before {
  content: '\f774';
}
.fa-water-arrow-up:before,
.fa-water-rise:before {
  content: '\f775';
}
.fa-ladder-water:before,
.fa-swimming-pool:before,
.fa-water-ladder:before {
  content: '\f5c5';
}
.fa-heart-rate:before,
.fa-wave-pulse:before {
  content: '\f5f8';
}
.fa-wave-sine:before {
  content: '\f899';
}
.fa-wave-square:before {
  content: '\f83e';
}
.fa-wave-triangle:before {
  content: '\f89a';
}
.fa-waveform:before {
  content: '\f8f1';
}
.fa-waveform-lines:before {
  content: '\f8f2';
}
.fa-weight-hanging:before {
  content: '\f5cd';
}
.fa-weight-scale:before,
.fa-weight:before {
  content: '\f496';
}
.fa-whale:before {
  content: '\f72c';
}
.fa-wheat:before {
  content: '\f72d';
}
.fa-wheat-alt:before,
.fa-wheat-awn:before {
  content: '\e2c9';
}
.fa-wheelchair:before {
  content: '\f193';
}
.fa-wheelchair-alt:before,
.fa-wheelchair-move:before {
  content: '\e2ca';
}
.fa-glass-whiskey:before,
.fa-whiskey-glass:before {
  content: '\f7a0';
}
.fa-glass-whiskey-rocks:before,
.fa-whiskey-glass-ice:before {
  content: '\f7a1';
}
.fa-whistle:before {
  content: '\f460';
}
.fa-wifi-3:before,
.fa-wifi-strong:before,
.fa-wifi:before {
  content: '\f1eb';
}
.fa-wifi-exclamation:before {
  content: '\e2cb';
}
.fa-wifi-2:before,
.fa-wifi-fair:before {
  content: '\f6ab';
}
.fa-wifi-slash:before {
  content: '\f6ac';
}
.fa-wifi-1:before,
.fa-wifi-weak:before {
  content: '\f6aa';
}
.fa-wind:before {
  content: '\f72e';
}
.fa-wind-turbine:before {
  content: '\f89b';
}
.fa-wind-circle-exclamation:before,
.fa-wind-warning:before {
  content: '\f776';
}
.fa-window:before {
  content: '\f40e';
}
.fa-window-alt:before,
.fa-window-flip:before {
  content: '\f40f';
}
.fa-window-frame:before {
  content: '\e04f';
}
.fa-window-frame-open:before {
  content: '\e050';
}
.fa-window-maximize:before {
  content: '\f2d0';
}
.fa-window-minimize:before {
  content: '\f2d1';
}
.fa-window-restore:before {
  content: '\f2d2';
}
.fa-windsock:before {
  content: '\f777';
}
.fa-wine-bottle:before {
  content: '\f72f';
}
.fa-wine-glass:before {
  content: '\f4e3';
}
.fa-fragile:before,
.fa-wine-glass-crack:before {
  content: '\f4bb';
}
.fa-wine-glass-alt:before,
.fa-wine-glass-empty:before {
  content: '\f5ce';
}
.fa-krw:before,
.fa-won-sign:before,
.fa-won:before {
  content: '\f159';
}
.fa-wreath:before {
  content: '\f7e2';
}
.fa-wrench:before {
  content: '\f0ad';
}
.fa-wrench-simple:before {
  content: '\e2cc';
}
.fa-x:before {
  content: '\e2cd';
}
.fa-x-ray:before {
  content: '\f497';
}
.fa-close:before,
.fa-multiply:before,
.fa-remove:before,
.fa-times:before,
.fa-xmark:before {
  content: '\f00d';
}
.fa-times-to-slot:before,
.fa-vote-nay:before,
.fa-xmark-to-slot:before {
  content: '\f771';
}
.fa-y:before {
  content: '\e2ce';
}
.fa-cny:before,
.fa-jpy:before,
.fa-rmb:before,
.fa-yen-sign:before,
.fa-yen:before {
  content: '\f157';
}
.fa-yin-yang:before {
  content: '\f6ad';
}
.fa-z:before {
  content: '\e2cf';
}
.fa-sr-only,
.fa-sr-only-focusable:not(:focus),
.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/assets/fonts/fa-brands-400.woff2) format('woff2'),
    url(/assets/fonts/fa-brands-400.woff) format('woff'),
    url(/assets/fonts/fa-brands-400.ttf) format('truetype');
}
.fa-brands,
.fab {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400;
}
.fa-500px:before {
  content: '\f26e';
}
.fa-accessible-icon:before {
  content: '\f368';
}
.fa-accusoft:before {
  content: '\f369';
}
.fa-acquisitions-incorporated:before {
  content: '\f6af';
}
.fa-adn:before {
  content: '\f170';
}
.fa-adversal:before {
  content: '\f36a';
}
.fa-affiliatetheme:before {
  content: '\f36b';
}
.fa-airbnb:before {
  content: '\f834';
}
.fa-algolia:before {
  content: '\f36c';
}
.fa-alipay:before {
  content: '\f642';
}
.fa-amazon:before {
  content: '\f270';
}
.fa-amazon-pay:before {
  content: '\f42c';
}
.fa-amilia:before {
  content: '\f36d';
}
.fa-android:before {
  content: '\f17b';
}
.fa-angellist:before {
  content: '\f209';
}
.fa-angrycreative:before {
  content: '\f36e';
}
.fa-angular:before {
  content: '\f420';
}
.fa-app-store:before {
  content: '\f36f';
}
.fa-app-store-ios:before {
  content: '\f370';
}
.fa-apper:before {
  content: '\f371';
}
.fa-apple:before {
  content: '\f179';
}
.fa-apple-pay:before {
  content: '\f415';
}
.fa-artstation:before {
  content: '\f77a';
}
.fa-asymmetrik:before {
  content: '\f372';
}
.fa-atlassian:before {
  content: '\f77b';
}
.fa-audible:before {
  content: '\f373';
}
.fa-autoprefixer:before {
  content: '\f41c';
}
.fa-avianex:before {
  content: '\f374';
}
.fa-aviato:before {
  content: '\f421';
}
.fa-aws:before {
  content: '\f375';
}
.fa-bandcamp:before {
  content: '\f2d5';
}
.fa-battle-net:before {
  content: '\f835';
}
.fa-behance:before {
  content: '\f1b4';
}
.fa-behance-square:before {
  content: '\f1b5';
}
.fa-bimobject:before {
  content: '\f378';
}
.fa-bitbucket:before {
  content: '\f171';
}
.fa-bitcoin:before {
  content: '\f379';
}
.fa-bity:before {
  content: '\f37a';
}
.fa-black-tie:before {
  content: '\f27e';
}
.fa-blackberry:before {
  content: '\f37b';
}
.fa-blogger:before {
  content: '\f37c';
}
.fa-blogger-b:before {
  content: '\f37d';
}
.fa-bluetooth:before {
  content: '\f293';
}
.fa-bluetooth-b:before {
  content: '\f294';
}
.fa-bootstrap:before {
  content: '\f836';
}
.fa-btc:before {
  content: '\f15a';
}
.fa-buffer:before {
  content: '\f837';
}
.fa-buromobelexperte:before {
  content: '\f37f';
}
.fa-buy-n-large:before {
  content: '\f8a6';
}
.fa-buysellads:before {
  content: '\f20d';
}
.fa-canadian-maple-leaf:before {
  content: '\f785';
}
.fa-cc-amazon-pay:before {
  content: '\f42d';
}
.fa-cc-amex:before {
  content: '\f1f3';
}
.fa-cc-apple-pay:before {
  content: '\f416';
}
.fa-cc-diners-club:before {
  content: '\f24c';
}
.fa-cc-discover:before {
  content: '\f1f2';
}
.fa-cc-jcb:before {
  content: '\f24b';
}
.fa-cc-mastercard:before {
  content: '\f1f1';
}
.fa-cc-paypal:before {
  content: '\f1f4';
}
.fa-cc-stripe:before {
  content: '\f1f5';
}
.fa-cc-visa:before {
  content: '\f1f0';
}
.fa-centercode:before {
  content: '\f380';
}
.fa-centos:before {
  content: '\f789';
}
.fa-chrome:before {
  content: '\f268';
}
.fa-chromecast:before {
  content: '\f838';
}
.fa-cloudflare:before {
  content: '\e07d';
}
.fa-cloudscale:before {
  content: '\f383';
}
.fa-cloudsmith:before {
  content: '\f384';
}
.fa-cloudversify:before {
  content: '\f385';
}
.fa-codepen:before {
  content: '\f1cb';
}
.fa-codiepie:before {
  content: '\f284';
}
.fa-confluence:before {
  content: '\f78d';
}
.fa-connectdevelop:before {
  content: '\f20e';
}
.fa-contao:before {
  content: '\f26d';
}
.fa-cotton-bureau:before {
  content: '\f89e';
}
.fa-cpanel:before {
  content: '\f388';
}
.fa-creative-commons:before {
  content: '\f25e';
}
.fa-creative-commons-by:before {
  content: '\f4e7';
}
.fa-creative-commons-nc:before {
  content: '\f4e8';
}
.fa-creative-commons-nc-eu:before {
  content: '\f4e9';
}
.fa-creative-commons-nc-jp:before {
  content: '\f4ea';
}
.fa-creative-commons-nd:before {
  content: '\f4eb';
}
.fa-creative-commons-pd:before {
  content: '\f4ec';
}
.fa-creative-commons-pd-alt:before {
  content: '\f4ed';
}
.fa-creative-commons-remix:before {
  content: '\f4ee';
}
.fa-creative-commons-sa:before {
  content: '\f4ef';
}
.fa-creative-commons-sampling:before {
  content: '\f4f0';
}
.fa-creative-commons-sampling-plus:before {
  content: '\f4f1';
}
.fa-creative-commons-share:before {
  content: '\f4f2';
}
.fa-creative-commons-zero:before {
  content: '\f4f3';
}
.fa-critical-role:before {
  content: '\f6c9';
}
.fa-css3:before {
  content: '\f13c';
}
.fa-css3-alt:before {
  content: '\f38b';
}
.fa-cuttlefish:before {
  content: '\f38c';
}
.fa-d-and-d:before {
  content: '\f38d';
}
.fa-d-and-d-beyond:before {
  content: '\f6ca';
}
.fa-dailymotion:before {
  content: '\e052';
}
.fa-dashcube:before {
  content: '\f210';
}
.fa-deezer:before {
  content: '\e077';
}
.fa-delicious:before {
  content: '\f1a5';
}
.fa-deploydog:before {
  content: '\f38e';
}
.fa-deskpro:before {
  content: '\f38f';
}
.fa-dev:before {
  content: '\f6cc';
}
.fa-deviantart:before {
  content: '\f1bd';
}
.fa-dhl:before {
  content: '\f790';
}
.fa-diaspora:before {
  content: '\f791';
}
.fa-digg:before {
  content: '\f1a6';
}
.fa-digital-ocean:before {
  content: '\f391';
}
.fa-discord:before {
  content: '\f392';
}
.fa-discourse:before {
  content: '\f393';
}
.fa-dochub:before {
  content: '\f394';
}
.fa-docker:before {
  content: '\f395';
}
.fa-draft2digital:before {
  content: '\f396';
}
.fa-dribbble:before {
  content: '\f17d';
}
.fa-dribbble-square:before {
  content: '\f397';
}
.fa-dropbox:before {
  content: '\f16b';
}
.fa-drupal:before {
  content: '\f1a9';
}
.fa-dyalog:before {
  content: '\f399';
}
.fa-earlybirds:before {
  content: '\f39a';
}
.fa-ebay:before {
  content: '\f4f4';
}
.fa-edge:before {
  content: '\f282';
}
.fa-edge-legacy:before {
  content: '\e078';
}
.fa-elementor:before {
  content: '\f430';
}
.fa-ello:before {
  content: '\f5f1';
}
.fa-ember:before {
  content: '\f423';
}
.fa-empire:before {
  content: '\f1d1';
}
.fa-envira:before {
  content: '\f299';
}
.fa-erlang:before {
  content: '\f39d';
}
.fa-ethereum:before {
  content: '\f42e';
}
.fa-etsy:before {
  content: '\f2d7';
}
.fa-evernote:before {
  content: '\f839';
}
.fa-expeditedssl:before {
  content: '\f23e';
}
.fa-facebook:before {
  content: '\f09a';
}
.fa-facebook-f:before {
  content: '\f39e';
}
.fa-facebook-messenger:before {
  content: '\f39f';
}
.fa-facebook-square:before {
  content: '\f082';
}
.fa-fantasy-flight-games:before {
  content: '\f6dc';
}
.fa-fedex:before {
  content: '\f797';
}
.fa-fedora:before {
  content: '\f798';
}
.fa-figma:before {
  content: '\f799';
}
.fa-firefox:before {
  content: '\f269';
}
.fa-firefox-browser:before {
  content: '\e007';
}
.fa-first-order:before {
  content: '\f2b0';
}
.fa-first-order-alt:before {
  content: '\f50a';
}
.fa-firstdraft:before {
  content: '\f3a1';
}
.fa-flickr:before {
  content: '\f16e';
}
.fa-flipboard:before {
  content: '\f44d';
}
.fa-fly:before {
  content: '\f417';
}
.fa-font-awesome-flag:before,
.fa-font-awesome-logo-full:before,
.fa-font-awesome:before {
  content: '\f2b4';
}
.fa-fonticons:before {
  content: '\f280';
}
.fa-fonticons-fi:before {
  content: '\f3a2';
}
.fa-fort-awesome:before {
  content: '\f286';
}
.fa-fort-awesome-alt:before {
  content: '\f3a3';
}
.fa-forumbee:before {
  content: '\f211';
}
.fa-foursquare:before {
  content: '\f180';
}
.fa-free-code-camp:before {
  content: '\f2c5';
}
.fa-freebsd:before {
  content: '\f3a4';
}
.fa-fulcrum:before {
  content: '\f50b';
}
.fa-galactic-republic:before {
  content: '\f50c';
}
.fa-galactic-senate:before {
  content: '\f50d';
}
.fa-get-pocket:before {
  content: '\f265';
}
.fa-gg:before {
  content: '\f260';
}
.fa-gg-circle:before {
  content: '\f261';
}
.fa-git:before {
  content: '\f1d3';
}
.fa-git-alt:before {
  content: '\f841';
}
.fa-git-square:before {
  content: '\f1d2';
}
.fa-github:before {
  content: '\f09b';
}
.fa-github-alt:before {
  content: '\f113';
}
.fa-github-square:before {
  content: '\f092';
}
.fa-gitkraken:before {
  content: '\f3a6';
}
.fa-gitlab:before {
  content: '\f296';
}
.fa-gitter:before {
  content: '\f426';
}
.fa-glide:before {
  content: '\f2a5';
}
.fa-glide-g:before {
  content: '\f2a6';
}
.fa-gofore:before {
  content: '\f3a7';
}
.fa-goodreads:before {
  content: '\f3a8';
}
.fa-goodreads-g:before {
  content: '\f3a9';
}
.fa-google:before {
  content: '\f1a0';
}
.fa-google-drive:before {
  content: '\f3aa';
}
.fa-google-pay:before {
  content: '\e079';
}
.fa-google-play:before {
  content: '\f3ab';
}
.fa-google-plus:before {
  content: '\f2b3';
}
.fa-google-plus-g:before {
  content: '\f0d5';
}
.fa-google-plus-square:before {
  content: '\f0d4';
}
.fa-google-wallet:before {
  content: '\f1ee';
}
.fa-gratipay:before {
  content: '\f184';
}
.fa-grav:before {
  content: '\f2d6';
}
.fa-gripfire:before {
  content: '\f3ac';
}
.fa-grunt:before {
  content: '\f3ad';
}
.fa-guilded:before {
  content: '\e07e';
}
.fa-gulp:before {
  content: '\f3ae';
}
.fa-hacker-news:before {
  content: '\f1d4';
}
.fa-hacker-news-square:before {
  content: '\f3af';
}
.fa-hackerrank:before {
  content: '\f5f7';
}
.fa-hips:before {
  content: '\f452';
}
.fa-hire-a-helper:before {
  content: '\f3b0';
}
.fa-hive:before {
  content: '\e07f';
}
.fa-hooli:before {
  content: '\f427';
}
.fa-hornbill:before {
  content: '\f592';
}
.fa-hotjar:before {
  content: '\f3b1';
}
.fa-houzz:before {
  content: '\f27c';
}
.fa-html5:before {
  content: '\f13b';
}
.fa-hubspot:before {
  content: '\f3b2';
}
.fa-ideal:before {
  content: '\e013';
}
.fa-imdb:before {
  content: '\f2d8';
}
.fa-innosoft:before {
  content: '\e080';
}
.fa-instagram:before {
  content: '\f16d';
}
.fa-instagram-square:before {
  content: '\e055';
}
.fa-instalod:before {
  content: '\e081';
}
.fa-intercom:before {
  content: '\f7af';
}
.fa-internet-explorer:before {
  content: '\f26b';
}
.fa-invision:before {
  content: '\f7b0';
}
.fa-ioxhost:before {
  content: '\f208';
}
.fa-itch-io:before {
  content: '\f83a';
}
.fa-itunes:before {
  content: '\f3b4';
}
.fa-itunes-note:before {
  content: '\f3b5';
}
.fa-java:before {
  content: '\f4e4';
}
.fa-jedi-order:before {
  content: '\f50e';
}
.fa-jenkins:before {
  content: '\f3b6';
}
.fa-jira:before {
  content: '\f7b1';
}
.fa-joget:before {
  content: '\f3b7';
}
.fa-joomla:before {
  content: '\f1aa';
}
.fa-js:before {
  content: '\f3b8';
}
.fa-js-square:before {
  content: '\f3b9';
}
.fa-jsfiddle:before {
  content: '\f1cc';
}
.fa-kaggle:before {
  content: '\f5fa';
}
.fa-keybase:before {
  content: '\f4f5';
}
.fa-keycdn:before {
  content: '\f3ba';
}
.fa-kickstarter:before {
  content: '\f3bb';
}
.fa-kickstarter-k:before {
  content: '\f3bc';
}
.fa-korvue:before {
  content: '\f42f';
}
.fa-laravel:before {
  content: '\f3bd';
}
.fa-lastfm:before {
  content: '\f202';
}
.fa-lastfm-square:before {
  content: '\f203';
}
.fa-leanpub:before {
  content: '\f212';
}
.fa-less:before {
  content: '\f41d';
}
.fa-line:before {
  content: '\f3c0';
}
.fa-linkedin:before {
  content: '\f08c';
}
.fa-linkedin-in:before {
  content: '\f0e1';
}
.fa-linode:before {
  content: '\f2b8';
}
.fa-linux:before {
  content: '\f17c';
}
.fa-lyft:before {
  content: '\f3c3';
}
.fa-magento:before {
  content: '\f3c4';
}
.fa-mailchimp:before {
  content: '\f59e';
}
.fa-mandalorian:before {
  content: '\f50f';
}
.fa-markdown:before {
  content: '\f60f';
}
.fa-mastodon:before {
  content: '\f4f6';
}
.fa-maxcdn:before {
  content: '\f136';
}
.fa-mdb:before {
  content: '\f8ca';
}
.fa-medapps:before {
  content: '\f3c6';
}
.fa-medium-m:before,
.fa-medium:before {
  content: '\f23a';
}
.fa-medrt:before {
  content: '\f3c8';
}
.fa-meetup:before {
  content: '\f2e0';
}
.fa-megaport:before {
  content: '\f5a3';
}
.fa-mendeley:before {
  content: '\f7b3';
}
.fa-microblog:before {
  content: '\e01a';
}
.fa-microsoft:before {
  content: '\f3ca';
}
.fa-mix:before {
  content: '\f3cb';
}
.fa-mixcloud:before {
  content: '\f289';
}
.fa-mixer:before {
  content: '\e056';
}
.fa-mizuni:before {
  content: '\f3cc';
}
.fa-modx:before {
  content: '\f285';
}
.fa-monero:before {
  content: '\f3d0';
}
.fa-napster:before {
  content: '\f3d2';
}
.fa-neos:before {
  content: '\f612';
}
.fa-nimblr:before {
  content: '\f5a8';
}
.fa-node:before {
  content: '\f419';
}
.fa-node-js:before {
  content: '\f3d3';
}
.fa-npm:before {
  content: '\f3d4';
}
.fa-ns8:before {
  content: '\f3d5';
}
.fa-nutritionix:before {
  content: '\f3d6';
}
.fa-octopus-deploy:before {
  content: '\e082';
}
.fa-odnoklassniki:before {
  content: '\f263';
}
.fa-odnoklassniki-square:before {
  content: '\f264';
}
.fa-old-republic:before {
  content: '\f510';
}
.fa-opencart:before {
  content: '\f23d';
}
.fa-openid:before {
  content: '\f19b';
}
.fa-opera:before {
  content: '\f26a';
}
.fa-optin-monster:before {
  content: '\f23c';
}
.fa-orcid:before {
  content: '\f8d2';
}
.fa-osi:before {
  content: '\f41a';
}
.fa-page4:before {
  content: '\f3d7';
}
.fa-pagelines:before {
  content: '\f18c';
}
.fa-palfed:before {
  content: '\f3d8';
}
.fa-patreon:before {
  content: '\f3d9';
}
.fa-paypal:before {
  content: '\f1ed';
}
.fa-penny-arcade:before {
  content: '\f704';
}
.fa-perbyte:before {
  content: '\e083';
}
.fa-periscope:before {
  content: '\f3da';
}
.fa-phabricator:before {
  content: '\f3db';
}
.fa-phoenix-framework:before {
  content: '\f3dc';
}
.fa-phoenix-squadron:before {
  content: '\f511';
}
.fa-php:before {
  content: '\f457';
}
.fa-pied-piper:before {
  content: '\f2ae';
}
.fa-pied-piper-alt:before {
  content: '\f1a8';
}
.fa-pied-piper-hat:before {
  content: '\f4e5';
}
.fa-pied-piper-pp:before {
  content: '\f1a7';
}
.fa-pied-piper-square:before {
  content: '\e01e';
}
.fa-pinterest:before {
  content: '\f0d2';
}
.fa-pinterest-p:before {
  content: '\f231';
}
.fa-pinterest-square:before {
  content: '\f0d3';
}
.fa-playstation:before {
  content: '\f3df';
}
.fa-product-hunt:before {
  content: '\f288';
}
.fa-pushed:before {
  content: '\f3e1';
}
.fa-python:before {
  content: '\f3e2';
}
.fa-qq:before {
  content: '\f1d6';
}
.fa-quinscape:before {
  content: '\f459';
}
.fa-quora:before {
  content: '\f2c4';
}
.fa-r-project:before {
  content: '\f4f7';
}
.fa-raspberry-pi:before {
  content: '\f7bb';
}
.fa-ravelry:before {
  content: '\f2d9';
}
.fa-react:before {
  content: '\f41b';
}
.fa-reacteurope:before {
  content: '\f75d';
}
.fa-readme:before {
  content: '\f4d5';
}
.fa-rebel:before {
  content: '\f1d0';
}
.fa-red-river:before {
  content: '\f3e3';
}
.fa-reddit:before {
  content: '\f1a1';
}
.fa-reddit-alien:before {
  content: '\f281';
}
.fa-reddit-square:before {
  content: '\f1a2';
}
.fa-redhat:before {
  content: '\f7bc';
}
.fa-renren:before {
  content: '\f18b';
}
.fa-replyd:before {
  content: '\f3e6';
}
.fa-researchgate:before {
  content: '\f4f8';
}
.fa-resolving:before {
  content: '\f3e7';
}
.fa-rev:before {
  content: '\f5b2';
}
.fa-rocketchat:before {
  content: '\f3e8';
}
.fa-rockrms:before {
  content: '\f3e9';
}
.fa-rust:before {
  content: '\e07a';
}
.fa-safari:before {
  content: '\f267';
}
.fa-salesforce:before {
  content: '\f83b';
}
.fa-sass:before {
  content: '\f41e';
}
.fa-schlix:before {
  content: '\f3ea';
}
.fa-scribd:before {
  content: '\f28a';
}
.fa-searchengin:before {
  content: '\f3eb';
}
.fa-sellcast:before {
  content: '\f2da';
}
.fa-sellsy:before {
  content: '\f213';
}
.fa-servicestack:before {
  content: '\f3ec';
}
.fa-shirtsinbulk:before {
  content: '\f214';
}
.fa-shopify:before {
  content: '\e057';
}
.fa-shopware:before {
  content: '\f5b5';
}
.fa-simplybuilt:before {
  content: '\f215';
}
.fa-sistrix:before {
  content: '\f3ee';
}
.fa-sith:before {
  content: '\f512';
}
.fa-sketch:before {
  content: '\f7c6';
}
.fa-skyatlas:before {
  content: '\f216';
}
.fa-skype:before {
  content: '\f17e';
}
.fa-slack-hash:before,
.fa-slack:before {
  content: '\f198';
}
.fa-slideshare:before {
  content: '\f1e7';
}
.fa-snapchat-ghost:before,
.fa-snapchat:before {
  content: '\f2ab';
}
.fa-snapchat-square:before {
  content: '\f2ad';
}
.fa-soundcloud:before {
  content: '\f1be';
}
.fa-sourcetree:before {
  content: '\f7d3';
}
.fa-speakap:before {
  content: '\f3f3';
}
.fa-speaker-deck:before {
  content: '\f83c';
}
.fa-spotify:before {
  content: '\f1bc';
}
.fa-square-font-awesome:before {
  content: '\f425';
}
.fa-font-awesome-alt:before,
.fa-square-font-awesome-stroke:before {
  content: '\f35c';
}
.fa-squarespace:before {
  content: '\f5be';
}
.fa-stack-exchange:before {
  content: '\f18d';
}
.fa-stack-overflow:before {
  content: '\f16c';
}
.fa-stackpath:before {
  content: '\f842';
}
.fa-staylinked:before {
  content: '\f3f5';
}
.fa-steam:before {
  content: '\f1b6';
}
.fa-steam-square:before {
  content: '\f1b7';
}
.fa-steam-symbol:before {
  content: '\f3f6';
}
.fa-sticker-mule:before {
  content: '\f3f7';
}
.fa-strava:before {
  content: '\f428';
}
.fa-stripe:before {
  content: '\f429';
}
.fa-stripe-s:before {
  content: '\f42a';
}
.fa-studiovinari:before {
  content: '\f3f8';
}
.fa-stumbleupon:before {
  content: '\f1a4';
}
.fa-stumbleupon-circle:before {
  content: '\f1a3';
}
.fa-superpowers:before {
  content: '\f2dd';
}
.fa-supple:before {
  content: '\f3f9';
}
.fa-suse:before {
  content: '\f7d6';
}
.fa-swift:before {
  content: '\f8e1';
}
.fa-symfony:before {
  content: '\f83d';
}
.fa-teamspeak:before {
  content: '\f4f9';
}
.fa-telegram-plane:before,
.fa-telegram:before {
  content: '\f2c6';
}
.fa-tencent-weibo:before {
  content: '\f1d5';
}
.fa-the-red-yeti:before {
  content: '\f69d';
}
.fa-themeco:before {
  content: '\f5c6';
}
.fa-themeisle:before {
  content: '\f2b2';
}
.fa-think-peaks:before {
  content: '\f731';
}
.fa-tiktok:before {
  content: '\e07b';
}
.fa-trade-federation:before {
  content: '\f513';
}
.fa-trello:before {
  content: '\f181';
}
.fa-tripadvisor:before {
  content: '\f262';
}
.fa-tumblr:before {
  content: '\f173';
}
.fa-tumblr-square:before {
  content: '\f174';
}
.fa-twitch:before {
  content: '\f1e8';
}
.fa-twitter:before {
  content: '\f099';
}
.fa-twitter-square:before {
  content: '\f081';
}
.fa-typo3:before {
  content: '\f42b';
}
.fa-uber:before {
  content: '\f402';
}
.fa-ubuntu:before {
  content: '\f7df';
}
.fa-uikit:before {
  content: '\f403';
}
.fa-umbraco:before {
  content: '\f8e8';
}
.fa-uncharted:before {
  content: '\e084';
}
.fa-uniregistry:before {
  content: '\f404';
}
.fa-unity:before {
  content: '\e049';
}
.fa-unsplash:before {
  content: '\e07c';
}
.fa-untappd:before {
  content: '\f405';
}
.fa-ups:before {
  content: '\f7e0';
}
.fa-usb:before {
  content: '\f287';
}
.fa-usps:before {
  content: '\f7e1';
}
.fa-ussunnah:before {
  content: '\f407';
}
.fa-vaadin:before {
  content: '\f408';
}
.fa-viacoin:before {
  content: '\f237';
}
.fa-viadeo:before {
  content: '\f2a9';
}
.fa-viadeo-square:before {
  content: '\f2aa';
}
.fa-viber:before {
  content: '\f409';
}
.fa-vimeo:before {
  content: '\f40a';
}
.fa-vimeo-square:before {
  content: '\f194';
}
.fa-vimeo-v:before {
  content: '\f27d';
}
.fa-vine:before {
  content: '\f1ca';
}
.fa-vk:before {
  content: '\f189';
}
.fa-vnv:before {
  content: '\f40b';
}
.fa-vuejs:before {
  content: '\f41f';
}
.fa-watchman-monitoring:before {
  content: '\e087';
}
.fa-waze:before {
  content: '\f83f';
}
.fa-weebly:before {
  content: '\f5cc';
}
.fa-weibo:before {
  content: '\f18a';
}
.fa-weixin:before {
  content: '\f1d7';
}
.fa-whatsapp:before {
  content: '\f232';
}
.fa-whatsapp-square:before {
  content: '\f40c';
}
.fa-whmcs:before {
  content: '\f40d';
}
.fa-wikipedia-w:before {
  content: '\f266';
}
.fa-windows:before {
  content: '\f17a';
}
.fa-wix:before {
  content: '\f5cf';
}
.fa-wizards-of-the-coast:before {
  content: '\f730';
}
.fa-wodu:before {
  content: '\e088';
}
.fa-wolf-pack-battalion:before {
  content: '\f514';
}
.fa-wordpress:before {
  content: '\f19a';
}
.fa-wordpress-simple:before {
  content: '\f411';
}
.fa-wpbeginner:before {
  content: '\f297';
}
.fa-wpexplorer:before {
  content: '\f2de';
}
.fa-wpforms:before {
  content: '\f298';
}
.fa-wpressr:before {
  content: '\f3e4';
}
.fa-xbox:before {
  content: '\f412';
}
.fa-xing:before {
  content: '\f168';
}
.fa-xing-square:before {
  content: '\f169';
}
.fa-y-combinator:before {
  content: '\f23b';
}
.fa-yahoo:before {
  content: '\f19e';
}
.fa-yammer:before {
  content: '\f840';
}
.fa-yandex:before {
  content: '\f413';
}
.fa-yandex-international:before {
  content: '\f414';
}
.fa-yarn:before {
  content: '\f7e3';
}
.fa-yelp:before {
  content: '\f1e9';
}
.fa-yoast:before {
  content: '\f2b1';
}
.fa-youtube:before {
  content: '\f167';
}
.fa-youtube-square:before {
  content: '\f431';
}
.fa-zhihu:before {
  content: '\f63f';
}
@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/assets/fonts/fa-duotone-900.woff2) format('woff2'),
    url(/assets/fonts/fa-duotone-900.woff) format('woff'),
    url(/assets/fonts/fa-duotone-900.ttf) format('truetype');
}
.fa-duotone,
.fad {
  position: relative;
  font-family: 'Font Awesome 6 Duotone';
  font-weight: 900;
}
.fa-duotone:before,
.fad:before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}
.fa-duotone:after,
.fad:after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
}
.fa-duotone.fa-swap-opacity:before,
.fa-duotone:after,
.fa-swap-opacity .fa-duotone:before,
.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before,
.fad:after {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}
.fa-duotone.fa-swap-opacity:after,
.fa-swap-opacity .fa-duotone:after,
.fa-swap-opacity .fad:after,
.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}
.fa-duotone.fa-inverse,
.fad.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff);
}
.fa-duotone.fa-stack-1x,
.fa-duotone.fa-stack-2x,
.fad.fa-stack-1x,
.fad.fa-stack-2x {
  position: absolute;
}
.fa-duotone.fa-fw:before,
.fa-duotone.fa-stack-1x:before,
.fa-duotone.fa-stack-2x:before,
.fad.fa-fw:before,
.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.fa-duotone.fa-0:after,
.fad.fa-0:after {
  content: '\10e089';
}
.fa-duotone.fa-1:after,
.fad.fa-1:after {
  content: '\10e08a';
}
.fa-duotone.fa-2:after,
.fad.fa-2:after {
  content: '\10e08b';
}
.fa-duotone.fa-3:after,
.fad.fa-3:after {
  content: '\10e08c';
}
.fa-duotone.fa-4:after,
.fad.fa-4:after {
  content: '\10e08d';
}
.fa-duotone.fa-5:after,
.fad.fa-5:after {
  content: '\10e08e';
}
.fa-duotone.fa-6:after,
.fad.fa-6:after {
  content: '\10e08f';
}
.fa-duotone.fa-7:after,
.fad.fa-7:after {
  content: '\10e090';
}
.fa-duotone.fa-8:after,
.fad.fa-8:after {
  content: '\10e091';
}
.fa-duotone.fa-9:after,
.fad.fa-9:after {
  content: '\10e092';
}
.fa-duotone.fa-a:after,
.fad.fa-a:after {
  content: '\10e093';
}
.fa-duotone.fa-abacus:after,
.fad.fa-abacus:after {
  content: '\10f640';
}
.fa-duotone.fa-acorn:after,
.fad.fa-acorn:after {
  content: '\10f6ae';
}
.fa-duotone.fa-address-book:after,
.fa-duotone.fa-contact-book:after,
.fad.fa-address-book:after,
.fad.fa-contact-book:after {
  content: '\10f2b9';
}
.fa-duotone.fa-address-card:after,
.fa-duotone.fa-contact-card:after,
.fa-duotone.fa-vcard:after,
.fad.fa-address-card:after,
.fad.fa-contact-card:after,
.fad.fa-vcard:after {
  content: '\10f2bb';
}
.fa-duotone.fa-air-conditioner:after,
.fad.fa-air-conditioner:after {
  content: '\10f8f4';
}
.fa-duotone.fa-air-freshener:after,
.fad.fa-air-freshener:after {
  content: '\10f5d0';
}
.fa-duotone.fa-airplay:after,
.fad.fa-airplay:after {
  content: '\10e094';
}
.fa-duotone.fa-alarm-clock:after,
.fad.fa-alarm-clock:after {
  content: '\10f34e';
}
.fa-duotone.fa-alarm-exclamation:after,
.fad.fa-alarm-exclamation:after {
  content: '\10f843';
}
.fa-duotone.fa-alarm-plus:after,
.fad.fa-alarm-plus:after {
  content: '\10f844';
}
.fa-duotone.fa-alarm-snooze:after,
.fad.fa-alarm-snooze:after {
  content: '\10f845';
}
.fa-duotone.fa-album:after,
.fad.fa-album:after {
  content: '\10f89f';
}
.fa-duotone.fa-album-collection:after,
.fad.fa-album-collection:after {
  content: '\10f8a0';
}
.fa-duotone.fa-alicorn:after,
.fad.fa-alicorn:after {
  content: '\10f6b0';
}
.fa-duotone.fa-alien:after,
.fad.fa-alien:after {
  content: '\10f8f5';
}
.fa-duotone.fa-alien-8bit:after,
.fa-duotone.fa-alien-monster:after,
.fad.fa-alien-8bit:after,
.fad.fa-alien-monster:after {
  content: '\10f8f6';
}
.fa-duotone.fa-align-center:after,
.fad.fa-align-center:after {
  content: '\10f037';
}
.fa-duotone.fa-align-justify:after,
.fad.fa-align-justify:after {
  content: '\10f039';
}
.fa-duotone.fa-align-left:after,
.fad.fa-align-left:after {
  content: '\10f036';
}
.fa-duotone.fa-align-right:after,
.fad.fa-align-right:after {
  content: '\10f038';
}
.fa-duotone.fa-align-slash:after,
.fad.fa-align-slash:after {
  content: '\10f846';
}
.fa-duotone.fa-alt:after,
.fad.fa-alt:after {
  content: '\10e095';
}
.fa-duotone.fa-amp-guitar:after,
.fad.fa-amp-guitar:after {
  content: '\10f8a1';
}
.fa-duotone.fa-ampersand:after,
.fad.fa-ampersand:after {
  content: '\10e096';
}
.fa-duotone.fa-anchor:after,
.fad.fa-anchor:after {
  content: '\10f13d';
}
.fa-duotone.fa-angel:after,
.fad.fa-angel:after {
  content: '\10f779';
}
.fa-duotone.fa-angle:after,
.fad.fa-angle:after {
  content: '\10e097';
}
.fa-duotone.fa-angle-90:after,
.fad.fa-angle-90:after {
  content: '\10e098';
}
.fa-duotone.fa-angle-down:after,
.fad.fa-angle-down:after {
  content: '\10f107';
}
.fa-duotone.fa-angle-left:after,
.fad.fa-angle-left:after {
  content: '\10f104';
}
.fa-duotone.fa-angle-right:after,
.fad.fa-angle-right:after {
  content: '\10f105';
}
.fa-duotone.fa-angle-up:after,
.fad.fa-angle-up:after {
  content: '\10f106';
}
.fa-duotone.fa-angle-double-down:after,
.fa-duotone.fa-angles-down:after,
.fad.fa-angle-double-down:after,
.fad.fa-angles-down:after {
  content: '\10f103';
}
.fa-duotone.fa-angle-double-left:after,
.fa-duotone.fa-angles-left:after,
.fad.fa-angle-double-left:after,
.fad.fa-angles-left:after {
  content: '\10f100';
}
.fa-duotone.fa-angle-double-right:after,
.fa-duotone.fa-angles-right:after,
.fad.fa-angle-double-right:after,
.fad.fa-angles-right:after {
  content: '\10f101';
}
.fa-duotone.fa-angle-double-up:after,
.fa-duotone.fa-angles-up:after,
.fad.fa-angle-double-up:after,
.fad.fa-angles-up:after {
  content: '\10f102';
}
.fa-duotone.fa-ankh:after,
.fad.fa-ankh:after {
  content: '\10f644';
}
.fa-duotone.fa-aperture:after,
.fad.fa-aperture:after {
  content: '\10e099';
}
.fa-duotone.fa-apple-core:after,
.fad.fa-apple-core:after {
  content: '\10e09a';
}
.fa-duotone.fa-apple-crate:after,
.fad.fa-apple-crate:after {
  content: '\10f6b1';
}
.fa-duotone.fa-apple-alt:after,
.fa-duotone.fa-apple-whole:after,
.fad.fa-apple-alt:after,
.fad.fa-apple-whole:after {
  content: '\10f5d1';
}
.fa-duotone.fa-archway:after,
.fad.fa-archway:after {
  content: '\10f557';
}
.fa-duotone.fa-arrow-down:after,
.fad.fa-arrow-down:after {
  content: '\10f063';
}
.fa-duotone.fa-arrow-down-1-9:after,
.fa-duotone.fa-sort-numeric-asc:after,
.fa-duotone.fa-sort-numeric-down:after,
.fad.fa-arrow-down-1-9:after,
.fad.fa-sort-numeric-asc:after,
.fad.fa-sort-numeric-down:after {
  content: '\10f162';
}
.fa-duotone.fa-arrow-down-9-1:after,
.fa-duotone.fa-sort-numeric-desc:after,
.fa-duotone.fa-sort-numeric-down-alt:after,
.fad.fa-arrow-down-9-1:after,
.fad.fa-sort-numeric-desc:after,
.fad.fa-sort-numeric-down-alt:after {
  content: '\10f886';
}
.fa-duotone.fa-arrow-down-a-z:after,
.fa-duotone.fa-sort-alpha-asc:after,
.fa-duotone.fa-sort-alpha-down:after,
.fad.fa-arrow-down-a-z:after,
.fad.fa-sort-alpha-asc:after,
.fad.fa-sort-alpha-down:after {
  content: '\10f15d';
}
.fa-duotone.fa-arrow-down-arrow-up:after,
.fa-duotone.fa-sort-alt:after,
.fad.fa-arrow-down-arrow-up:after,
.fad.fa-sort-alt:after {
  content: '\10f883';
}
.fa-duotone.fa-arrow-down-big-small:after,
.fa-duotone.fa-sort-size-down:after,
.fad.fa-arrow-down-big-small:after,
.fad.fa-sort-size-down:after {
  content: '\10f88c';
}
.fa-duotone.fa-arrow-down-from-dotted-line:after,
.fad.fa-arrow-down-from-dotted-line:after {
  content: '\10e09b';
}
.fa-duotone.fa-arrow-down-from-line:after,
.fa-duotone.fa-arrow-from-top:after,
.fad.fa-arrow-down-from-line:after,
.fad.fa-arrow-from-top:after {
  content: '\10f345';
}
.fa-duotone.fa-arrow-down-left:after,
.fad.fa-arrow-down-left:after {
  content: '\10e09c';
}
.fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after,
.fad.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: '\10e09d';
}
.fa-duotone.fa-arrow-down-long:after,
.fa-duotone.fa-long-arrow-down:after,
.fad.fa-arrow-down-long:after,
.fad.fa-long-arrow-down:after {
  content: '\10f175';
}
.fa-duotone.fa-arrow-down-right:after,
.fad.fa-arrow-down-right:after {
  content: '\10e09e';
}
.fa-duotone.fa-arrow-down-short-wide:after,
.fa-duotone.fa-sort-amount-desc:after,
.fa-duotone.fa-sort-amount-down-alt:after,
.fad.fa-arrow-down-short-wide:after,
.fad.fa-sort-amount-desc:after,
.fad.fa-sort-amount-down-alt:after {
  content: '\10f884';
}
.fa-duotone.fa-arrow-down-small-big:after,
.fa-duotone.fa-sort-size-down-alt:after,
.fad.fa-arrow-down-small-big:after,
.fad.fa-sort-size-down-alt:after {
  content: '\10f88d';
}
.fa-duotone.fa-arrow-down-square-triangle:after,
.fa-duotone.fa-sort-shapes-down-alt:after,
.fad.fa-arrow-down-square-triangle:after,
.fad.fa-sort-shapes-down-alt:after {
  content: '\10f889';
}
.fa-duotone.fa-arrow-down-to-bracket:after,
.fad.fa-arrow-down-to-bracket:after {
  content: '\10e09f';
}
.fa-duotone.fa-arrow-down-to-dotted-line:after,
.fad.fa-arrow-down-to-dotted-line:after {
  content: '\10e0a0';
}
.fa-duotone.fa-arrow-down-to-line:after,
.fa-duotone.fa-arrow-to-bottom:after,
.fad.fa-arrow-down-to-line:after,
.fad.fa-arrow-to-bottom:after {
  content: '\10f33d';
}
.fa-duotone.fa-arrow-down-to-square:after,
.fad.fa-arrow-down-to-square:after {
  content: '\10e0a1';
}
.fa-duotone.fa-arrow-down-triangle-square:after,
.fa-duotone.fa-sort-shapes-down:after,
.fad.fa-arrow-down-triangle-square:after,
.fad.fa-sort-shapes-down:after {
  content: '\10f888';
}
.fa-duotone.fa-arrow-down-wide-short:after,
.fa-duotone.fa-sort-amount-asc:after,
.fa-duotone.fa-sort-amount-down:after,
.fad.fa-arrow-down-wide-short:after,
.fad.fa-sort-amount-asc:after,
.fad.fa-sort-amount-down:after {
  content: '\10f160';
}
.fa-duotone.fa-arrow-down-z-a:after,
.fa-duotone.fa-sort-alpha-desc:after,
.fa-duotone.fa-sort-alpha-down-alt:after,
.fad.fa-arrow-down-z-a:after,
.fad.fa-sort-alpha-desc:after,
.fad.fa-sort-alpha-down-alt:after {
  content: '\10f881';
}
.fa-duotone.fa-arrow-left:after,
.fad.fa-arrow-left:after {
  content: '\10f060';
}
.fa-duotone.fa-arrow-from-right:after,
.fa-duotone.fa-arrow-left-from-line:after,
.fad.fa-arrow-from-right:after,
.fad.fa-arrow-left-from-line:after {
  content: '\10f344';
}
.fa-duotone.fa-arrow-left-long:after,
.fa-duotone.fa-long-arrow-left:after,
.fad.fa-arrow-left-long:after,
.fad.fa-long-arrow-left:after {
  content: '\10f177';
}
.fa-duotone.fa-arrow-left-rotate:after,
.fa-duotone.fa-arrow-rotate-left:after,
.fa-duotone.fa-undo:after,
.fad.fa-arrow-left-rotate:after,
.fad.fa-arrow-rotate-left:after,
.fad.fa-undo:after {
  content: '\10f0e2';
}
.fa-duotone.fa-arrow-left-to-line:after,
.fa-duotone.fa-arrow-to-left:after,
.fad.fa-arrow-left-to-line:after,
.fad.fa-arrow-to-left:after {
  content: '\10f33e';
}
.fa-duotone.fa-arrow-pointer:after,
.fa-duotone.fa-mouse-pointer:after,
.fad.fa-arrow-pointer:after,
.fad.fa-mouse-pointer:after {
  content: '\10f245';
}
.fa-duotone.fa-arrow-right:after,
.fad.fa-arrow-right:after {
  content: '\10f061';
}
.fa-duotone.fa-arrow-right-arrow-left:after,
.fa-duotone.fa-exchange:after,
.fad.fa-arrow-right-arrow-left:after,
.fad.fa-exchange:after {
  content: '\10f0ec';
}
.fa-duotone.fa-arrow-right-from-bracket:after,
.fa-duotone.fa-sign-out:after,
.fad.fa-arrow-right-from-bracket:after,
.fad.fa-sign-out:after {
  content: '\10f08b';
}
.fa-duotone.fa-arrow-from-left:after,
.fa-duotone.fa-arrow-right-from-line:after,
.fad.fa-arrow-from-left:after,
.fad.fa-arrow-right-from-line:after {
  content: '\10f343';
}
.fa-duotone.fa-arrow-right-long:after,
.fa-duotone.fa-long-arrow-right:after,
.fad.fa-arrow-right-long:after,
.fad.fa-long-arrow-right:after {
  content: '\10f178';
}
.fa-duotone.fa-arrow-right-to-bracket:after,
.fa-duotone.fa-sign-in:after,
.fad.fa-arrow-right-to-bracket:after,
.fad.fa-sign-in:after {
  content: '\10f090';
}
.fa-duotone.fa-arrow-right-to-line:after,
.fa-duotone.fa-arrow-to-right:after,
.fad.fa-arrow-right-to-line:after,
.fad.fa-arrow-to-right:after {
  content: '\10f340';
}
.fa-duotone.fa-arrow-rotate-forward:after,
.fa-duotone.fa-redo:after,
.fa-duotone.fa-rotate-right:after,
.fad.fa-arrow-rotate-forward:after,
.fad.fa-redo:after,
.fad.fa-rotate-right:after {
  content: '\10f01e';
}
.fa-duotone.fa-arrow-trend-down:after,
.fad.fa-arrow-trend-down:after {
  content: '\10e0a2';
}
.fa-duotone.fa-arrow-trend-up:after,
.fad.fa-arrow-trend-up:after {
  content: '\10e0a3';
}
.fa-duotone.fa-arrow-turn-down:after,
.fa-duotone.fa-level-down:after,
.fad.fa-arrow-turn-down:after,
.fad.fa-level-down:after {
  content: '\10f149';
}
.fa-duotone.fa-arrow-turn-up:after,
.fa-duotone.fa-level-up:after,
.fad.fa-arrow-turn-up:after,
.fad.fa-level-up:after {
  content: '\10f148';
}
.fa-duotone.fa-arrow-up:after,
.fad.fa-arrow-up:after {
  content: '\10f062';
}
.fa-duotone.fa-arrow-up-1-9:after,
.fa-duotone.fa-sort-numeric-up:after,
.fad.fa-arrow-up-1-9:after,
.fad.fa-sort-numeric-up:after {
  content: '\10f163';
}
.fa-duotone.fa-arrow-up-9-1:after,
.fa-duotone.fa-sort-numeric-up-alt:after,
.fad.fa-arrow-up-9-1:after,
.fad.fa-sort-numeric-up-alt:after {
  content: '\10f887';
}
.fa-duotone.fa-arrow-up-a-z:after,
.fa-duotone.fa-sort-alpha-up:after,
.fad.fa-arrow-up-a-z:after,
.fad.fa-sort-alpha-up:after {
  content: '\10f15e';
}
.fa-duotone.fa-arrow-up-arrow-down:after,
.fa-duotone.fa-sort-up-down:after,
.fad.fa-arrow-up-arrow-down:after,
.fad.fa-sort-up-down:after {
  content: '\10e0a4';
}
.fa-duotone.fa-arrow-up-big-small:after,
.fa-duotone.fa-sort-size-up:after,
.fad.fa-arrow-up-big-small:after,
.fad.fa-sort-size-up:after {
  content: '\10f88e';
}
.fa-duotone.fa-arrow-up-from-bracket:after,
.fad.fa-arrow-up-from-bracket:after {
  content: '\10e0a5';
}
.fa-duotone.fa-arrow-up-from-dotted-line:after,
.fad.fa-arrow-up-from-dotted-line:after {
  content: '\10e0a6';
}
.fa-duotone.fa-arrow-from-bottom:after,
.fa-duotone.fa-arrow-up-from-line:after,
.fad.fa-arrow-from-bottom:after,
.fad.fa-arrow-up-from-line:after {
  content: '\10f342';
}
.fa-duotone.fa-arrow-up-from-square:after,
.fad.fa-arrow-up-from-square:after {
  content: '\10e0a7';
}
.fa-duotone.fa-arrow-up-left:after,
.fad.fa-arrow-up-left:after {
  content: '\10e0a8';
}
.fa-duotone.fa-arrow-up-left-from-circle:after,
.fad.fa-arrow-up-left-from-circle:after {
  content: '\10e0a9';
}
.fa-duotone.fa-arrow-up-long:after,
.fa-duotone.fa-long-arrow-up:after,
.fad.fa-arrow-up-long:after,
.fad.fa-long-arrow-up:after {
  content: '\10f176';
}
.fa-duotone.fa-arrow-up-right:after,
.fad.fa-arrow-up-right:after {
  content: '\10e0aa';
}
.fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after,
.fad.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: '\10e0ab';
}
.fa-duotone.fa-arrow-up-right-from-square:after,
.fa-duotone.fa-external-link:after,
.fad.fa-arrow-up-right-from-square:after,
.fad.fa-external-link:after {
  content: '\10f08e';
}
.fa-duotone.fa-arrow-up-short-wide:after,
.fa-duotone.fa-sort-amount-up-alt:after,
.fad.fa-arrow-up-short-wide:after,
.fad.fa-sort-amount-up-alt:after {
  content: '\10f885';
}
.fa-duotone.fa-arrow-up-small-big:after,
.fa-duotone.fa-sort-size-up-alt:after,
.fad.fa-arrow-up-small-big:after,
.fad.fa-sort-size-up-alt:after {
  content: '\10f88f';
}
.fa-duotone.fa-arrow-up-square-triangle:after,
.fa-duotone.fa-sort-shapes-up-alt:after,
.fad.fa-arrow-up-square-triangle:after,
.fad.fa-sort-shapes-up-alt:after {
  content: '\10f88b';
}
.fa-duotone.fa-arrow-up-to-dotted-line:after,
.fad.fa-arrow-up-to-dotted-line:after {
  content: '\10e0ac';
}
.fa-duotone.fa-arrow-to-top:after,
.fa-duotone.fa-arrow-up-to-line:after,
.fad.fa-arrow-to-top:after,
.fad.fa-arrow-up-to-line:after {
  content: '\10f341';
}
.fa-duotone.fa-arrow-up-triangle-square:after,
.fa-duotone.fa-sort-shapes-up:after,
.fad.fa-arrow-up-triangle-square:after,
.fad.fa-sort-shapes-up:after {
  content: '\10f88a';
}
.fa-duotone.fa-arrow-up-wide-short:after,
.fa-duotone.fa-sort-amount-up:after,
.fad.fa-arrow-up-wide-short:after,
.fad.fa-sort-amount-up:after {
  content: '\10f161';
}
.fa-duotone.fa-arrow-up-z-a:after,
.fa-duotone.fa-sort-alpha-up-alt:after,
.fad.fa-arrow-up-z-a:after,
.fad.fa-sort-alpha-up-alt:after {
  content: '\10f882';
}
.fa-duotone.fa-arrows-cross:after,
.fad.fa-arrows-cross:after {
  content: '\10e0ad';
}
.fa-duotone.fa-arrows-from-dotted-line:after,
.fad.fa-arrows-from-dotted-line:after {
  content: '\10e0ae';
}
.fa-duotone.fa-arrows-from-line:after,
.fad.fa-arrows-from-line:after {
  content: '\10e0af';
}
.fa-duotone.fa-arrows-h:after,
.fa-duotone.fa-arrows-left-right:after,
.fad.fa-arrows-h:after,
.fad.fa-arrows-left-right:after {
  content: '\10f07e';
}
.fa-duotone.fa-arrows-maximize:after,
.fa-duotone.fa-expand-arrows:after,
.fad.fa-arrows-maximize:after,
.fad.fa-expand-arrows:after {
  content: '\10f31d';
}
.fa-duotone.fa-arrows-minimize:after,
.fa-duotone.fa-compress-arrows:after,
.fad.fa-arrows-minimize:after,
.fad.fa-compress-arrows:after {
  content: '\10e0b0';
}
.fa-duotone.fa-arrows-repeat:after,
.fa-duotone.fa-repeat-alt:after,
.fad.fa-arrows-repeat:after,
.fad.fa-repeat-alt:after {
  content: '\10f364';
}
.fa-duotone.fa-arrows-repeat-1:after,
.fa-duotone.fa-repeat-1-alt:after,
.fad.fa-arrows-repeat-1:after,
.fad.fa-repeat-1-alt:after {
  content: '\10f366';
}
.fa-duotone.fa-arrows-retweet:after,
.fa-duotone.fa-retweet-alt:after,
.fad.fa-arrows-retweet:after,
.fad.fa-retweet-alt:after {
  content: '\10f361';
}
.fa-duotone.fa-arrows-rotate:after,
.fa-duotone.fa-refresh:after,
.fa-duotone.fa-sync:after,
.fad.fa-arrows-rotate:after,
.fad.fa-refresh:after,
.fad.fa-sync:after {
  content: '\10f021';
}
.fa-duotone.fa-arrows-to-dotted-line:after,
.fad.fa-arrows-to-dotted-line:after {
  content: '\10e0b1';
}
.fa-duotone.fa-arrows-to-line:after,
.fad.fa-arrows-to-line:after {
  content: '\10e0b2';
}
.fa-duotone.fa-arrows-up-down:after,
.fa-duotone.fa-arrows-v:after,
.fad.fa-arrows-up-down:after,
.fad.fa-arrows-v:after {
  content: '\10f07d';
}
.fa-duotone.fa-arrows-up-down-left-right:after,
.fa-duotone.fa-arrows:after,
.fad.fa-arrows-up-down-left-right:after,
.fad.fa-arrows:after {
  content: '\10f047';
}
.fa-duotone.fa-asterisk:after,
.fad.fa-asterisk:after {
  content: '\10f069';
}
.fa-duotone.fa-at:after,
.fad.fa-at:after {
  content: '\10f1fa';
}
.fa-duotone.fa-atom:after,
.fad.fa-atom:after {
  content: '\10f5d2';
}
.fa-duotone.fa-atom-alt:after,
.fa-duotone.fa-atom-simple:after,
.fad.fa-atom-alt:after,
.fad.fa-atom-simple:after {
  content: '\10f5d3';
}
.fa-duotone.fa-audio-description:after,
.fad.fa-audio-description:after {
  content: '\10f29e';
}
.fa-duotone.fa-audio-description-slash:after,
.fad.fa-audio-description-slash:after {
  content: '\10e0b3';
}
.fa-duotone.fa-austral-sign:after,
.fad.fa-austral-sign:after {
  content: '\10e0b4';
}
.fa-duotone.fa-avocado:after,
.fad.fa-avocado:after {
  content: '\10e0b5';
}
.fa-duotone.fa-award:after,
.fad.fa-award:after {
  content: '\10f559';
}
.fa-duotone.fa-award-simple:after,
.fad.fa-award-simple:after {
  content: '\10e0b6';
}
.fa-duotone.fa-axe:after,
.fad.fa-axe:after {
  content: '\10f6b2';
}
.fa-duotone.fa-axe-battle:after,
.fad.fa-axe-battle:after {
  content: '\10f6b3';
}
.fa-duotone.fa-b:after,
.fad.fa-b:after {
  content: '\10e0b7';
}
.fa-duotone.fa-baby:after,
.fad.fa-baby:after {
  content: '\10f77c';
}
.fa-duotone.fa-baby-carriage:after,
.fa-duotone.fa-carriage-baby:after,
.fad.fa-baby-carriage:after,
.fad.fa-carriage-baby:after {
  content: '\10f77d';
}
.fa-duotone.fa-backpack:after,
.fad.fa-backpack:after {
  content: '\10f5d4';
}
.fa-duotone.fa-backward:after,
.fad.fa-backward:after {
  content: '\10f04a';
}
.fa-duotone.fa-backward-fast:after,
.fa-duotone.fa-fast-backward:after,
.fad.fa-backward-fast:after,
.fad.fa-fast-backward:after {
  content: '\10f049';
}
.fa-duotone.fa-backward-step:after,
.fa-duotone.fa-step-backward:after,
.fad.fa-backward-step:after,
.fad.fa-step-backward:after {
  content: '\10f048';
}
.fa-duotone.fa-bacon:after,
.fad.fa-bacon:after {
  content: '\10f7e5';
}
.fa-duotone.fa-bacteria:after,
.fad.fa-bacteria:after {
  content: '\10e059';
}
.fa-duotone.fa-bacterium:after,
.fad.fa-bacterium:after {
  content: '\10e05a';
}
.fa-duotone.fa-badge:after,
.fad.fa-badge:after {
  content: '\10f335';
}
.fa-duotone.fa-badge-check:after,
.fad.fa-badge-check:after {
  content: '\10f336';
}
.fa-duotone.fa-badge-dollar:after,
.fad.fa-badge-dollar:after {
  content: '\10f645';
}
.fa-duotone.fa-badge-percent:after,
.fad.fa-badge-percent:after {
  content: '\10f646';
}
.fa-duotone.fa-badge-sheriff:after,
.fad.fa-badge-sheriff:after {
  content: '\10f8a2';
}
.fa-duotone.fa-badger-honey:after,
.fad.fa-badger-honey:after {
  content: '\10f6b4';
}
.fa-duotone.fa-bag-shopping:after,
.fa-duotone.fa-shopping-bag:after,
.fad.fa-bag-shopping:after,
.fad.fa-shopping-bag:after {
  content: '\10f290';
}
.fa-duotone.fa-bags-shopping:after,
.fad.fa-bags-shopping:after {
  content: '\10f847';
}
.fa-duotone.fa-bahai:after,
.fad.fa-bahai:after {
  content: '\10f666';
}
.fa-duotone.fa-baht-sign:after,
.fad.fa-baht-sign:after {
  content: '\10e0b8';
}
.fa-duotone.fa-ball-pile:after,
.fad.fa-ball-pile:after {
  content: '\10f77e';
}
.fa-duotone.fa-ballot:after,
.fad.fa-ballot:after {
  content: '\10f732';
}
.fa-duotone.fa-ballot-check:after,
.fad.fa-ballot-check:after {
  content: '\10f733';
}
.fa-duotone.fa-ban:after,
.fa-duotone.fa-cancel:after,
.fad.fa-ban:after,
.fad.fa-cancel:after {
  content: '\10f05e';
}
.fa-duotone.fa-ban-bug:after,
.fa-duotone.fa-debug:after,
.fad.fa-ban-bug:after,
.fad.fa-debug:after {
  content: '\10f7f9';
}
.fa-duotone.fa-ban-parking:after,
.fa-duotone.fa-parking-circle-slash:after,
.fad.fa-ban-parking:after,
.fad.fa-parking-circle-slash:after {
  content: '\10f616';
}
.fa-duotone.fa-ban-smoking:after,
.fa-duotone.fa-smoking-ban:after,
.fad.fa-ban-smoking:after,
.fad.fa-smoking-ban:after {
  content: '\10f54d';
}
.fa-duotone.fa-band-aid:after,
.fa-duotone.fa-bandage:after,
.fad.fa-band-aid:after,
.fad.fa-bandage:after {
  content: '\10f462';
}
.fa-duotone.fa-banjo:after,
.fad.fa-banjo:after {
  content: '\10f8a3';
}
.fa-duotone.fa-bank:after,
.fa-duotone.fa-institution:after,
.fa-duotone.fa-university:after,
.fad.fa-bank:after,
.fad.fa-institution:after,
.fad.fa-university:after {
  content: '\10f19c';
}
.fa-duotone.fa-barcode:after,
.fad.fa-barcode:after {
  content: '\10f02a';
}
.fa-duotone.fa-barcode-read:after,
.fad.fa-barcode-read:after {
  content: '\10f464';
}
.fa-duotone.fa-barcode-scan:after,
.fad.fa-barcode-scan:after {
  content: '\10f465';
}
.fa-duotone.fa-bars:after,
.fa-duotone.fa-navicon:after,
.fad.fa-bars:after,
.fad.fa-navicon:after {
  content: '\10f0c9';
}
.fa-duotone.fa-bars-filter:after,
.fad.fa-bars-filter:after {
  content: '\10e0b9';
}
.fa-duotone.fa-bars-progress:after,
.fa-duotone.fa-tasks-alt:after,
.fad.fa-bars-progress:after,
.fad.fa-tasks-alt:after {
  content: '\10f828';
}
.fa-duotone.fa-bars-sort:after,
.fad.fa-bars-sort:after {
  content: '\10e0ba';
}
.fa-duotone.fa-bars-staggered:after,
.fa-duotone.fa-reorder:after,
.fa-duotone.fa-stream:after,
.fad.fa-bars-staggered:after,
.fad.fa-reorder:after,
.fad.fa-stream:after {
  content: '\10f550';
}
.fa-duotone.fa-baseball-ball:after,
.fad.fa-baseball-ball:after {
  content: '\10f433';
}
.fa-duotone.fa-baseball-bat-ball:after,
.fa-duotone.fa-baseball:after,
.fad.fa-baseball-bat-ball:after,
.fad.fa-baseball:after {
  content: '\10f432';
}
.fa-duotone.fa-basket-shopping:after,
.fa-duotone.fa-shopping-basket:after,
.fad.fa-basket-shopping:after,
.fad.fa-shopping-basket:after {
  content: '\10f291';
}
.fa-duotone.fa-basket-shopping-simple:after,
.fa-duotone.fa-shopping-basket-alt:after,
.fad.fa-basket-shopping-simple:after,
.fad.fa-shopping-basket-alt:after {
  content: '\10e0bb';
}
.fa-duotone.fa-basketball-ball:after,
.fad.fa-basketball-ball:after {
  content: '\10f434';
}
.fa-duotone.fa-basketball-hoop:after,
.fad.fa-basketball-hoop:after {
  content: '\10f435';
}
.fa-duotone.fa-bat:after,
.fad.fa-bat:after {
  content: '\10f6b5';
}
.fa-duotone.fa-bath:after,
.fa-duotone.fa-bathtub:after,
.fad.fa-bath:after,
.fad.fa-bathtub:after {
  content: '\10f2cd';
}
.fa-duotone.fa-battery-bolt:after,
.fad.fa-battery-bolt:after {
  content: '\10f376';
}
.fa-duotone.fa-battery-0:after,
.fa-duotone.fa-battery-empty:after,
.fad.fa-battery-0:after,
.fad.fa-battery-empty:after {
  content: '\10f244';
}
.fa-duotone.fa-battery-exclamation:after,
.fad.fa-battery-exclamation:after {
  content: '\10e0bc';
}
.fa-duotone.fa-battery-5:after,
.fa-duotone.fa-battery-full:after,
.fa-duotone.fa-battery:after,
.fad.fa-battery-5:after,
.fad.fa-battery-full:after,
.fad.fa-battery:after {
  content: '\10f240';
}
.fa-duotone.fa-battery-3:after,
.fa-duotone.fa-battery-half:after,
.fad.fa-battery-3:after,
.fad.fa-battery-half:after {
  content: '\10f242';
}
.fa-duotone.fa-battery-1:after,
.fa-duotone.fa-battery-low:after,
.fad.fa-battery-1:after,
.fad.fa-battery-low:after {
  content: '\10e0bd';
}
.fa-duotone.fa-battery-2:after,
.fa-duotone.fa-battery-quarter:after,
.fad.fa-battery-2:after,
.fad.fa-battery-quarter:after {
  content: '\10f243';
}
.fa-duotone.fa-battery-slash:after,
.fad.fa-battery-slash:after {
  content: '\10f377';
}
.fa-duotone.fa-battery-4:after,
.fa-duotone.fa-battery-three-quarters:after,
.fad.fa-battery-4:after,
.fad.fa-battery-three-quarters:after {
  content: '\10f241';
}
.fa-duotone.fa-bed:after,
.fad.fa-bed:after {
  content: '\10f236';
}
.fa-duotone.fa-bed-bunk:after,
.fad.fa-bed-bunk:after {
  content: '\10f8f8';
}
.fa-duotone.fa-bed-empty:after,
.fad.fa-bed-empty:after {
  content: '\10f8f9';
}
.fa-duotone.fa-bed-alt:after,
.fa-duotone.fa-bed-front:after,
.fad.fa-bed-alt:after,
.fad.fa-bed-front:after {
  content: '\10f8f7';
}
.fa-duotone.fa-bed-pulse:after,
.fa-duotone.fa-procedures:after,
.fad.fa-bed-pulse:after,
.fad.fa-procedures:after {
  content: '\10f487';
}
.fa-duotone.fa-bee:after,
.fad.fa-bee:after {
  content: '\10e0be';
}
.fa-duotone.fa-beer-foam:after,
.fa-duotone.fa-beer-mug:after,
.fad.fa-beer-foam:after,
.fad.fa-beer-mug:after {
  content: '\10e0bf';
}
.fa-duotone.fa-beer-mug-empty:after,
.fa-duotone.fa-beer:after,
.fad.fa-beer-mug-empty:after,
.fad.fa-beer:after {
  content: '\10f0fc';
}
.fa-duotone.fa-bell:after,
.fad.fa-bell:after {
  content: '\10f0f3';
}
.fa-duotone.fa-bell-concierge:after,
.fa-duotone.fa-concierge-bell:after,
.fad.fa-bell-concierge:after,
.fad.fa-concierge-bell:after {
  content: '\10f562';
}
.fa-duotone.fa-bell-exclamation:after,
.fad.fa-bell-exclamation:after {
  content: '\10f848';
}
.fa-duotone.fa-bell-on:after,
.fad.fa-bell-on:after {
  content: '\10f8fa';
}
.fa-duotone.fa-bell-plus:after,
.fad.fa-bell-plus:after {
  content: '\10f849';
}
.fa-duotone.fa-bell-school:after,
.fad.fa-bell-school:after {
  content: '\10f5d5';
}
.fa-duotone.fa-bell-school-slash:after,
.fad.fa-bell-school-slash:after {
  content: '\10f5d6';
}
.fa-duotone.fa-bell-slash:after,
.fad.fa-bell-slash:after {
  content: '\10f1f6';
}
.fa-duotone.fa-bells:after,
.fad.fa-bells:after {
  content: '\10f77f';
}
.fa-duotone.fa-bezier-curve:after,
.fad.fa-bezier-curve:after {
  content: '\10f55b';
}
.fa-duotone.fa-bicycle:after,
.fad.fa-bicycle:after {
  content: '\10f206';
}
.fa-duotone.fa-binoculars:after,
.fad.fa-binoculars:after {
  content: '\10f1e5';
}
.fa-duotone.fa-biohazard:after,
.fad.fa-biohazard:after {
  content: '\10f780';
}
.fa-duotone.fa-bitcoin-sign:after,
.fad.fa-bitcoin-sign:after {
  content: '\10e0c0';
}
.fa-duotone.fa-blanket:after,
.fad.fa-blanket:after {
  content: '\10f498';
}
.fa-duotone.fa-blender:after,
.fad.fa-blender:after {
  content: '\10f517';
}
.fa-duotone.fa-blender-phone:after,
.fad.fa-blender-phone:after {
  content: '\10f6b6';
}
.fa-duotone.fa-blinds:after,
.fad.fa-blinds:after {
  content: '\10f8fb';
}
.fa-duotone.fa-blinds-open:after,
.fad.fa-blinds-open:after {
  content: '\10f8fc';
}
.fa-duotone.fa-blinds-raised:after,
.fad.fa-blinds-raised:after {
  content: '\10f8fd';
}
.fa-duotone.fa-block-quote:after,
.fad.fa-block-quote:after {
  content: '\10e0c1';
}
.fa-duotone.fa-blog:after,
.fad.fa-blog:after {
  content: '\10f781';
}
.fa-duotone.fa-bluetooth:after,
.fad.fa-bluetooth:after {
  content: '\10f293';
}
.fa-duotone.fa-bold:after,
.fad.fa-bold:after {
  content: '\10f032';
}
.fa-duotone.fa-bolt:after,
.fa-duotone.fa-flash:after,
.fad.fa-bolt:after,
.fad.fa-flash:after {
  content: '\10f0e7';
}
.fa-duotone.fa-bolt-auto:after,
.fad.fa-bolt-auto:after {
  content: '\10e0c2';
}
.fa-duotone.fa-bolt-lightning:after,
.fad.fa-bolt-lightning:after {
  content: '\10e0c3';
}
.fa-duotone.fa-bolt-slash:after,
.fad.fa-bolt-slash:after {
  content: '\10e0c4';
}
.fa-duotone.fa-bomb:after,
.fad.fa-bomb:after {
  content: '\10f1e2';
}
.fa-duotone.fa-bone:after,
.fad.fa-bone:after {
  content: '\10f5d7';
}
.fa-duotone.fa-bone-break:after,
.fad.fa-bone-break:after {
  content: '\10f5d8';
}
.fa-duotone.fa-bong:after,
.fad.fa-bong:after {
  content: '\10f55c';
}
.fa-duotone.fa-book:after,
.fad.fa-book:after {
  content: '\10f02d';
}
.fa-duotone.fa-book-arrow-right:after,
.fad.fa-book-arrow-right:after {
  content: '\10e0c5';
}
.fa-duotone.fa-book-arrow-up:after,
.fad.fa-book-arrow-up:after {
  content: '\10e0c6';
}
.fa-duotone.fa-atlas:after,
.fa-duotone.fa-book-atlas:after,
.fad.fa-atlas:after,
.fad.fa-book-atlas:after {
  content: '\10f558';
}
.fa-duotone.fa-bible:after,
.fa-duotone.fa-book-bible:after,
.fad.fa-bible:after,
.fad.fa-book-bible:after {
  content: '\10f647';
}
.fa-duotone.fa-book-alt:after,
.fa-duotone.fa-book-blank:after,
.fad.fa-book-alt:after,
.fad.fa-book-blank:after {
  content: '\10f5d9';
}
.fa-duotone.fa-book-bookmark:after,
.fad.fa-book-bookmark:after {
  content: '\10e0c7';
}
.fa-duotone.fa-book-circle-arrow-right:after,
.fad.fa-book-circle-arrow-right:after {
  content: '\10e0c8';
}
.fa-duotone.fa-book-circle-arrow-up:after,
.fad.fa-book-circle-arrow-up:after {
  content: '\10e0c9';
}
.fa-duotone.fa-book-copy:after,
.fad.fa-book-copy:after {
  content: '\10e0ca';
}
.fa-duotone.fa-book-font:after,
.fad.fa-book-font:after {
  content: '\10e0cb';
}
.fa-duotone.fa-book-heart:after,
.fad.fa-book-heart:after {
  content: '\10f499';
}
.fa-duotone.fa-book-journal-whills:after,
.fa-duotone.fa-journal-whills:after,
.fad.fa-book-journal-whills:after,
.fad.fa-journal-whills:after {
  content: '\10f66a';
}
.fa-duotone.fa-book-law:after,
.fad.fa-book-law:after {
  content: '\10e0cc';
}
.fa-duotone.fa-book-medical:after,
.fad.fa-book-medical:after {
  content: '\10f7e6';
}
.fa-duotone.fa-book-open:after,
.fad.fa-book-open:after {
  content: '\10f518';
}
.fa-duotone.fa-book-open-alt:after,
.fa-duotone.fa-book-open-cover:after,
.fad.fa-book-open-alt:after,
.fad.fa-book-open-cover:after {
  content: '\10e0cd';
}
.fa-duotone.fa-book-open-reader:after,
.fa-duotone.fa-book-reader:after,
.fad.fa-book-open-reader:after,
.fad.fa-book-reader:after {
  content: '\10f5da';
}
.fa-duotone.fa-book-quran:after,
.fa-duotone.fa-quran:after,
.fad.fa-book-quran:after,
.fad.fa-quran:after {
  content: '\10f687';
}
.fa-duotone.fa-book-section:after,
.fad.fa-book-section:after {
  content: '\10e0ce';
}
.fa-duotone.fa-book-dead:after,
.fa-duotone.fa-book-skull:after,
.fad.fa-book-dead:after,
.fad.fa-book-skull:after {
  content: '\10f6b7';
}
.fa-duotone.fa-book-sparkles:after,
.fa-duotone.fa-book-spells:after,
.fad.fa-book-sparkles:after,
.fad.fa-book-spells:after {
  content: '\10f6b8';
}
.fa-duotone.fa-book-tanakh:after,
.fa-duotone.fa-tanakh:after,
.fad.fa-book-tanakh:after,
.fad.fa-tanakh:after {
  content: '\10f827';
}
.fa-duotone.fa-book-user:after,
.fad.fa-book-user:after {
  content: '\10f7e7';
}
.fa-duotone.fa-bookmark:after,
.fad.fa-bookmark:after {
  content: '\10f02e';
}
.fa-duotone.fa-bookmark-slash:after,
.fad.fa-bookmark-slash:after {
  content: '\10e0cf';
}
.fa-duotone.fa-books:after,
.fad.fa-books:after {
  content: '\10f5db';
}
.fa-duotone.fa-books-medical:after,
.fad.fa-books-medical:after {
  content: '\10f7e8';
}
.fa-duotone.fa-boombox:after,
.fad.fa-boombox:after {
  content: '\10f8a5';
}
.fa-duotone.fa-boot:after,
.fad.fa-boot:after {
  content: '\10f782';
}
.fa-duotone.fa-booth-curtain:after,
.fad.fa-booth-curtain:after {
  content: '\10f734';
}
.fa-duotone.fa-border-all:after,
.fad.fa-border-all:after {
  content: '\10f84c';
}
.fa-duotone.fa-border-bottom:after,
.fad.fa-border-bottom:after {
  content: '\10f84d';
}
.fa-duotone.fa-border-bottom-right:after,
.fa-duotone.fa-border-style-alt:after,
.fad.fa-border-bottom-right:after,
.fad.fa-border-style-alt:after {
  content: '\10f854';
}
.fa-duotone.fa-border-center-h:after,
.fad.fa-border-center-h:after {
  content: '\10f89c';
}
.fa-duotone.fa-border-center-v:after,
.fad.fa-border-center-v:after {
  content: '\10f89d';
}
.fa-duotone.fa-border-inner:after,
.fad.fa-border-inner:after {
  content: '\10f84e';
}
.fa-duotone.fa-border-left:after,
.fad.fa-border-left:after {
  content: '\10f84f';
}
.fa-duotone.fa-border-none:after,
.fad.fa-border-none:after {
  content: '\10f850';
}
.fa-duotone.fa-border-outer:after,
.fad.fa-border-outer:after {
  content: '\10f851';
}
.fa-duotone.fa-border-right:after,
.fad.fa-border-right:after {
  content: '\10f852';
}
.fa-duotone.fa-border-top:after,
.fad.fa-border-top:after {
  content: '\10f855';
}
.fa-duotone.fa-border-style:after,
.fa-duotone.fa-border-top-left:after,
.fad.fa-border-style:after,
.fad.fa-border-top-left:after {
  content: '\10f853';
}
.fa-duotone.fa-bow-arrow:after,
.fad.fa-bow-arrow:after {
  content: '\10f6b9';
}
.fa-duotone.fa-bowl-hot:after,
.fa-duotone.fa-soup:after,
.fad.fa-bowl-hot:after,
.fad.fa-soup:after {
  content: '\10f823';
}
.fa-duotone.fa-bowling-ball:after,
.fad.fa-bowling-ball:after {
  content: '\10f436';
}
.fa-duotone.fa-bowling-ball-pin:after,
.fad.fa-bowling-ball-pin:after {
  content: '\10e0d0';
}
.fa-duotone.fa-bowling-pins:after,
.fad.fa-bowling-pins:after {
  content: '\10f437';
}
.fa-duotone.fa-box:after,
.fad.fa-box:after {
  content: '\10f466';
}
.fa-duotone.fa-archive:after,
.fa-duotone.fa-box-archive:after,
.fad.fa-archive:after,
.fad.fa-box-archive:after {
  content: '\10f187';
}
.fa-duotone.fa-box-ballot:after,
.fad.fa-box-ballot:after {
  content: '\10f735';
}
.fa-duotone.fa-box-check:after,
.fad.fa-box-check:after {
  content: '\10f467';
}
.fa-duotone.fa-box-circle-check:after,
.fad.fa-box-circle-check:after {
  content: '\10e0d1';
}
.fa-duotone.fa-box-dollar:after,
.fa-duotone.fa-box-usd:after,
.fad.fa-box-dollar:after,
.fad.fa-box-usd:after {
  content: '\10f4a0';
}
.fa-duotone.fa-box-heart:after,
.fad.fa-box-heart:after {
  content: '\10f49d';
}
.fa-duotone.fa-box-open:after,
.fad.fa-box-open:after {
  content: '\10f49e';
}
.fa-duotone.fa-box-full:after,
.fa-duotone.fa-box-open-full:after,
.fad.fa-box-full:after,
.fad.fa-box-open-full:after {
  content: '\10f49c';
}
.fa-duotone.fa-box-alt:after,
.fa-duotone.fa-box-taped:after,
.fad.fa-box-alt:after,
.fad.fa-box-taped:after {
  content: '\10f49a';
}
.fa-duotone.fa-box-tissue:after,
.fad.fa-box-tissue:after {
  content: '\10e05b';
}
.fa-duotone.fa-boxes-alt:after,
.fa-duotone.fa-boxes-stacked:after,
.fa-duotone.fa-boxes:after,
.fad.fa-boxes-alt:after,
.fad.fa-boxes-stacked:after,
.fad.fa-boxes:after {
  content: '\10f468';
}
.fa-duotone.fa-boxing-glove:after,
.fa-duotone.fa-glove-boxing:after,
.fad.fa-boxing-glove:after,
.fad.fa-glove-boxing:after {
  content: '\10f438';
}
.fa-duotone.fa-bracket-curly-left:after,
.fa-duotone.fa-bracket-curly:after,
.fad.fa-bracket-curly-left:after,
.fad.fa-bracket-curly:after {
  content: '\10e0d2';
}
.fa-duotone.fa-bracket-curly-right:after,
.fad.fa-bracket-curly-right:after {
  content: '\10e0d3';
}
.fa-duotone.fa-bracket-round:after,
.fa-duotone.fa-parenthesis:after,
.fad.fa-bracket-round:after,
.fad.fa-parenthesis:after {
  content: '\10e0d4';
}
.fa-duotone.fa-bracket-round-right:after,
.fad.fa-bracket-round-right:after {
  content: '\10e0d5';
}
.fa-duotone.fa-bracket-left:after,
.fa-duotone.fa-bracket-square:after,
.fa-duotone.fa-bracket:after,
.fad.fa-bracket-left:after,
.fad.fa-bracket-square:after,
.fad.fa-bracket:after {
  content: '\10e0d6';
}
.fa-duotone.fa-bracket-square-right:after,
.fad.fa-bracket-square-right:after {
  content: '\10e0d7';
}
.fa-duotone.fa-brackets-curly:after,
.fad.fa-brackets-curly:after {
  content: '\10f7ea';
}
.fa-duotone.fa-brackets-round:after,
.fa-duotone.fa-parentheses:after,
.fad.fa-brackets-round:after,
.fad.fa-parentheses:after {
  content: '\10e0d8';
}
.fa-duotone.fa-brackets-square:after,
.fa-duotone.fa-brackets:after,
.fad.fa-brackets-square:after,
.fad.fa-brackets:after {
  content: '\10f7e9';
}
.fa-duotone.fa-braille:after,
.fad.fa-braille:after {
  content: '\10f2a1';
}
.fa-duotone.fa-brain:after,
.fad.fa-brain:after {
  content: '\10f5dc';
}
.fa-duotone.fa-brain-arrow-curved-right:after,
.fa-duotone.fa-mind-share:after,
.fad.fa-brain-arrow-curved-right:after,
.fad.fa-mind-share:after {
  content: '\10f677';
}
.fa-duotone.fa-brain-circuit:after,
.fad.fa-brain-circuit:after {
  content: '\10e0d9';
}
.fa-duotone.fa-brake-warning:after,
.fad.fa-brake-warning:after {
  content: '\10e0da';
}
.fa-duotone.fa-bread-loaf:after,
.fad.fa-bread-loaf:after {
  content: '\10f7eb';
}
.fa-duotone.fa-bread-slice:after,
.fad.fa-bread-slice:after {
  content: '\10f7ec';
}
.fa-duotone.fa-briefcase:after,
.fad.fa-briefcase:after {
  content: '\10f0b1';
}
.fa-duotone.fa-briefcase-arrow-right:after,
.fad.fa-briefcase-arrow-right:after {
  content: '\10e0db';
}
.fa-duotone.fa-briefcase-clock:after,
.fa-duotone.fa-business-time:after,
.fad.fa-briefcase-clock:after,
.fad.fa-business-time:after {
  content: '\10f64a';
}
.fa-duotone.fa-briefcase-medical:after,
.fad.fa-briefcase-medical:after {
  content: '\10f469';
}
.fa-duotone.fa-bring-forward:after,
.fad.fa-bring-forward:after {
  content: '\10f856';
}
.fa-duotone.fa-bring-front:after,
.fad.fa-bring-front:after {
  content: '\10f857';
}
.fa-duotone.fa-broom:after,
.fad.fa-broom:after {
  content: '\10f51a';
}
.fa-duotone.fa-browser:after,
.fad.fa-browser:after {
  content: '\10f37e';
}
.fa-duotone.fa-browsers:after,
.fad.fa-browsers:after {
  content: '\10e0dc';
}
.fa-duotone.fa-brush:after,
.fad.fa-brush:after {
  content: '\10f55d';
}
.fa-duotone.fa-bug:after,
.fad.fa-bug:after {
  content: '\10f188';
}
.fa-duotone.fa-building:after,
.fad.fa-building:after {
  content: '\10f1ad';
}
.fa-duotone.fa-buildings:after,
.fad.fa-buildings:after {
  content: '\10e0dd';
}
.fa-duotone.fa-bullhorn:after,
.fad.fa-bullhorn:after {
  content: '\10f0a1';
}
.fa-duotone.fa-bullseye:after,
.fad.fa-bullseye:after {
  content: '\10f140';
}
.fa-duotone.fa-bullseye-arrow:after,
.fad.fa-bullseye-arrow:after {
  content: '\10f648';
}
.fa-duotone.fa-bullseye-pointer:after,
.fad.fa-bullseye-pointer:after {
  content: '\10f649';
}
.fa-duotone.fa-burger:after,
.fa-duotone.fa-hamburger:after,
.fad.fa-burger:after,
.fad.fa-hamburger:after {
  content: '\10f805';
}
.fa-duotone.fa-burger-cheese:after,
.fa-duotone.fa-cheeseburger:after,
.fad.fa-burger-cheese:after,
.fad.fa-cheeseburger:after {
  content: '\10f7f1';
}
.fa-duotone.fa-burger-fries:after,
.fad.fa-burger-fries:after {
  content: '\10e0de';
}
.fa-duotone.fa-burger-glass:after,
.fad.fa-burger-glass:after {
  content: '\10e0df';
}
.fa-duotone.fa-burger-soda:after,
.fad.fa-burger-soda:after {
  content: '\10f858';
}
.fa-duotone.fa-burrito:after,
.fad.fa-burrito:after {
  content: '\10f7ed';
}
.fa-duotone.fa-bus:after,
.fad.fa-bus:after {
  content: '\10f207';
}
.fa-duotone.fa-bus-school:after,
.fad.fa-bus-school:after {
  content: '\10f5dd';
}
.fa-duotone.fa-bus-alt:after,
.fa-duotone.fa-bus-simple:after,
.fad.fa-bus-alt:after,
.fad.fa-bus-simple:after {
  content: '\10f55e';
}
.fa-duotone.fa-c:after,
.fad.fa-c:after {
  content: '\10e0e0';
}
.fa-duotone.fa-cabinet-filing:after,
.fad.fa-cabinet-filing:after {
  content: '\10f64b';
}
.fa-duotone.fa-cable-car:after,
.fad.fa-cable-car:after {
  content: '\10e0e1';
}
.fa-duotone.fa-cactus:after,
.fad.fa-cactus:after {
  content: '\10f8a7';
}
.fa-duotone.fa-birthday-cake:after,
.fa-duotone.fa-cake-candles:after,
.fad.fa-birthday-cake:after,
.fad.fa-cake-candles:after {
  content: '\10f1fd';
}
.fa-duotone.fa-calculator:after,
.fad.fa-calculator:after {
  content: '\10f1ec';
}
.fa-duotone.fa-calculator-alt:after,
.fa-duotone.fa-calculator-simple:after,
.fad.fa-calculator-alt:after,
.fad.fa-calculator-simple:after {
  content: '\10f64c';
}
.fa-duotone.fa-calendar:after,
.fad.fa-calendar:after {
  content: '\10f133';
}
.fa-duotone.fa-calendar-arrow-down:after,
.fa-duotone.fa-calendar-download:after,
.fad.fa-calendar-arrow-down:after,
.fad.fa-calendar-download:after {
  content: '\10e0e2';
}
.fa-duotone.fa-calendar-arrow-up:after,
.fa-duotone.fa-calendar-upload:after,
.fad.fa-calendar-arrow-up:after,
.fad.fa-calendar-upload:after {
  content: '\10e0e3';
}
.fa-duotone.fa-calendar-check:after,
.fad.fa-calendar-check:after {
  content: '\10f274';
}
.fa-duotone.fa-calendar-clock:after,
.fa-duotone.fa-calendar-time:after,
.fad.fa-calendar-clock:after,
.fad.fa-calendar-time:after {
  content: '\10e0e4';
}
.fa-duotone.fa-calendar-day:after,
.fad.fa-calendar-day:after {
  content: '\10f783';
}
.fa-duotone.fa-calendar-alt:after,
.fa-duotone.fa-calendar-days:after,
.fad.fa-calendar-alt:after,
.fad.fa-calendar-days:after {
  content: '\10f073';
}
.fa-duotone.fa-calendar-exclamation:after,
.fad.fa-calendar-exclamation:after {
  content: '\10f334';
}
.fa-duotone.fa-calendar-heart:after,
.fad.fa-calendar-heart:after {
  content: '\10e0e5';
}
.fa-duotone.fa-calendar-image:after,
.fad.fa-calendar-image:after {
  content: '\10e0e6';
}
.fa-duotone.fa-calendar-lines:after,
.fa-duotone.fa-calendar-note:after,
.fad.fa-calendar-lines:after,
.fad.fa-calendar-note:after {
  content: '\10e0e7';
}
.fa-duotone.fa-calendar-minus:after,
.fad.fa-calendar-minus:after {
  content: '\10f272';
}
.fa-duotone.fa-calendar-edit:after,
.fa-duotone.fa-calendar-pen:after,
.fad.fa-calendar-edit:after,
.fad.fa-calendar-pen:after {
  content: '\10f333';
}
.fa-duotone.fa-calendar-plus:after,
.fad.fa-calendar-plus:after {
  content: '\10f271';
}
.fa-duotone.fa-calendar-range:after,
.fad.fa-calendar-range:after {
  content: '\10e0e8';
}
.fa-duotone.fa-calendar-star:after,
.fad.fa-calendar-star:after {
  content: '\10f736';
}
.fa-duotone.fa-calendar-week:after,
.fad.fa-calendar-week:after {
  content: '\10f784';
}
.fa-duotone.fa-calendar-times:after,
.fa-duotone.fa-calendar-xmark:after,
.fad.fa-calendar-times:after,
.fad.fa-calendar-xmark:after {
  content: '\10f273';
}
.fa-duotone.fa-calendars:after,
.fad.fa-calendars:after {
  content: '\10e0e9';
}
.fa-duotone.fa-camcorder:after,
.fa-duotone.fa-video-handheld:after,
.fad.fa-camcorder:after,
.fad.fa-video-handheld:after {
  content: '\10f8a8';
}
.fa-duotone.fa-camera-alt:after,
.fa-duotone.fa-camera:after,
.fad.fa-camera-alt:after,
.fad.fa-camera:after {
  content: '\10f030';
}
.fa-duotone.fa-camera-cctv:after,
.fa-duotone.fa-cctv:after,
.fad.fa-camera-cctv:after,
.fad.fa-cctv:after {
  content: '\10f8ac';
}
.fa-duotone.fa-camera-movie:after,
.fad.fa-camera-movie:after {
  content: '\10f8a9';
}
.fa-duotone.fa-camera-polaroid:after,
.fad.fa-camera-polaroid:after {
  content: '\10f8aa';
}
.fa-duotone.fa-camera-retro:after,
.fad.fa-camera-retro:after {
  content: '\10f083';
}
.fa-duotone.fa-camera-rotate:after,
.fad.fa-camera-rotate:after {
  content: '\10e0ea';
}
.fa-duotone.fa-camera-home:after,
.fa-duotone.fa-camera-security:after,
.fad.fa-camera-home:after,
.fad.fa-camera-security:after {
  content: '\10f8fe';
}
.fa-duotone.fa-camera-slash:after,
.fad.fa-camera-slash:after {
  content: '\10e0eb';
}
.fa-duotone.fa-camera-viewfinder:after,
.fad.fa-camera-viewfinder:after {
  content: '\10e0ec';
}
.fa-duotone.fa-camera-web:after,
.fa-duotone.fa-webcam:after,
.fad.fa-camera-web:after,
.fad.fa-webcam:after {
  content: '\10f832';
}
.fa-duotone.fa-camera-web-slash:after,
.fa-duotone.fa-webcam-slash:after,
.fad.fa-camera-web-slash:after,
.fad.fa-webcam-slash:after {
  content: '\10f833';
}
.fa-duotone.fa-campfire:after,
.fad.fa-campfire:after {
  content: '\10f6ba';
}
.fa-duotone.fa-campground:after,
.fad.fa-campground:after {
  content: '\10f6bb';
}
.fa-duotone.fa-candle-holder:after,
.fad.fa-candle-holder:after {
  content: '\10f6bc';
}
.fa-duotone.fa-candy-cane:after,
.fad.fa-candy-cane:after {
  content: '\10f786';
}
.fa-duotone.fa-candy-corn:after,
.fad.fa-candy-corn:after {
  content: '\10f6bd';
}
.fa-duotone.fa-cannabis:after,
.fad.fa-cannabis:after {
  content: '\10f55f';
}
.fa-duotone.fa-capsules:after,
.fad.fa-capsules:after {
  content: '\10f46b';
}
.fa-duotone.fa-automobile:after,
.fa-duotone.fa-car:after,
.fad.fa-automobile:after,
.fad.fa-car:after {
  content: '\10f1b9';
}
.fa-duotone.fa-battery-car:after,
.fa-duotone.fa-car-battery:after,
.fad.fa-battery-car:after,
.fad.fa-car-battery:after {
  content: '\10f5df';
}
.fa-duotone.fa-car-building:after,
.fad.fa-car-building:after {
  content: '\10f859';
}
.fa-duotone.fa-car-bump:after,
.fad.fa-car-bump:after {
  content: '\10f5e0';
}
.fa-duotone.fa-car-bus:after,
.fad.fa-car-bus:after {
  content: '\10f85a';
}
.fa-duotone.fa-car-crash:after,
.fad.fa-car-crash:after {
  content: '\10f5e1';
}
.fa-duotone.fa-car-garage:after,
.fad.fa-car-garage:after {
  content: '\10f5e2';
}
.fa-duotone.fa-car-alt:after,
.fa-duotone.fa-car-rear:after,
.fad.fa-car-alt:after,
.fad.fa-car-rear:after {
  content: '\10f5de';
}
.fa-duotone.fa-car-side:after,
.fad.fa-car-side:after {
  content: '\10f5e4';
}
.fa-duotone.fa-car-tilt:after,
.fad.fa-car-tilt:after {
  content: '\10f5e5';
}
.fa-duotone.fa-car-wash:after,
.fad.fa-car-wash:after {
  content: '\10f5e6';
}
.fa-duotone.fa-car-mechanic:after,
.fa-duotone.fa-car-wrench:after,
.fad.fa-car-mechanic:after,
.fad.fa-car-wrench:after {
  content: '\10f5e3';
}
.fa-duotone.fa-caravan:after,
.fad.fa-caravan:after {
  content: '\10f8ff';
}
.fa-duotone.fa-caravan-alt:after,
.fa-duotone.fa-caravan-simple:after,
.fad.fa-caravan-alt:after,
.fad.fa-caravan-simple:after {
  content: '\10e000';
}
.fa-duotone.fa-caret-down:after,
.fad.fa-caret-down:after {
  content: '\10f0d7';
}
.fa-duotone.fa-caret-left:after,
.fad.fa-caret-left:after {
  content: '\10f0d9';
}
.fa-duotone.fa-caret-right:after,
.fad.fa-caret-right:after {
  content: '\10f0da';
}
.fa-duotone.fa-caret-up:after,
.fad.fa-caret-up:after {
  content: '\10f0d8';
}
.fa-duotone.fa-carrot:after,
.fad.fa-carrot:after {
  content: '\10f787';
}
.fa-duotone.fa-cars:after,
.fad.fa-cars:after {
  content: '\10f85b';
}
.fa-duotone.fa-cart-arrow-down:after,
.fad.fa-cart-arrow-down:after {
  content: '\10f218';
}
.fa-duotone.fa-cart-flatbed:after,
.fa-duotone.fa-dolly-flatbed:after,
.fad.fa-cart-flatbed:after,
.fad.fa-dolly-flatbed:after {
  content: '\10f474';
}
.fa-duotone.fa-cart-flatbed-boxes:after,
.fa-duotone.fa-dolly-flatbed-alt:after,
.fad.fa-cart-flatbed-boxes:after,
.fad.fa-dolly-flatbed-alt:after {
  content: '\10f475';
}
.fa-duotone.fa-cart-flatbed-empty:after,
.fa-duotone.fa-dolly-flatbed-empty:after,
.fad.fa-cart-flatbed-empty:after,
.fad.fa-dolly-flatbed-empty:after {
  content: '\10f476';
}
.fa-duotone.fa-cart-flatbed-suitcase:after,
.fa-duotone.fa-luggage-cart:after,
.fad.fa-cart-flatbed-suitcase:after,
.fad.fa-luggage-cart:after {
  content: '\10f59d';
}
.fa-duotone.fa-cart-plus:after,
.fad.fa-cart-plus:after {
  content: '\10f217';
}
.fa-duotone.fa-cart-shopping:after,
.fa-duotone.fa-shopping-cart:after,
.fad.fa-cart-shopping:after,
.fad.fa-shopping-cart:after {
  content: '\10f07a';
}
.fa-duotone.fa-cart-shopping-fast:after,
.fad.fa-cart-shopping-fast:after {
  content: '\10e0ed';
}
.fa-duotone.fa-betamax:after,
.fa-duotone.fa-casette-betamax:after,
.fad.fa-betamax:after,
.fad.fa-casette-betamax:after {
  content: '\10f8a4';
}
.fa-duotone.fa-casette-vhs:after,
.fa-duotone.fa-vhs:after,
.fad.fa-casette-vhs:after,
.fad.fa-vhs:after {
  content: '\10f8ec';
}
.fa-duotone.fa-cash-register:after,
.fad.fa-cash-register:after {
  content: '\10f788';
}
.fa-duotone.fa-cassette-tape:after,
.fad.fa-cassette-tape:after {
  content: '\10f8ab';
}
.fa-duotone.fa-castle:after,
.fad.fa-castle:after {
  content: '\10e0ee';
}
.fa-duotone.fa-cat:after,
.fad.fa-cat:after {
  content: '\10f6be';
}
.fa-duotone.fa-cat-space:after,
.fad.fa-cat-space:after {
  content: '\10e001';
}
.fa-duotone.fa-cauldron:after,
.fad.fa-cauldron:after {
  content: '\10f6bf';
}
.fa-duotone.fa-cedi-sign:after,
.fad.fa-cedi-sign:after {
  content: '\10e0ef';
}
.fa-duotone.fa-cent-sign:after,
.fad.fa-cent-sign:after {
  content: '\10e0f0';
}
.fa-duotone.fa-certificate:after,
.fad.fa-certificate:after {
  content: '\10f0a3';
}
.fa-duotone.fa-chair:after,
.fad.fa-chair:after {
  content: '\10f6c0';
}
.fa-duotone.fa-chair-office:after,
.fad.fa-chair-office:after {
  content: '\10f6c1';
}
.fa-duotone.fa-blackboard:after,
.fa-duotone.fa-chalkboard:after,
.fad.fa-blackboard:after,
.fad.fa-chalkboard:after {
  content: '\10f51b';
}
.fa-duotone.fa-chalkboard-teacher:after,
.fa-duotone.fa-chalkboard-user:after,
.fad.fa-chalkboard-teacher:after,
.fad.fa-chalkboard-user:after {
  content: '\10f51c';
}
.fa-duotone.fa-champagne-glass:after,
.fa-duotone.fa-glass-champagne:after,
.fad.fa-champagne-glass:after,
.fad.fa-glass-champagne:after {
  content: '\10f79e';
}
.fa-duotone.fa-champagne-glasses:after,
.fa-duotone.fa-glass-cheers:after,
.fad.fa-champagne-glasses:after,
.fad.fa-glass-cheers:after {
  content: '\10f79f';
}
.fa-duotone.fa-charging-station:after,
.fad.fa-charging-station:after {
  content: '\10f5e7';
}
.fa-duotone.fa-area-chart:after,
.fa-duotone.fa-chart-area:after,
.fad.fa-area-chart:after,
.fad.fa-chart-area:after {
  content: '\10f1fe';
}
.fa-duotone.fa-bar-chart:after,
.fa-duotone.fa-chart-bar:after,
.fad.fa-bar-chart:after,
.fad.fa-chart-bar:after {
  content: '\10f080';
}
.fa-duotone.fa-chart-bullet:after,
.fad.fa-chart-bullet:after {
  content: '\10e0f1';
}
.fa-duotone.fa-chart-candlestick:after,
.fad.fa-chart-candlestick:after {
  content: '\10e0f2';
}
.fa-duotone.fa-chart-column:after,
.fad.fa-chart-column:after {
  content: '\10e0f3';
}
.fa-duotone.fa-chart-gantt:after,
.fad.fa-chart-gantt:after {
  content: '\10e0f4';
}
.fa-duotone.fa-chart-line:after,
.fa-duotone.fa-line-chart:after,
.fad.fa-chart-line:after,
.fad.fa-line-chart:after {
  content: '\10f201';
}
.fa-duotone.fa-chart-line-down:after,
.fad.fa-chart-line-down:after {
  content: '\10f64d';
}
.fa-duotone.fa-chart-line-up:after,
.fad.fa-chart-line-up:after {
  content: '\10e0f5';
}
.fa-duotone.fa-analytics:after,
.fa-duotone.fa-chart-mixed:after,
.fad.fa-analytics:after,
.fad.fa-chart-mixed:after {
  content: '\10f643';
}
.fa-duotone.fa-chart-network:after,
.fad.fa-chart-network:after {
  content: '\10f78a';
}
.fa-duotone.fa-chart-pie:after,
.fa-duotone.fa-pie-chart:after,
.fad.fa-chart-pie:after,
.fad.fa-pie-chart:after {
  content: '\10f200';
}
.fa-duotone.fa-chart-pie-alt:after,
.fa-duotone.fa-chart-pie-simple:after,
.fad.fa-chart-pie-alt:after,
.fad.fa-chart-pie-simple:after {
  content: '\10f64e';
}
.fa-duotone.fa-chart-pyramid:after,
.fad.fa-chart-pyramid:after {
  content: '\10e0f6';
}
.fa-duotone.fa-chart-radar:after,
.fad.fa-chart-radar:after {
  content: '\10e0f7';
}
.fa-duotone.fa-chart-scatter:after,
.fad.fa-chart-scatter:after {
  content: '\10f7ee';
}
.fa-duotone.fa-chart-scatter-3d:after,
.fad.fa-chart-scatter-3d:after {
  content: '\10e0f8';
}
.fa-duotone.fa-chart-scatter-bubble:after,
.fad.fa-chart-scatter-bubble:after {
  content: '\10e0f9';
}
.fa-duotone.fa-chart-tree-map:after,
.fad.fa-chart-tree-map:after {
  content: '\10e0fa';
}
.fa-duotone.fa-chart-user:after,
.fa-duotone.fa-user-chart:after,
.fad.fa-chart-user:after,
.fad.fa-user-chart:after {
  content: '\10f6a3';
}
.fa-duotone.fa-chart-waterfall:after,
.fad.fa-chart-waterfall:after {
  content: '\10e0fb';
}
.fa-duotone.fa-check:after,
.fad.fa-check:after {
  content: '\10f00c';
}
.fa-duotone.fa-check-double:after,
.fad.fa-check-double:after {
  content: '\10f560';
}
.fa-duotone.fa-check-to-slot:after,
.fa-duotone.fa-vote-yea:after,
.fad.fa-check-to-slot:after,
.fad.fa-vote-yea:after {
  content: '\10f772';
}
.fa-duotone.fa-cheese:after,
.fad.fa-cheese:after {
  content: '\10f7ef';
}
.fa-duotone.fa-cheese-swiss:after,
.fad.fa-cheese-swiss:after {
  content: '\10f7f0';
}
.fa-duotone.fa-cherries:after,
.fad.fa-cherries:after {
  content: '\10e0fc';
}
.fa-duotone.fa-chess:after,
.fad.fa-chess:after {
  content: '\10f439';
}
.fa-duotone.fa-chess-bishop:after,
.fad.fa-chess-bishop:after {
  content: '\10f43a';
}
.fa-duotone.fa-chess-bishop-alt:after,
.fa-duotone.fa-chess-bishop-piece:after,
.fad.fa-chess-bishop-alt:after,
.fad.fa-chess-bishop-piece:after {
  content: '\10f43b';
}
.fa-duotone.fa-chess-board:after,
.fad.fa-chess-board:after {
  content: '\10f43c';
}
.fa-duotone.fa-chess-clock:after,
.fad.fa-chess-clock:after {
  content: '\10f43d';
}
.fa-duotone.fa-chess-clock-alt:after,
.fa-duotone.fa-chess-clock-flip:after,
.fad.fa-chess-clock-alt:after,
.fad.fa-chess-clock-flip:after {
  content: '\10f43e';
}
.fa-duotone.fa-chess-king:after,
.fad.fa-chess-king:after {
  content: '\10f43f';
}
.fa-duotone.fa-chess-king-alt:after,
.fa-duotone.fa-chess-king-piece:after,
.fad.fa-chess-king-alt:after,
.fad.fa-chess-king-piece:after {
  content: '\10f440';
}
.fa-duotone.fa-chess-knight:after,
.fad.fa-chess-knight:after {
  content: '\10f441';
}
.fa-duotone.fa-chess-knight-alt:after,
.fa-duotone.fa-chess-knight-piece:after,
.fad.fa-chess-knight-alt:after,
.fad.fa-chess-knight-piece:after {
  content: '\10f442';
}
.fa-duotone.fa-chess-pawn:after,
.fad.fa-chess-pawn:after {
  content: '\10f443';
}
.fa-duotone.fa-chess-pawn-alt:after,
.fa-duotone.fa-chess-pawn-piece:after,
.fad.fa-chess-pawn-alt:after,
.fad.fa-chess-pawn-piece:after {
  content: '\10f444';
}
.fa-duotone.fa-chess-queen:after,
.fad.fa-chess-queen:after {
  content: '\10f445';
}
.fa-duotone.fa-chess-queen-alt:after,
.fa-duotone.fa-chess-queen-piece:after,
.fad.fa-chess-queen-alt:after,
.fad.fa-chess-queen-piece:after {
  content: '\10f446';
}
.fa-duotone.fa-chess-rook:after,
.fad.fa-chess-rook:after {
  content: '\10f447';
}
.fa-duotone.fa-chess-rook-alt:after,
.fa-duotone.fa-chess-rook-piece:after,
.fad.fa-chess-rook-alt:after,
.fad.fa-chess-rook-piece:after {
  content: '\10f448';
}
.fa-duotone.fa-chevron-down:after,
.fad.fa-chevron-down:after {
  content: '\10f078';
}
.fa-duotone.fa-chevron-left:after,
.fad.fa-chevron-left:after {
  content: '\10f053';
}
.fa-duotone.fa-chevron-right:after,
.fad.fa-chevron-right:after {
  content: '\10f054';
}
.fa-duotone.fa-chevron-up:after,
.fad.fa-chevron-up:after {
  content: '\10f077';
}
.fa-duotone.fa-chevron-double-down:after,
.fa-duotone.fa-chevrons-down:after,
.fad.fa-chevron-double-down:after,
.fad.fa-chevrons-down:after {
  content: '\10f322';
}
.fa-duotone.fa-chevron-double-left:after,
.fa-duotone.fa-chevrons-left:after,
.fad.fa-chevron-double-left:after,
.fad.fa-chevrons-left:after {
  content: '\10f323';
}
.fa-duotone.fa-chevron-double-right:after,
.fa-duotone.fa-chevrons-right:after,
.fad.fa-chevron-double-right:after,
.fad.fa-chevrons-right:after {
  content: '\10f324';
}
.fa-duotone.fa-chevron-double-up:after,
.fa-duotone.fa-chevrons-up:after,
.fad.fa-chevron-double-up:after,
.fad.fa-chevrons-up:after {
  content: '\10f325';
}
.fa-duotone.fa-child:after,
.fad.fa-child:after {
  content: '\10f1ae';
}
.fa-duotone.fa-chimney:after,
.fad.fa-chimney:after {
  content: '\10f78b';
}
.fa-duotone.fa-church:after,
.fad.fa-church:after {
  content: '\10f51d';
}
.fa-duotone.fa-circle:after,
.fad.fa-circle:after {
  content: '\10f111';
}
.fa-duotone.fa-circle-0:after,
.fad.fa-circle-0:after {
  content: '\10e0fd';
}
.fa-duotone.fa-circle-1:after,
.fad.fa-circle-1:after {
  content: '\10e0fe';
}
.fa-duotone.fa-circle-2:after,
.fad.fa-circle-2:after {
  content: '\10e0ff';
}
.fa-duotone.fa-circle-3:after,
.fad.fa-circle-3:after {
  content: '\10e100';
}
.fa-duotone.fa-circle-4:after,
.fad.fa-circle-4:after {
  content: '\10e101';
}
.fa-duotone.fa-circle-5:after,
.fad.fa-circle-5:after {
  content: '\10e102';
}
.fa-duotone.fa-circle-6:after,
.fad.fa-circle-6:after {
  content: '\10e103';
}
.fa-duotone.fa-circle-7:after,
.fad.fa-circle-7:after {
  content: '\10e104';
}
.fa-duotone.fa-circle-8:after,
.fad.fa-circle-8:after {
  content: '\10e105';
}
.fa-duotone.fa-circle-9:after,
.fad.fa-circle-9:after {
  content: '\10e106';
}
.fa-duotone.fa-circle-a:after,
.fad.fa-circle-a:after {
  content: '\10e107';
}
.fa-duotone.fa-circle-ampersand:after,
.fad.fa-circle-ampersand:after {
  content: '\10e108';
}
.fa-duotone.fa-arrow-circle-down:after,
.fa-duotone.fa-circle-arrow-down:after,
.fad.fa-arrow-circle-down:after,
.fad.fa-circle-arrow-down:after {
  content: '\10f0ab';
}
.fa-duotone.fa-circle-arrow-down-left:after,
.fad.fa-circle-arrow-down-left:after {
  content: '\10e109';
}
.fa-duotone.fa-circle-arrow-down-right:after,
.fad.fa-circle-arrow-down-right:after {
  content: '\10e10a';
}
.fa-duotone.fa-arrow-circle-left:after,
.fa-duotone.fa-circle-arrow-left:after,
.fad.fa-arrow-circle-left:after,
.fad.fa-circle-arrow-left:after {
  content: '\10f0a8';
}
.fa-duotone.fa-arrow-circle-right:after,
.fa-duotone.fa-circle-arrow-right:after,
.fad.fa-arrow-circle-right:after,
.fad.fa-circle-arrow-right:after {
  content: '\10f0a9';
}
.fa-duotone.fa-arrow-circle-up:after,
.fa-duotone.fa-circle-arrow-up:after,
.fad.fa-arrow-circle-up:after,
.fad.fa-circle-arrow-up:after {
  content: '\10f0aa';
}
.fa-duotone.fa-circle-arrow-up-left:after,
.fad.fa-circle-arrow-up-left:after {
  content: '\10e10b';
}
.fa-duotone.fa-circle-arrow-up-right:after,
.fad.fa-circle-arrow-up-right:after {
  content: '\10e10c';
}
.fa-duotone.fa-circle-b:after,
.fad.fa-circle-b:after {
  content: '\10e10d';
}
.fa-duotone.fa-circle-bolt:after,
.fad.fa-circle-bolt:after {
  content: '\10e10e';
}
.fa-duotone.fa-book-circle:after,
.fa-duotone.fa-circle-book-open:after,
.fad.fa-book-circle:after,
.fad.fa-circle-book-open:after {
  content: '\10e10f';
}
.fa-duotone.fa-bookmark-circle:after,
.fa-duotone.fa-circle-bookmark:after,
.fad.fa-bookmark-circle:after,
.fad.fa-circle-bookmark:after {
  content: '\10e110';
}
.fa-duotone.fa-circle-c:after,
.fad.fa-circle-c:after {
  content: '\10e111';
}
.fa-duotone.fa-calendar-circle:after,
.fa-duotone.fa-circle-calendar:after,
.fad.fa-calendar-circle:after,
.fad.fa-circle-calendar:after {
  content: '\10e112';
}
.fa-duotone.fa-camera-circle:after,
.fa-duotone.fa-circle-camera:after,
.fad.fa-camera-circle:after,
.fad.fa-circle-camera:after {
  content: '\10e113';
}
.fa-duotone.fa-caret-circle-down:after,
.fa-duotone.fa-circle-caret-down:after,
.fad.fa-caret-circle-down:after,
.fad.fa-circle-caret-down:after {
  content: '\10f32d';
}
.fa-duotone.fa-caret-circle-left:after,
.fa-duotone.fa-circle-caret-left:after,
.fad.fa-caret-circle-left:after,
.fad.fa-circle-caret-left:after {
  content: '\10f32e';
}
.fa-duotone.fa-caret-circle-right:after,
.fa-duotone.fa-circle-caret-right:after,
.fad.fa-caret-circle-right:after,
.fad.fa-circle-caret-right:after {
  content: '\10f330';
}
.fa-duotone.fa-caret-circle-up:after,
.fa-duotone.fa-circle-caret-up:after,
.fad.fa-caret-circle-up:after,
.fad.fa-circle-caret-up:after {
  content: '\10f331';
}
.fa-duotone.fa-check-circle:after,
.fa-duotone.fa-circle-check:after,
.fad.fa-check-circle:after,
.fad.fa-circle-check:after {
  content: '\10f058';
}
.fa-duotone.fa-chevron-circle-down:after,
.fa-duotone.fa-circle-chevron-down:after,
.fad.fa-chevron-circle-down:after,
.fad.fa-circle-chevron-down:after {
  content: '\10f13a';
}
.fa-duotone.fa-chevron-circle-left:after,
.fa-duotone.fa-circle-chevron-left:after,
.fad.fa-chevron-circle-left:after,
.fad.fa-circle-chevron-left:after {
  content: '\10f137';
}
.fa-duotone.fa-chevron-circle-right:after,
.fa-duotone.fa-circle-chevron-right:after,
.fad.fa-chevron-circle-right:after,
.fad.fa-circle-chevron-right:after {
  content: '\10f138';
}
.fa-duotone.fa-chevron-circle-up:after,
.fa-duotone.fa-circle-chevron-up:after,
.fad.fa-chevron-circle-up:after,
.fad.fa-circle-chevron-up:after {
  content: '\10f139';
}
.fa-duotone.fa-circle-d:after,
.fad.fa-circle-d:after {
  content: '\10e114';
}
.fa-duotone.fa-circle-dashed:after,
.fad.fa-circle-dashed:after {
  content: '\10e115';
}
.fa-duotone.fa-circle-divide:after,
.fad.fa-circle-divide:after {
  content: '\10e116';
}
.fa-duotone.fa-circle-dollar:after,
.fa-duotone.fa-dollar-circle:after,
.fa-duotone.fa-usd-circle:after,
.fad.fa-circle-dollar:after,
.fad.fa-dollar-circle:after,
.fad.fa-usd-circle:after {
  content: '\10f2e8';
}
.fa-duotone.fa-circle-dollar-to-slot:after,
.fa-duotone.fa-donate:after,
.fad.fa-circle-dollar-to-slot:after,
.fad.fa-donate:after {
  content: '\10f4b9';
}
.fa-duotone.fa-circle-dot:after,
.fa-duotone.fa-dot-circle:after,
.fad.fa-circle-dot:after,
.fad.fa-dot-circle:after {
  content: '\10f192';
}
.fa-duotone.fa-arrow-alt-circle-down:after,
.fa-duotone.fa-circle-down:after,
.fad.fa-arrow-alt-circle-down:after,
.fad.fa-circle-down:after {
  content: '\10f358';
}
.fa-duotone.fa-circle-down-left:after,
.fad.fa-circle-down-left:after {
  content: '\10e117';
}
.fa-duotone.fa-circle-down-right:after,
.fad.fa-circle-down-right:after {
  content: '\10e118';
}
.fa-duotone.fa-circle-e:after,
.fad.fa-circle-e:after {
  content: '\10e119';
}
.fa-duotone.fa-circle-envelope:after,
.fa-duotone.fa-envelope-circle:after,
.fad.fa-circle-envelope:after,
.fad.fa-envelope-circle:after {
  content: '\10e11a';
}
.fa-duotone.fa-circle-exclamation:after,
.fa-duotone.fa-exclamation-circle:after,
.fad.fa-circle-exclamation:after,
.fad.fa-exclamation-circle:after {
  content: '\10f06a';
}
.fa-duotone.fa-circle-exclamation-check:after,
.fad.fa-circle-exclamation-check:after {
  content: '\10e11b';
}
.fa-duotone.fa-circle-f:after,
.fad.fa-circle-f:after {
  content: '\10e11c';
}
.fa-duotone.fa-circle-g:after,
.fad.fa-circle-g:after {
  content: '\10e11d';
}
.fa-duotone.fa-circle-h:after,
.fa-duotone.fa-hospital-symbol:after,
.fad.fa-circle-h:after,
.fad.fa-hospital-symbol:after {
  content: '\10f47e';
}
.fa-duotone.fa-circle-half:after,
.fad.fa-circle-half:after {
  content: '\10e11e';
}
.fa-duotone.fa-adjust:after,
.fa-duotone.fa-circle-half-stroke:after,
.fad.fa-adjust:after,
.fad.fa-circle-half-stroke:after {
  content: '\10f042';
}
.fa-duotone.fa-circle-heart:after,
.fa-duotone.fa-heart-circle:after,
.fad.fa-circle-heart:after,
.fad.fa-heart-circle:after {
  content: '\10f4c7';
}
.fa-duotone.fa-circle-i:after,
.fad.fa-circle-i:after {
  content: '\10e11f';
}
.fa-duotone.fa-circle-info:after,
.fa-duotone.fa-info-circle:after,
.fad.fa-circle-info:after,
.fad.fa-info-circle:after {
  content: '\10f05a';
}
.fa-duotone.fa-circle-j:after,
.fad.fa-circle-j:after {
  content: '\10e120';
}
.fa-duotone.fa-circle-k:after,
.fad.fa-circle-k:after {
  content: '\10e121';
}
.fa-duotone.fa-circle-l:after,
.fad.fa-circle-l:after {
  content: '\10e122';
}
.fa-duotone.fa-arrow-alt-circle-left:after,
.fa-duotone.fa-circle-left:after,
.fad.fa-arrow-alt-circle-left:after,
.fad.fa-circle-left:after {
  content: '\10f359';
}
.fa-duotone.fa-circle-location-arrow:after,
.fa-duotone.fa-location-circle:after,
.fad.fa-circle-location-arrow:after,
.fad.fa-location-circle:after {
  content: '\10f602';
}
.fa-duotone.fa-circle-m:after,
.fad.fa-circle-m:after {
  content: '\10e123';
}
.fa-duotone.fa-circle-microphone:after,
.fa-duotone.fa-microphone-circle:after,
.fad.fa-circle-microphone:after,
.fad.fa-microphone-circle:after {
  content: '\10e124';
}
.fa-duotone.fa-circle-microphone-lines:after,
.fa-duotone.fa-microphone-circle-alt:after,
.fad.fa-circle-microphone-lines:after,
.fad.fa-microphone-circle-alt:after {
  content: '\10e125';
}
.fa-duotone.fa-circle-minus:after,
.fa-duotone.fa-minus-circle:after,
.fad.fa-circle-minus:after,
.fad.fa-minus-circle:after {
  content: '\10f056';
}
.fa-duotone.fa-circle-n:after,
.fad.fa-circle-n:after {
  content: '\10e126';
}
.fa-duotone.fa-circle-notch:after,
.fad.fa-circle-notch:after {
  content: '\10f1ce';
}
.fa-duotone.fa-circle-o:after,
.fad.fa-circle-o:after {
  content: '\10e127';
}
.fa-duotone.fa-circle-p:after,
.fad.fa-circle-p:after {
  content: '\10e128';
}
.fa-duotone.fa-circle-parking:after,
.fa-duotone.fa-parking-circle:after,
.fad.fa-circle-parking:after,
.fad.fa-parking-circle:after {
  content: '\10f615';
}
.fa-duotone.fa-circle-pause:after,
.fa-duotone.fa-pause-circle:after,
.fad.fa-circle-pause:after,
.fad.fa-pause-circle:after {
  content: '\10f28b';
}
.fa-duotone.fa-circle-phone:after,
.fa-duotone.fa-phone-circle:after,
.fad.fa-circle-phone:after,
.fad.fa-phone-circle:after {
  content: '\10e129';
}
.fa-duotone.fa-circle-phone-flip:after,
.fa-duotone.fa-phone-circle-alt:after,
.fad.fa-circle-phone-flip:after,
.fad.fa-phone-circle-alt:after {
  content: '\10e12a';
}
.fa-duotone.fa-circle-phone-hangup:after,
.fa-duotone.fa-phone-circle-down:after,
.fad.fa-circle-phone-hangup:after,
.fad.fa-phone-circle-down:after {
  content: '\10e12b';
}
.fa-duotone.fa-circle-play:after,
.fa-duotone.fa-play-circle:after,
.fad.fa-circle-play:after,
.fad.fa-play-circle:after {
  content: '\10f144';
}
.fa-duotone.fa-circle-plus:after,
.fa-duotone.fa-plus-circle:after,
.fad.fa-circle-plus:after,
.fad.fa-plus-circle:after {
  content: '\10f055';
}
.fa-duotone.fa-circle-q:after,
.fad.fa-circle-q:after {
  content: '\10e12c';
}
.fa-duotone.fa-circle-quarter:after,
.fad.fa-circle-quarter:after {
  content: '\10e12d';
}
.fa-duotone.fa-circle-question:after,
.fa-duotone.fa-question-circle:after,
.fad.fa-circle-question:after,
.fad.fa-question-circle:after {
  content: '\10f059';
}
.fa-duotone.fa-circle-r:after,
.fad.fa-circle-r:after {
  content: '\10e12e';
}
.fa-duotone.fa-circle-radiation:after,
.fa-duotone.fa-radiation-alt:after,
.fad.fa-circle-radiation:after,
.fad.fa-radiation-alt:after {
  content: '\10f7ba';
}
.fa-duotone.fa-arrow-alt-circle-right:after,
.fa-duotone.fa-circle-right:after,
.fad.fa-arrow-alt-circle-right:after,
.fad.fa-circle-right:after {
  content: '\10f35a';
}
.fa-duotone.fa-circle-s:after,
.fad.fa-circle-s:after {
  content: '\10e12f';
}
.fa-duotone.fa-circle-small:after,
.fad.fa-circle-small:after {
  content: '\10e130';
}
.fa-duotone.fa-circle-sort:after,
.fa-duotone.fa-sort-circle:after,
.fad.fa-circle-sort:after,
.fad.fa-sort-circle:after {
  content: '\10e030';
}
.fa-duotone.fa-circle-sort-down:after,
.fa-duotone.fa-sort-circle-down:after,
.fad.fa-circle-sort-down:after,
.fad.fa-sort-circle-down:after {
  content: '\10e031';
}
.fa-duotone.fa-circle-sort-up:after,
.fa-duotone.fa-sort-circle-up:after,
.fad.fa-circle-sort-up:after,
.fad.fa-sort-circle-up:after {
  content: '\10e032';
}
.fa-duotone.fa-circle-star:after,
.fa-duotone.fa-star-circle:after,
.fad.fa-circle-star:after,
.fad.fa-star-circle:after {
  content: '\10e131';
}
.fa-duotone.fa-circle-stop:after,
.fa-duotone.fa-stop-circle:after,
.fad.fa-circle-stop:after,
.fad.fa-stop-circle:after {
  content: '\10f28d';
}
.fa-duotone.fa-circle-t:after,
.fad.fa-circle-t:after {
  content: '\10e132';
}
.fa-duotone.fa-circle-three-quarters:after,
.fad.fa-circle-three-quarters:after {
  content: '\10e133';
}
.fa-duotone.fa-circle-trash:after,
.fa-duotone.fa-trash-circle:after,
.fad.fa-circle-trash:after,
.fad.fa-trash-circle:after {
  content: '\10e134';
}
.fa-duotone.fa-circle-u:after,
.fad.fa-circle-u:after {
  content: '\10e135';
}
.fa-duotone.fa-arrow-alt-circle-up:after,
.fa-duotone.fa-circle-up:after,
.fad.fa-arrow-alt-circle-up:after,
.fad.fa-circle-up:after {
  content: '\10f35b';
}
.fa-duotone.fa-circle-up-left:after,
.fad.fa-circle-up-left:after {
  content: '\10e136';
}
.fa-duotone.fa-circle-up-right:after,
.fad.fa-circle-up-right:after {
  content: '\10e137';
}
.fa-duotone.fa-circle-user:after,
.fa-duotone.fa-user-circle:after,
.fad.fa-circle-user:after,
.fad.fa-user-circle:after {
  content: '\10f2bd';
}
.fa-duotone.fa-circle-v:after,
.fad.fa-circle-v:after {
  content: '\10e138';
}
.fa-duotone.fa-circle-video:after,
.fa-duotone.fa-video-circle:after,
.fad.fa-circle-video:after,
.fad.fa-video-circle:after {
  content: '\10e139';
}
.fa-duotone.fa-circle-w:after,
.fad.fa-circle-w:after {
  content: '\10e13a';
}
.fa-duotone.fa-circle-waveform-lines:after,
.fa-duotone.fa-waveform-circle:after,
.fad.fa-circle-waveform-lines:after,
.fad.fa-waveform-circle:after {
  content: '\10e13b';
}
.fa-duotone.fa-circle-x:after,
.fad.fa-circle-x:after {
  content: '\10e13c';
}
.fa-duotone.fa-circle-xmark:after,
.fa-duotone.fa-times-circle:after,
.fa-duotone.fa-xmark-circle:after,
.fad.fa-circle-xmark:after,
.fad.fa-times-circle:after,
.fad.fa-xmark-circle:after {
  content: '\10f057';
}
.fa-duotone.fa-circle-y:after,
.fad.fa-circle-y:after {
  content: '\10e13d';
}
.fa-duotone.fa-circle-z:after,
.fad.fa-circle-z:after {
  content: '\10e13e';
}
.fa-duotone.fa-city:after,
.fad.fa-city:after {
  content: '\10f64f';
}
.fa-duotone.fa-clapperboard:after,
.fad.fa-clapperboard:after {
  content: '\10e13f';
}
.fa-duotone.fa-clapperboard-play:after,
.fad.fa-clapperboard-play:after {
  content: '\10e140';
}
.fa-duotone.fa-clarinet:after,
.fad.fa-clarinet:after {
  content: '\10f8ad';
}
.fa-duotone.fa-claw-marks:after,
.fad.fa-claw-marks:after {
  content: '\10f6c2';
}
.fa-duotone.fa-clipboard:after,
.fad.fa-clipboard:after {
  content: '\10f328';
}
.fa-duotone.fa-clipboard-check:after,
.fad.fa-clipboard-check:after {
  content: '\10f46c';
}
.fa-duotone.fa-clipboard-list:after,
.fad.fa-clipboard-list:after {
  content: '\10f46d';
}
.fa-duotone.fa-clipboard-list-check:after,
.fad.fa-clipboard-list-check:after {
  content: '\10f737';
}
.fa-duotone.fa-clipboard-medical:after,
.fad.fa-clipboard-medical:after {
  content: '\10e141';
}
.fa-duotone.fa-clipboard-prescription:after,
.fad.fa-clipboard-prescription:after {
  content: '\10f5e8';
}
.fa-duotone.fa-clipboard-user:after,
.fad.fa-clipboard-user:after {
  content: '\10f7f3';
}
.fa-duotone.fa-clock:after,
.fad.fa-clock:after {
  content: '\10f017';
}
.fa-duotone.fa-clock-desk:after,
.fad.fa-clock-desk:after {
  content: '\10e142';
}
.fa-duotone.fa-clock-rotate-left:after,
.fa-duotone.fa-history:after,
.fad.fa-clock-rotate-left:after,
.fad.fa-history:after {
  content: '\10f1da';
}
.fa-duotone.fa-clone:after,
.fad.fa-clone:after {
  content: '\10f24d';
}
.fa-duotone.fa-closed-captioning:after,
.fad.fa-closed-captioning:after {
  content: '\10f20a';
}
.fa-duotone.fa-closed-captioning-slash:after,
.fad.fa-closed-captioning-slash:after {
  content: '\10e143';
}
.fa-duotone.fa-clothes-hanger:after,
.fad.fa-clothes-hanger:after {
  content: '\10e144';
}
.fa-duotone.fa-cloud:after,
.fad.fa-cloud:after {
  content: '\10f0c2';
}
.fa-duotone.fa-cloud-arrow-down:after,
.fa-duotone.fa-cloud-download-alt:after,
.fa-duotone.fa-cloud-download:after,
.fad.fa-cloud-arrow-down:after,
.fad.fa-cloud-download-alt:after,
.fad.fa-cloud-download:after {
  content: '\10f0ed';
}
.fa-duotone.fa-cloud-arrow-up:after,
.fa-duotone.fa-cloud-upload-alt:after,
.fa-duotone.fa-cloud-upload:after,
.fad.fa-cloud-arrow-up:after,
.fad.fa-cloud-upload-alt:after,
.fad.fa-cloud-upload:after {
  content: '\10f0ee';
}
.fa-duotone.fa-cloud-bolt:after,
.fa-duotone.fa-thunderstorm:after,
.fad.fa-cloud-bolt:after,
.fad.fa-thunderstorm:after {
  content: '\10f76c';
}
.fa-duotone.fa-cloud-bolt-moon:after,
.fa-duotone.fa-thunderstorm-moon:after,
.fad.fa-cloud-bolt-moon:after,
.fad.fa-thunderstorm-moon:after {
  content: '\10f76d';
}
.fa-duotone.fa-cloud-bolt-sun:after,
.fa-duotone.fa-thunderstorm-sun:after,
.fad.fa-cloud-bolt-sun:after,
.fad.fa-thunderstorm-sun:after {
  content: '\10f76e';
}
.fa-duotone.fa-cloud-drizzle:after,
.fad.fa-cloud-drizzle:after {
  content: '\10f738';
}
.fa-duotone.fa-cloud-fog:after,
.fa-duotone.fa-fog:after,
.fad.fa-cloud-fog:after,
.fad.fa-fog:after {
  content: '\10f74e';
}
.fa-duotone.fa-cloud-hail:after,
.fad.fa-cloud-hail:after {
  content: '\10f739';
}
.fa-duotone.fa-cloud-hail-mixed:after,
.fad.fa-cloud-hail-mixed:after {
  content: '\10f73a';
}
.fa-duotone.fa-cloud-meatball:after,
.fad.fa-cloud-meatball:after {
  content: '\10f73b';
}
.fa-duotone.fa-cloud-moon:after,
.fad.fa-cloud-moon:after {
  content: '\10f6c3';
}
.fa-duotone.fa-cloud-moon-rain:after,
.fad.fa-cloud-moon-rain:after {
  content: '\10f73c';
}
.fa-duotone.fa-cloud-music:after,
.fad.fa-cloud-music:after {
  content: '\10f8ae';
}
.fa-duotone.fa-cloud-rain:after,
.fad.fa-cloud-rain:after {
  content: '\10f73d';
}
.fa-duotone.fa-cloud-rainbow:after,
.fad.fa-cloud-rainbow:after {
  content: '\10f73e';
}
.fa-duotone.fa-cloud-showers:after,
.fad.fa-cloud-showers:after {
  content: '\10f73f';
}
.fa-duotone.fa-cloud-showers-heavy:after,
.fad.fa-cloud-showers-heavy:after {
  content: '\10f740';
}
.fa-duotone.fa-cloud-slash:after,
.fad.fa-cloud-slash:after {
  content: '\10e145';
}
.fa-duotone.fa-cloud-sleet:after,
.fad.fa-cloud-sleet:after {
  content: '\10f741';
}
.fa-duotone.fa-cloud-snow:after,
.fad.fa-cloud-snow:after {
  content: '\10f742';
}
.fa-duotone.fa-cloud-sun:after,
.fad.fa-cloud-sun:after {
  content: '\10f6c4';
}
.fa-duotone.fa-cloud-sun-rain:after,
.fad.fa-cloud-sun-rain:after {
  content: '\10f743';
}
.fa-duotone.fa-cloud-word:after,
.fad.fa-cloud-word:after {
  content: '\10e146';
}
.fa-duotone.fa-clouds:after,
.fad.fa-clouds:after {
  content: '\10f744';
}
.fa-duotone.fa-clouds-moon:after,
.fad.fa-clouds-moon:after {
  content: '\10f745';
}
.fa-duotone.fa-clouds-sun:after,
.fad.fa-clouds-sun:after {
  content: '\10f746';
}
.fa-duotone.fa-clover:after,
.fad.fa-clover:after {
  content: '\10e147';
}
.fa-duotone.fa-club:after,
.fad.fa-club:after {
  content: '\10f327';
}
.fa-duotone.fa-code:after,
.fad.fa-code:after {
  content: '\10f121';
}
.fa-duotone.fa-code-branch:after,
.fad.fa-code-branch:after {
  content: '\10f126';
}
.fa-duotone.fa-code-commit:after,
.fad.fa-code-commit:after {
  content: '\10f386';
}
.fa-duotone.fa-code-compare:after,
.fad.fa-code-compare:after {
  content: '\10e148';
}
.fa-duotone.fa-code-fork:after,
.fad.fa-code-fork:after {
  content: '\10e149';
}
.fa-duotone.fa-code-merge:after,
.fad.fa-code-merge:after {
  content: '\10f387';
}
.fa-duotone.fa-code-pull-request:after,
.fad.fa-code-pull-request:after {
  content: '\10e14a';
}
.fa-duotone.fa-code-simple:after,
.fad.fa-code-simple:after {
  content: '\10e14b';
}
.fa-duotone.fa-coffee-bean:after,
.fad.fa-coffee-bean:after {
  content: '\10e14c';
}
.fa-duotone.fa-coffee-beans:after,
.fad.fa-coffee-beans:after {
  content: '\10e14d';
}
.fa-duotone.fa-coffee-pot:after,
.fad.fa-coffee-pot:after {
  content: '\10e002';
}
.fa-duotone.fa-coffin:after,
.fad.fa-coffin:after {
  content: '\10f6c6';
}
.fa-duotone.fa-coffin-cross:after,
.fad.fa-coffin-cross:after {
  content: '\10e051';
}
.fa-duotone.fa-coin:after,
.fad.fa-coin:after {
  content: '\10f85c';
}
.fa-duotone.fa-coins:after,
.fad.fa-coins:after {
  content: '\10f51e';
}
.fa-duotone.fa-colon-sign:after,
.fad.fa-colon-sign:after {
  content: '\10e14e';
}
.fa-duotone.fa-comet:after,
.fad.fa-comet:after {
  content: '\10e003';
}
.fa-duotone.fa-command:after,
.fad.fa-command:after {
  content: '\10e14f';
}
.fa-duotone.fa-comment:after,
.fad.fa-comment:after {
  content: '\10f075';
}
.fa-duotone.fa-comment-arrow-down:after,
.fad.fa-comment-arrow-down:after {
  content: '\10e150';
}
.fa-duotone.fa-comment-arrow-up:after,
.fad.fa-comment-arrow-up:after {
  content: '\10e151';
}
.fa-duotone.fa-comment-arrow-up-right:after,
.fad.fa-comment-arrow-up-right:after {
  content: '\10e152';
}
.fa-duotone.fa-comment-captions:after,
.fad.fa-comment-captions:after {
  content: '\10e153';
}
.fa-duotone.fa-comment-check:after,
.fad.fa-comment-check:after {
  content: '\10f4ac';
}
.fa-duotone.fa-comment-code:after,
.fad.fa-comment-code:after {
  content: '\10e154';
}
.fa-duotone.fa-comment-dollar:after,
.fad.fa-comment-dollar:after {
  content: '\10f651';
}
.fa-duotone.fa-comment-dots:after,
.fa-duotone.fa-commenting:after,
.fad.fa-comment-dots:after,
.fad.fa-commenting:after {
  content: '\10f4ad';
}
.fa-duotone.fa-comment-exclamation:after,
.fad.fa-comment-exclamation:after {
  content: '\10f4af';
}
.fa-duotone.fa-comment-image:after,
.fad.fa-comment-image:after {
  content: '\10e155';
}
.fa-duotone.fa-comment-lines:after,
.fad.fa-comment-lines:after {
  content: '\10f4b0';
}
.fa-duotone.fa-comment-medical:after,
.fad.fa-comment-medical:after {
  content: '\10f7f5';
}
.fa-duotone.fa-comment-middle:after,
.fad.fa-comment-middle:after {
  content: '\10e156';
}
.fa-duotone.fa-comment-middle-top:after,
.fad.fa-comment-middle-top:after {
  content: '\10e157';
}
.fa-duotone.fa-comment-minus:after,
.fad.fa-comment-minus:after {
  content: '\10f4b1';
}
.fa-duotone.fa-comment-music:after,
.fad.fa-comment-music:after {
  content: '\10f8b0';
}
.fa-duotone.fa-comment-edit:after,
.fa-duotone.fa-comment-pen:after,
.fad.fa-comment-edit:after,
.fad.fa-comment-pen:after {
  content: '\10f4ae';
}
.fa-duotone.fa-comment-plus:after,
.fad.fa-comment-plus:after {
  content: '\10f4b2';
}
.fa-duotone.fa-comment-question:after,
.fad.fa-comment-question:after {
  content: '\10e158';
}
.fa-duotone.fa-comment-quote:after,
.fad.fa-comment-quote:after {
  content: '\10e159';
}
.fa-duotone.fa-comment-slash:after,
.fad.fa-comment-slash:after {
  content: '\10f4b3';
}
.fa-duotone.fa-comment-smile:after,
.fad.fa-comment-smile:after {
  content: '\10f4b4';
}
.fa-duotone.fa-comment-sms:after,
.fa-duotone.fa-sms:after,
.fad.fa-comment-sms:after,
.fad.fa-sms:after {
  content: '\10f7cd';
}
.fa-duotone.fa-comment-text:after,
.fad.fa-comment-text:after {
  content: '\10e15a';
}
.fa-duotone.fa-comment-times:after,
.fa-duotone.fa-comment-xmark:after,
.fad.fa-comment-times:after,
.fad.fa-comment-xmark:after {
  content: '\10f4b5';
}
.fa-duotone.fa-comments:after,
.fad.fa-comments:after {
  content: '\10f086';
}
.fa-duotone.fa-comments-dollar:after,
.fad.fa-comments-dollar:after {
  content: '\10f653';
}
.fa-duotone.fa-comments-question:after,
.fad.fa-comments-question:after {
  content: '\10e15b';
}
.fa-duotone.fa-comments-question-check:after,
.fad.fa-comments-question-check:after {
  content: '\10e15c';
}
.fa-duotone.fa-compact-disc:after,
.fad.fa-compact-disc:after {
  content: '\10f51f';
}
.fa-duotone.fa-compass:after,
.fad.fa-compass:after {
  content: '\10f14e';
}
.fa-duotone.fa-compass-drafting:after,
.fa-duotone.fa-drafting-compass:after,
.fad.fa-compass-drafting:after,
.fad.fa-drafting-compass:after {
  content: '\10f568';
}
.fa-duotone.fa-compass-slash:after,
.fad.fa-compass-slash:after {
  content: '\10f5e9';
}
.fa-duotone.fa-compress:after,
.fad.fa-compress:after {
  content: '\10f066';
}
.fa-duotone.fa-compress-wide:after,
.fad.fa-compress-wide:after {
  content: '\10f326';
}
.fa-duotone.fa-computer-classic:after,
.fad.fa-computer-classic:after {
  content: '\10f8b1';
}
.fa-duotone.fa-computer-mouse:after,
.fa-duotone.fa-mouse:after,
.fad.fa-computer-mouse:after,
.fad.fa-mouse:after {
  content: '\10f8cc';
}
.fa-duotone.fa-computer-mouse-scrollwheel:after,
.fa-duotone.fa-mouse-alt:after,
.fad.fa-computer-mouse-scrollwheel:after,
.fad.fa-mouse-alt:after {
  content: '\10f8cd';
}
.fa-duotone.fa-computer-speaker:after,
.fad.fa-computer-speaker:after {
  content: '\10f8b2';
}
.fa-duotone.fa-container-storage:after,
.fad.fa-container-storage:after {
  content: '\10f4b7';
}
.fa-duotone.fa-conveyor-belt:after,
.fad.fa-conveyor-belt:after {
  content: '\10f46e';
}
.fa-duotone.fa-conveyor-belt-alt:after,
.fa-duotone.fa-conveyor-belt-boxes:after,
.fad.fa-conveyor-belt-alt:after,
.fad.fa-conveyor-belt-boxes:after {
  content: '\10f46f';
}
.fa-duotone.fa-conveyor-belt-empty:after,
.fad.fa-conveyor-belt-empty:after {
  content: '\10e15d';
}
.fa-duotone.fa-cookie:after,
.fad.fa-cookie:after {
  content: '\10f563';
}
.fa-duotone.fa-cookie-bite:after,
.fad.fa-cookie-bite:after {
  content: '\10f564';
}
.fa-duotone.fa-copy:after,
.fad.fa-copy:after {
  content: '\10f0c5';
}
.fa-duotone.fa-copyright:after,
.fad.fa-copyright:after {
  content: '\10f1f9';
}
.fa-duotone.fa-corn:after,
.fad.fa-corn:after {
  content: '\10f6c7';
}
.fa-duotone.fa-couch:after,
.fad.fa-couch:after {
  content: '\10f4b8';
}
.fa-duotone.fa-cow:after,
.fad.fa-cow:after {
  content: '\10f6c8';
}
.fa-duotone.fa-cowbell:after,
.fad.fa-cowbell:after {
  content: '\10f8b3';
}
.fa-duotone.fa-cowbell-circle-plus:after,
.fa-duotone.fa-cowbell-more:after,
.fad.fa-cowbell-circle-plus:after,
.fad.fa-cowbell-more:after {
  content: '\10f8b4';
}
.fa-duotone.fa-credit-card-alt:after,
.fa-duotone.fa-credit-card:after,
.fad.fa-credit-card-alt:after,
.fad.fa-credit-card:after {
  content: '\10f09d';
}
.fa-duotone.fa-credit-card-blank:after,
.fad.fa-credit-card-blank:after {
  content: '\10f389';
}
.fa-duotone.fa-credit-card-front:after,
.fad.fa-credit-card-front:after {
  content: '\10f38a';
}
.fa-duotone.fa-cricket-bat-ball:after,
.fa-duotone.fa-cricket:after,
.fad.fa-cricket-bat-ball:after,
.fad.fa-cricket:after {
  content: '\10f449';
}
.fa-duotone.fa-croissant:after,
.fad.fa-croissant:after {
  content: '\10f7f6';
}
.fa-duotone.fa-crop:after,
.fad.fa-crop:after {
  content: '\10f125';
}
.fa-duotone.fa-crop-alt:after,
.fa-duotone.fa-crop-simple:after,
.fad.fa-crop-alt:after,
.fad.fa-crop-simple:after {
  content: '\10f565';
}
.fa-duotone.fa-cross:after,
.fad.fa-cross:after {
  content: '\10f654';
}
.fa-duotone.fa-crosshairs:after,
.fad.fa-crosshairs:after {
  content: '\10f05b';
}
.fa-duotone.fa-crow:after,
.fad.fa-crow:after {
  content: '\10f520';
}
.fa-duotone.fa-crown:after,
.fad.fa-crown:after {
  content: '\10f521';
}
.fa-duotone.fa-crutch:after,
.fad.fa-crutch:after {
  content: '\10f7f7';
}
.fa-duotone.fa-crutches:after,
.fad.fa-crutches:after {
  content: '\10f7f8';
}
.fa-duotone.fa-cruzeiro-sign:after,
.fad.fa-cruzeiro-sign:after {
  content: '\10e15e';
}
.fa-duotone.fa-cube:after,
.fad.fa-cube:after {
  content: '\10f1b2';
}
.fa-duotone.fa-cubes:after,
.fad.fa-cubes:after {
  content: '\10f1b3';
}
.fa-duotone.fa-coffee-togo:after,
.fa-duotone.fa-cup-togo:after,
.fad.fa-coffee-togo:after,
.fad.fa-cup-togo:after {
  content: '\10f6c5';
}
.fa-duotone.fa-curling-stone:after,
.fa-duotone.fa-curling:after,
.fad.fa-curling-stone:after,
.fad.fa-curling:after {
  content: '\10f44a';
}
.fa-duotone.fa-d:after,
.fad.fa-d:after {
  content: '\10e15f';
}
.fa-duotone.fa-dagger:after,
.fad.fa-dagger:after {
  content: '\10f6cb';
}
.fa-duotone.fa-database:after,
.fad.fa-database:after {
  content: '\10f1c0';
}
.fa-duotone.fa-deer:after,
.fad.fa-deer:after {
  content: '\10f78e';
}
.fa-duotone.fa-deer-rudolph:after,
.fad.fa-deer-rudolph:after {
  content: '\10f78f';
}
.fa-duotone.fa-backspace:after,
.fa-duotone.fa-delete-left:after,
.fad.fa-backspace:after,
.fad.fa-delete-left:after {
  content: '\10f55a';
}
.fa-duotone.fa-delete-right:after,
.fad.fa-delete-right:after {
  content: '\10e160';
}
.fa-duotone.fa-democrat:after,
.fad.fa-democrat:after {
  content: '\10f747';
}
.fa-duotone.fa-desktop-alt:after,
.fa-duotone.fa-desktop:after,
.fad.fa-desktop-alt:after,
.fad.fa-desktop:after {
  content: '\10f108';
}
.fa-duotone.fa-desktop-arrow-down:after,
.fad.fa-desktop-arrow-down:after {
  content: '\10e161';
}
.fa-duotone.fa-dharmachakra:after,
.fad.fa-dharmachakra:after {
  content: '\10f655';
}
.fa-duotone.fa-diagram-lean-canvas:after,
.fad.fa-diagram-lean-canvas:after {
  content: '\10e162';
}
.fa-duotone.fa-diagram-nested:after,
.fad.fa-diagram-nested:after {
  content: '\10e163';
}
.fa-duotone.fa-diagram-project:after,
.fa-duotone.fa-project-diagram:after,
.fad.fa-diagram-project:after,
.fad.fa-project-diagram:after {
  content: '\10f542';
}
.fa-duotone.fa-diagram-sankey:after,
.fad.fa-diagram-sankey:after {
  content: '\10e164';
}
.fa-duotone.fa-diagram-stankey:after,
.fad.fa-diagram-stankey:after {
  content: '\10e165';
}
.fa-duotone.fa-diagram-venn:after,
.fad.fa-diagram-venn:after {
  content: '\10e166';
}
.fa-duotone.fa-dial-med-high:after,
.fa-duotone.fa-dial:after,
.fad.fa-dial-med-high:after,
.fad.fa-dial:after {
  content: '\10e167';
}
.fa-duotone.fa-dial-high:after,
.fad.fa-dial-high:after {
  content: '\10e168';
}
.fa-duotone.fa-dial-low:after,
.fad.fa-dial-low:after {
  content: '\10e169';
}
.fa-duotone.fa-dial-max:after,
.fad.fa-dial-max:after {
  content: '\10e16a';
}
.fa-duotone.fa-dial-med:after,
.fad.fa-dial-med:after {
  content: '\10e16b';
}
.fa-duotone.fa-dial-med-low:after,
.fad.fa-dial-med-low:after {
  content: '\10e16c';
}
.fa-duotone.fa-dial-min:after,
.fad.fa-dial-min:after {
  content: '\10e16d';
}
.fa-duotone.fa-diamond:after,
.fad.fa-diamond:after {
  content: '\10f219';
}
.fa-duotone.fa-diamond-turn-right:after,
.fa-duotone.fa-directions:after,
.fad.fa-diamond-turn-right:after,
.fad.fa-directions:after {
  content: '\10f5eb';
}
.fa-duotone.fa-dice:after,
.fad.fa-dice:after {
  content: '\10f522';
}
.fa-duotone.fa-dice-d10:after,
.fad.fa-dice-d10:after {
  content: '\10f6cd';
}
.fa-duotone.fa-dice-d12:after,
.fad.fa-dice-d12:after {
  content: '\10f6ce';
}
.fa-duotone.fa-dice-d20:after,
.fad.fa-dice-d20:after {
  content: '\10f6cf';
}
.fa-duotone.fa-dice-d4:after,
.fad.fa-dice-d4:after {
  content: '\10f6d0';
}
.fa-duotone.fa-dice-d6:after,
.fad.fa-dice-d6:after {
  content: '\10f6d1';
}
.fa-duotone.fa-dice-d8:after,
.fad.fa-dice-d8:after {
  content: '\10f6d2';
}
.fa-duotone.fa-dice-five:after,
.fad.fa-dice-five:after {
  content: '\10f523';
}
.fa-duotone.fa-dice-four:after,
.fad.fa-dice-four:after {
  content: '\10f524';
}
.fa-duotone.fa-dice-one:after,
.fad.fa-dice-one:after {
  content: '\10f525';
}
.fa-duotone.fa-dice-six:after,
.fad.fa-dice-six:after {
  content: '\10f526';
}
.fa-duotone.fa-dice-three:after,
.fad.fa-dice-three:after {
  content: '\10f527';
}
.fa-duotone.fa-dice-two:after,
.fad.fa-dice-two:after {
  content: '\10f528';
}
.fa-duotone.fa-diploma:after,
.fa-duotone.fa-scroll-ribbon:after,
.fad.fa-diploma:after,
.fad.fa-scroll-ribbon:after {
  content: '\10f5ea';
}
.fa-duotone.fa-disc-drive:after,
.fad.fa-disc-drive:after {
  content: '\10f8b5';
}
.fa-duotone.fa-disease:after,
.fad.fa-disease:after {
  content: '\10f7fa';
}
.fa-duotone.fa-display:after,
.fad.fa-display:after {
  content: '\10e16e';
}
.fa-duotone.fa-display-arrow-down:after,
.fad.fa-display-arrow-down:after {
  content: '\10e16f';
}
.fa-duotone.fa-desktop-code:after,
.fa-duotone.fa-display-code:after,
.fad.fa-desktop-code:after,
.fad.fa-display-code:after {
  content: '\10e170';
}
.fa-duotone.fa-desktop-medical:after,
.fa-duotone.fa-display-medical:after,
.fad.fa-desktop-medical:after,
.fad.fa-display-medical:after {
  content: '\10e171';
}
.fa-duotone.fa-desktop-slash:after,
.fa-duotone.fa-display-slash:after,
.fad.fa-desktop-slash:after,
.fad.fa-display-slash:after {
  content: '\10e172';
}
.fa-duotone.fa-divide:after,
.fad.fa-divide:after {
  content: '\10f529';
}
.fa-duotone.fa-dna:after,
.fad.fa-dna:after {
  content: '\10f471';
}
.fa-duotone.fa-do-not-enter:after,
.fad.fa-do-not-enter:after {
  content: '\10f5ec';
}
.fa-duotone.fa-dog:after,
.fad.fa-dog:after {
  content: '\10f6d3';
}
.fa-duotone.fa-dog-leashed:after,
.fad.fa-dog-leashed:after {
  content: '\10f6d4';
}
.fa-duotone.fa-dollar-sign:after,
.fa-duotone.fa-dollar:after,
.fa-duotone.fa-usd:after,
.fad.fa-dollar-sign:after,
.fad.fa-dollar:after,
.fad.fa-usd:after {
  content: '\10f155';
}
.fa-duotone.fa-dolly-box:after,
.fa-duotone.fa-dolly:after,
.fad.fa-dolly-box:after,
.fad.fa-dolly:after {
  content: '\10f472';
}
.fa-duotone.fa-dolly-empty:after,
.fad.fa-dolly-empty:after {
  content: '\10f473';
}
.fa-duotone.fa-dolphin:after,
.fad.fa-dolphin:after {
  content: '\10e173';
}
.fa-duotone.fa-dong-sign:after,
.fad.fa-dong-sign:after {
  content: '\10e174';
}
.fa-duotone.fa-door-closed:after,
.fad.fa-door-closed:after {
  content: '\10f52a';
}
.fa-duotone.fa-door-open:after,
.fad.fa-door-open:after {
  content: '\10f52b';
}
.fa-duotone.fa-dove:after,
.fad.fa-dove:after {
  content: '\10f4ba';
}
.fa-duotone.fa-arrow-alt-down:after,
.fa-duotone.fa-down:after,
.fad.fa-arrow-alt-down:after,
.fad.fa-down:after {
  content: '\10f354';
}
.fa-duotone.fa-arrow-alt-from-top:after,
.fa-duotone.fa-down-from-line:after,
.fad.fa-arrow-alt-from-top:after,
.fad.fa-down-from-line:after {
  content: '\10f349';
}
.fa-duotone.fa-down-left:after,
.fad.fa-down-left:after {
  content: '\10e175';
}
.fa-duotone.fa-compress-alt:after,
.fa-duotone.fa-down-left-and-up-right-to-center:after,
.fad.fa-compress-alt:after,
.fad.fa-down-left-and-up-right-to-center:after {
  content: '\10f422';
}
.fa-duotone.fa-down-long:after,
.fa-duotone.fa-long-arrow-alt-down:after,
.fad.fa-down-long:after,
.fad.fa-long-arrow-alt-down:after {
  content: '\10f309';
}
.fa-duotone.fa-down-right:after,
.fad.fa-down-right:after {
  content: '\10e176';
}
.fa-duotone.fa-arrow-alt-to-bottom:after,
.fa-duotone.fa-down-to-line:after,
.fad.fa-arrow-alt-to-bottom:after,
.fad.fa-down-to-line:after {
  content: '\10f34a';
}
.fa-duotone.fa-download:after,
.fad.fa-download:after {
  content: '\10f019';
}
.fa-duotone.fa-dragon:after,
.fad.fa-dragon:after {
  content: '\10f6d5';
}
.fa-duotone.fa-draw-circle:after,
.fad.fa-draw-circle:after {
  content: '\10f5ed';
}
.fa-duotone.fa-draw-polygon:after,
.fad.fa-draw-polygon:after {
  content: '\10f5ee';
}
.fa-duotone.fa-draw-square:after,
.fad.fa-draw-square:after {
  content: '\10f5ef';
}
.fa-duotone.fa-dreidel:after,
.fad.fa-dreidel:after {
  content: '\10f792';
}
.fa-duotone.fa-drone:after,
.fad.fa-drone:after {
  content: '\10f85f';
}
.fa-duotone.fa-drone-alt:after,
.fa-duotone.fa-drone-front:after,
.fad.fa-drone-alt:after,
.fad.fa-drone-front:after {
  content: '\10f860';
}
.fa-duotone.fa-droplet:after,
.fa-duotone.fa-tint:after,
.fad.fa-droplet:after,
.fad.fa-tint:after {
  content: '\10f043';
}
.fa-duotone.fa-dewpoint:after,
.fa-duotone.fa-droplet-degree:after,
.fad.fa-dewpoint:after,
.fad.fa-droplet-degree:after {
  content: '\10f748';
}
.fa-duotone.fa-droplet-percent:after,
.fa-duotone.fa-humidity:after,
.fad.fa-droplet-percent:after,
.fad.fa-humidity:after {
  content: '\10f750';
}
.fa-duotone.fa-droplet-slash:after,
.fa-duotone.fa-tint-slash:after,
.fad.fa-droplet-slash:after,
.fad.fa-tint-slash:after {
  content: '\10f5c7';
}
.fa-duotone.fa-drum:after,
.fad.fa-drum:after {
  content: '\10f569';
}
.fa-duotone.fa-drum-steelpan:after,
.fad.fa-drum-steelpan:after {
  content: '\10f56a';
}
.fa-duotone.fa-drumstick:after,
.fad.fa-drumstick:after {
  content: '\10f6d6';
}
.fa-duotone.fa-drumstick-bite:after,
.fad.fa-drumstick-bite:after {
  content: '\10f6d7';
}
.fa-duotone.fa-dryer:after,
.fad.fa-dryer:after {
  content: '\10f861';
}
.fa-duotone.fa-dryer-alt:after,
.fa-duotone.fa-dryer-heat:after,
.fad.fa-dryer-alt:after,
.fad.fa-dryer-heat:after {
  content: '\10f862';
}
.fa-duotone.fa-duck:after,
.fad.fa-duck:after {
  content: '\10f6d8';
}
.fa-duotone.fa-dumbbell:after,
.fad.fa-dumbbell:after {
  content: '\10f44b';
}
.fa-duotone.fa-dumpster:after,
.fad.fa-dumpster:after {
  content: '\10f793';
}
.fa-duotone.fa-dumpster-fire:after,
.fad.fa-dumpster-fire:after {
  content: '\10f794';
}
.fa-duotone.fa-dungeon:after,
.fad.fa-dungeon:after {
  content: '\10f6d9';
}
.fa-duotone.fa-e:after,
.fad.fa-e:after {
  content: '\10e177';
}
.fa-duotone.fa-ear:after,
.fad.fa-ear:after {
  content: '\10f5f0';
}
.fa-duotone.fa-deaf:after,
.fa-duotone.fa-deafness:after,
.fa-duotone.fa-ear-deaf:after,
.fa-duotone.fa-hard-of-hearing:after,
.fad.fa-deaf:after,
.fad.fa-deafness:after,
.fad.fa-ear-deaf:after,
.fad.fa-hard-of-hearing:after {
  content: '\10f2a4';
}
.fa-duotone.fa-assistive-listening-systems:after,
.fa-duotone.fa-ear-listen:after,
.fad.fa-assistive-listening-systems:after,
.fad.fa-ear-listen:after {
  content: '\10f2a2';
}
.fa-duotone.fa-ear-muffs:after,
.fad.fa-ear-muffs:after {
  content: '\10f795';
}
.fa-duotone.fa-earth-africa:after,
.fa-duotone.fa-globe-africa:after,
.fad.fa-earth-africa:after,
.fad.fa-globe-africa:after {
  content: '\10f57c';
}
.fa-duotone.fa-earth-americas:after,
.fa-duotone.fa-earth:after,
.fa-duotone.fa-globe-americas:after,
.fad.fa-earth-americas:after,
.fad.fa-earth:after,
.fad.fa-globe-americas:after {
  content: '\10f57d';
}
.fa-duotone.fa-earth-asia:after,
.fa-duotone.fa-globe-asia:after,
.fad.fa-earth-asia:after,
.fad.fa-globe-asia:after {
  content: '\10f57e';
}
.fa-duotone.fa-earth-europa:after,
.fa-duotone.fa-globe-europe:after,
.fad.fa-earth-europa:after,
.fad.fa-globe-europe:after {
  content: '\10f7a2';
}
.fa-duotone.fa-eclipse:after,
.fad.fa-eclipse:after {
  content: '\10f749';
}
.fa-duotone.fa-egg:after,
.fad.fa-egg:after {
  content: '\10f7fb';
}
.fa-duotone.fa-egg-fried:after,
.fad.fa-egg-fried:after {
  content: '\10f7fc';
}
.fa-duotone.fa-eggplant:after,
.fad.fa-eggplant:after {
  content: '\10e178';
}
.fa-duotone.fa-eject:after,
.fad.fa-eject:after {
  content: '\10f052';
}
.fa-duotone.fa-elephant:after,
.fad.fa-elephant:after {
  content: '\10f6da';
}
.fa-duotone.fa-elevator:after,
.fad.fa-elevator:after {
  content: '\10e179';
}
.fa-duotone.fa-ellipsis-h:after,
.fa-duotone.fa-ellipsis:after,
.fad.fa-ellipsis-h:after,
.fad.fa-ellipsis:after {
  content: '\10f141';
}
.fa-duotone.fa-ellipsis-h-alt:after,
.fa-duotone.fa-ellipsis-stroke:after,
.fad.fa-ellipsis-h-alt:after,
.fad.fa-ellipsis-stroke:after {
  content: '\10f39b';
}
.fa-duotone.fa-ellipsis-stroke-vertical:after,
.fa-duotone.fa-ellipsis-v-alt:after,
.fad.fa-ellipsis-stroke-vertical:after,
.fad.fa-ellipsis-v-alt:after {
  content: '\10f39c';
}
.fa-duotone.fa-ellipsis-v:after,
.fa-duotone.fa-ellipsis-vertical:after,
.fad.fa-ellipsis-v:after,
.fad.fa-ellipsis-vertical:after {
  content: '\10f142';
}
.fa-duotone.fa-empty-set:after,
.fad.fa-empty-set:after {
  content: '\10f656';
}
.fa-duotone.fa-engine:after,
.fad.fa-engine:after {
  content: '\10e17a';
}
.fa-duotone.fa-engine-exclamation:after,
.fa-duotone.fa-engine-warning:after,
.fad.fa-engine-exclamation:after,
.fad.fa-engine-warning:after {
  content: '\10f5f2';
}
.fa-duotone.fa-envelope:after,
.fad.fa-envelope:after {
  content: '\10f0e0';
}
.fa-duotone.fa-envelope-badge:after,
.fa-duotone.fa-envelope-dot:after,
.fad.fa-envelope-badge:after,
.fad.fa-envelope-dot:after {
  content: '\10e17b';
}
.fa-duotone.fa-envelope-open:after,
.fad.fa-envelope-open:after {
  content: '\10f2b6';
}
.fa-duotone.fa-envelope-open-dollar:after,
.fad.fa-envelope-open-dollar:after {
  content: '\10f657';
}
.fa-duotone.fa-envelope-open-text:after,
.fad.fa-envelope-open-text:after {
  content: '\10f658';
}
.fa-duotone.fa-envelopes:after,
.fad.fa-envelopes:after {
  content: '\10e17c';
}
.fa-duotone.fa-envelopes-bulk:after,
.fa-duotone.fa-mail-bulk:after,
.fad.fa-envelopes-bulk:after,
.fad.fa-mail-bulk:after {
  content: '\10f674';
}
.fa-duotone.fa-equals:after,
.fad.fa-equals:after {
  content: '\10f52c';
}
.fa-duotone.fa-eraser:after,
.fad.fa-eraser:after {
  content: '\10f12d';
}
.fa-duotone.fa-escalator:after,
.fad.fa-escalator:after {
  content: '\10e17d';
}
.fa-duotone.fa-ethernet:after,
.fad.fa-ethernet:after {
  content: '\10f796';
}
.fa-duotone.fa-eur:after,
.fa-duotone.fa-euro-sign:after,
.fa-duotone.fa-euro:after,
.fad.fa-eur:after,
.fad.fa-euro-sign:after,
.fad.fa-euro:after {
  content: '\10f153';
}
.fa-duotone.fa-exclamation:after,
.fad.fa-exclamation:after {
  content: '\10f12a';
}
.fa-duotone.fa-expand:after,
.fad.fa-expand:after {
  content: '\10f065';
}
.fa-duotone.fa-expand-wide:after,
.fad.fa-expand-wide:after {
  content: '\10f320';
}
.fa-duotone.fa-eye:after,
.fad.fa-eye:after {
  content: '\10f06e';
}
.fa-duotone.fa-eye-dropper-empty:after,
.fa-duotone.fa-eye-dropper:after,
.fa-duotone.fa-eyedropper:after,
.fad.fa-eye-dropper-empty:after,
.fad.fa-eye-dropper:after,
.fad.fa-eyedropper:after {
  content: '\10f1fb';
}
.fa-duotone.fa-eye-dropper-full:after,
.fad.fa-eye-dropper-full:after {
  content: '\10e17e';
}
.fa-duotone.fa-eye-dropper-half:after,
.fad.fa-eye-dropper-half:after {
  content: '\10e17f';
}
.fa-duotone.fa-eye-evil:after,
.fad.fa-eye-evil:after {
  content: '\10f6db';
}
.fa-duotone.fa-eye-low-vision:after,
.fa-duotone.fa-low-vision:after,
.fad.fa-eye-low-vision:after,
.fad.fa-low-vision:after {
  content: '\10f2a8';
}
.fa-duotone.fa-eye-slash:after,
.fad.fa-eye-slash:after {
  content: '\10f070';
}
.fa-duotone.fa-f:after,
.fad.fa-f:after {
  content: '\10e180';
}
.fa-duotone.fa-angry:after,
.fa-duotone.fa-face-angry:after,
.fad.fa-angry:after,
.fad.fa-face-angry:after {
  content: '\10f556';
}
.fa-duotone.fa-dizzy:after,
.fa-duotone.fa-face-dizzy:after,
.fad.fa-dizzy:after,
.fad.fa-face-dizzy:after {
  content: '\10f567';
}
.fa-duotone.fa-face-flushed:after,
.fa-duotone.fa-flushed:after,
.fad.fa-face-flushed:after,
.fad.fa-flushed:after {
  content: '\10f579';
}
.fa-duotone.fa-face-frown:after,
.fa-duotone.fa-frown:after,
.fad.fa-face-frown:after,
.fad.fa-frown:after {
  content: '\10f119';
}
.fa-duotone.fa-face-frown-open:after,
.fa-duotone.fa-frown-open:after,
.fad.fa-face-frown-open:after,
.fad.fa-frown-open:after {
  content: '\10f57a';
}
.fa-duotone.fa-face-grimace:after,
.fa-duotone.fa-grimace:after,
.fad.fa-face-grimace:after,
.fad.fa-grimace:after {
  content: '\10f57f';
}
.fa-duotone.fa-face-grin:after,
.fa-duotone.fa-grin:after,
.fad.fa-face-grin:after,
.fad.fa-grin:after {
  content: '\10f580';
}
.fa-duotone.fa-face-grin-beam:after,
.fa-duotone.fa-grin-beam:after,
.fad.fa-face-grin-beam:after,
.fad.fa-grin-beam:after {
  content: '\10f582';
}
.fa-duotone.fa-face-grin-beam-sweat:after,
.fa-duotone.fa-grin-beam-sweat:after,
.fad.fa-face-grin-beam-sweat:after,
.fad.fa-grin-beam-sweat:after {
  content: '\10f583';
}
.fa-duotone.fa-face-grin-hearts:after,
.fa-duotone.fa-grin-hearts:after,
.fad.fa-face-grin-hearts:after,
.fad.fa-grin-hearts:after {
  content: '\10f584';
}
.fa-duotone.fa-face-grin-squint:after,
.fa-duotone.fa-grin-squint:after,
.fad.fa-face-grin-squint:after,
.fad.fa-grin-squint:after {
  content: '\10f585';
}
.fa-duotone.fa-face-grin-squint-tears:after,
.fa-duotone.fa-grin-squint-tears:after,
.fad.fa-face-grin-squint-tears:after,
.fad.fa-grin-squint-tears:after {
  content: '\10f586';
}
.fa-duotone.fa-face-grin-stars:after,
.fa-duotone.fa-grin-stars:after,
.fad.fa-face-grin-stars:after,
.fad.fa-grin-stars:after {
  content: '\10f587';
}
.fa-duotone.fa-face-grin-tears:after,
.fa-duotone.fa-grin-tears:after,
.fad.fa-face-grin-tears:after,
.fad.fa-grin-tears:after {
  content: '\10f588';
}
.fa-duotone.fa-face-grin-tongue:after,
.fa-duotone.fa-grin-tongue:after,
.fad.fa-face-grin-tongue:after,
.fad.fa-grin-tongue:after {
  content: '\10f589';
}
.fa-duotone.fa-face-grin-tongue-squint:after,
.fa-duotone.fa-grin-tongue-squint:after,
.fad.fa-face-grin-tongue-squint:after,
.fad.fa-grin-tongue-squint:after {
  content: '\10f58a';
}
.fa-duotone.fa-face-grin-tongue-wink:after,
.fa-duotone.fa-grin-tongue-wink:after,
.fad.fa-face-grin-tongue-wink:after,
.fad.fa-grin-tongue-wink:after {
  content: '\10f58b';
}
.fa-duotone.fa-face-grin-wide:after,
.fa-duotone.fa-grin-alt:after,
.fad.fa-face-grin-wide:after,
.fad.fa-grin-alt:after {
  content: '\10f581';
}
.fa-duotone.fa-face-grin-wink:after,
.fa-duotone.fa-grin-wink:after,
.fad.fa-face-grin-wink:after,
.fad.fa-grin-wink:after {
  content: '\10f58c';
}
.fa-duotone.fa-face-kiss:after,
.fa-duotone.fa-kiss:after,
.fad.fa-face-kiss:after,
.fad.fa-kiss:after {
  content: '\10f596';
}
.fa-duotone.fa-face-kiss-beam:after,
.fa-duotone.fa-kiss-beam:after,
.fad.fa-face-kiss-beam:after,
.fad.fa-kiss-beam:after {
  content: '\10f597';
}
.fa-duotone.fa-face-kiss-wink-heart:after,
.fa-duotone.fa-kiss-wink-heart:after,
.fad.fa-face-kiss-wink-heart:after,
.fad.fa-kiss-wink-heart:after {
  content: '\10f598';
}
.fa-duotone.fa-face-laugh:after,
.fa-duotone.fa-laugh:after,
.fad.fa-face-laugh:after,
.fad.fa-laugh:after {
  content: '\10f599';
}
.fa-duotone.fa-face-laugh-beam:after,
.fa-duotone.fa-laugh-beam:after,
.fad.fa-face-laugh-beam:after,
.fad.fa-laugh-beam:after {
  content: '\10f59a';
}
.fa-duotone.fa-face-laugh-squint:after,
.fa-duotone.fa-laugh-squint:after,
.fad.fa-face-laugh-squint:after,
.fad.fa-laugh-squint:after {
  content: '\10f59b';
}
.fa-duotone.fa-face-laugh-wink:after,
.fa-duotone.fa-laugh-wink:after,
.fad.fa-face-laugh-wink:after,
.fad.fa-laugh-wink:after {
  content: '\10f59c';
}
.fa-duotone.fa-face-meh:after,
.fa-duotone.fa-meh:after,
.fad.fa-face-meh:after,
.fad.fa-meh:after {
  content: '\10f11a';
}
.fa-duotone.fa-face-meh-blank:after,
.fa-duotone.fa-meh-blank:after,
.fad.fa-face-meh-blank:after,
.fad.fa-meh-blank:after {
  content: '\10f5a4';
}
.fa-duotone.fa-face-rolling-eyes:after,
.fa-duotone.fa-meh-rolling-eyes:after,
.fad.fa-face-rolling-eyes:after,
.fad.fa-meh-rolling-eyes:after {
  content: '\10f5a5';
}
.fa-duotone.fa-face-sad-cry:after,
.fa-duotone.fa-sad-cry:after,
.fad.fa-face-sad-cry:after,
.fad.fa-sad-cry:after {
  content: '\10f5b3';
}
.fa-duotone.fa-face-sad-tear:after,
.fa-duotone.fa-sad-tear:after,
.fad.fa-face-sad-tear:after,
.fad.fa-sad-tear:after {
  content: '\10f5b4';
}
.fa-duotone.fa-face-smile:after,
.fa-duotone.fa-smile:after,
.fad.fa-face-smile:after,
.fad.fa-smile:after {
  content: '\10f118';
}
.fa-duotone.fa-face-smile-beam:after,
.fa-duotone.fa-smile-beam:after,
.fad.fa-face-smile-beam:after,
.fad.fa-smile-beam:after {
  content: '\10f5b8';
}
.fa-duotone.fa-face-smile-plus:after,
.fa-duotone.fa-smile-plus:after,
.fad.fa-face-smile-plus:after,
.fad.fa-smile-plus:after {
  content: '\10f5b9';
}
.fa-duotone.fa-face-smile-wink:after,
.fa-duotone.fa-smile-wink:after,
.fad.fa-face-smile-wink:after,
.fad.fa-smile-wink:after {
  content: '\10f4da';
}
.fa-duotone.fa-face-surprise:after,
.fa-duotone.fa-surprise:after,
.fad.fa-face-surprise:after,
.fad.fa-surprise:after {
  content: '\10f5c2';
}
.fa-duotone.fa-face-tired:after,
.fa-duotone.fa-tired:after,
.fad.fa-face-tired:after,
.fad.fa-tired:after {
  content: '\10f5c8';
}
.fa-duotone.fa-fan:after,
.fad.fa-fan:after {
  content: '\10f863';
}
.fa-duotone.fa-fan-table:after,
.fad.fa-fan-table:after {
  content: '\10e004';
}
.fa-duotone.fa-barn-silo:after,
.fa-duotone.fa-farm:after,
.fad.fa-barn-silo:after,
.fad.fa-farm:after {
  content: '\10f864';
}
.fa-duotone.fa-faucet:after,
.fad.fa-faucet:after {
  content: '\10e005';
}
.fa-duotone.fa-faucet-drip:after,
.fad.fa-faucet-drip:after {
  content: '\10e006';
}
.fa-duotone.fa-fax:after,
.fad.fa-fax:after {
  content: '\10f1ac';
}
.fa-duotone.fa-feather:after,
.fad.fa-feather:after {
  content: '\10f52d';
}
.fa-duotone.fa-feather-alt:after,
.fa-duotone.fa-feather-pointed:after,
.fad.fa-feather-alt:after,
.fad.fa-feather-pointed:after {
  content: '\10f56b';
}
.fa-duotone.fa-fence:after,
.fad.fa-fence:after {
  content: '\10e181';
}
.fa-duotone.fa-field-hockey-stick-ball:after,
.fa-duotone.fa-field-hockey:after,
.fad.fa-field-hockey-stick-ball:after,
.fad.fa-field-hockey:after {
  content: '\10f44c';
}
.fa-duotone.fa-file:after,
.fad.fa-file:after {
  content: '\10f15b';
}
.fa-duotone.fa-file-arrow-down:after,
.fa-duotone.fa-file-download:after,
.fad.fa-file-arrow-down:after,
.fad.fa-file-download:after {
  content: '\10f56d';
}
.fa-duotone.fa-file-arrow-up:after,
.fa-duotone.fa-file-upload:after,
.fad.fa-file-arrow-up:after,
.fad.fa-file-upload:after {
  content: '\10f574';
}
.fa-duotone.fa-file-audio:after,
.fad.fa-file-audio:after {
  content: '\10f1c7';
}
.fa-duotone.fa-file-binary:after,
.fad.fa-file-binary:after {
  content: '\10e182';
}
.fa-duotone.fa-file-award:after,
.fa-duotone.fa-file-certificate:after,
.fad.fa-file-award:after,
.fad.fa-file-certificate:after {
  content: '\10f5f3';
}
.fa-duotone.fa-file-chart-column:after,
.fa-duotone.fa-file-chart-line:after,
.fad.fa-file-chart-column:after,
.fad.fa-file-chart-line:after {
  content: '\10f659';
}
.fa-duotone.fa-file-chart-pie:after,
.fad.fa-file-chart-pie:after {
  content: '\10f65a';
}
.fa-duotone.fa-file-check:after,
.fad.fa-file-check:after {
  content: '\10f316';
}
.fa-duotone.fa-file-code:after,
.fad.fa-file-code:after {
  content: '\10f1c9';
}
.fa-duotone.fa-file-contract:after,
.fad.fa-file-contract:after {
  content: '\10f56c';
}
.fa-duotone.fa-file-csv:after,
.fad.fa-file-csv:after {
  content: '\10f6dd';
}
.fa-duotone.fa-file-dashed-line:after,
.fa-duotone.fa-page-break:after,
.fad.fa-file-dashed-line:after,
.fad.fa-page-break:after {
  content: '\10f877';
}
.fa-duotone.fa-file-excel:after,
.fad.fa-file-excel:after {
  content: '\10f1c3';
}
.fa-duotone.fa-file-exclamation:after,
.fad.fa-file-exclamation:after {
  content: '\10f31a';
}
.fa-duotone.fa-arrow-right-from-file:after,
.fa-duotone.fa-file-export:after,
.fad.fa-arrow-right-from-file:after,
.fad.fa-file-export:after {
  content: '\10f56e';
}
.fa-duotone.fa-file-heart:after,
.fad.fa-file-heart:after {
  content: '\10e183';
}
.fa-duotone.fa-file-image:after,
.fad.fa-file-image:after {
  content: '\10f1c5';
}
.fa-duotone.fa-arrow-right-to-file:after,
.fa-duotone.fa-file-import:after,
.fad.fa-arrow-right-to-file:after,
.fad.fa-file-import:after {
  content: '\10f56f';
}
.fa-duotone.fa-file-invoice:after,
.fad.fa-file-invoice:after {
  content: '\10f570';
}
.fa-duotone.fa-file-invoice-dollar:after,
.fad.fa-file-invoice-dollar:after {
  content: '\10f571';
}
.fa-duotone.fa-file-alt:after,
.fa-duotone.fa-file-lines:after,
.fa-duotone.fa-file-text:after,
.fad.fa-file-alt:after,
.fad.fa-file-lines:after,
.fad.fa-file-text:after {
  content: '\10f15c';
}
.fa-duotone.fa-file-magnifying-glass:after,
.fa-duotone.fa-file-search:after,
.fad.fa-file-magnifying-glass:after,
.fad.fa-file-search:after {
  content: '\10f865';
}
.fa-duotone.fa-file-medical:after,
.fad.fa-file-medical:after {
  content: '\10f477';
}
.fa-duotone.fa-file-minus:after,
.fad.fa-file-minus:after {
  content: '\10f318';
}
.fa-duotone.fa-file-music:after,
.fad.fa-file-music:after {
  content: '\10f8b6';
}
.fa-duotone.fa-file-pdf:after,
.fad.fa-file-pdf:after {
  content: '\10f1c1';
}
.fa-duotone.fa-file-edit:after,
.fa-duotone.fa-file-pen:after,
.fad.fa-file-edit:after,
.fad.fa-file-pen:after {
  content: '\10f31c';
}
.fa-duotone.fa-file-plus:after,
.fad.fa-file-plus:after {
  content: '\10f319';
}
.fa-duotone.fa-file-plus-minus:after,
.fad.fa-file-plus-minus:after {
  content: '\10e184';
}
.fa-duotone.fa-file-powerpoint:after,
.fad.fa-file-powerpoint:after {
  content: '\10f1c4';
}
.fa-duotone.fa-file-prescription:after,
.fad.fa-file-prescription:after {
  content: '\10f572';
}
.fa-duotone.fa-file-signature:after,
.fad.fa-file-signature:after {
  content: '\10f573';
}
.fa-duotone.fa-file-spreadsheet:after,
.fad.fa-file-spreadsheet:after {
  content: '\10f65b';
}
.fa-duotone.fa-file-user:after,
.fad.fa-file-user:after {
  content: '\10f65c';
}
.fa-duotone.fa-file-video:after,
.fad.fa-file-video:after {
  content: '\10f1c8';
}
.fa-duotone.fa-file-medical-alt:after,
.fa-duotone.fa-file-waveform:after,
.fad.fa-file-medical-alt:after,
.fad.fa-file-waveform:after {
  content: '\10f478';
}
.fa-duotone.fa-file-word:after,
.fad.fa-file-word:after {
  content: '\10f1c2';
}
.fa-duotone.fa-file-times:after,
.fa-duotone.fa-file-xmark:after,
.fad.fa-file-times:after,
.fad.fa-file-xmark:after {
  content: '\10f317';
}
.fa-duotone.fa-file-archive:after,
.fa-duotone.fa-file-zipper:after,
.fad.fa-file-archive:after,
.fad.fa-file-zipper:after {
  content: '\10f1c6';
}
.fa-duotone.fa-files:after,
.fad.fa-files:after {
  content: '\10e185';
}
.fa-duotone.fa-files-medical:after,
.fad.fa-files-medical:after {
  content: '\10f7fd';
}
.fa-duotone.fa-fill:after,
.fad.fa-fill:after {
  content: '\10f575';
}
.fa-duotone.fa-fill-drip:after,
.fad.fa-fill-drip:after {
  content: '\10f576';
}
.fa-duotone.fa-film:after,
.fad.fa-film:after {
  content: '\10f008';
}
.fa-duotone.fa-film-canister:after,
.fad.fa-film-canister:after {
  content: '\10f8b7';
}
.fa-duotone.fa-film-alt:after,
.fa-duotone.fa-film-simple:after,
.fad.fa-film-alt:after,
.fad.fa-film-simple:after {
  content: '\10f3a0';
}
.fa-duotone.fa-film-slash:after,
.fad.fa-film-slash:after {
  content: '\10e186';
}
.fa-duotone.fa-films:after,
.fad.fa-films:after {
  content: '\10e187';
}
.fa-duotone.fa-filter:after,
.fad.fa-filter:after {
  content: '\10f0b0';
}
.fa-duotone.fa-filter-circle-dollar:after,
.fa-duotone.fa-funnel-dollar:after,
.fad.fa-filter-circle-dollar:after,
.fad.fa-funnel-dollar:after {
  content: '\10f662';
}
.fa-duotone.fa-filter-circle-xmark:after,
.fad.fa-filter-circle-xmark:after {
  content: '\10e188';
}
.fa-duotone.fa-filter-list:after,
.fad.fa-filter-list:after {
  content: '\10e189';
}
.fa-duotone.fa-filter-slash:after,
.fad.fa-filter-slash:after {
  content: '\10e18a';
}
.fa-duotone.fa-filters:after,
.fad.fa-filters:after {
  content: '\10e18b';
}
.fa-duotone.fa-fingerprint:after,
.fad.fa-fingerprint:after {
  content: '\10f577';
}
.fa-duotone.fa-fire:after,
.fad.fa-fire:after {
  content: '\10f06d';
}
.fa-duotone.fa-fire-extinguisher:after,
.fad.fa-fire-extinguisher:after {
  content: '\10f134';
}
.fa-duotone.fa-fire-flame:after,
.fa-duotone.fa-flame:after,
.fad.fa-fire-flame:after,
.fad.fa-flame:after {
  content: '\10f6df';
}
.fa-duotone.fa-fire-alt:after,
.fa-duotone.fa-fire-flame-curved:after,
.fad.fa-fire-alt:after,
.fad.fa-fire-flame-curved:after {
  content: '\10f7e4';
}
.fa-duotone.fa-burn:after,
.fa-duotone.fa-fire-flame-simple:after,
.fad.fa-burn:after,
.fad.fa-fire-flame-simple:after {
  content: '\10f46a';
}
.fa-duotone.fa-fire-hydrant:after,
.fad.fa-fire-hydrant:after {
  content: '\10e18c';
}
.fa-duotone.fa-fire-smoke:after,
.fad.fa-fire-smoke:after {
  content: '\10f74b';
}
.fa-duotone.fa-fireplace:after,
.fad.fa-fireplace:after {
  content: '\10f79a';
}
.fa-duotone.fa-fish:after,
.fad.fa-fish:after {
  content: '\10f578';
}
.fa-duotone.fa-fish-cooked:after,
.fad.fa-fish-cooked:after {
  content: '\10f7fe';
}
.fa-duotone.fa-flag:after,
.fad.fa-flag:after {
  content: '\10f024';
}
.fa-duotone.fa-flag-checkered:after,
.fad.fa-flag-checkered:after {
  content: '\10f11e';
}
.fa-duotone.fa-flag-pennant:after,
.fa-duotone.fa-pennant:after,
.fad.fa-flag-pennant:after,
.fad.fa-pennant:after {
  content: '\10f456';
}
.fa-duotone.fa-flag-alt:after,
.fa-duotone.fa-flag-swallowtail:after,
.fad.fa-flag-alt:after,
.fad.fa-flag-swallowtail:after {
  content: '\10f74c';
}
.fa-duotone.fa-flag-usa:after,
.fad.fa-flag-usa:after {
  content: '\10f74d';
}
.fa-duotone.fa-flashlight:after,
.fad.fa-flashlight:after {
  content: '\10f8b8';
}
.fa-duotone.fa-flask:after,
.fad.fa-flask:after {
  content: '\10f0c3';
}
.fa-duotone.fa-flask-poison:after,
.fa-duotone.fa-flask-round-poison:after,
.fad.fa-flask-poison:after,
.fad.fa-flask-round-poison:after {
  content: '\10f6e0';
}
.fa-duotone.fa-flask-potion:after,
.fa-duotone.fa-flask-round-potion:after,
.fad.fa-flask-potion:after,
.fad.fa-flask-round-potion:after {
  content: '\10f6e1';
}
.fa-duotone.fa-floppy-disk:after,
.fa-duotone.fa-save:after,
.fad.fa-floppy-disk:after,
.fad.fa-save:after {
  content: '\10f0c7';
}
.fa-duotone.fa-floppy-disk-circle-arrow-right:after,
.fa-duotone.fa-save-circle-arrow-right:after,
.fad.fa-floppy-disk-circle-arrow-right:after,
.fad.fa-save-circle-arrow-right:after {
  content: '\10e18d';
}
.fa-duotone.fa-floppy-disk-circle-xmark:after,
.fa-duotone.fa-floppy-disk-times:after,
.fa-duotone.fa-save-circle-xmark:after,
.fa-duotone.fa-save-times:after,
.fad.fa-floppy-disk-circle-xmark:after,
.fad.fa-floppy-disk-times:after,
.fad.fa-save-circle-xmark:after,
.fad.fa-save-times:after {
  content: '\10e18e';
}
.fa-duotone.fa-floppy-disk-pen:after,
.fad.fa-floppy-disk-pen:after {
  content: '\10e18f';
}
.fa-duotone.fa-floppy-disks:after,
.fad.fa-floppy-disks:after {
  content: '\10e190';
}
.fa-duotone.fa-florin-sign:after,
.fad.fa-florin-sign:after {
  content: '\10e191';
}
.fa-duotone.fa-flower:after,
.fad.fa-flower:after {
  content: '\10f7ff';
}
.fa-duotone.fa-flower-daffodil:after,
.fad.fa-flower-daffodil:after {
  content: '\10f800';
}
.fa-duotone.fa-flower-tulip:after,
.fad.fa-flower-tulip:after {
  content: '\10f801';
}
.fa-duotone.fa-flute:after,
.fad.fa-flute:after {
  content: '\10f8b9';
}
.fa-duotone.fa-flux-capacitor:after,
.fad.fa-flux-capacitor:after {
  content: '\10f8ba';
}
.fa-duotone.fa-folder:after,
.fad.fa-folder:after {
  content: '\10f07b';
}
.fa-duotone.fa-folder-arrow-down:after,
.fa-duotone.fa-folder-download:after,
.fad.fa-folder-arrow-down:after,
.fad.fa-folder-download:after {
  content: '\10e053';
}
.fa-duotone.fa-folder-arrow-up:after,
.fa-duotone.fa-folder-upload:after,
.fad.fa-folder-arrow-up:after,
.fad.fa-folder-upload:after {
  content: '\10e054';
}
.fa-duotone.fa-folder-blank:after,
.fad.fa-folder-blank:after {
  content: '\10e192';
}
.fa-duotone.fa-folder-bookmark:after,
.fad.fa-folder-bookmark:after {
  content: '\10e193';
}
.fa-duotone.fa-folder-cog:after,
.fa-duotone.fa-folder-gear:after,
.fad.fa-folder-cog:after,
.fad.fa-folder-gear:after {
  content: '\10e194';
}
.fa-duotone.fa-folder-grid:after,
.fad.fa-folder-grid:after {
  content: '\10e195';
}
.fa-duotone.fa-folder-heart:after,
.fad.fa-folder-heart:after {
  content: '\10e196';
}
.fa-duotone.fa-folder-image:after,
.fad.fa-folder-image:after {
  content: '\10e197';
}
.fa-duotone.fa-folder-magnifying-glass:after,
.fa-duotone.fa-folder-search:after,
.fad.fa-folder-magnifying-glass:after,
.fad.fa-folder-search:after {
  content: '\10e198';
}
.fa-duotone.fa-folder-medical:after,
.fad.fa-folder-medical:after {
  content: '\10e199';
}
.fa-duotone.fa-folder-minus:after,
.fad.fa-folder-minus:after {
  content: '\10f65d';
}
.fa-duotone.fa-folder-music:after,
.fad.fa-folder-music:after {
  content: '\10e19a';
}
.fa-duotone.fa-folder-open:after,
.fad.fa-folder-open:after {
  content: '\10f07c';
}
.fa-duotone.fa-folder-plus:after,
.fad.fa-folder-plus:after {
  content: '\10f65e';
}
.fa-duotone.fa-folder-tree:after,
.fad.fa-folder-tree:after {
  content: '\10f802';
}
.fa-duotone.fa-folder-user:after,
.fad.fa-folder-user:after {
  content: '\10e19b';
}
.fa-duotone.fa-folder-times:after,
.fa-duotone.fa-folder-xmark:after,
.fad.fa-folder-times:after,
.fad.fa-folder-xmark:after {
  content: '\10f65f';
}
.fa-duotone.fa-folders:after,
.fad.fa-folders:after {
  content: '\10f660';
}
.fa-duotone.fa-font:after,
.fad.fa-font:after {
  content: '\10f031';
}
.fa-duotone.fa-font-awesome-flag:after,
.fa-duotone.fa-font-awesome-logo-full:after,
.fa-duotone.fa-font-awesome:after,
.fad.fa-font-awesome-flag:after,
.fad.fa-font-awesome-logo-full:after,
.fad.fa-font-awesome:after {
  content: '\10f2b4';
}
.fa-duotone.fa-font-case:after,
.fad.fa-font-case:after {
  content: '\10f866';
}
.fa-duotone.fa-football-ball:after,
.fad.fa-football-ball:after {
  content: '\10f44e';
}
.fa-duotone.fa-football-helmet:after,
.fad.fa-football-helmet:after {
  content: '\10f44f';
}
.fa-duotone.fa-fork:after,
.fa-duotone.fa-utensil-fork:after,
.fad.fa-fork:after,
.fad.fa-utensil-fork:after {
  content: '\10f2e3';
}
.fa-duotone.fa-fork-knife:after,
.fa-duotone.fa-utensils-alt:after,
.fad.fa-fork-knife:after,
.fad.fa-utensils-alt:after {
  content: '\10f2e6';
}
.fa-duotone.fa-forklift:after,
.fad.fa-forklift:after {
  content: '\10f47a';
}
.fa-duotone.fa-forward:after,
.fad.fa-forward:after {
  content: '\10f04e';
}
.fa-duotone.fa-fast-forward:after,
.fa-duotone.fa-forward-fast:after,
.fad.fa-fast-forward:after,
.fad.fa-forward-fast:after {
  content: '\10f050';
}
.fa-duotone.fa-forward-step:after,
.fa-duotone.fa-step-forward:after,
.fad.fa-forward-step:after,
.fad.fa-step-forward:after {
  content: '\10f051';
}
.fa-duotone.fa-franc-sign:after,
.fad.fa-franc-sign:after {
  content: '\10e19c';
}
.fa-duotone.fa-french-fries:after,
.fad.fa-french-fries:after {
  content: '\10f803';
}
.fa-duotone.fa-frog:after,
.fad.fa-frog:after {
  content: '\10f52e';
}
.fa-duotone.fa-function:after,
.fad.fa-function:after {
  content: '\10f661';
}
.fa-duotone.fa-futbol-ball:after,
.fa-duotone.fa-futbol:after,
.fa-duotone.fa-soccer-ball:after,
.fad.fa-futbol-ball:after,
.fad.fa-futbol:after,
.fad.fa-soccer-ball:after {
  content: '\10f1e3';
}
.fa-duotone.fa-g:after,
.fad.fa-g:after {
  content: '\10e19d';
}
.fa-duotone.fa-galaxy:after,
.fad.fa-galaxy:after {
  content: '\10e008';
}
.fa-duotone.fa-game-board:after,
.fad.fa-game-board:after {
  content: '\10f867';
}
.fa-duotone.fa-game-board-alt:after,
.fa-duotone.fa-game-board-simple:after,
.fad.fa-game-board-alt:after,
.fad.fa-game-board-simple:after {
  content: '\10f868';
}
.fa-duotone.fa-game-console-handheld:after,
.fad.fa-game-console-handheld:after {
  content: '\10f8bb';
}
.fa-duotone.fa-gamepad:after,
.fad.fa-gamepad:after {
  content: '\10f11b';
}
.fa-duotone.fa-gamepad-alt:after,
.fa-duotone.fa-gamepad-modern:after,
.fad.fa-gamepad-alt:after,
.fad.fa-gamepad-modern:after {
  content: '\10f8bc';
}
.fa-duotone.fa-garage:after,
.fad.fa-garage:after {
  content: '\10e009';
}
.fa-duotone.fa-garage-car:after,
.fad.fa-garage-car:after {
  content: '\10e00a';
}
.fa-duotone.fa-garage-open:after,
.fad.fa-garage-open:after {
  content: '\10e00b';
}
.fa-duotone.fa-gas-pump:after,
.fad.fa-gas-pump:after {
  content: '\10f52f';
}
.fa-duotone.fa-gas-pump-slash:after,
.fad.fa-gas-pump-slash:after {
  content: '\10f5f4';
}
.fa-duotone.fa-dashboard:after,
.fa-duotone.fa-gauge-high:after,
.fa-duotone.fa-gauge:after,
.fa-duotone.fa-tachometer-alt-fast:after,
.fa-duotone.fa-tachometer-alt:after,
.fad.fa-dashboard:after,
.fad.fa-gauge-high:after,
.fad.fa-gauge:after,
.fad.fa-tachometer-alt-fast:after,
.fad.fa-tachometer-alt:after {
  content: '\10f625';
}
.fa-duotone.fa-gauge-low:after,
.fa-duotone.fa-tachometer-alt-slow:after,
.fad.fa-gauge-low:after,
.fad.fa-tachometer-alt-slow:after {
  content: '\10f627';
}
.fa-duotone.fa-gauge-max:after,
.fa-duotone.fa-tachometer-alt-fastest:after,
.fad.fa-gauge-max:after,
.fad.fa-tachometer-alt-fastest:after {
  content: '\10f626';
}
.fa-duotone.fa-gauge-med:after,
.fa-duotone.fa-tachometer-alt-average:after,
.fad.fa-gauge-med:after,
.fad.fa-tachometer-alt-average:after {
  content: '\10f624';
}
.fa-duotone.fa-gauge-min:after,
.fa-duotone.fa-tachometer-alt-slowest:after,
.fad.fa-gauge-min:after,
.fad.fa-tachometer-alt-slowest:after {
  content: '\10f628';
}
.fa-duotone.fa-gauge-simple-high:after,
.fa-duotone.fa-gauge-simple:after,
.fa-duotone.fa-tachometer:after,
.fad.fa-gauge-simple-high:after,
.fad.fa-gauge-simple:after,
.fad.fa-tachometer:after {
  content: '\10f62a';
}
.fa-duotone.fa-gauge-simple-low:after,
.fa-duotone.fa-tachometer-slow:after,
.fad.fa-gauge-simple-low:after,
.fad.fa-tachometer-slow:after {
  content: '\10f62c';
}
.fa-duotone.fa-gauge-simple-max:after,
.fa-duotone.fa-tachometer-fastest:after,
.fad.fa-gauge-simple-max:after,
.fad.fa-tachometer-fastest:after {
  content: '\10f62b';
}
.fa-duotone.fa-gauge-simple-med:after,
.fa-duotone.fa-tachometer-average:after,
.fad.fa-gauge-simple-med:after,
.fad.fa-tachometer-average:after {
  content: '\10f629';
}
.fa-duotone.fa-gauge-simple-min:after,
.fa-duotone.fa-tachometer-slowest:after,
.fad.fa-gauge-simple-min:after,
.fad.fa-tachometer-slowest:after {
  content: '\10f62d';
}
.fa-duotone.fa-gavel:after,
.fa-duotone.fa-legal:after,
.fad.fa-gavel:after,
.fad.fa-legal:after {
  content: '\10f0e3';
}
.fa-duotone.fa-cog:after,
.fa-duotone.fa-gear:after,
.fad.fa-cog:after,
.fad.fa-gear:after {
  content: '\10f013';
}
.fa-duotone.fa-cogs:after,
.fa-duotone.fa-gears:after,
.fad.fa-cogs:after,
.fad.fa-gears:after {
  content: '\10f085';
}
.fa-duotone.fa-gem:after,
.fad.fa-gem:after {
  content: '\10f3a5';
}
.fa-duotone.fa-genderless:after,
.fad.fa-genderless:after {
  content: '\10f22d';
}
.fa-duotone.fa-ghost:after,
.fad.fa-ghost:after {
  content: '\10f6e2';
}
.fa-duotone.fa-gif:after,
.fad.fa-gif:after {
  content: '\10e19e';
}
.fa-duotone.fa-gift:after,
.fad.fa-gift:after {
  content: '\10f06b';
}
.fa-duotone.fa-gift-card:after,
.fad.fa-gift-card:after {
  content: '\10f663';
}
.fa-duotone.fa-gifts:after,
.fad.fa-gifts:after {
  content: '\10f79c';
}
.fa-duotone.fa-gingerbread-man:after,
.fad.fa-gingerbread-man:after {
  content: '\10f79d';
}
.fa-duotone.fa-glass:after,
.fad.fa-glass:after {
  content: '\10f804';
}
.fa-duotone.fa-glass-citrus:after,
.fad.fa-glass-citrus:after {
  content: '\10f869';
}
.fa-duotone.fa-glass-empty:after,
.fad.fa-glass-empty:after {
  content: '\10e19f';
}
.fa-duotone.fa-glass-half-empty:after,
.fa-duotone.fa-glass-half-full:after,
.fa-duotone.fa-glass-half:after,
.fad.fa-glass-half-empty:after,
.fad.fa-glass-half-full:after,
.fad.fa-glass-half:after {
  content: '\10e1a0';
}
.fa-duotone.fa-glasses:after,
.fad.fa-glasses:after {
  content: '\10f530';
}
.fa-duotone.fa-glasses-alt:after,
.fa-duotone.fa-glasses-round:after,
.fad.fa-glasses-alt:after,
.fad.fa-glasses-round:after {
  content: '\10f5f5';
}
.fa-duotone.fa-globe:after,
.fad.fa-globe:after {
  content: '\10f0ac';
}
.fa-duotone.fa-globe-snow:after,
.fad.fa-globe-snow:after {
  content: '\10f7a3';
}
.fa-duotone.fa-globe-stand:after,
.fad.fa-globe-stand:after {
  content: '\10f5f6';
}
.fa-duotone.fa-golf-ball-tee:after,
.fa-duotone.fa-golf-ball:after,
.fad.fa-golf-ball-tee:after,
.fad.fa-golf-ball:after {
  content: '\10f450';
}
.fa-duotone.fa-golf-club:after,
.fad.fa-golf-club:after {
  content: '\10f451';
}
.fa-duotone.fa-gopuram:after,
.fad.fa-gopuram:after {
  content: '\10f664';
}
.fa-duotone.fa-graduation-cap:after,
.fa-duotone.fa-mortar-board:after,
.fad.fa-graduation-cap:after,
.fad.fa-mortar-board:after {
  content: '\10f19d';
}
.fa-duotone.fa-gramophone:after,
.fad.fa-gramophone:after {
  content: '\10f8bd';
}
.fa-duotone.fa-grate:after,
.fad.fa-grate:after {
  content: '\10e1a1';
}
.fa-duotone.fa-grate-droplet:after,
.fad.fa-grate-droplet:after {
  content: '\10e1a2';
}
.fa-duotone.fa-greater-than:after,
.fad.fa-greater-than:after {
  content: '\10f531';
}
.fa-duotone.fa-greater-than-equal:after,
.fad.fa-greater-than-equal:after {
  content: '\10f532';
}
.fa-duotone.fa-grid-3:after,
.fa-duotone.fa-grid:after,
.fad.fa-grid-3:after,
.fad.fa-grid:after {
  content: '\10e1a3';
}
.fa-duotone.fa-grid-2:after,
.fad.fa-grid-2:after {
  content: '\10e1a4';
}
.fa-duotone.fa-grid-2-plus:after,
.fad.fa-grid-2-plus:after {
  content: '\10e1a5';
}
.fa-duotone.fa-grid-4:after,
.fad.fa-grid-4:after {
  content: '\10e1a6';
}
.fa-duotone.fa-grid-5:after,
.fad.fa-grid-5:after {
  content: '\10e1a7';
}
.fa-duotone.fa-grip-horizontal:after,
.fa-duotone.fa-grip:after,
.fad.fa-grip-horizontal:after,
.fad.fa-grip:after {
  content: '\10f58d';
}
.fa-duotone.fa-grip-lines:after,
.fad.fa-grip-lines:after {
  content: '\10f7a4';
}
.fa-duotone.fa-grip-lines-vertical:after,
.fad.fa-grip-lines-vertical:after {
  content: '\10f7a5';
}
.fa-duotone.fa-grip-vertical:after,
.fad.fa-grip-vertical:after {
  content: '\10f58e';
}
.fa-duotone.fa-guarani-sign:after,
.fad.fa-guarani-sign:after {
  content: '\10e1a8';
}
.fa-duotone.fa-guitar:after,
.fad.fa-guitar:after {
  content: '\10f7a6';
}
.fa-duotone.fa-guitar-electric:after,
.fad.fa-guitar-electric:after {
  content: '\10f8be';
}
.fa-duotone.fa-guitars:after,
.fad.fa-guitars:after {
  content: '\10f8bf';
}
.fa-duotone.fa-gun:after,
.fad.fa-gun:after {
  content: '\10e1a9';
}
.fa-duotone.fa-gun-slash:after,
.fad.fa-gun-slash:after {
  content: '\10e1aa';
}
.fa-duotone.fa-gun-squirt:after,
.fad.fa-gun-squirt:after {
  content: '\10e1ab';
}
.fa-duotone.fa-h:after,
.fad.fa-h:after {
  content: '\10e1ac';
}
.fa-duotone.fa-h1:after,
.fad.fa-h1:after {
  content: '\10f313';
}
.fa-duotone.fa-h2:after,
.fad.fa-h2:after {
  content: '\10f314';
}
.fa-duotone.fa-h3:after,
.fad.fa-h3:after {
  content: '\10f315';
}
.fa-duotone.fa-h4:after,
.fad.fa-h4:after {
  content: '\10f86a';
}
.fa-duotone.fa-hammer:after,
.fad.fa-hammer:after {
  content: '\10f6e3';
}
.fa-duotone.fa-hammer-war:after,
.fad.fa-hammer-war:after {
  content: '\10f6e4';
}
.fa-duotone.fa-hamsa:after,
.fad.fa-hamsa:after {
  content: '\10f665';
}
.fa-duotone.fa-hand-back-point-down:after,
.fad.fa-hand-back-point-down:after {
  content: '\10e1ad';
}
.fa-duotone.fa-hand-back-point-left:after,
.fad.fa-hand-back-point-left:after {
  content: '\10e1ae';
}
.fa-duotone.fa-hand-back-point-right:after,
.fad.fa-hand-back-point-right:after {
  content: '\10e1af';
}
.fa-duotone.fa-hand-back-point-up:after,
.fad.fa-hand-back-point-up:after {
  content: '\10e1b0';
}
.fa-duotone.fa-allergies:after,
.fa-duotone.fa-hand-dots:after,
.fad.fa-allergies:after,
.fad.fa-hand-dots:after {
  content: '\10f461';
}
.fa-duotone.fa-hand-fingers-crossed:after,
.fad.fa-hand-fingers-crossed:after {
  content: '\10e1b1';
}
.fa-duotone.fa-fist-raised:after,
.fa-duotone.fa-hand-fist:after,
.fad.fa-fist-raised:after,
.fad.fa-hand-fist:after {
  content: '\10f6de';
}
.fa-duotone.fa-hand-heart:after,
.fad.fa-hand-heart:after {
  content: '\10f4bc';
}
.fa-duotone.fa-hand-holding:after,
.fad.fa-hand-holding:after {
  content: '\10f4bd';
}
.fa-duotone.fa-hand-holding-box:after,
.fad.fa-hand-holding-box:after {
  content: '\10f47b';
}
.fa-duotone.fa-hand-holding-dollar:after,
.fa-duotone.fa-hand-holding-usd:after,
.fad.fa-hand-holding-dollar:after,
.fad.fa-hand-holding-usd:after {
  content: '\10f4c0';
}
.fa-duotone.fa-hand-holding-droplet:after,
.fa-duotone.fa-hand-holding-water:after,
.fad.fa-hand-holding-droplet:after,
.fad.fa-hand-holding-water:after {
  content: '\10f4c1';
}
.fa-duotone.fa-hand-holding-heart:after,
.fad.fa-hand-holding-heart:after {
  content: '\10f4be';
}
.fa-duotone.fa-hand-holding-magic:after,
.fad.fa-hand-holding-magic:after {
  content: '\10f6e5';
}
.fa-duotone.fa-hand-holding-medical:after,
.fad.fa-hand-holding-medical:after {
  content: '\10e05c';
}
.fa-duotone.fa-hand-holding-seedling:after,
.fad.fa-hand-holding-seedling:after {
  content: '\10f4bf';
}
.fa-duotone.fa-hand-holding-skull:after,
.fad.fa-hand-holding-skull:after {
  content: '\10e1b2';
}
.fa-duotone.fa-hand-lizard:after,
.fad.fa-hand-lizard:after {
  content: '\10f258';
}
.fa-duotone.fa-hand-love:after,
.fad.fa-hand-love:after {
  content: '\10e1b3';
}
.fa-duotone.fa-hand-middle-finger:after,
.fad.fa-hand-middle-finger:after {
  content: '\10f806';
}
.fa-duotone.fa-hand-paper:after,
.fad.fa-hand-paper:after {
  content: '\10f256';
}
.fa-duotone.fa-hand-peace:after,
.fad.fa-hand-peace:after {
  content: '\10f25b';
}
.fa-duotone.fa-hand-point-down:after,
.fad.fa-hand-point-down:after {
  content: '\10f0a7';
}
.fa-duotone.fa-hand-point-left:after,
.fad.fa-hand-point-left:after {
  content: '\10f0a5';
}
.fa-duotone.fa-hand-point-right:after,
.fad.fa-hand-point-right:after {
  content: '\10f0a4';
}
.fa-duotone.fa-hand-point-up:after,
.fad.fa-hand-point-up:after {
  content: '\10f0a6';
}
.fa-duotone.fa-hand-pointer:after,
.fad.fa-hand-pointer:after {
  content: '\10f25a';
}
.fa-duotone.fa-hand-rock:after,
.fad.fa-hand-rock:after {
  content: '\10f255';
}
.fa-duotone.fa-hand-scissors:after,
.fad.fa-hand-scissors:after {
  content: '\10f257';
}
.fa-duotone.fa-hand-sparkles:after,
.fad.fa-hand-sparkles:after {
  content: '\10e05d';
}
.fa-duotone.fa-hand-spock:after,
.fad.fa-hand-spock:after {
  content: '\10f259';
}
.fa-duotone.fa-hand-wave:after,
.fad.fa-hand-wave:after {
  content: '\10e1b4';
}
.fa-duotone.fa-hands:after,
.fa-duotone.fa-sign-language:after,
.fa-duotone.fa-signing:after,
.fad.fa-hands:after,
.fad.fa-sign-language:after,
.fad.fa-signing:after {
  content: '\10f2a7';
}
.fa-duotone.fa-american-sign-language-interpreting:after,
.fa-duotone.fa-asl-interpreting:after,
.fa-duotone.fa-hands-american-sign-language-interpreting:after,
.fa-duotone.fa-hands-asl-interpreting:after,
.fad.fa-american-sign-language-interpreting:after,
.fad.fa-asl-interpreting:after,
.fad.fa-hands-american-sign-language-interpreting:after,
.fad.fa-hands-asl-interpreting:after {
  content: '\10f2a3';
}
.fa-duotone.fa-hands-bubbles:after,
.fa-duotone.fa-hands-wash:after,
.fad.fa-hands-bubbles:after,
.fad.fa-hands-wash:after {
  content: '\10e05e';
}
.fa-duotone.fa-hands-clapping:after,
.fad.fa-hands-clapping:after {
  content: '\10e1b5';
}
.fa-duotone.fa-hands-holding:after,
.fad.fa-hands-holding:after {
  content: '\10f4c2';
}
.fa-duotone.fa-hand-receiving:after,
.fa-duotone.fa-hands-holding-diamond:after,
.fad.fa-hand-receiving:after,
.fad.fa-hands-holding-diamond:after {
  content: '\10f47c';
}
.fa-duotone.fa-hands-holding-dollar:after,
.fa-duotone.fa-hands-usd:after,
.fad.fa-hands-holding-dollar:after,
.fad.fa-hands-usd:after {
  content: '\10f4c5';
}
.fa-duotone.fa-hands-heart:after,
.fa-duotone.fa-hands-holding-heart:after,
.fad.fa-hands-heart:after,
.fad.fa-hands-holding-heart:after {
  content: '\10f4c3';
}
.fa-duotone.fa-hands-horns:after,
.fad.fa-hands-horns:after {
  content: '\10e1b6';
}
.fa-duotone.fa-hands-praying:after,
.fa-duotone.fa-praying-hands:after,
.fad.fa-hands-praying:after,
.fad.fa-praying-hands:after {
  content: '\10f684';
}
.fa-duotone.fa-handshake:after,
.fad.fa-handshake:after {
  content: '\10f2b5';
}
.fa-duotone.fa-hands-helping:after,
.fa-duotone.fa-handshake-angle:after,
.fad.fa-hands-helping:after,
.fad.fa-handshake-angle:after {
  content: '\10f4c4';
}
.fa-duotone.fa-handshake-alt:after,
.fa-duotone.fa-handshake-simple:after,
.fad.fa-handshake-alt:after,
.fad.fa-handshake-simple:after {
  content: '\10f4c6';
}
.fa-duotone.fa-handshake-alt-slash:after,
.fa-duotone.fa-handshake-simple-slash:after,
.fad.fa-handshake-alt-slash:after,
.fad.fa-handshake-simple-slash:after {
  content: '\10e05f';
}
.fa-duotone.fa-handshake-slash:after,
.fad.fa-handshake-slash:after {
  content: '\10e060';
}
.fa-duotone.fa-hanukiah:after,
.fad.fa-hanukiah:after {
  content: '\10f6e6';
}
.fa-duotone.fa-hard-drive:after,
.fa-duotone.fa-hdd:after,
.fad.fa-hard-drive:after,
.fad.fa-hdd:after {
  content: '\10f0a0';
}
.fa-duotone.fa-hashtag:after,
.fad.fa-hashtag:after {
  content: '\10f292';
}
.fa-duotone.fa-hat-chef:after,
.fad.fa-hat-chef:after {
  content: '\10f86b';
}
.fa-duotone.fa-hat-cowboy:after,
.fad.fa-hat-cowboy:after {
  content: '\10f8c0';
}
.fa-duotone.fa-hat-cowboy-side:after,
.fad.fa-hat-cowboy-side:after {
  content: '\10f8c1';
}
.fa-duotone.fa-hat-santa:after,
.fad.fa-hat-santa:after {
  content: '\10f7a7';
}
.fa-duotone.fa-hat-winter:after,
.fad.fa-hat-winter:after {
  content: '\10f7a8';
}
.fa-duotone.fa-hat-witch:after,
.fad.fa-hat-witch:after {
  content: '\10f6e7';
}
.fa-duotone.fa-hat-wizard:after,
.fad.fa-hat-wizard:after {
  content: '\10f6e8';
}
.fa-duotone.fa-head-side:after,
.fad.fa-head-side:after {
  content: '\10f6e9';
}
.fa-duotone.fa-head-side-brain:after,
.fad.fa-head-side-brain:after {
  content: '\10f808';
}
.fa-duotone.fa-head-side-cough:after,
.fad.fa-head-side-cough:after {
  content: '\10e061';
}
.fa-duotone.fa-head-side-cough-slash:after,
.fad.fa-head-side-cough-slash:after {
  content: '\10e062';
}
.fa-duotone.fa-head-side-goggles:after,
.fa-duotone.fa-head-vr:after,
.fad.fa-head-side-goggles:after,
.fad.fa-head-vr:after {
  content: '\10f6ea';
}
.fa-duotone.fa-head-side-headphones:after,
.fad.fa-head-side-headphones:after {
  content: '\10f8c2';
}
.fa-duotone.fa-head-side-heart:after,
.fad.fa-head-side-heart:after {
  content: '\10e1b7';
}
.fa-duotone.fa-head-side-mask:after,
.fad.fa-head-side-mask:after {
  content: '\10e063';
}
.fa-duotone.fa-head-side-medical:after,
.fad.fa-head-side-medical:after {
  content: '\10f809';
}
.fa-duotone.fa-head-side-virus:after,
.fad.fa-head-side-virus:after {
  content: '\10e064';
}
.fa-duotone.fa-header:after,
.fa-duotone.fa-heading:after,
.fad.fa-header:after,
.fad.fa-heading:after {
  content: '\10f1dc';
}
.fa-duotone.fa-headphones:after,
.fad.fa-headphones:after {
  content: '\10f025';
}
.fa-duotone.fa-headphones-alt:after,
.fa-duotone.fa-headphones-simple:after,
.fad.fa-headphones-alt:after,
.fad.fa-headphones-simple:after {
  content: '\10f58f';
}
.fa-duotone.fa-headset:after,
.fad.fa-headset:after {
  content: '\10f590';
}
.fa-duotone.fa-heart:after,
.fad.fa-heart:after {
  content: '\10f004';
}
.fa-duotone.fa-heart-broken:after,
.fa-duotone.fa-heart-crack:after,
.fad.fa-heart-broken:after,
.fad.fa-heart-crack:after {
  content: '\10f7a9';
}
.fa-duotone.fa-heart-half:after,
.fad.fa-heart-half:after {
  content: '\10e1b8';
}
.fa-duotone.fa-heart-half-alt:after,
.fa-duotone.fa-heart-half-stroke:after,
.fad.fa-heart-half-alt:after,
.fad.fa-heart-half-stroke:after {
  content: '\10e1b9';
}
.fa-duotone.fa-heart-pulse:after,
.fa-duotone.fa-heartbeat:after,
.fad.fa-heart-pulse:after,
.fad.fa-heartbeat:after {
  content: '\10f21e';
}
.fa-duotone.fa-heat:after,
.fad.fa-heat:after {
  content: '\10e00c';
}
.fa-duotone.fa-helicopter:after,
.fad.fa-helicopter:after {
  content: '\10f533';
}
.fa-duotone.fa-helmet-battle:after,
.fad.fa-helmet-battle:after {
  content: '\10f6eb';
}
.fa-duotone.fa-hard-hat:after,
.fa-duotone.fa-hat-hard:after,
.fa-duotone.fa-helmet-safety:after,
.fad.fa-hard-hat:after,
.fad.fa-hat-hard:after,
.fad.fa-helmet-safety:after {
  content: '\10f807';
}
.fa-duotone.fa-hexagon:after,
.fad.fa-hexagon:after {
  content: '\10f312';
}
.fa-duotone.fa-hexagon-divide:after,
.fad.fa-hexagon-divide:after {
  content: '\10e1ba';
}
.fa-duotone.fa-hexagon-minus:after,
.fa-duotone.fa-minus-hexagon:after,
.fad.fa-hexagon-minus:after,
.fad.fa-minus-hexagon:after {
  content: '\10f307';
}
.fa-duotone.fa-hexagon-plus:after,
.fa-duotone.fa-plus-hexagon:after,
.fad.fa-hexagon-plus:after,
.fad.fa-plus-hexagon:after {
  content: '\10f300';
}
.fa-duotone.fa-hexagon-xmark:after,
.fa-duotone.fa-times-hexagon:after,
.fa-duotone.fa-xmark-hexagon:after,
.fad.fa-hexagon-xmark:after,
.fad.fa-times-hexagon:after,
.fad.fa-xmark-hexagon:after {
  content: '\10f2ee';
}
.fa-duotone.fa-high-definition:after,
.fad.fa-high-definition:after {
  content: '\10e1bb';
}
.fa-duotone.fa-highlighter:after,
.fad.fa-highlighter:after {
  content: '\10f591';
}
.fa-duotone.fa-highlighter-line:after,
.fad.fa-highlighter-line:after {
  content: '\10e1bc';
}
.fa-duotone.fa-hippo:after,
.fad.fa-hippo:after {
  content: '\10f6ed';
}
.fa-duotone.fa-hockey-mask:after,
.fad.fa-hockey-mask:after {
  content: '\10f6ee';
}
.fa-duotone.fa-hockey-puck:after,
.fad.fa-hockey-puck:after {
  content: '\10f453';
}
.fa-duotone.fa-hockey-sticks:after,
.fad.fa-hockey-sticks:after {
  content: '\10f454';
}
.fa-duotone.fa-holly-berry:after,
.fad.fa-holly-berry:after {
  content: '\10f7aa';
}
.fa-duotone.fa-home-lg:after,
.fa-duotone.fa-home:after,
.fad.fa-home-lg:after,
.fad.fa-home:after {
  content: '\10f015';
}
.fa-duotone.fa-home-heart:after,
.fad.fa-home-heart:after {
  content: '\10f4c9';
}
.fa-duotone.fa-home-lg-alt:after,
.fa-duotone.fa-home-simple:after,
.fad.fa-home-lg-alt:after,
.fad.fa-home-simple:after {
  content: '\10f80a';
}
.fa-duotone.fa-home-user:after,
.fad.fa-home-user:after {
  content: '\10e1bd';
}
.fa-duotone.fa-hood-cloak:after,
.fad.fa-hood-cloak:after {
  content: '\10f6ef';
}
.fa-duotone.fa-horizontal-rule:after,
.fad.fa-horizontal-rule:after {
  content: '\10f86c';
}
.fa-duotone.fa-horse:after,
.fad.fa-horse:after {
  content: '\10f6f0';
}
.fa-duotone.fa-horse-head:after,
.fad.fa-horse-head:after {
  content: '\10f7ab';
}
.fa-duotone.fa-horse-saddle:after,
.fad.fa-horse-saddle:after {
  content: '\10f8c3';
}
.fa-duotone.fa-hospital:after,
.fad.fa-hospital:after {
  content: '\10f0f8';
}
.fa-duotone.fa-hospital-user:after,
.fad.fa-hospital-user:after {
  content: '\10f80d';
}
.fa-duotone.fa-hospital-alt:after,
.fa-duotone.fa-hospital-wide:after,
.fad.fa-hospital-alt:after,
.fad.fa-hospital-wide:after {
  content: '\10f47d';
}
.fa-duotone.fa-hospitals:after,
.fad.fa-hospitals:after {
  content: '\10f80e';
}
.fa-duotone.fa-hot-tub-person:after,
.fa-duotone.fa-hot-tub:after,
.fad.fa-hot-tub-person:after,
.fad.fa-hot-tub:after {
  content: '\10f593';
}
.fa-duotone.fa-hotdog:after,
.fad.fa-hotdog:after {
  content: '\10f80f';
}
.fa-duotone.fa-hotel:after,
.fad.fa-hotel:after {
  content: '\10f594';
}
.fa-duotone.fa-hourglass-2:after,
.fa-duotone.fa-hourglass-half:after,
.fa-duotone.fa-hourglass:after,
.fad.fa-hourglass-2:after,
.fad.fa-hourglass-half:after,
.fad.fa-hourglass:after {
  content: '\10f254';
}
.fa-duotone.fa-hourglass-empty:after,
.fad.fa-hourglass-empty:after {
  content: '\10f252';
}
.fa-duotone.fa-hourglass-3:after,
.fa-duotone.fa-hourglass-end:after,
.fad.fa-hourglass-3:after,
.fad.fa-hourglass-end:after {
  content: '\10f253';
}
.fa-duotone.fa-hourglass-1:after,
.fa-duotone.fa-hourglass-start:after,
.fad.fa-hourglass-1:after,
.fad.fa-hourglass-start:after {
  content: '\10f251';
}
.fa-duotone.fa-house:after,
.fad.fa-house:after {
  content: '\10e00d';
}
.fa-duotone.fa-house-building:after,
.fad.fa-house-building:after {
  content: '\10e1be';
}
.fa-duotone.fa-house-crack:after,
.fa-duotone.fa-house-damage:after,
.fad.fa-house-crack:after,
.fad.fa-house-damage:after {
  content: '\10f6f1';
}
.fa-duotone.fa-house-day:after,
.fad.fa-house-day:after {
  content: '\10e00e';
}
.fa-duotone.fa-house-flood:after,
.fad.fa-house-flood:after {
  content: '\10f74f';
}
.fa-duotone.fa-house-heart:after,
.fad.fa-house-heart:after {
  content: '\10e1bf';
}
.fa-duotone.fa-house-laptop:after,
.fa-duotone.fa-laptop-house:after,
.fad.fa-house-laptop:after,
.fad.fa-laptop-house:after {
  content: '\10e066';
}
.fa-duotone.fa-clinic-medical:after,
.fa-duotone.fa-house-medical:after,
.fad.fa-clinic-medical:after,
.fad.fa-house-medical:after {
  content: '\10f7f2';
}
.fa-duotone.fa-house-night:after,
.fad.fa-house-night:after {
  content: '\10e010';
}
.fa-duotone.fa-house-person-depart:after,
.fa-duotone.fa-house-person-leave:after,
.fad.fa-house-person-depart:after,
.fad.fa-house-person-leave:after {
  content: '\10e00f';
}
.fa-duotone.fa-house-person-arrive:after,
.fa-duotone.fa-house-person-return:after,
.fad.fa-house-person-arrive:after,
.fad.fa-house-person-return:after {
  content: '\10e011';
}
.fa-duotone.fa-house-signal:after,
.fad.fa-house-signal:after {
  content: '\10e012';
}
.fa-duotone.fa-house-tree:after,
.fad.fa-house-tree:after {
  content: '\10e1c0';
}
.fa-duotone.fa-house-turret:after,
.fad.fa-house-turret:after {
  content: '\10e1c1';
}
.fa-duotone.fa-house-user:after,
.fad.fa-house-user:after {
  content: '\10e065';
}
.fa-duotone.fa-hryvnia-sign:after,
.fa-duotone.fa-hryvnia:after,
.fad.fa-hryvnia-sign:after,
.fad.fa-hryvnia:after {
  content: '\10f6f2';
}
.fa-duotone.fa-hurricane:after,
.fad.fa-hurricane:after {
  content: '\10f751';
}
.fa-duotone.fa-i:after,
.fad.fa-i:after {
  content: '\10e1c2';
}
.fa-duotone.fa-i-cursor:after,
.fad.fa-i-cursor:after {
  content: '\10f246';
}
.fa-duotone.fa-ice-cream:after,
.fad.fa-ice-cream:after {
  content: '\10f810';
}
.fa-duotone.fa-ice-skate:after,
.fad.fa-ice-skate:after {
  content: '\10f7ac';
}
.fa-duotone.fa-icicles:after,
.fad.fa-icicles:after {
  content: '\10f7ad';
}
.fa-duotone.fa-heart-music-camera-bolt:after,
.fa-duotone.fa-icons:after,
.fad.fa-heart-music-camera-bolt:after,
.fad.fa-icons:after {
  content: '\10f86d';
}
.fa-duotone.fa-id-badge:after,
.fad.fa-id-badge:after {
  content: '\10f2c1';
}
.fa-duotone.fa-drivers-license:after,
.fa-duotone.fa-id-card:after,
.fad.fa-drivers-license:after,
.fad.fa-id-card:after {
  content: '\10f2c2';
}
.fa-duotone.fa-id-card-alt:after,
.fa-duotone.fa-id-card-clip:after,
.fad.fa-id-card-alt:after,
.fad.fa-id-card-clip:after {
  content: '\10f47f';
}
.fa-duotone.fa-igloo:after,
.fad.fa-igloo:after {
  content: '\10f7ae';
}
.fa-duotone.fa-image:after,
.fad.fa-image:after {
  content: '\10f03e';
}
.fa-duotone.fa-image-landscape:after,
.fa-duotone.fa-landscape:after,
.fad.fa-image-landscape:after,
.fad.fa-landscape:after {
  content: '\10e1c3';
}
.fa-duotone.fa-image-polaroid:after,
.fad.fa-image-polaroid:after {
  content: '\10f8c4';
}
.fa-duotone.fa-image-polaroid-user:after,
.fad.fa-image-polaroid-user:after {
  content: '\10e1c4';
}
.fa-duotone.fa-image-portrait:after,
.fa-duotone.fa-portrait:after,
.fad.fa-image-portrait:after,
.fad.fa-portrait:after {
  content: '\10f3e0';
}
.fa-duotone.fa-image-slash:after,
.fad.fa-image-slash:after {
  content: '\10e1c5';
}
.fa-duotone.fa-image-user:after,
.fad.fa-image-user:after {
  content: '\10e1c6';
}
.fa-duotone.fa-images:after,
.fad.fa-images:after {
  content: '\10f302';
}
.fa-duotone.fa-images-user:after,
.fad.fa-images-user:after {
  content: '\10e1c7';
}
.fa-duotone.fa-inbox:after,
.fad.fa-inbox:after {
  content: '\10f01c';
}
.fa-duotone.fa-inbox-full:after,
.fad.fa-inbox-full:after {
  content: '\10e1c8';
}
.fa-duotone.fa-inbox-arrow-down:after,
.fa-duotone.fa-inbox-in:after,
.fad.fa-inbox-arrow-down:after,
.fad.fa-inbox-in:after {
  content: '\10f310';
}
.fa-duotone.fa-inbox-arrow-up:after,
.fa-duotone.fa-inbox-out:after,
.fad.fa-inbox-arrow-up:after,
.fad.fa-inbox-out:after {
  content: '\10f311';
}
.fa-duotone.fa-inboxes:after,
.fad.fa-inboxes:after {
  content: '\10e1c9';
}
.fa-duotone.fa-indent:after,
.fad.fa-indent:after {
  content: '\10f03c';
}
.fa-duotone.fa-indian-rupee-sign:after,
.fa-duotone.fa-indian-rupee:after,
.fa-duotone.fa-inr:after,
.fad.fa-indian-rupee-sign:after,
.fad.fa-indian-rupee:after,
.fad.fa-inr:after {
  content: '\10e1ca';
}
.fa-duotone.fa-industry:after,
.fad.fa-industry:after {
  content: '\10f275';
}
.fa-duotone.fa-industry-alt:after,
.fa-duotone.fa-industry-windows:after,
.fad.fa-industry-alt:after,
.fad.fa-industry-windows:after {
  content: '\10f3b3';
}
.fa-duotone.fa-infinity:after,
.fad.fa-infinity:after {
  content: '\10f534';
}
.fa-duotone.fa-info:after,
.fad.fa-info:after {
  content: '\10f129';
}
.fa-duotone.fa-inhaler:after,
.fad.fa-inhaler:after {
  content: '\10f5f9';
}
.fa-duotone.fa-input-numeric:after,
.fad.fa-input-numeric:after {
  content: '\10e1cb';
}
.fa-duotone.fa-input-pipe:after,
.fad.fa-input-pipe:after {
  content: '\10e1cc';
}
.fa-duotone.fa-input-text:after,
.fad.fa-input-text:after {
  content: '\10e1cd';
}
.fa-duotone.fa-integral:after,
.fad.fa-integral:after {
  content: '\10f667';
}
.fa-duotone.fa-intersection:after,
.fad.fa-intersection:after {
  content: '\10f668';
}
.fa-duotone.fa-island-tree-palm:after,
.fa-duotone.fa-island-tropical:after,
.fad.fa-island-tree-palm:after,
.fad.fa-island-tropical:after {
  content: '\10f811';
}
.fa-duotone.fa-italic:after,
.fad.fa-italic:after {
  content: '\10f033';
}
.fa-duotone.fa-j:after,
.fad.fa-j:after {
  content: '\10e1ce';
}
.fa-duotone.fa-jack-o-lantern:after,
.fad.fa-jack-o-lantern:after {
  content: '\10f30e';
}
.fa-duotone.fa-jedi:after,
.fad.fa-jedi:after {
  content: '\10f669';
}
.fa-duotone.fa-fighter-jet:after,
.fa-duotone.fa-jet-fighter:after,
.fad.fa-fighter-jet:after,
.fad.fa-jet-fighter:after {
  content: '\10f0fb';
}
.fa-duotone.fa-joint:after,
.fad.fa-joint:after {
  content: '\10f595';
}
.fa-duotone.fa-joystick:after,
.fad.fa-joystick:after {
  content: '\10f8c5';
}
.fa-duotone.fa-jug:after,
.fad.fa-jug:after {
  content: '\10f8c6';
}
.fa-duotone.fa-k:after,
.fad.fa-k:after {
  content: '\10e1cf';
}
.fa-duotone.fa-kaaba:after,
.fad.fa-kaaba:after {
  content: '\10f66b';
}
.fa-duotone.fa-kazoo:after,
.fad.fa-kazoo:after {
  content: '\10f8c7';
}
.fa-duotone.fa-kerning:after,
.fad.fa-kerning:after {
  content: '\10f86f';
}
.fa-duotone.fa-key:after,
.fad.fa-key:after {
  content: '\10f084';
}
.fa-duotone.fa-key-skeleton:after,
.fad.fa-key-skeleton:after {
  content: '\10f6f3';
}
.fa-duotone.fa-keyboard:after,
.fad.fa-keyboard:after {
  content: '\10f11c';
}
.fa-duotone.fa-keyboard-down:after,
.fad.fa-keyboard-down:after {
  content: '\10e1d0';
}
.fa-duotone.fa-keyboard-left:after,
.fad.fa-keyboard-left:after {
  content: '\10e1d1';
}
.fa-duotone.fa-keynote:after,
.fad.fa-keynote:after {
  content: '\10f66c';
}
.fa-duotone.fa-khanda:after,
.fad.fa-khanda:after {
  content: '\10f66d';
}
.fa-duotone.fa-kidneys:after,
.fad.fa-kidneys:after {
  content: '\10f5fb';
}
.fa-duotone.fa-kip-sign:after,
.fad.fa-kip-sign:after {
  content: '\10e1d2';
}
.fa-duotone.fa-first-aid:after,
.fa-duotone.fa-kit-medical:after,
.fad.fa-first-aid:after,
.fad.fa-kit-medical:after {
  content: '\10f479';
}
.fa-duotone.fa-kite:after,
.fad.fa-kite:after {
  content: '\10f6f4';
}
.fa-duotone.fa-kiwi-bird:after,
.fad.fa-kiwi-bird:after {
  content: '\10f535';
}
.fa-duotone.fa-knife:after,
.fa-duotone.fa-utensil-knife:after,
.fad.fa-knife:after,
.fad.fa-utensil-knife:after {
  content: '\10f2e4';
}
.fa-duotone.fa-knife-kitchen:after,
.fad.fa-knife-kitchen:after {
  content: '\10f6f5';
}
.fa-duotone.fa-l:after,
.fad.fa-l:after {
  content: '\10e1d3';
}
.fa-duotone.fa-lambda:after,
.fad.fa-lambda:after {
  content: '\10f66e';
}
.fa-duotone.fa-lamp:after,
.fad.fa-lamp:after {
  content: '\10f4ca';
}
.fa-duotone.fa-lamp-desk:after,
.fad.fa-lamp-desk:after {
  content: '\10e014';
}
.fa-duotone.fa-lamp-floor:after,
.fad.fa-lamp-floor:after {
  content: '\10e015';
}
.fa-duotone.fa-lamp-street:after,
.fad.fa-lamp-street:after {
  content: '\10e1d4';
}
.fa-duotone.fa-landmark:after,
.fad.fa-landmark:after {
  content: '\10f66f';
}
.fa-duotone.fa-landmark-alt:after,
.fa-duotone.fa-landmark-dome:after,
.fad.fa-landmark-alt:after,
.fad.fa-landmark-dome:after {
  content: '\10f752';
}
.fa-duotone.fa-language:after,
.fad.fa-language:after {
  content: '\10f1ab';
}
.fa-duotone.fa-laptop:after,
.fad.fa-laptop:after {
  content: '\10f109';
}
.fa-duotone.fa-laptop-arrow-down:after,
.fad.fa-laptop-arrow-down:after {
  content: '\10e1d5';
}
.fa-duotone.fa-laptop-code:after,
.fad.fa-laptop-code:after {
  content: '\10f5fc';
}
.fa-duotone.fa-laptop-medical:after,
.fad.fa-laptop-medical:after {
  content: '\10f812';
}
.fa-duotone.fa-laptop-mobile:after,
.fa-duotone.fa-phone-laptop:after,
.fad.fa-laptop-mobile:after,
.fad.fa-phone-laptop:after {
  content: '\10f87a';
}
.fa-duotone.fa-laptop-slash:after,
.fad.fa-laptop-slash:after {
  content: '\10e1d6';
}
.fa-duotone.fa-lari-sign:after,
.fad.fa-lari-sign:after {
  content: '\10e1d7';
}
.fa-duotone.fa-lasso:after,
.fad.fa-lasso:after {
  content: '\10f8c8';
}
.fa-duotone.fa-lasso-sparkles:after,
.fad.fa-lasso-sparkles:after {
  content: '\10e1d8';
}
.fa-duotone.fa-layer-group:after,
.fa-duotone.fa-layers:after,
.fad.fa-layer-group:after,
.fad.fa-layers:after {
  content: '\10f5fd';
}
.fa-duotone.fa-layer-minus:after,
.fa-duotone.fa-layers-minus:after,
.fad.fa-layer-minus:after,
.fad.fa-layers-minus:after {
  content: '\10f5fe';
}
.fa-duotone.fa-layer-plus:after,
.fa-duotone.fa-layers-plus:after,
.fad.fa-layer-plus:after,
.fad.fa-layers-plus:after {
  content: '\10f5ff';
}
.fa-duotone.fa-leaf:after,
.fad.fa-leaf:after {
  content: '\10f06c';
}
.fa-duotone.fa-leaf-heart:after,
.fad.fa-leaf-heart:after {
  content: '\10f4cb';
}
.fa-duotone.fa-leaf-maple:after,
.fad.fa-leaf-maple:after {
  content: '\10f6f6';
}
.fa-duotone.fa-leaf-oak:after,
.fad.fa-leaf-oak:after {
  content: '\10f6f7';
}
.fa-duotone.fa-arrow-alt-left:after,
.fa-duotone.fa-left:after,
.fad.fa-arrow-alt-left:after,
.fad.fa-left:after {
  content: '\10f355';
}
.fa-duotone.fa-arrow-alt-from-right:after,
.fa-duotone.fa-left-from-line:after,
.fad.fa-arrow-alt-from-right:after,
.fad.fa-left-from-line:after {
  content: '\10f348';
}
.fa-duotone.fa-left-long:after,
.fa-duotone.fa-long-arrow-alt-left:after,
.fad.fa-left-long:after,
.fad.fa-long-arrow-alt-left:after {
  content: '\10f30a';
}
.fa-duotone.fa-arrows-alt-h:after,
.fa-duotone.fa-left-right:after,
.fad.fa-arrows-alt-h:after,
.fad.fa-left-right:after {
  content: '\10f337';
}
.fa-duotone.fa-arrow-alt-to-left:after,
.fa-duotone.fa-left-to-line:after,
.fad.fa-arrow-alt-to-left:after,
.fad.fa-left-to-line:after {
  content: '\10f34b';
}
.fa-duotone.fa-lemon:after,
.fad.fa-lemon:after {
  content: '\10f094';
}
.fa-duotone.fa-less-than:after,
.fad.fa-less-than:after {
  content: '\10f536';
}
.fa-duotone.fa-less-than-equal:after,
.fad.fa-less-than-equal:after {
  content: '\10f537';
}
.fa-duotone.fa-life-ring:after,
.fad.fa-life-ring:after {
  content: '\10f1cd';
}
.fa-duotone.fa-light-ceiling:after,
.fad.fa-light-ceiling:after {
  content: '\10e016';
}
.fa-duotone.fa-light-switch:after,
.fad.fa-light-switch:after {
  content: '\10e017';
}
.fa-duotone.fa-light-switch-off:after,
.fad.fa-light-switch-off:after {
  content: '\10e018';
}
.fa-duotone.fa-light-switch-on:after,
.fad.fa-light-switch-on:after {
  content: '\10e019';
}
.fa-duotone.fa-lightbulb:after,
.fad.fa-lightbulb:after {
  content: '\10f0eb';
}
.fa-duotone.fa-lightbulb-dollar:after,
.fad.fa-lightbulb-dollar:after {
  content: '\10f670';
}
.fa-duotone.fa-lightbulb-exclamation:after,
.fad.fa-lightbulb-exclamation:after {
  content: '\10f671';
}
.fa-duotone.fa-lightbulb-on:after,
.fad.fa-lightbulb-on:after {
  content: '\10f672';
}
.fa-duotone.fa-lightbulb-slash:after,
.fad.fa-lightbulb-slash:after {
  content: '\10f673';
}
.fa-duotone.fa-lights-holiday:after,
.fad.fa-lights-holiday:after {
  content: '\10f7b2';
}
.fa-duotone.fa-line-columns:after,
.fad.fa-line-columns:after {
  content: '\10f870';
}
.fa-duotone.fa-line-height:after,
.fad.fa-line-height:after {
  content: '\10f871';
}
.fa-duotone.fa-chain:after,
.fa-duotone.fa-link:after,
.fad.fa-chain:after,
.fad.fa-link:after {
  content: '\10f0c1';
}
.fa-duotone.fa-chain-horizontal:after,
.fa-duotone.fa-link-horizontal:after,
.fad.fa-chain-horizontal:after,
.fad.fa-link-horizontal:after {
  content: '\10e1d9';
}
.fa-duotone.fa-chain-horizontal-slash:after,
.fa-duotone.fa-link-horizontal-slash:after,
.fad.fa-chain-horizontal-slash:after,
.fad.fa-link-horizontal-slash:after {
  content: '\10e1da';
}
.fa-duotone.fa-link-simple:after,
.fad.fa-link-simple:after {
  content: '\10e1db';
}
.fa-duotone.fa-link-simple-slash:after,
.fad.fa-link-simple-slash:after {
  content: '\10e1dc';
}
.fa-duotone.fa-chain-broken:after,
.fa-duotone.fa-chain-slash:after,
.fa-duotone.fa-link-slash:after,
.fa-duotone.fa-unlink:after,
.fad.fa-chain-broken:after,
.fad.fa-chain-slash:after,
.fad.fa-link-slash:after,
.fad.fa-unlink:after {
  content: '\10f127';
}
.fa-duotone.fa-lips:after,
.fad.fa-lips:after {
  content: '\10f600';
}
.fa-duotone.fa-lira-sign:after,
.fad.fa-lira-sign:after {
  content: '\10f195';
}
.fa-duotone.fa-list-squares:after,
.fa-duotone.fa-list:after,
.fad.fa-list-squares:after,
.fad.fa-list:after {
  content: '\10f03a';
}
.fa-duotone.fa-list-check:after,
.fa-duotone.fa-tasks:after,
.fad.fa-list-check:after,
.fad.fa-tasks:after {
  content: '\10f0ae';
}
.fa-duotone.fa-list-dropdown:after,
.fad.fa-list-dropdown:after {
  content: '\10e1dd';
}
.fa-duotone.fa-list-music:after,
.fad.fa-list-music:after {
  content: '\10f8c9';
}
.fa-duotone.fa-list-1-2:after,
.fa-duotone.fa-list-numeric:after,
.fa-duotone.fa-list-ol:after,
.fad.fa-list-1-2:after,
.fad.fa-list-numeric:after,
.fad.fa-list-ol:after {
  content: '\10f0cb';
}
.fa-duotone.fa-list-radio:after,
.fad.fa-list-radio:after {
  content: '\10e1de';
}
.fa-duotone.fa-list-timeline:after,
.fad.fa-list-timeline:after {
  content: '\10e1df';
}
.fa-duotone.fa-list-tree:after,
.fad.fa-list-tree:after {
  content: '\10e1e0';
}
.fa-duotone.fa-list-dots:after,
.fa-duotone.fa-list-ul:after,
.fad.fa-list-dots:after,
.fad.fa-list-ul:after {
  content: '\10f0ca';
}
.fa-duotone.fa-litecoin-sign:after,
.fad.fa-litecoin-sign:after {
  content: '\10e1e1';
}
.fa-duotone.fa-loader:after,
.fad.fa-loader:after {
  content: '\10e1e2';
}
.fa-duotone.fa-location:after,
.fa-duotone.fa-map-marker:after,
.fad.fa-location:after,
.fad.fa-map-marker:after {
  content: '\10f041';
}
.fa-duotone.fa-location-arrow:after,
.fad.fa-location-arrow:after {
  content: '\10f124';
}
.fa-duotone.fa-location-check:after,
.fa-duotone.fa-map-marker-check:after,
.fad.fa-location-check:after,
.fad.fa-map-marker-check:after {
  content: '\10f606';
}
.fa-duotone.fa-location-crosshairs:after,
.fad.fa-location-crosshairs:after {
  content: '\10f601';
}
.fa-duotone.fa-location-crosshairs-slash:after,
.fad.fa-location-crosshairs-slash:after {
  content: '\10f603';
}
.fa-duotone.fa-location-dot:after,
.fa-duotone.fa-map-marker-alt:after,
.fad.fa-location-dot:after,
.fad.fa-map-marker-alt:after {
  content: '\10f3c5';
}
.fa-duotone.fa-location-dot-slash:after,
.fa-duotone.fa-map-marker-alt-slash:after,
.fad.fa-location-dot-slash:after,
.fad.fa-map-marker-alt-slash:after {
  content: '\10f605';
}
.fa-duotone.fa-location-exclamation:after,
.fa-duotone.fa-map-marker-exclamation:after,
.fad.fa-location-exclamation:after,
.fad.fa-map-marker-exclamation:after {
  content: '\10f608';
}
.fa-duotone.fa-location-minus:after,
.fa-duotone.fa-map-marker-minus:after,
.fad.fa-location-minus:after,
.fad.fa-map-marker-minus:after {
  content: '\10f609';
}
.fa-duotone.fa-location-pen:after,
.fa-duotone.fa-map-marker-edit:after,
.fad.fa-location-pen:after,
.fad.fa-map-marker-edit:after {
  content: '\10f607';
}
.fa-duotone.fa-location-plus:after,
.fa-duotone.fa-map-marker-plus:after,
.fad.fa-location-plus:after,
.fad.fa-map-marker-plus:after {
  content: '\10f60a';
}
.fa-duotone.fa-location-question:after,
.fa-duotone.fa-map-marker-question:after,
.fad.fa-location-question:after,
.fad.fa-map-marker-question:after {
  content: '\10f60b';
}
.fa-duotone.fa-location-slash:after,
.fa-duotone.fa-map-marker-slash:after,
.fad.fa-location-slash:after,
.fad.fa-map-marker-slash:after {
  content: '\10f60c';
}
.fa-duotone.fa-location-smile:after,
.fa-duotone.fa-map-marker-smile:after,
.fad.fa-location-smile:after,
.fad.fa-map-marker-smile:after {
  content: '\10f60d';
}
.fa-duotone.fa-location-xmark:after,
.fa-duotone.fa-map-marker-times:after,
.fa-duotone.fa-map-marker-xmark:after,
.fad.fa-location-xmark:after,
.fad.fa-map-marker-times:after,
.fad.fa-map-marker-xmark:after {
  content: '\10f60e';
}
.fa-duotone.fa-lock:after,
.fad.fa-lock:after {
  content: '\10f023';
}
.fa-duotone.fa-lock-alt:after,
.fa-duotone.fa-lock-keyhole:after,
.fad.fa-lock-alt:after,
.fad.fa-lock-keyhole:after {
  content: '\10f30d';
}
.fa-duotone.fa-lock-keyhole-open:after,
.fa-duotone.fa-lock-open-alt:after,
.fad.fa-lock-keyhole-open:after,
.fad.fa-lock-open-alt:after {
  content: '\10f3c2';
}
.fa-duotone.fa-lock-open:after,
.fad.fa-lock-open:after {
  content: '\10f3c1';
}
.fa-duotone.fa-couch-small:after,
.fa-duotone.fa-loveseat:after,
.fad.fa-couch-small:after,
.fad.fa-loveseat:after {
  content: '\10f4cc';
}
.fa-duotone.fa-luchador-mask:after,
.fa-duotone.fa-luchador:after,
.fa-duotone.fa-mask-luchador:after,
.fad.fa-luchador-mask:after,
.fad.fa-luchador:after,
.fad.fa-mask-luchador:after {
  content: '\10f455';
}
.fa-duotone.fa-lungs:after,
.fad.fa-lungs:after {
  content: '\10f604';
}
.fa-duotone.fa-lungs-virus:after,
.fad.fa-lungs-virus:after {
  content: '\10e067';
}
.fa-duotone.fa-m:after,
.fad.fa-m:after {
  content: '\10e1e3';
}
.fa-duotone.fa-mace:after,
.fad.fa-mace:after {
  content: '\10f6f8';
}
.fa-duotone.fa-magnet:after,
.fad.fa-magnet:after {
  content: '\10f076';
}
.fa-duotone.fa-magnifying-glass:after,
.fa-duotone.fa-search:after,
.fad.fa-magnifying-glass:after,
.fad.fa-search:after {
  content: '\10f002';
}
.fa-duotone.fa-magnifying-glass-dollar:after,
.fa-duotone.fa-search-dollar:after,
.fad.fa-magnifying-glass-dollar:after,
.fad.fa-search-dollar:after {
  content: '\10f688';
}
.fa-duotone.fa-magnifying-glass-location:after,
.fa-duotone.fa-search-location:after,
.fad.fa-magnifying-glass-location:after,
.fad.fa-search-location:after {
  content: '\10f689';
}
.fa-duotone.fa-magnifying-glass-minus:after,
.fa-duotone.fa-search-minus:after,
.fad.fa-magnifying-glass-minus:after,
.fad.fa-search-minus:after {
  content: '\10f010';
}
.fa-duotone.fa-magnifying-glass-plus:after,
.fa-duotone.fa-search-plus:after,
.fad.fa-magnifying-glass-plus:after,
.fad.fa-search-plus:after {
  content: '\10f00e';
}
.fa-duotone.fa-mailbox:after,
.fad.fa-mailbox:after {
  content: '\10f813';
}
.fa-duotone.fa-manat-sign:after,
.fad.fa-manat-sign:after {
  content: '\10e1e4';
}
.fa-duotone.fa-mandolin:after,
.fad.fa-mandolin:after {
  content: '\10f6f9';
}
.fa-duotone.fa-manhole:after,
.fad.fa-manhole:after {
  content: '\10e1e5';
}
.fa-duotone.fa-map:after,
.fad.fa-map:after {
  content: '\10f279';
}
.fa-duotone.fa-map-location:after,
.fa-duotone.fa-map-marked:after,
.fad.fa-map-location:after,
.fad.fa-map-marked:after {
  content: '\10f59f';
}
.fa-duotone.fa-map-location-dot:after,
.fa-duotone.fa-map-marked-alt:after,
.fad.fa-map-location-dot:after,
.fad.fa-map-marked-alt:after {
  content: '\10f5a0';
}
.fa-duotone.fa-map-pin:after,
.fad.fa-map-pin:after {
  content: '\10f276';
}
.fa-duotone.fa-marker:after,
.fad.fa-marker:after {
  content: '\10f5a1';
}
.fa-duotone.fa-mars:after,
.fad.fa-mars:after {
  content: '\10f222';
}
.fa-duotone.fa-mars-double:after,
.fad.fa-mars-double:after {
  content: '\10f227';
}
.fa-duotone.fa-mars-stroke:after,
.fad.fa-mars-stroke:after {
  content: '\10f229';
}
.fa-duotone.fa-mars-stroke-h:after,
.fa-duotone.fa-mars-stroke-right:after,
.fad.fa-mars-stroke-h:after,
.fad.fa-mars-stroke-right:after {
  content: '\10f22b';
}
.fa-duotone.fa-mars-stroke-up:after,
.fa-duotone.fa-mars-stroke-v:after,
.fad.fa-mars-stroke-up:after,
.fad.fa-mars-stroke-v:after {
  content: '\10f22a';
}
.fa-duotone.fa-glass-martini-alt:after,
.fa-duotone.fa-martini-glass:after,
.fad.fa-glass-martini-alt:after,
.fad.fa-martini-glass:after {
  content: '\10f57b';
}
.fa-duotone.fa-cocktail:after,
.fa-duotone.fa-martini-glass-citrus:after,
.fad.fa-cocktail:after,
.fad.fa-martini-glass-citrus:after {
  content: '\10f561';
}
.fa-duotone.fa-glass-martini:after,
.fa-duotone.fa-martini-glass-empty:after,
.fad.fa-glass-martini:after,
.fad.fa-martini-glass-empty:after {
  content: '\10f000';
}
.fa-duotone.fa-mask:after,
.fad.fa-mask:after {
  content: '\10f6fa';
}
.fa-duotone.fa-mask-face:after,
.fad.fa-mask-face:after {
  content: '\10e1e6';
}
.fa-duotone.fa-masks-theater:after,
.fa-duotone.fa-theater-masks:after,
.fad.fa-masks-theater:after,
.fad.fa-theater-masks:after {
  content: '\10f630';
}
.fa-duotone.fa-expand-arrows-alt:after,
.fa-duotone.fa-maximize:after,
.fad.fa-expand-arrows-alt:after,
.fad.fa-maximize:after {
  content: '\10f31e';
}
.fa-duotone.fa-meat:after,
.fad.fa-meat:after {
  content: '\10f814';
}
.fa-duotone.fa-medal:after,
.fad.fa-medal:after {
  content: '\10f5a2';
}
.fa-duotone.fa-megaphone:after,
.fad.fa-megaphone:after {
  content: '\10f675';
}
.fa-duotone.fa-memo:after,
.fad.fa-memo:after {
  content: '\10e1e7';
}
.fa-duotone.fa-memo-circle-check:after,
.fad.fa-memo-circle-check:after {
  content: '\10e1e8';
}
.fa-duotone.fa-memory:after,
.fad.fa-memory:after {
  content: '\10f538';
}
.fa-duotone.fa-menorah:after,
.fad.fa-menorah:after {
  content: '\10f676';
}
.fa-duotone.fa-mercury:after,
.fad.fa-mercury:after {
  content: '\10f223';
}
.fa-duotone.fa-comment-alt:after,
.fa-duotone.fa-message:after,
.fad.fa-comment-alt:after,
.fad.fa-message:after {
  content: '\10f27a';
}
.fa-duotone.fa-comment-alt-arrow-down:after,
.fa-duotone.fa-message-arrow-down:after,
.fad.fa-comment-alt-arrow-down:after,
.fad.fa-message-arrow-down:after {
  content: '\10e1e9';
}
.fa-duotone.fa-comment-alt-arrow-up:after,
.fa-duotone.fa-message-arrow-up:after,
.fad.fa-comment-alt-arrow-up:after,
.fad.fa-message-arrow-up:after {
  content: '\10e1ea';
}
.fa-duotone.fa-message-arrow-up-right:after,
.fad.fa-message-arrow-up-right:after {
  content: '\10e1eb';
}
.fa-duotone.fa-comment-alt-captions:after,
.fa-duotone.fa-message-captions:after,
.fad.fa-comment-alt-captions:after,
.fad.fa-message-captions:after {
  content: '\10e1ec';
}
.fa-duotone.fa-comment-alt-check:after,
.fa-duotone.fa-message-check:after,
.fad.fa-comment-alt-check:after,
.fad.fa-message-check:after {
  content: '\10f4a2';
}
.fa-duotone.fa-message-code:after,
.fad.fa-message-code:after {
  content: '\10e1ed';
}
.fa-duotone.fa-comment-alt-dollar:after,
.fa-duotone.fa-message-dollar:after,
.fad.fa-comment-alt-dollar:after,
.fad.fa-message-dollar:after {
  content: '\10f650';
}
.fa-duotone.fa-comment-alt-dots:after,
.fa-duotone.fa-message-dots:after,
.fa-duotone.fa-messaging:after,
.fad.fa-comment-alt-dots:after,
.fad.fa-message-dots:after,
.fad.fa-messaging:after {
  content: '\10f4a3';
}
.fa-duotone.fa-comment-alt-exclamation:after,
.fa-duotone.fa-message-exclamation:after,
.fad.fa-comment-alt-exclamation:after,
.fad.fa-message-exclamation:after {
  content: '\10f4a5';
}
.fa-duotone.fa-comment-alt-image:after,
.fa-duotone.fa-message-image:after,
.fad.fa-comment-alt-image:after,
.fad.fa-message-image:after {
  content: '\10e1ee';
}
.fa-duotone.fa-comment-alt-lines:after,
.fa-duotone.fa-message-lines:after,
.fad.fa-comment-alt-lines:after,
.fad.fa-message-lines:after {
  content: '\10f4a6';
}
.fa-duotone.fa-comment-alt-medical:after,
.fa-duotone.fa-message-medical:after,
.fad.fa-comment-alt-medical:after,
.fad.fa-message-medical:after {
  content: '\10f7f4';
}
.fa-duotone.fa-comment-middle-alt:after,
.fa-duotone.fa-message-middle:after,
.fad.fa-comment-middle-alt:after,
.fad.fa-message-middle:after {
  content: '\10e1ef';
}
.fa-duotone.fa-comment-middle-top-alt:after,
.fa-duotone.fa-message-middle-top:after,
.fad.fa-comment-middle-top-alt:after,
.fad.fa-message-middle-top:after {
  content: '\10e1f0';
}
.fa-duotone.fa-comment-alt-minus:after,
.fa-duotone.fa-message-minus:after,
.fad.fa-comment-alt-minus:after,
.fad.fa-message-minus:after {
  content: '\10f4a7';
}
.fa-duotone.fa-comment-alt-music:after,
.fa-duotone.fa-message-music:after,
.fad.fa-comment-alt-music:after,
.fad.fa-message-music:after {
  content: '\10f8af';
}
.fa-duotone.fa-comment-alt-edit:after,
.fa-duotone.fa-message-edit:after,
.fa-duotone.fa-message-pen:after,
.fad.fa-comment-alt-edit:after,
.fad.fa-message-edit:after,
.fad.fa-message-pen:after {
  content: '\10f4a4';
}
.fa-duotone.fa-comment-alt-plus:after,
.fa-duotone.fa-message-plus:after,
.fad.fa-comment-alt-plus:after,
.fad.fa-message-plus:after {
  content: '\10f4a8';
}
.fa-duotone.fa-message-question:after,
.fad.fa-message-question:after {
  content: '\10e1f1';
}
.fa-duotone.fa-comment-alt-quote:after,
.fa-duotone.fa-message-quote:after,
.fad.fa-comment-alt-quote:after,
.fad.fa-message-quote:after {
  content: '\10e1f2';
}
.fa-duotone.fa-comment-alt-slash:after,
.fa-duotone.fa-message-slash:after,
.fad.fa-comment-alt-slash:after,
.fad.fa-message-slash:after {
  content: '\10f4a9';
}
.fa-duotone.fa-comment-alt-smile:after,
.fa-duotone.fa-message-smile:after,
.fad.fa-comment-alt-smile:after,
.fad.fa-message-smile:after {
  content: '\10f4aa';
}
.fa-duotone.fa-message-sms:after,
.fad.fa-message-sms:after {
  content: '\10e1f3';
}
.fa-duotone.fa-comment-alt-text:after,
.fa-duotone.fa-message-text:after,
.fad.fa-comment-alt-text:after,
.fad.fa-message-text:after {
  content: '\10e1f4';
}
.fa-duotone.fa-comment-alt-times:after,
.fa-duotone.fa-message-times:after,
.fa-duotone.fa-message-xmark:after,
.fad.fa-comment-alt-times:after,
.fad.fa-message-times:after,
.fad.fa-message-xmark:after {
  content: '\10f4ab';
}
.fa-duotone.fa-comments-alt:after,
.fa-duotone.fa-messages:after,
.fad.fa-comments-alt:after,
.fad.fa-messages:after {
  content: '\10f4b6';
}
.fa-duotone.fa-comments-alt-dollar:after,
.fa-duotone.fa-messages-dollar:after,
.fad.fa-comments-alt-dollar:after,
.fad.fa-messages-dollar:after {
  content: '\10f652';
}
.fa-duotone.fa-messages-question:after,
.fad.fa-messages-question:after {
  content: '\10e1f5';
}
.fa-duotone.fa-meteor:after,
.fad.fa-meteor:after {
  content: '\10f753';
}
.fa-duotone.fa-meter:after,
.fad.fa-meter:after {
  content: '\10e1f6';
}
.fa-duotone.fa-meter-bolt:after,
.fad.fa-meter-bolt:after {
  content: '\10e1f7';
}
.fa-duotone.fa-meter-droplet:after,
.fad.fa-meter-droplet:after {
  content: '\10e1f8';
}
.fa-duotone.fa-meter-fire:after,
.fad.fa-meter-fire:after {
  content: '\10e1f9';
}
.fa-duotone.fa-microchip:after,
.fad.fa-microchip:after {
  content: '\10f2db';
}
.fa-duotone.fa-microchip-ai:after,
.fad.fa-microchip-ai:after {
  content: '\10e1fa';
}
.fa-duotone.fa-microphone:after,
.fad.fa-microphone:after {
  content: '\10f130';
}
.fa-duotone.fa-microphone-alt:after,
.fa-duotone.fa-microphone-lines:after,
.fad.fa-microphone-alt:after,
.fad.fa-microphone-lines:after {
  content: '\10f3c9';
}
.fa-duotone.fa-microphone-alt-slash:after,
.fa-duotone.fa-microphone-lines-slash:after,
.fad.fa-microphone-alt-slash:after,
.fad.fa-microphone-lines-slash:after {
  content: '\10f539';
}
.fa-duotone.fa-microphone-slash:after,
.fad.fa-microphone-slash:after {
  content: '\10f131';
}
.fa-duotone.fa-microphone-stand:after,
.fad.fa-microphone-stand:after {
  content: '\10f8cb';
}
.fa-duotone.fa-microscope:after,
.fad.fa-microscope:after {
  content: '\10f610';
}
.fa-duotone.fa-microwave:after,
.fad.fa-microwave:after {
  content: '\10e01b';
}
.fa-duotone.fa-mill-sign:after,
.fad.fa-mill-sign:after {
  content: '\10e1fb';
}
.fa-duotone.fa-compress-arrows-alt:after,
.fa-duotone.fa-minimize:after,
.fad.fa-compress-arrows-alt:after,
.fad.fa-minimize:after {
  content: '\10f78c';
}
.fa-duotone.fa-minus:after,
.fa-duotone.fa-subtract:after,
.fad.fa-minus:after,
.fad.fa-subtract:after {
  content: '\10f068';
}
.fa-duotone.fa-mistletoe:after,
.fad.fa-mistletoe:after {
  content: '\10f7b4';
}
.fa-duotone.fa-mitten:after,
.fad.fa-mitten:after {
  content: '\10f7b5';
}
.fa-duotone.fa-mobile-android:after,
.fa-duotone.fa-mobile-phone:after,
.fa-duotone.fa-mobile:after,
.fad.fa-mobile-android:after,
.fad.fa-mobile-phone:after,
.fad.fa-mobile:after {
  content: '\10f3ce';
}
.fa-duotone.fa-mobile-button:after,
.fad.fa-mobile-button:after {
  content: '\10f10b';
}
.fa-duotone.fa-mobile-iphone:after,
.fa-duotone.fa-mobile-notch:after,
.fad.fa-mobile-iphone:after,
.fad.fa-mobile-notch:after {
  content: '\10e1fc';
}
.fa-duotone.fa-mobile-android-alt:after,
.fa-duotone.fa-mobile-screen:after,
.fad.fa-mobile-android-alt:after,
.fad.fa-mobile-screen:after {
  content: '\10f3cf';
}
.fa-duotone.fa-mobile-alt:after,
.fa-duotone.fa-mobile-screen-button:after,
.fad.fa-mobile-alt:after,
.fad.fa-mobile-screen-button:after {
  content: '\10f3cd';
}
.fa-duotone.fa-money-bill:after,
.fad.fa-money-bill:after {
  content: '\10f0d6';
}
.fa-duotone.fa-money-bill-1:after,
.fa-duotone.fa-money-bill-alt:after,
.fad.fa-money-bill-1:after,
.fad.fa-money-bill-alt:after {
  content: '\10f3d1';
}
.fa-duotone.fa-money-bill-1-wave:after,
.fa-duotone.fa-money-bill-wave-alt:after,
.fad.fa-money-bill-1-wave:after,
.fad.fa-money-bill-wave-alt:after {
  content: '\10f53b';
}
.fa-duotone.fa-money-bill-simple:after,
.fad.fa-money-bill-simple:after {
  content: '\10e1fd';
}
.fa-duotone.fa-money-bill-simple-wave:after,
.fad.fa-money-bill-simple-wave:after {
  content: '\10e1fe';
}
.fa-duotone.fa-money-bill-wave:after,
.fad.fa-money-bill-wave:after {
  content: '\10f53a';
}
.fa-duotone.fa-money-bills:after,
.fad.fa-money-bills:after {
  content: '\10e1ff';
}
.fa-duotone.fa-money-bills-alt:after,
.fa-duotone.fa-money-bills-simple:after,
.fad.fa-money-bills-alt:after,
.fad.fa-money-bills-simple:after {
  content: '\10e200';
}
.fa-duotone.fa-money-check:after,
.fad.fa-money-check:after {
  content: '\10f53c';
}
.fa-duotone.fa-money-check-alt:after,
.fa-duotone.fa-money-check-dollar:after,
.fad.fa-money-check-alt:after,
.fad.fa-money-check-dollar:after {
  content: '\10f53d';
}
.fa-duotone.fa-money-check-dollar-pen:after,
.fa-duotone.fa-money-check-edit-alt:after,
.fad.fa-money-check-dollar-pen:after,
.fad.fa-money-check-edit-alt:after {
  content: '\10f873';
}
.fa-duotone.fa-money-check-edit:after,
.fa-duotone.fa-money-check-pen:after,
.fad.fa-money-check-edit:after,
.fad.fa-money-check-pen:after {
  content: '\10f872';
}
.fa-duotone.fa-monitor-heart-rate:after,
.fa-duotone.fa-monitor-waveform:after,
.fad.fa-monitor-heart-rate:after,
.fad.fa-monitor-waveform:after {
  content: '\10f611';
}
.fa-duotone.fa-monkey:after,
.fad.fa-monkey:after {
  content: '\10f6fb';
}
.fa-duotone.fa-monument:after,
.fad.fa-monument:after {
  content: '\10f5a6';
}
.fa-duotone.fa-moon:after,
.fad.fa-moon:after {
  content: '\10f186';
}
.fa-duotone.fa-moon-cloud:after,
.fad.fa-moon-cloud:after {
  content: '\10f754';
}
.fa-duotone.fa-eclipse-alt:after,
.fa-duotone.fa-moon-over-sun:after,
.fad.fa-eclipse-alt:after,
.fad.fa-moon-over-sun:after {
  content: '\10f74a';
}
.fa-duotone.fa-moon-stars:after,
.fad.fa-moon-stars:after {
  content: '\10f755';
}
.fa-duotone.fa-mortar-pestle:after,
.fad.fa-mortar-pestle:after {
  content: '\10f5a7';
}
.fa-duotone.fa-mosque:after,
.fad.fa-mosque:after {
  content: '\10f678';
}
.fa-duotone.fa-motorcycle:after,
.fad.fa-motorcycle:after {
  content: '\10f21c';
}
.fa-duotone.fa-mountain:after,
.fad.fa-mountain:after {
  content: '\10f6fc';
}
.fa-duotone.fa-mountains:after,
.fad.fa-mountains:after {
  content: '\10f6fd';
}
.fa-duotone.fa-mp3-player:after,
.fad.fa-mp3-player:after {
  content: '\10f8ce';
}
.fa-duotone.fa-mug:after,
.fad.fa-mug:after {
  content: '\10f874';
}
.fa-duotone.fa-mug-hot:after,
.fad.fa-mug-hot:after {
  content: '\10f7b6';
}
.fa-duotone.fa-mug-marshmallows:after,
.fad.fa-mug-marshmallows:after {
  content: '\10f7b7';
}
.fa-duotone.fa-coffee:after,
.fa-duotone.fa-mug-saucer:after,
.fad.fa-coffee:after,
.fad.fa-mug-saucer:after {
  content: '\10f0f4';
}
.fa-duotone.fa-mug-tea:after,
.fad.fa-mug-tea:after {
  content: '\10f875';
}
.fa-duotone.fa-mug-tea-saucer:after,
.fad.fa-mug-tea-saucer:after {
  content: '\10e201';
}
.fa-duotone.fa-music:after,
.fad.fa-music:after {
  content: '\10f001';
}
.fa-duotone.fa-music-alt:after,
.fa-duotone.fa-music-note:after,
.fad.fa-music-alt:after,
.fad.fa-music-note:after {
  content: '\10f8cf';
}
.fa-duotone.fa-music-alt-slash:after,
.fa-duotone.fa-music-note-slash:after,
.fad.fa-music-alt-slash:after,
.fad.fa-music-note-slash:after {
  content: '\10f8d0';
}
.fa-duotone.fa-music-slash:after,
.fad.fa-music-slash:after {
  content: '\10f8d1';
}
.fa-duotone.fa-n:after,
.fad.fa-n:after {
  content: '\10e202';
}
.fa-duotone.fa-naira-sign:after,
.fad.fa-naira-sign:after {
  content: '\10e203';
}
.fa-duotone.fa-narwhal:after,
.fad.fa-narwhal:after {
  content: '\10f6fe';
}
.fa-duotone.fa-network-wired:after,
.fad.fa-network-wired:after {
  content: '\10f6ff';
}
.fa-duotone.fa-neuter:after,
.fad.fa-neuter:after {
  content: '\10f22c';
}
.fa-duotone.fa-newspaper:after,
.fad.fa-newspaper:after {
  content: '\10f1ea';
}
.fa-duotone.fa-not-equal:after,
.fad.fa-not-equal:after {
  content: '\10f53e';
}
.fa-duotone.fa-notdef:after,
.fad.fa-notdef:after {
  content: '\10e204';
}
.fa-duotone.fa-note:after,
.fad.fa-note:after {
  content: '\10e205';
}
.fa-duotone.fa-note-medical:after,
.fad.fa-note-medical:after {
  content: '\10e206';
}
.fa-duotone.fa-note-sticky:after,
.fa-duotone.fa-sticky-note:after,
.fad.fa-note-sticky:after,
.fad.fa-sticky-note:after {
  content: '\10f249';
}
.fa-duotone.fa-notes:after,
.fad.fa-notes:after {
  content: '\10e207';
}
.fa-duotone.fa-notes-medical:after,
.fad.fa-notes-medical:after {
  content: '\10f481';
}
.fa-duotone.fa-o:after,
.fad.fa-o:after {
  content: '\10e208';
}
.fa-duotone.fa-object-group:after,
.fad.fa-object-group:after {
  content: '\10f247';
}
.fa-duotone.fa-object-ungroup:after,
.fad.fa-object-ungroup:after {
  content: '\10f248';
}
.fa-duotone.fa-octagon:after,
.fad.fa-octagon:after {
  content: '\10f306';
}
.fa-duotone.fa-octagon-divide:after,
.fad.fa-octagon-divide:after {
  content: '\10e209';
}
.fa-duotone.fa-octagon-exclamation:after,
.fad.fa-octagon-exclamation:after {
  content: '\10e20a';
}
.fa-duotone.fa-minus-octagon:after,
.fa-duotone.fa-octagon-minus:after,
.fad.fa-minus-octagon:after,
.fad.fa-octagon-minus:after {
  content: '\10f308';
}
.fa-duotone.fa-octagon-plus:after,
.fa-duotone.fa-plus-octagon:after,
.fad.fa-octagon-plus:after,
.fad.fa-plus-octagon:after {
  content: '\10f301';
}
.fa-duotone.fa-octagon-xmark:after,
.fa-duotone.fa-times-octagon:after,
.fa-duotone.fa-xmark-octagon:after,
.fad.fa-octagon-xmark:after,
.fad.fa-times-octagon:after,
.fad.fa-xmark-octagon:after {
  content: '\10f2f0';
}
.fa-duotone.fa-oil-can:after,
.fad.fa-oil-can:after {
  content: '\10f613';
}
.fa-duotone.fa-oil-can-drip:after,
.fad.fa-oil-can-drip:after {
  content: '\10e20b';
}
.fa-duotone.fa-oil-temp:after,
.fa-duotone.fa-oil-temperature:after,
.fad.fa-oil-temp:after,
.fad.fa-oil-temperature:after {
  content: '\10f614';
}
.fa-duotone.fa-om:after,
.fad.fa-om:after {
  content: '\10f679';
}
.fa-duotone.fa-omega:after,
.fad.fa-omega:after {
  content: '\10f67a';
}
.fa-duotone.fa-option:after,
.fad.fa-option:after {
  content: '\10e20c';
}
.fa-duotone.fa-ornament:after,
.fad.fa-ornament:after {
  content: '\10f7b8';
}
.fa-duotone.fa-otter:after,
.fad.fa-otter:after {
  content: '\10f700';
}
.fa-duotone.fa-dedent:after,
.fa-duotone.fa-outdent:after,
.fad.fa-dedent:after,
.fad.fa-outdent:after {
  content: '\10f03b';
}
.fa-duotone.fa-outlet:after,
.fad.fa-outlet:after {
  content: '\10e01c';
}
.fa-duotone.fa-oven:after,
.fad.fa-oven:after {
  content: '\10e01d';
}
.fa-duotone.fa-overline:after,
.fad.fa-overline:after {
  content: '\10f876';
}
.fa-duotone.fa-p:after,
.fad.fa-p:after {
  content: '\10e20d';
}
.fa-duotone.fa-pager:after,
.fad.fa-pager:after {
  content: '\10f815';
}
.fa-duotone.fa-paint-brush:after,
.fad.fa-paint-brush:after {
  content: '\10f1fc';
}
.fa-duotone.fa-paint-brush-alt:after,
.fa-duotone.fa-paint-brush-fine:after,
.fad.fa-paint-brush-alt:after,
.fad.fa-paint-brush-fine:after {
  content: '\10f5a9';
}
.fa-duotone.fa-paint-roller:after,
.fad.fa-paint-roller:after {
  content: '\10f5aa';
}
.fa-duotone.fa-paintbrush-pencil:after,
.fad.fa-paintbrush-pencil:after {
  content: '\10e20e';
}
.fa-duotone.fa-palette:after,
.fad.fa-palette:after {
  content: '\10f53f';
}
.fa-duotone.fa-palette-boxes:after,
.fad.fa-palette-boxes:after {
  content: '\10e20f';
}
.fa-duotone.fa-pallet:after,
.fad.fa-pallet:after {
  content: '\10f482';
}
.fa-duotone.fa-pallet-box:after,
.fad.fa-pallet-box:after {
  content: '\10e210';
}
.fa-duotone.fa-pallet-alt:after,
.fa-duotone.fa-pallet-boxes:after,
.fad.fa-pallet-alt:after,
.fad.fa-pallet-boxes:after {
  content: '\10f483';
}
.fa-duotone.fa-panorama:after,
.fad.fa-panorama:after {
  content: '\10e211';
}
.fa-duotone.fa-paper-plane:after,
.fad.fa-paper-plane:after {
  content: '\10f1d8';
}
.fa-duotone.fa-paper-plane-alt:after,
.fa-duotone.fa-paper-plane-top:after,
.fa-duotone.fa-send:after,
.fad.fa-paper-plane-alt:after,
.fad.fa-paper-plane-top:after,
.fad.fa-send:after {
  content: '\10e212';
}
.fa-duotone.fa-paperclip:after,
.fad.fa-paperclip:after {
  content: '\10f0c6';
}
.fa-duotone.fa-parachute-box:after,
.fad.fa-parachute-box:after {
  content: '\10f4cd';
}
.fa-duotone.fa-paragraph:after,
.fad.fa-paragraph:after {
  content: '\10f1dd';
}
.fa-duotone.fa-paragraph-left:after,
.fa-duotone.fa-paragraph-rtl:after,
.fad.fa-paragraph-left:after,
.fad.fa-paragraph-rtl:after {
  content: '\10f878';
}
.fa-duotone.fa-passport:after,
.fad.fa-passport:after {
  content: '\10f5ab';
}
.fa-duotone.fa-file-clipboard:after,
.fa-duotone.fa-paste:after,
.fad.fa-file-clipboard:after,
.fad.fa-paste:after {
  content: '\10f0ea';
}
.fa-duotone.fa-pause:after,
.fad.fa-pause:after {
  content: '\10f04c';
}
.fa-duotone.fa-paw:after,
.fad.fa-paw:after {
  content: '\10f1b0';
}
.fa-duotone.fa-paw-claws:after,
.fad.fa-paw-claws:after {
  content: '\10f702';
}
.fa-duotone.fa-paw-alt:after,
.fa-duotone.fa-paw-simple:after,
.fad.fa-paw-alt:after,
.fad.fa-paw-simple:after {
  content: '\10f701';
}
.fa-duotone.fa-peace:after,
.fad.fa-peace:after {
  content: '\10f67c';
}
.fa-duotone.fa-peach:after,
.fad.fa-peach:after {
  content: '\10e213';
}
.fa-duotone.fa-pear:after,
.fad.fa-pear:after {
  content: '\10e214';
}
.fa-duotone.fa-pedestal:after,
.fad.fa-pedestal:after {
  content: '\10e215';
}
.fa-duotone.fa-pegasus:after,
.fad.fa-pegasus:after {
  content: '\10f703';
}
.fa-duotone.fa-pen:after,
.fad.fa-pen:after {
  content: '\10f304';
}
.fa-duotone.fa-pen-circle:after,
.fad.fa-pen-circle:after {
  content: '\10e216';
}
.fa-duotone.fa-pen-alt:after,
.fa-duotone.fa-pen-clip:after,
.fad.fa-pen-alt:after,
.fad.fa-pen-clip:after {
  content: '\10f305';
}
.fa-duotone.fa-pen-alt-slash:after,
.fa-duotone.fa-pen-clip-slash:after,
.fad.fa-pen-alt-slash:after,
.fad.fa-pen-clip-slash:after {
  content: '\10e217';
}
.fa-duotone.fa-pen-fancy:after,
.fad.fa-pen-fancy:after {
  content: '\10f5ac';
}
.fa-duotone.fa-pen-fancy-slash:after,
.fad.fa-pen-fancy-slash:after {
  content: '\10e218';
}
.fa-duotone.fa-pen-field:after,
.fad.fa-pen-field:after {
  content: '\10e219';
}
.fa-duotone.fa-pen-line:after,
.fad.fa-pen-line:after {
  content: '\10e21a';
}
.fa-duotone.fa-pen-nib:after,
.fad.fa-pen-nib:after {
  content: '\10f5ad';
}
.fa-duotone.fa-pen-paintbrush:after,
.fa-duotone.fa-pencil-paintbrush:after,
.fad.fa-pen-paintbrush:after,
.fad.fa-pencil-paintbrush:after {
  content: '\10f618';
}
.fa-duotone.fa-pen-ruler:after,
.fa-duotone.fa-pencil-ruler:after,
.fad.fa-pen-ruler:after,
.fad.fa-pencil-ruler:after {
  content: '\10f5ae';
}
.fa-duotone.fa-pen-slash:after,
.fad.fa-pen-slash:after {
  content: '\10e21b';
}
.fa-duotone.fa-pen-swirl:after,
.fad.fa-pen-swirl:after {
  content: '\10e21c';
}
.fa-duotone.fa-edit:after,
.fa-duotone.fa-pen-to-square:after,
.fad.fa-edit:after,
.fad.fa-pen-to-square:after {
  content: '\10f044';
}
.fa-duotone.fa-pencil-alt:after,
.fa-duotone.fa-pencil:after,
.fad.fa-pencil-alt:after,
.fad.fa-pencil:after {
  content: '\10f040';
}
.fa-duotone.fa-pencil-slash:after,
.fad.fa-pencil-slash:after {
  content: '\10e21d';
}
.fa-duotone.fa-people-arrows-left-right:after,
.fa-duotone.fa-people-arrows:after,
.fad.fa-people-arrows-left-right:after,
.fad.fa-people-arrows:after {
  content: '\10e068';
}
.fa-duotone.fa-people-carry-box:after,
.fa-duotone.fa-people-carry:after,
.fad.fa-people-carry-box:after,
.fad.fa-people-carry:after {
  content: '\10f4ce';
}
.fa-duotone.fa-pepper-hot:after,
.fad.fa-pepper-hot:after {
  content: '\10f816';
}
.fa-duotone.fa-percent:after,
.fa-duotone.fa-percentage:after,
.fad.fa-percent:after,
.fad.fa-percentage:after {
  content: '\10f295';
}
.fa-duotone.fa-male:after,
.fa-duotone.fa-person:after,
.fad.fa-male:after,
.fad.fa-person:after {
  content: '\10f183';
}
.fa-duotone.fa-biking:after,
.fa-duotone.fa-person-biking:after,
.fad.fa-biking:after,
.fad.fa-person-biking:after {
  content: '\10f84a';
}
.fa-duotone.fa-biking-mountain:after,
.fa-duotone.fa-person-biking-mountain:after,
.fad.fa-biking-mountain:after,
.fad.fa-person-biking-mountain:after {
  content: '\10f84b';
}
.fa-duotone.fa-person-booth:after,
.fad.fa-person-booth:after {
  content: '\10f756';
}
.fa-duotone.fa-person-carry-box:after,
.fa-duotone.fa-person-carry:after,
.fad.fa-person-carry-box:after,
.fad.fa-person-carry:after {
  content: '\10f4cf';
}
.fa-duotone.fa-digging:after,
.fa-duotone.fa-person-digging:after,
.fad.fa-digging:after,
.fad.fa-person-digging:after {
  content: '\10f85e';
}
.fa-duotone.fa-person-dolly:after,
.fad.fa-person-dolly:after {
  content: '\10f4d0';
}
.fa-duotone.fa-person-dolly-empty:after,
.fad.fa-person-dolly-empty:after {
  content: '\10f4d1';
}
.fa-duotone.fa-diagnoses:after,
.fa-duotone.fa-person-dots-from-line:after,
.fad.fa-diagnoses:after,
.fad.fa-person-dots-from-line:after {
  content: '\10f470';
}
.fa-duotone.fa-female:after,
.fa-duotone.fa-person-dress:after,
.fad.fa-female:after,
.fad.fa-person-dress:after {
  content: '\10f182';
}
.fa-duotone.fa-person-dress-simple:after,
.fad.fa-person-dress-simple:after {
  content: '\10e21e';
}
.fa-duotone.fa-person-from-portal:after,
.fa-duotone.fa-portal-exit:after,
.fad.fa-person-from-portal:after,
.fad.fa-portal-exit:after {
  content: '\10e023';
}
.fa-duotone.fa-hiking:after,
.fa-duotone.fa-person-hiking:after,
.fad.fa-hiking:after,
.fad.fa-person-hiking:after {
  content: '\10f6ec';
}
.fa-duotone.fa-person-pinball:after,
.fad.fa-person-pinball:after {
  content: '\10e21f';
}
.fa-duotone.fa-person-praying:after,
.fa-duotone.fa-pray:after,
.fad.fa-person-praying:after,
.fad.fa-pray:after {
  content: '\10f683';
}
.fa-duotone.fa-person-running:after,
.fa-duotone.fa-running:after,
.fad.fa-person-running:after,
.fad.fa-running:after {
  content: '\10f70c';
}
.fa-duotone.fa-person-seat:after,
.fad.fa-person-seat:after {
  content: '\10e220';
}
.fa-duotone.fa-person-seat-reclined:after,
.fad.fa-person-seat-reclined:after {
  content: '\10e221';
}
.fa-duotone.fa-person-sign:after,
.fad.fa-person-sign:after {
  content: '\10f757';
}
.fa-duotone.fa-person-simple:after,
.fad.fa-person-simple:after {
  content: '\10e222';
}
.fa-duotone.fa-person-skating:after,
.fa-duotone.fa-skating:after,
.fad.fa-person-skating:after,
.fad.fa-skating:after {
  content: '\10f7c5';
}
.fa-duotone.fa-person-ski-jumping:after,
.fa-duotone.fa-ski-jump:after,
.fad.fa-person-ski-jumping:after,
.fad.fa-ski-jump:after {
  content: '\10f7c7';
}
.fa-duotone.fa-person-ski-lift:after,
.fa-duotone.fa-ski-lift:after,
.fad.fa-person-ski-lift:after,
.fad.fa-ski-lift:after {
  content: '\10f7c8';
}
.fa-duotone.fa-person-skiing:after,
.fa-duotone.fa-skiing:after,
.fad.fa-person-skiing:after,
.fad.fa-skiing:after {
  content: '\10f7c9';
}
.fa-duotone.fa-person-skiing-nordic:after,
.fa-duotone.fa-skiing-nordic:after,
.fad.fa-person-skiing-nordic:after,
.fad.fa-skiing-nordic:after {
  content: '\10f7ca';
}
.fa-duotone.fa-person-sledding:after,
.fa-duotone.fa-sledding:after,
.fad.fa-person-sledding:after,
.fad.fa-sledding:after {
  content: '\10f7cb';
}
.fa-duotone.fa-person-snowboarding:after,
.fa-duotone.fa-snowboarding:after,
.fad.fa-person-snowboarding:after,
.fad.fa-snowboarding:after {
  content: '\10f7ce';
}
.fa-duotone.fa-person-snowmobiling:after,
.fa-duotone.fa-snowmobile:after,
.fad.fa-person-snowmobiling:after,
.fad.fa-snowmobile:after {
  content: '\10f7d1';
}
.fa-duotone.fa-person-swimming:after,
.fa-duotone.fa-swimmer:after,
.fad.fa-person-swimming:after,
.fad.fa-swimmer:after {
  content: '\10f5c4';
}
.fa-duotone.fa-person-to-portal:after,
.fa-duotone.fa-portal-enter:after,
.fad.fa-person-to-portal:after,
.fad.fa-portal-enter:after {
  content: '\10e022';
}
.fa-duotone.fa-person-walking:after,
.fa-duotone.fa-walking:after,
.fad.fa-person-walking:after,
.fad.fa-walking:after {
  content: '\10f554';
}
.fa-duotone.fa-blind:after,
.fa-duotone.fa-person-walking-with-cane:after,
.fad.fa-blind:after,
.fad.fa-person-walking-with-cane:after {
  content: '\10f29d';
}
.fa-duotone.fa-peseta-sign:after,
.fad.fa-peseta-sign:after {
  content: '\10e223';
}
.fa-duotone.fa-peso-sign:after,
.fad.fa-peso-sign:after {
  content: '\10e224';
}
.fa-duotone.fa-phone:after,
.fad.fa-phone:after {
  content: '\10f095';
}
.fa-duotone.fa-phone-arrow-down-left:after,
.fa-duotone.fa-phone-arrow-down:after,
.fa-duotone.fa-phone-incoming:after,
.fad.fa-phone-arrow-down-left:after,
.fad.fa-phone-arrow-down:after,
.fad.fa-phone-incoming:after {
  content: '\10e225';
}
.fa-duotone.fa-phone-arrow-up-right:after,
.fa-duotone.fa-phone-arrow-up:after,
.fa-duotone.fa-phone-outgoing:after,
.fad.fa-phone-arrow-up-right:after,
.fad.fa-phone-arrow-up:after,
.fad.fa-phone-outgoing:after {
  content: '\10e226';
}
.fa-duotone.fa-phone-alt:after,
.fa-duotone.fa-phone-flip:after,
.fad.fa-phone-alt:after,
.fad.fa-phone-flip:after {
  content: '\10f879';
}
.fa-duotone.fa-phone-hangup:after,
.fad.fa-phone-hangup:after {
  content: '\10e227';
}
.fa-duotone.fa-phone-missed:after,
.fad.fa-phone-missed:after {
  content: '\10e228';
}
.fa-duotone.fa-phone-office:after,
.fad.fa-phone-office:after {
  content: '\10f67d';
}
.fa-duotone.fa-phone-plus:after,
.fad.fa-phone-plus:after {
  content: '\10f4d2';
}
.fa-duotone.fa-phone-rotary:after,
.fad.fa-phone-rotary:after {
  content: '\10f8d3';
}
.fa-duotone.fa-phone-slash:after,
.fad.fa-phone-slash:after {
  content: '\10f3dd';
}
.fa-duotone.fa-phone-volume:after,
.fa-duotone.fa-volume-control-phone:after,
.fad.fa-phone-volume:after,
.fad.fa-volume-control-phone:after {
  content: '\10f2a0';
}
.fa-duotone.fa-phone-xmark:after,
.fad.fa-phone-xmark:after {
  content: '\10e229';
}
.fa-duotone.fa-photo-film:after,
.fa-duotone.fa-photo-video:after,
.fad.fa-photo-film:after,
.fad.fa-photo-video:after {
  content: '\10f87c';
}
.fa-duotone.fa-photo-film-music:after,
.fad.fa-photo-film-music:after {
  content: '\10e22a';
}
.fa-duotone.fa-pi:after,
.fad.fa-pi:after {
  content: '\10f67e';
}
.fa-duotone.fa-piano:after,
.fad.fa-piano:after {
  content: '\10f8d4';
}
.fa-duotone.fa-piano-keyboard:after,
.fad.fa-piano-keyboard:after {
  content: '\10f8d5';
}
.fa-duotone.fa-pie:after,
.fad.fa-pie:after {
  content: '\10f705';
}
.fa-duotone.fa-pig:after,
.fad.fa-pig:after {
  content: '\10f706';
}
.fa-duotone.fa-piggy-bank:after,
.fad.fa-piggy-bank:after {
  content: '\10f4d3';
}
.fa-duotone.fa-pills:after,
.fad.fa-pills:after {
  content: '\10f484';
}
.fa-duotone.fa-pinball:after,
.fad.fa-pinball:after {
  content: '\10e22b';
}
.fa-duotone.fa-pizza:after,
.fad.fa-pizza:after {
  content: '\10f817';
}
.fa-duotone.fa-pizza-slice:after,
.fad.fa-pizza-slice:after {
  content: '\10f818';
}
.fa-duotone.fa-place-of-worship:after,
.fad.fa-place-of-worship:after {
  content: '\10f67f';
}
.fa-duotone.fa-plane:after,
.fad.fa-plane:after {
  content: '\10f072';
}
.fa-duotone.fa-plane-arrival:after,
.fad.fa-plane-arrival:after {
  content: '\10f5af';
}
.fa-duotone.fa-plane-departure:after,
.fad.fa-plane-departure:after {
  content: '\10f5b0';
}
.fa-duotone.fa-plane-alt:after,
.fa-duotone.fa-plane-engines:after,
.fad.fa-plane-alt:after,
.fad.fa-plane-engines:after {
  content: '\10f3de';
}
.fa-duotone.fa-plane-prop:after,
.fad.fa-plane-prop:after {
  content: '\10e22c';
}
.fa-duotone.fa-plane-slash:after,
.fad.fa-plane-slash:after {
  content: '\10e069';
}
.fa-duotone.fa-plane-tail:after,
.fad.fa-plane-tail:after {
  content: '\10e22d';
}
.fa-duotone.fa-plane-up:after,
.fad.fa-plane-up:after {
  content: '\10e22e';
}
.fa-duotone.fa-plane-up-slash:after,
.fad.fa-plane-up-slash:after {
  content: '\10e22f';
}
.fa-duotone.fa-planet-moon:after,
.fad.fa-planet-moon:after {
  content: '\10e01f';
}
.fa-duotone.fa-planet-ringed:after,
.fad.fa-planet-ringed:after {
  content: '\10e020';
}
.fa-duotone.fa-play:after,
.fad.fa-play:after {
  content: '\10f04b';
}
.fa-duotone.fa-play-pause:after,
.fad.fa-play-pause:after {
  content: '\10e230';
}
.fa-duotone.fa-plug:after,
.fad.fa-plug:after {
  content: '\10f1e6';
}
.fa-duotone.fa-add:after,
.fa-duotone.fa-plus:after,
.fad.fa-add:after,
.fad.fa-plus:after {
  content: '\10f067';
}
.fa-duotone.fa-plus-minus:after,
.fad.fa-plus-minus:after {
  content: '\10e231';
}
.fa-duotone.fa-podcast:after,
.fad.fa-podcast:after {
  content: '\10f2ce';
}
.fa-duotone.fa-podium:after,
.fad.fa-podium:after {
  content: '\10f680';
}
.fa-duotone.fa-podium-star:after,
.fad.fa-podium-star:after {
  content: '\10f758';
}
.fa-duotone.fa-police-box:after,
.fad.fa-police-box:after {
  content: '\10e021';
}
.fa-duotone.fa-poll-people:after,
.fad.fa-poll-people:after {
  content: '\10f759';
}
.fa-duotone.fa-poo:after,
.fad.fa-poo:after {
  content: '\10f2fe';
}
.fa-duotone.fa-poo-bolt:after,
.fa-duotone.fa-poo-storm:after,
.fad.fa-poo-bolt:after,
.fad.fa-poo-storm:after {
  content: '\10f75a';
}
.fa-duotone.fa-poop:after,
.fad.fa-poop:after {
  content: '\10f619';
}
.fa-duotone.fa-popcorn:after,
.fad.fa-popcorn:after {
  content: '\10f819';
}
.fa-duotone.fa-power-off:after,
.fad.fa-power-off:after {
  content: '\10f011';
}
.fa-duotone.fa-prescription:after,
.fad.fa-prescription:after {
  content: '\10f5b1';
}
.fa-duotone.fa-prescription-bottle:after,
.fad.fa-prescription-bottle:after {
  content: '\10f485';
}
.fa-duotone.fa-prescription-bottle-alt:after,
.fa-duotone.fa-prescription-bottle-medical:after,
.fad.fa-prescription-bottle-alt:after,
.fad.fa-prescription-bottle-medical:after {
  content: '\10f486';
}
.fa-duotone.fa-presentation-screen:after,
.fa-duotone.fa-presentation:after,
.fad.fa-presentation-screen:after,
.fad.fa-presentation:after {
  content: '\10f685';
}
.fa-duotone.fa-print:after,
.fad.fa-print:after {
  content: '\10f02f';
}
.fa-duotone.fa-print-magnifying-glass:after,
.fa-duotone.fa-print-search:after,
.fad.fa-print-magnifying-glass:after,
.fad.fa-print-search:after {
  content: '\10f81a';
}
.fa-duotone.fa-print-slash:after,
.fad.fa-print-slash:after {
  content: '\10f686';
}
.fa-duotone.fa-projector:after,
.fad.fa-projector:after {
  content: '\10f8d6';
}
.fa-duotone.fa-pump-medical:after,
.fad.fa-pump-medical:after {
  content: '\10e06a';
}
.fa-duotone.fa-pump-soap:after,
.fad.fa-pump-soap:after {
  content: '\10e06b';
}
.fa-duotone.fa-pumpkin:after,
.fad.fa-pumpkin:after {
  content: '\10f707';
}
.fa-duotone.fa-puzzle-piece:after,
.fad.fa-puzzle-piece:after {
  content: '\10f12e';
}
.fa-duotone.fa-puzzle-piece-alt:after,
.fa-duotone.fa-puzzle-piece-simple:after,
.fad.fa-puzzle-piece-alt:after,
.fad.fa-puzzle-piece-simple:after {
  content: '\10e232';
}
.fa-duotone.fa-q:after,
.fad.fa-q:after {
  content: '\10e233';
}
.fa-duotone.fa-qrcode:after,
.fad.fa-qrcode:after {
  content: '\10f029';
}
.fa-duotone.fa-question:after,
.fad.fa-question:after {
  content: '\10f128';
}
.fa-duotone.fa-broom-ball:after,
.fa-duotone.fa-quidditch-broom-ball:after,
.fa-duotone.fa-quidditch:after,
.fad.fa-broom-ball:after,
.fad.fa-quidditch-broom-ball:after,
.fad.fa-quidditch:after {
  content: '\10f458';
}
.fa-duotone.fa-quote-left:after,
.fad.fa-quote-left:after {
  content: '\10f10d';
}
.fa-duotone.fa-quote-left-alt:after,
.fa-duotone.fa-quote-left-round:after,
.fad.fa-quote-left-alt:after,
.fad.fa-quote-left-round:after {
  content: '\10e234';
}
.fa-duotone.fa-quote-right:after,
.fad.fa-quote-right:after {
  content: '\10f10e';
}
.fa-duotone.fa-quote-right-alt:after,
.fa-duotone.fa-quote-right-round:after,
.fad.fa-quote-right-alt:after,
.fad.fa-quote-right-round:after {
  content: '\10e235';
}
.fa-duotone.fa-r:after,
.fad.fa-r:after {
  content: '\10e236';
}
.fa-duotone.fa-rabbit:after,
.fad.fa-rabbit:after {
  content: '\10f708';
}
.fa-duotone.fa-rabbit-fast:after,
.fa-duotone.fa-rabbit-running:after,
.fad.fa-rabbit-fast:after,
.fad.fa-rabbit-running:after {
  content: '\10f709';
}
.fa-duotone.fa-racquet:after,
.fad.fa-racquet:after {
  content: '\10f45a';
}
.fa-duotone.fa-radar:after,
.fad.fa-radar:after {
  content: '\10e024';
}
.fa-duotone.fa-radiation:after,
.fad.fa-radiation:after {
  content: '\10f7b9';
}
.fa-duotone.fa-radio:after,
.fad.fa-radio:after {
  content: '\10f8d7';
}
.fa-duotone.fa-radio-alt:after,
.fa-duotone.fa-radio-tuner:after,
.fad.fa-radio-alt:after,
.fad.fa-radio-tuner:after {
  content: '\10f8d8';
}
.fa-duotone.fa-rainbow:after,
.fad.fa-rainbow:after {
  content: '\10f75b';
}
.fa-duotone.fa-raindrops:after,
.fad.fa-raindrops:after {
  content: '\10f75c';
}
.fa-duotone.fa-ram:after,
.fad.fa-ram:after {
  content: '\10f70a';
}
.fa-duotone.fa-ramp-loading:after,
.fad.fa-ramp-loading:after {
  content: '\10f4d4';
}
.fa-duotone.fa-raygun:after,
.fad.fa-raygun:after {
  content: '\10e025';
}
.fa-duotone.fa-receipt:after,
.fad.fa-receipt:after {
  content: '\10f543';
}
.fa-duotone.fa-record-vinyl:after,
.fad.fa-record-vinyl:after {
  content: '\10f8d9';
}
.fa-duotone.fa-rectangle-landscape:after,
.fa-duotone.fa-rectangle:after,
.fad.fa-rectangle-landscape:after,
.fad.fa-rectangle:after {
  content: '\10f2fa';
}
.fa-duotone.fa-ad:after,
.fa-duotone.fa-rectangle-ad:after,
.fad.fa-ad:after,
.fad.fa-rectangle-ad:after {
  content: '\10f641';
}
.fa-duotone.fa-barcode-alt:after,
.fa-duotone.fa-rectangle-barcode:after,
.fad.fa-barcode-alt:after,
.fad.fa-rectangle-barcode:after {
  content: '\10f463';
}
.fa-duotone.fa-list-alt:after,
.fa-duotone.fa-rectangle-list:after,
.fad.fa-list-alt:after,
.fad.fa-rectangle-list:after {
  content: '\10f022';
}
.fa-duotone.fa-rectangle-terminal:after,
.fad.fa-rectangle-terminal:after {
  content: '\10e237';
}
.fa-duotone.fa-rectangle-portrait:after,
.fa-duotone.fa-rectangle-vertical:after,
.fad.fa-rectangle-portrait:after,
.fad.fa-rectangle-vertical:after {
  content: '\10f2fb';
}
.fa-duotone.fa-rectangle-vertical-history:after,
.fad.fa-rectangle-vertical-history:after {
  content: '\10e238';
}
.fa-duotone.fa-rectangle-wide:after,
.fad.fa-rectangle-wide:after {
  content: '\10f2fc';
}
.fa-duotone.fa-rectangle-times:after,
.fa-duotone.fa-rectangle-xmark:after,
.fa-duotone.fa-times-rectangle:after,
.fa-duotone.fa-window-close:after,
.fad.fa-rectangle-times:after,
.fad.fa-rectangle-xmark:after,
.fad.fa-times-rectangle:after,
.fad.fa-window-close:after {
  content: '\10f410';
}
.fa-duotone.fa-recycle:after,
.fad.fa-recycle:after {
  content: '\10f1b8';
}
.fa-duotone.fa-refrigerator:after,
.fad.fa-refrigerator:after {
  content: '\10e026';
}
.fa-duotone.fa-registered:after,
.fad.fa-registered:after {
  content: '\10f25d';
}
.fa-duotone.fa-repeat:after,
.fad.fa-repeat:after {
  content: '\10f363';
}
.fa-duotone.fa-repeat-1:after,
.fad.fa-repeat-1:after {
  content: '\10f365';
}
.fa-duotone.fa-mail-reply:after,
.fa-duotone.fa-reply:after,
.fad.fa-mail-reply:after,
.fad.fa-reply:after {
  content: '\10f3e5';
}
.fa-duotone.fa-mail-reply-all:after,
.fa-duotone.fa-reply-all:after,
.fad.fa-mail-reply-all:after,
.fad.fa-reply-all:after {
  content: '\10f122';
}
.fa-duotone.fa-reply-clock:after,
.fa-duotone.fa-reply-time:after,
.fad.fa-reply-clock:after,
.fad.fa-reply-time:after {
  content: '\10e239';
}
.fa-duotone.fa-republican:after,
.fad.fa-republican:after {
  content: '\10f75e';
}
.fa-duotone.fa-restroom:after,
.fad.fa-restroom:after {
  content: '\10f7bd';
}
.fa-duotone.fa-restroom-simple:after,
.fad.fa-restroom-simple:after {
  content: '\10e23a';
}
.fa-duotone.fa-retweet:after,
.fad.fa-retweet:after {
  content: '\10f079';
}
.fa-duotone.fa-rhombus:after,
.fad.fa-rhombus:after {
  content: '\10e23b';
}
.fa-duotone.fa-ribbon:after,
.fad.fa-ribbon:after {
  content: '\10f4d6';
}
.fa-duotone.fa-arrow-alt-right:after,
.fa-duotone.fa-right:after,
.fad.fa-arrow-alt-right:after,
.fad.fa-right:after {
  content: '\10f356';
}
.fa-duotone.fa-right-from-bracket:after,
.fa-duotone.fa-sign-out-alt:after,
.fad.fa-right-from-bracket:after,
.fad.fa-sign-out-alt:after {
  content: '\10f2f5';
}
.fa-duotone.fa-arrow-alt-from-left:after,
.fa-duotone.fa-right-from-line:after,
.fad.fa-arrow-alt-from-left:after,
.fad.fa-right-from-line:after {
  content: '\10f347';
}
.fa-duotone.fa-exchange-alt:after,
.fa-duotone.fa-right-left:after,
.fad.fa-exchange-alt:after,
.fad.fa-right-left:after {
  content: '\10f362';
}
.fa-duotone.fa-long-arrow-alt-right:after,
.fa-duotone.fa-right-long:after,
.fad.fa-long-arrow-alt-right:after,
.fad.fa-right-long:after {
  content: '\10f30b';
}
.fa-duotone.fa-right-to-bracket:after,
.fa-duotone.fa-sign-in-alt:after,
.fad.fa-right-to-bracket:after,
.fad.fa-sign-in-alt:after {
  content: '\10f2f6';
}
.fa-duotone.fa-arrow-alt-to-right:after,
.fa-duotone.fa-right-to-line:after,
.fad.fa-arrow-alt-to-right:after,
.fad.fa-right-to-line:after {
  content: '\10f34c';
}
.fa-duotone.fa-ring:after,
.fad.fa-ring:after {
  content: '\10f70b';
}
.fa-duotone.fa-rings-wedding:after,
.fad.fa-rings-wedding:after {
  content: '\10f81b';
}
.fa-duotone.fa-road:after,
.fad.fa-road:after {
  content: '\10f018';
}
.fa-duotone.fa-robot:after,
.fad.fa-robot:after {
  content: '\10f544';
}
.fa-duotone.fa-rocket:after,
.fad.fa-rocket:after {
  content: '\10f135';
}
.fa-duotone.fa-rocket-launch:after,
.fad.fa-rocket-launch:after {
  content: '\10e027';
}
.fa-duotone.fa-rotate:after,
.fa-duotone.fa-sync-alt:after,
.fad.fa-rotate:after,
.fad.fa-sync-alt:after {
  content: '\10f2f1';
}
.fa-duotone.fa-rotate-exclamation:after,
.fad.fa-rotate-exclamation:after {
  content: '\10e23c';
}
.fa-duotone.fa-redo-alt:after,
.fa-duotone.fa-rotate-forward:after,
.fad.fa-redo-alt:after,
.fad.fa-rotate-forward:after {
  content: '\10f2f9';
}
.fa-duotone.fa-rotate-left:after,
.fa-duotone.fa-undo-alt:after,
.fad.fa-rotate-left:after,
.fad.fa-undo-alt:after {
  content: '\10f2ea';
}
.fa-duotone.fa-route:after,
.fad.fa-route:after {
  content: '\10f4d7';
}
.fa-duotone.fa-route-highway:after,
.fad.fa-route-highway:after {
  content: '\10f61a';
}
.fa-duotone.fa-route-interstate:after,
.fad.fa-route-interstate:after {
  content: '\10f61b';
}
.fa-duotone.fa-router:after,
.fad.fa-router:after {
  content: '\10f8da';
}
.fa-duotone.fa-feed:after,
.fa-duotone.fa-rss:after,
.fad.fa-feed:after,
.fad.fa-rss:after {
  content: '\10f09e';
}
.fa-duotone.fa-rouble:after,
.fa-duotone.fa-rub:after,
.fa-duotone.fa-ruble-sign:after,
.fa-duotone.fa-ruble:after,
.fad.fa-rouble:after,
.fad.fa-rub:after,
.fad.fa-ruble-sign:after,
.fad.fa-ruble:after {
  content: '\10f158';
}
.fa-duotone.fa-ruler:after,
.fad.fa-ruler:after {
  content: '\10f545';
}
.fa-duotone.fa-ruler-combined:after,
.fad.fa-ruler-combined:after {
  content: '\10f546';
}
.fa-duotone.fa-ruler-horizontal:after,
.fad.fa-ruler-horizontal:after {
  content: '\10f547';
}
.fa-duotone.fa-ruler-triangle:after,
.fad.fa-ruler-triangle:after {
  content: '\10f61c';
}
.fa-duotone.fa-ruler-vertical:after,
.fad.fa-ruler-vertical:after {
  content: '\10f548';
}
.fa-duotone.fa-rupee-sign:after,
.fa-duotone.fa-rupee:after,
.fad.fa-rupee-sign:after,
.fad.fa-rupee:after {
  content: '\10f156';
}
.fa-duotone.fa-rupiah-sign:after,
.fad.fa-rupiah-sign:after {
  content: '\10e23d';
}
.fa-duotone.fa-rv:after,
.fad.fa-rv:after {
  content: '\10f7be';
}
.fa-duotone.fa-s:after,
.fad.fa-s:after {
  content: '\10e23e';
}
.fa-duotone.fa-sack:after,
.fad.fa-sack:after {
  content: '\10f81c';
}
.fa-duotone.fa-sack-dollar:after,
.fad.fa-sack-dollar:after {
  content: '\10f81d';
}
.fa-duotone.fa-bowl-salad:after,
.fa-duotone.fa-salad:after,
.fad.fa-bowl-salad:after,
.fad.fa-salad:after {
  content: '\10f81e';
}
.fa-duotone.fa-sandwich:after,
.fad.fa-sandwich:after {
  content: '\10f81f';
}
.fa-duotone.fa-satellite:after,
.fad.fa-satellite:after {
  content: '\10f7bf';
}
.fa-duotone.fa-satellite-dish:after,
.fad.fa-satellite-dish:after {
  content: '\10f7c0';
}
.fa-duotone.fa-sausage:after,
.fad.fa-sausage:after {
  content: '\10f820';
}
.fa-duotone.fa-saxophone:after,
.fad.fa-saxophone:after {
  content: '\10f8dc';
}
.fa-duotone.fa-sax-hot:after,
.fa-duotone.fa-saxophone-fire:after,
.fad.fa-sax-hot:after,
.fad.fa-saxophone-fire:after {
  content: '\10f8db';
}
.fa-duotone.fa-balance-scale:after,
.fa-duotone.fa-scale-balanced:after,
.fad.fa-balance-scale:after,
.fad.fa-scale-balanced:after {
  content: '\10f24e';
}
.fa-duotone.fa-balance-scale-left:after,
.fa-duotone.fa-scale-unbalanced:after,
.fad.fa-balance-scale-left:after,
.fad.fa-scale-unbalanced:after {
  content: '\10f515';
}
.fa-duotone.fa-balance-scale-right:after,
.fa-duotone.fa-scale-unbalanced-flip:after,
.fad.fa-balance-scale-right:after,
.fad.fa-scale-unbalanced-flip:after {
  content: '\10f516';
}
.fa-duotone.fa-scalpel:after,
.fad.fa-scalpel:after {
  content: '\10f61d';
}
.fa-duotone.fa-scalpel-line-dashed:after,
.fa-duotone.fa-scalpel-path:after,
.fad.fa-scalpel-line-dashed:after,
.fad.fa-scalpel-path:after {
  content: '\10f61e';
}
.fa-duotone.fa-scanner-image:after,
.fa-duotone.fa-scanner:after,
.fad.fa-scanner-image:after,
.fad.fa-scanner:after {
  content: '\10f8f3';
}
.fa-duotone.fa-scanner-gun:after,
.fad.fa-scanner-gun:after {
  content: '\10f488';
}
.fa-duotone.fa-scanner-keyboard:after,
.fad.fa-scanner-keyboard:after {
  content: '\10f489';
}
.fa-duotone.fa-scanner-touchscreen:after,
.fad.fa-scanner-touchscreen:after {
  content: '\10f48a';
}
.fa-duotone.fa-scarecrow:after,
.fad.fa-scarecrow:after {
  content: '\10f70d';
}
.fa-duotone.fa-scarf:after,
.fad.fa-scarf:after {
  content: '\10f7c1';
}
.fa-duotone.fa-school:after,
.fad.fa-school:after {
  content: '\10f549';
}
.fa-duotone.fa-cut:after,
.fa-duotone.fa-scissors:after,
.fad.fa-cut:after,
.fad.fa-scissors:after {
  content: '\10f0c4';
}
.fa-duotone.fa-screen-users:after,
.fa-duotone.fa-users-class:after,
.fad.fa-screen-users:after,
.fad.fa-users-class:after {
  content: '\10f63d';
}
.fa-duotone.fa-screencast:after,
.fad.fa-screencast:after {
  content: '\10e23f';
}
.fa-duotone.fa-screwdriver:after,
.fad.fa-screwdriver:after {
  content: '\10f54a';
}
.fa-duotone.fa-screwdriver-wrench:after,
.fa-duotone.fa-tools:after,
.fad.fa-screwdriver-wrench:after,
.fad.fa-tools:after {
  content: '\10f7d9';
}
.fa-duotone.fa-scribble:after,
.fad.fa-scribble:after {
  content: '\10e240';
}
.fa-duotone.fa-scroll:after,
.fad.fa-scroll:after {
  content: '\10f70e';
}
.fa-duotone.fa-scroll-old:after,
.fad.fa-scroll-old:after {
  content: '\10f70f';
}
.fa-duotone.fa-scroll-torah:after,
.fa-duotone.fa-torah:after,
.fad.fa-scroll-torah:after,
.fad.fa-torah:after {
  content: '\10f6a0';
}
.fa-duotone.fa-scrubber:after,
.fad.fa-scrubber:after {
  content: '\10f2f8';
}
.fa-duotone.fa-scythe:after,
.fad.fa-scythe:after {
  content: '\10f710';
}
.fa-duotone.fa-sd-card:after,
.fad.fa-sd-card:after {
  content: '\10f7c2';
}
.fa-duotone.fa-sd-cards:after,
.fad.fa-sd-cards:after {
  content: '\10e241';
}
.fa-duotone.fa-seal:after,
.fad.fa-seal:after {
  content: '\10e242';
}
.fa-duotone.fa-seal-exclamation:after,
.fad.fa-seal-exclamation:after {
  content: '\10e243';
}
.fa-duotone.fa-seal-question:after,
.fad.fa-seal-question:after {
  content: '\10e244';
}
.fa-duotone.fa-seat-airline:after,
.fad.fa-seat-airline:after {
  content: '\10e245';
}
.fa-duotone.fa-section:after,
.fad.fa-section:after {
  content: '\10e246';
}
.fa-duotone.fa-seedling:after,
.fa-duotone.fa-sprout:after,
.fad.fa-seedling:after,
.fad.fa-sprout:after {
  content: '\10f4d8';
}
.fa-duotone.fa-send-back:after,
.fad.fa-send-back:after {
  content: '\10f87e';
}
.fa-duotone.fa-send-backward:after,
.fad.fa-send-backward:after {
  content: '\10f87f';
}
.fa-duotone.fa-sensor:after,
.fad.fa-sensor:after {
  content: '\10e028';
}
.fa-duotone.fa-sensor-cloud:after,
.fa-duotone.fa-sensor-smoke:after,
.fad.fa-sensor-cloud:after,
.fad.fa-sensor-smoke:after {
  content: '\10e02c';
}
.fa-duotone.fa-sensor-fire:after,
.fad.fa-sensor-fire:after {
  content: '\10e02a';
}
.fa-duotone.fa-sensor-on:after,
.fad.fa-sensor-on:after {
  content: '\10e02b';
}
.fa-duotone.fa-sensor-alert:after,
.fa-duotone.fa-sensor-triangle-exclamation:after,
.fad.fa-sensor-alert:after,
.fad.fa-sensor-triangle-exclamation:after {
  content: '\10e029';
}
.fa-duotone.fa-server:after,
.fad.fa-server:after {
  content: '\10f233';
}
.fa-duotone.fa-shapes:after,
.fa-duotone.fa-triangle-circle-square:after,
.fad.fa-shapes:after,
.fad.fa-triangle-circle-square:after {
  content: '\10f61f';
}
.fa-duotone.fa-arrow-turn-right:after,
.fa-duotone.fa-mail-forward:after,
.fa-duotone.fa-share:after,
.fad.fa-arrow-turn-right:after,
.fad.fa-mail-forward:after,
.fad.fa-share:after {
  content: '\10f064';
}
.fa-duotone.fa-arrows-turn-right:after,
.fa-duotone.fa-share-all:after,
.fad.fa-arrows-turn-right:after,
.fad.fa-share-all:after {
  content: '\10f367';
}
.fa-duotone.fa-share-from-square:after,
.fa-duotone.fa-share-square:after,
.fad.fa-share-from-square:after,
.fad.fa-share-square:after {
  content: '\10f14d';
}
.fa-duotone.fa-share-alt:after,
.fa-duotone.fa-share-nodes:after,
.fad.fa-share-alt:after,
.fad.fa-share-nodes:after {
  content: '\10f1e0';
}
.fa-duotone.fa-sheep:after,
.fad.fa-sheep:after {
  content: '\10f711';
}
.fa-duotone.fa-ils:after,
.fa-duotone.fa-shekel-sign:after,
.fa-duotone.fa-shekel:after,
.fa-duotone.fa-sheqel-sign:after,
.fa-duotone.fa-sheqel:after,
.fad.fa-ils:after,
.fad.fa-shekel-sign:after,
.fad.fa-shekel:after,
.fad.fa-sheqel-sign:after,
.fad.fa-sheqel:after {
  content: '\10f20b';
}
.fa-duotone.fa-inventory:after,
.fa-duotone.fa-shelves:after,
.fad.fa-inventory:after,
.fad.fa-shelves:after {
  content: '\10f480';
}
.fa-duotone.fa-shelves-empty:after,
.fad.fa-shelves-empty:after {
  content: '\10e247';
}
.fa-duotone.fa-shield:after,
.fad.fa-shield:after {
  content: '\10f132';
}
.fa-duotone.fa-shield-alt:after,
.fa-duotone.fa-shield-blank:after,
.fad.fa-shield-alt:after,
.fad.fa-shield-blank:after {
  content: '\10f3ed';
}
.fa-duotone.fa-shield-check:after,
.fad.fa-shield-check:after {
  content: '\10f2f7';
}
.fa-duotone.fa-shield-cross:after,
.fad.fa-shield-cross:after {
  content: '\10f712';
}
.fa-duotone.fa-shield-exclamation:after,
.fad.fa-shield-exclamation:after {
  content: '\10e248';
}
.fa-duotone.fa-shield-keyhole:after,
.fad.fa-shield-keyhole:after {
  content: '\10e249';
}
.fa-duotone.fa-shield-minus:after,
.fad.fa-shield-minus:after {
  content: '\10e24a';
}
.fa-duotone.fa-shield-plus:after,
.fad.fa-shield-plus:after {
  content: '\10e24b';
}
.fa-duotone.fa-shield-slash:after,
.fad.fa-shield-slash:after {
  content: '\10e24c';
}
.fa-duotone.fa-shield-virus:after,
.fad.fa-shield-virus:after {
  content: '\10e06c';
}
.fa-duotone.fa-shield-times:after,
.fa-duotone.fa-shield-xmark:after,
.fad.fa-shield-times:after,
.fad.fa-shield-xmark:after {
  content: '\10e24d';
}
.fa-duotone.fa-ship:after,
.fad.fa-ship:after {
  content: '\10f21a';
}
.fa-duotone.fa-shish-kebab:after,
.fad.fa-shish-kebab:after {
  content: '\10f821';
}
.fa-duotone.fa-shoe-prints:after,
.fad.fa-shoe-prints:after {
  content: '\10f54b';
}
.fa-duotone.fa-shop:after,
.fa-duotone.fa-store-alt:after,
.fad.fa-shop:after,
.fad.fa-store-alt:after {
  content: '\10f54f';
}
.fa-duotone.fa-shop-slash:after,
.fa-duotone.fa-store-alt-slash:after,
.fad.fa-shop-slash:after,
.fad.fa-store-alt-slash:after {
  content: '\10e070';
}
.fa-duotone.fa-shovel:after,
.fad.fa-shovel:after {
  content: '\10f713';
}
.fa-duotone.fa-shovel-snow:after,
.fad.fa-shovel-snow:after {
  content: '\10f7c3';
}
.fa-duotone.fa-shower:after,
.fad.fa-shower:after {
  content: '\10f2cc';
}
.fa-duotone.fa-shower-alt:after,
.fa-duotone.fa-shower-down:after,
.fad.fa-shower-alt:after,
.fad.fa-shower-down:after {
  content: '\10e24e';
}
.fa-duotone.fa-shredder:after,
.fad.fa-shredder:after {
  content: '\10f68a';
}
.fa-duotone.fa-random:after,
.fa-duotone.fa-shuffle:after,
.fad.fa-random:after,
.fad.fa-shuffle:after {
  content: '\10f074';
}
.fa-duotone.fa-shuttle-space:after,
.fa-duotone.fa-space-shuttle:after,
.fad.fa-shuttle-space:after,
.fad.fa-space-shuttle:after {
  content: '\10f197';
}
.fa-duotone.fa-shuttlecock:after,
.fad.fa-shuttlecock:after {
  content: '\10f45b';
}
.fa-duotone.fa-sickle:after,
.fad.fa-sickle:after {
  content: '\10f822';
}
.fa-duotone.fa-sidebar:after,
.fad.fa-sidebar:after {
  content: '\10e24f';
}
.fa-duotone.fa-sidebar-flip:after,
.fad.fa-sidebar-flip:after {
  content: '\10e250';
}
.fa-duotone.fa-sigma:after,
.fad.fa-sigma:after {
  content: '\10f68b';
}
.fa-duotone.fa-sign-hanging:after,
.fa-duotone.fa-sign:after,
.fad.fa-sign-hanging:after,
.fad.fa-sign:after {
  content: '\10f4d9';
}
.fa-duotone.fa-signal-5:after,
.fa-duotone.fa-signal-perfect:after,
.fa-duotone.fa-signal:after,
.fad.fa-signal-5:after,
.fad.fa-signal-perfect:after,
.fad.fa-signal:after {
  content: '\10f012';
}
.fa-duotone.fa-signal-alt-4:after,
.fa-duotone.fa-signal-alt:after,
.fa-duotone.fa-signal-bars-strong:after,
.fa-duotone.fa-signal-bars:after,
.fad.fa-signal-alt-4:after,
.fad.fa-signal-alt:after,
.fad.fa-signal-bars-strong:after,
.fad.fa-signal-bars:after {
  content: '\10f690';
}
.fa-duotone.fa-signal-alt-2:after,
.fa-duotone.fa-signal-bars-fair:after,
.fad.fa-signal-alt-2:after,
.fad.fa-signal-bars-fair:after {
  content: '\10f692';
}
.fa-duotone.fa-signal-alt-3:after,
.fa-duotone.fa-signal-bars-good:after,
.fad.fa-signal-alt-3:after,
.fad.fa-signal-bars-good:after {
  content: '\10f693';
}
.fa-duotone.fa-signal-alt-slash:after,
.fa-duotone.fa-signal-bars-slash:after,
.fad.fa-signal-alt-slash:after,
.fad.fa-signal-bars-slash:after {
  content: '\10f694';
}
.fa-duotone.fa-signal-alt-1:after,
.fa-duotone.fa-signal-bars-weak:after,
.fad.fa-signal-alt-1:after,
.fad.fa-signal-bars-weak:after {
  content: '\10f691';
}
.fa-duotone.fa-signal-2:after,
.fa-duotone.fa-signal-fair:after,
.fad.fa-signal-2:after,
.fad.fa-signal-fair:after {
  content: '\10f68d';
}
.fa-duotone.fa-signal-3:after,
.fa-duotone.fa-signal-good:after,
.fad.fa-signal-3:after,
.fad.fa-signal-good:after {
  content: '\10f68e';
}
.fa-duotone.fa-signal-slash:after,
.fad.fa-signal-slash:after {
  content: '\10f695';
}
.fa-duotone.fa-signal-stream:after,
.fad.fa-signal-stream:after {
  content: '\10f8dd';
}
.fa-duotone.fa-signal-stream-slash:after,
.fad.fa-signal-stream-slash:after {
  content: '\10e251';
}
.fa-duotone.fa-signal-4:after,
.fa-duotone.fa-signal-strong:after,
.fad.fa-signal-4:after,
.fad.fa-signal-strong:after {
  content: '\10f68f';
}
.fa-duotone.fa-signal-1:after,
.fa-duotone.fa-signal-weak:after,
.fad.fa-signal-1:after,
.fad.fa-signal-weak:after {
  content: '\10f68c';
}
.fa-duotone.fa-signature:after,
.fad.fa-signature:after {
  content: '\10f5b7';
}
.fa-duotone.fa-map-signs:after,
.fa-duotone.fa-signs-post:after,
.fad.fa-map-signs:after,
.fad.fa-signs-post:after {
  content: '\10f277';
}
.fa-duotone.fa-sim-card:after,
.fad.fa-sim-card:after {
  content: '\10f7c4';
}
.fa-duotone.fa-sim-cards:after,
.fad.fa-sim-cards:after {
  content: '\10e252';
}
.fa-duotone.fa-sink:after,
.fad.fa-sink:after {
  content: '\10e06d';
}
.fa-duotone.fa-siren:after,
.fad.fa-siren:after {
  content: '\10e02d';
}
.fa-duotone.fa-siren-on:after,
.fad.fa-siren-on:after {
  content: '\10e02e';
}
.fa-duotone.fa-sitemap:after,
.fad.fa-sitemap:after {
  content: '\10f0e8';
}
.fa-duotone.fa-skeleton:after,
.fad.fa-skeleton:after {
  content: '\10f620';
}
.fa-duotone.fa-skull:after,
.fad.fa-skull:after {
  content: '\10f54c';
}
.fa-duotone.fa-skull-cow:after,
.fad.fa-skull-cow:after {
  content: '\10f8de';
}
.fa-duotone.fa-skull-crossbones:after,
.fad.fa-skull-crossbones:after {
  content: '\10f714';
}
.fa-duotone.fa-slash:after,
.fad.fa-slash:after {
  content: '\10f715';
}
.fa-duotone.fa-sleigh:after,
.fad.fa-sleigh:after {
  content: '\10f7cc';
}
.fa-duotone.fa-slider:after,
.fad.fa-slider:after {
  content: '\10e253';
}
.fa-duotone.fa-sliders-h:after,
.fa-duotone.fa-sliders:after,
.fad.fa-sliders-h:after,
.fad.fa-sliders:after {
  content: '\10f1de';
}
.fa-duotone.fa-sliders-up:after,
.fa-duotone.fa-sliders-v:after,
.fad.fa-sliders-up:after,
.fad.fa-sliders-v:after {
  content: '\10f3f1';
}
.fa-duotone.fa-smog:after,
.fad.fa-smog:after {
  content: '\10f75f';
}
.fa-duotone.fa-smoke:after,
.fad.fa-smoke:after {
  content: '\10f760';
}
.fa-duotone.fa-smoking:after,
.fad.fa-smoking:after {
  content: '\10f48d';
}
.fa-duotone.fa-snake:after,
.fad.fa-snake:after {
  content: '\10f716';
}
.fa-duotone.fa-snooze:after,
.fa-duotone.fa-zzz:after,
.fad.fa-snooze:after,
.fad.fa-zzz:after {
  content: '\10f880';
}
.fa-duotone.fa-snow-blowing:after,
.fad.fa-snow-blowing:after {
  content: '\10f761';
}
.fa-duotone.fa-snowflake:after,
.fad.fa-snowflake:after {
  content: '\10f2dc';
}
.fa-duotone.fa-snowflakes:after,
.fad.fa-snowflakes:after {
  content: '\10f7cf';
}
.fa-duotone.fa-snowman:after,
.fad.fa-snowman:after {
  content: '\10f7d0';
}
.fa-duotone.fa-frosty-head:after,
.fa-duotone.fa-snowman-head:after,
.fad.fa-frosty-head:after,
.fad.fa-snowman-head:after {
  content: '\10f79b';
}
.fa-duotone.fa-snowplow:after,
.fad.fa-snowplow:after {
  content: '\10f7d2';
}
.fa-duotone.fa-soap:after,
.fad.fa-soap:after {
  content: '\10e06e';
}
.fa-duotone.fa-socks:after,
.fad.fa-socks:after {
  content: '\10f696';
}
.fa-duotone.fa-solar-panel:after,
.fad.fa-solar-panel:after {
  content: '\10f5ba';
}
.fa-duotone.fa-solar-system:after,
.fad.fa-solar-system:after {
  content: '\10e02f';
}
.fa-duotone.fa-sort:after,
.fa-duotone.fa-unsorted:after,
.fad.fa-sort:after,
.fad.fa-unsorted:after {
  content: '\10f0dc';
}
.fa-duotone.fa-sort-desc:after,
.fa-duotone.fa-sort-down:after,
.fad.fa-sort-desc:after,
.fad.fa-sort-down:after {
  content: '\10f0dd';
}
.fa-duotone.fa-sort-asc:after,
.fa-duotone.fa-sort-up:after,
.fad.fa-sort-asc:after,
.fad.fa-sort-up:after {
  content: '\10f0de';
}
.fa-duotone.fa-spa:after,
.fad.fa-spa:after {
  content: '\10f5bb';
}
.fa-duotone.fa-space-station-moon:after,
.fad.fa-space-station-moon:after {
  content: '\10e033';
}
.fa-duotone.fa-space-station-moon-alt:after,
.fa-duotone.fa-space-station-moon-construction:after,
.fad.fa-space-station-moon-alt:after,
.fad.fa-space-station-moon-construction:after {
  content: '\10e034';
}
.fa-duotone.fa-spade:after,
.fad.fa-spade:after {
  content: '\10f2f4';
}
.fa-duotone.fa-pastafarianism:after,
.fa-duotone.fa-spaghetti-monster-flying:after,
.fad.fa-pastafarianism:after,
.fad.fa-spaghetti-monster-flying:after {
  content: '\10f67b';
}
.fa-duotone.fa-sparkles:after,
.fad.fa-sparkles:after {
  content: '\10f890';
}
.fa-duotone.fa-speaker:after,
.fad.fa-speaker:after {
  content: '\10f8df';
}
.fa-duotone.fa-speakers:after,
.fad.fa-speakers:after {
  content: '\10f8e0';
}
.fa-duotone.fa-spell-check:after,
.fad.fa-spell-check:after {
  content: '\10f891';
}
.fa-duotone.fa-spider:after,
.fad.fa-spider:after {
  content: '\10f717';
}
.fa-duotone.fa-spider-black-widow:after,
.fad.fa-spider-black-widow:after {
  content: '\10f718';
}
.fa-duotone.fa-spider-web:after,
.fad.fa-spider-web:after {
  content: '\10f719';
}
.fa-duotone.fa-spinner:after,
.fad.fa-spinner:after {
  content: '\10f110';
}
.fa-duotone.fa-spinner-third:after,
.fad.fa-spinner-third:after {
  content: '\10f3f4';
}
.fa-duotone.fa-split:after,
.fad.fa-split:after {
  content: '\10e254';
}
.fa-duotone.fa-splotch:after,
.fad.fa-splotch:after {
  content: '\10f5bc';
}
.fa-duotone.fa-spoon:after,
.fa-duotone.fa-utensil-spoon:after,
.fad.fa-spoon:after,
.fad.fa-utensil-spoon:after {
  content: '\10f2e5';
}
.fa-duotone.fa-spray-can:after,
.fad.fa-spray-can:after {
  content: '\10f5bd';
}
.fa-duotone.fa-sprinkler:after,
.fad.fa-sprinkler:after {
  content: '\10e035';
}
.fa-duotone.fa-square:after,
.fad.fa-square:after {
  content: '\10f0c8';
}
.fa-duotone.fa-square-0:after,
.fad.fa-square-0:after {
  content: '\10e255';
}
.fa-duotone.fa-square-1:after,
.fad.fa-square-1:after {
  content: '\10e256';
}
.fa-duotone.fa-square-2:after,
.fad.fa-square-2:after {
  content: '\10e257';
}
.fa-duotone.fa-square-3:after,
.fad.fa-square-3:after {
  content: '\10e258';
}
.fa-duotone.fa-square-4:after,
.fad.fa-square-4:after {
  content: '\10e259';
}
.fa-duotone.fa-square-5:after,
.fad.fa-square-5:after {
  content: '\10e25a';
}
.fa-duotone.fa-square-6:after,
.fad.fa-square-6:after {
  content: '\10e25b';
}
.fa-duotone.fa-square-7:after,
.fad.fa-square-7:after {
  content: '\10e25c';
}
.fa-duotone.fa-square-8:after,
.fad.fa-square-8:after {
  content: '\10e25d';
}
.fa-duotone.fa-square-9:after,
.fad.fa-square-9:after {
  content: '\10e25e';
}
.fa-duotone.fa-square-a:after,
.fad.fa-square-a:after {
  content: '\10e25f';
}
.fa-duotone.fa-square-ampersand:after,
.fad.fa-square-ampersand:after {
  content: '\10e260';
}
.fa-duotone.fa-arrow-square-down:after,
.fa-duotone.fa-square-arrow-down:after,
.fad.fa-arrow-square-down:after,
.fad.fa-square-arrow-down:after {
  content: '\10f339';
}
.fa-duotone.fa-square-arrow-down-left:after,
.fad.fa-square-arrow-down-left:after {
  content: '\10e261';
}
.fa-duotone.fa-square-arrow-down-right:after,
.fad.fa-square-arrow-down-right:after {
  content: '\10e262';
}
.fa-duotone.fa-arrow-square-left:after,
.fa-duotone.fa-square-arrow-left:after,
.fad.fa-arrow-square-left:after,
.fad.fa-square-arrow-left:after {
  content: '\10f33a';
}
.fa-duotone.fa-arrow-square-right:after,
.fa-duotone.fa-square-arrow-right:after,
.fad.fa-arrow-square-right:after,
.fad.fa-square-arrow-right:after {
  content: '\10f33b';
}
.fa-duotone.fa-arrow-square-up:after,
.fa-duotone.fa-square-arrow-up:after,
.fad.fa-arrow-square-up:after,
.fad.fa-square-arrow-up:after {
  content: '\10f33c';
}
.fa-duotone.fa-square-arrow-up-left:after,
.fad.fa-square-arrow-up-left:after {
  content: '\10e263';
}
.fa-duotone.fa-external-link-square:after,
.fa-duotone.fa-square-arrow-up-right:after,
.fad.fa-external-link-square:after,
.fad.fa-square-arrow-up-right:after {
  content: '\10f14c';
}
.fa-duotone.fa-square-b:after,
.fad.fa-square-b:after {
  content: '\10e264';
}
.fa-duotone.fa-square-bolt:after,
.fad.fa-square-bolt:after {
  content: '\10e265';
}
.fa-duotone.fa-square-c:after,
.fad.fa-square-c:after {
  content: '\10e266';
}
.fa-duotone.fa-caret-square-down:after,
.fa-duotone.fa-square-caret-down:after,
.fad.fa-caret-square-down:after,
.fad.fa-square-caret-down:after {
  content: '\10f150';
}
.fa-duotone.fa-caret-square-left:after,
.fa-duotone.fa-square-caret-left:after,
.fad.fa-caret-square-left:after,
.fad.fa-square-caret-left:after {
  content: '\10f191';
}
.fa-duotone.fa-caret-square-right:after,
.fa-duotone.fa-square-caret-right:after,
.fad.fa-caret-square-right:after,
.fad.fa-square-caret-right:after {
  content: '\10f152';
}
.fa-duotone.fa-caret-square-up:after,
.fa-duotone.fa-square-caret-up:after,
.fad.fa-caret-square-up:after,
.fad.fa-square-caret-up:after {
  content: '\10f151';
}
.fa-duotone.fa-check-square:after,
.fa-duotone.fa-square-check:after,
.fad.fa-check-square:after,
.fad.fa-square-check:after {
  content: '\10f14a';
}
.fa-duotone.fa-chevron-square-down:after,
.fa-duotone.fa-square-chevron-down:after,
.fad.fa-chevron-square-down:after,
.fad.fa-square-chevron-down:after {
  content: '\10f329';
}
.fa-duotone.fa-chevron-square-left:after,
.fa-duotone.fa-square-chevron-left:after,
.fad.fa-chevron-square-left:after,
.fad.fa-square-chevron-left:after {
  content: '\10f32a';
}
.fa-duotone.fa-chevron-square-right:after,
.fa-duotone.fa-square-chevron-right:after,
.fad.fa-chevron-square-right:after,
.fad.fa-square-chevron-right:after {
  content: '\10f32b';
}
.fa-duotone.fa-chevron-square-up:after,
.fa-duotone.fa-square-chevron-up:after,
.fad.fa-chevron-square-up:after,
.fad.fa-square-chevron-up:after {
  content: '\10f32c';
}
.fa-duotone.fa-square-code:after,
.fad.fa-square-code:after {
  content: '\10e267';
}
.fa-duotone.fa-square-d:after,
.fad.fa-square-d:after {
  content: '\10e268';
}
.fa-duotone.fa-square-dashed:after,
.fad.fa-square-dashed:after {
  content: '\10e269';
}
.fa-duotone.fa-square-divide:after,
.fad.fa-square-divide:after {
  content: '\10e26a';
}
.fa-duotone.fa-dollar-square:after,
.fa-duotone.fa-square-dollar:after,
.fa-duotone.fa-usd-square:after,
.fad.fa-dollar-square:after,
.fad.fa-square-dollar:after,
.fad.fa-usd-square:after {
  content: '\10f2e9';
}
.fa-duotone.fa-arrow-alt-square-down:after,
.fa-duotone.fa-square-down:after,
.fad.fa-arrow-alt-square-down:after,
.fad.fa-square-down:after {
  content: '\10f350';
}
.fa-duotone.fa-square-down-left:after,
.fad.fa-square-down-left:after {
  content: '\10e26b';
}
.fa-duotone.fa-square-down-right:after,
.fad.fa-square-down-right:after {
  content: '\10e26c';
}
.fa-duotone.fa-square-e:after,
.fad.fa-square-e:after {
  content: '\10e26d';
}
.fa-duotone.fa-envelope-square:after,
.fa-duotone.fa-square-envelope:after,
.fad.fa-envelope-square:after,
.fad.fa-square-envelope:after {
  content: '\10f199';
}
.fa-duotone.fa-exclamation-square:after,
.fa-duotone.fa-square-exclamation:after,
.fad.fa-exclamation-square:after,
.fad.fa-square-exclamation:after {
  content: '\10f321';
}
.fa-duotone.fa-square-f:after,
.fad.fa-square-f:after {
  content: '\10e26e';
}
.fa-duotone.fa-box-fragile:after,
.fa-duotone.fa-square-fragile:after,
.fa-duotone.fa-square-wine-glass-crack:after,
.fad.fa-box-fragile:after,
.fad.fa-square-fragile:after,
.fad.fa-square-wine-glass-crack:after {
  content: '\10f49b';
}
.fa-duotone.fa-square-full:after,
.fad.fa-square-full:after {
  content: '\10f45c';
}
.fa-duotone.fa-square-g:after,
.fad.fa-square-g:after {
  content: '\10e26f';
}
.fa-duotone.fa-h-square:after,
.fa-duotone.fa-square-h:after,
.fad.fa-h-square:after,
.fad.fa-square-h:after {
  content: '\10f0fd';
}
.fa-duotone.fa-heart-square:after,
.fa-duotone.fa-square-heart:after,
.fad.fa-heart-square:after,
.fad.fa-square-heart:after {
  content: '\10f4c8';
}
.fa-duotone.fa-square-i:after,
.fad.fa-square-i:after {
  content: '\10e270';
}
.fa-duotone.fa-info-square:after,
.fa-duotone.fa-square-info:after,
.fad.fa-info-square:after,
.fad.fa-square-info:after {
  content: '\10f30f';
}
.fa-duotone.fa-square-j:after,
.fad.fa-square-j:after {
  content: '\10e271';
}
.fa-duotone.fa-square-k:after,
.fad.fa-square-k:after {
  content: '\10e272';
}
.fa-duotone.fa-square-l:after,
.fad.fa-square-l:after {
  content: '\10e273';
}
.fa-duotone.fa-arrow-alt-square-left:after,
.fa-duotone.fa-square-left:after,
.fad.fa-arrow-alt-square-left:after,
.fad.fa-square-left:after {
  content: '\10f351';
}
.fa-duotone.fa-square-m:after,
.fad.fa-square-m:after {
  content: '\10e274';
}
.fa-duotone.fa-minus-square:after,
.fa-duotone.fa-square-minus:after,
.fad.fa-minus-square:after,
.fad.fa-square-minus:after {
  content: '\10f146';
}
.fa-duotone.fa-square-n:after,
.fad.fa-square-n:after {
  content: '\10e275';
}
.fa-duotone.fa-square-o:after,
.fad.fa-square-o:after {
  content: '\10e276';
}
.fa-duotone.fa-square-p:after,
.fad.fa-square-p:after {
  content: '\10e277';
}
.fa-duotone.fa-parking:after,
.fa-duotone.fa-square-parking:after,
.fad.fa-parking:after,
.fad.fa-square-parking:after {
  content: '\10f540';
}
.fa-duotone.fa-parking-slash:after,
.fa-duotone.fa-square-parking-slash:after,
.fad.fa-parking-slash:after,
.fad.fa-square-parking-slash:after {
  content: '\10f617';
}
.fa-duotone.fa-pen-square:after,
.fa-duotone.fa-pencil-square:after,
.fa-duotone.fa-square-pen:after,
.fad.fa-pen-square:after,
.fad.fa-pencil-square:after,
.fad.fa-square-pen:after {
  content: '\10f14b';
}
.fa-duotone.fa-phone-square:after,
.fa-duotone.fa-square-phone:after,
.fad.fa-phone-square:after,
.fad.fa-square-phone:after {
  content: '\10f098';
}
.fa-duotone.fa-phone-square-alt:after,
.fa-duotone.fa-square-phone-flip:after,
.fad.fa-phone-square-alt:after,
.fad.fa-square-phone-flip:after {
  content: '\10f87b';
}
.fa-duotone.fa-phone-square-down:after,
.fa-duotone.fa-square-phone-hangup:after,
.fad.fa-phone-square-down:after,
.fad.fa-square-phone-hangup:after {
  content: '\10e278';
}
.fa-duotone.fa-plus-square:after,
.fa-duotone.fa-square-plus:after,
.fad.fa-plus-square:after,
.fad.fa-square-plus:after {
  content: '\10f0fe';
}
.fa-duotone.fa-poll-h:after,
.fa-duotone.fa-square-poll-horizontal:after,
.fad.fa-poll-h:after,
.fad.fa-square-poll-horizontal:after {
  content: '\10f682';
}
.fa-duotone.fa-poll:after,
.fa-duotone.fa-square-poll-vertical:after,
.fad.fa-poll:after,
.fad.fa-square-poll-vertical:after {
  content: '\10f681';
}
.fa-duotone.fa-square-q:after,
.fad.fa-square-q:after {
  content: '\10e279';
}
.fa-duotone.fa-question-square:after,
.fa-duotone.fa-square-question:after,
.fad.fa-question-square:after,
.fad.fa-square-question:after {
  content: '\10f2fd';
}
.fa-duotone.fa-square-r:after,
.fad.fa-square-r:after {
  content: '\10e27a';
}
.fa-duotone.fa-arrow-alt-square-right:after,
.fa-duotone.fa-square-right:after,
.fad.fa-arrow-alt-square-right:after,
.fad.fa-square-right:after {
  content: '\10f352';
}
.fa-duotone.fa-square-root:after,
.fad.fa-square-root:after {
  content: '\10f697';
}
.fa-duotone.fa-square-root-alt:after,
.fa-duotone.fa-square-root-variable:after,
.fad.fa-square-root-alt:after,
.fad.fa-square-root-variable:after {
  content: '\10f698';
}
.fa-duotone.fa-rss-square:after,
.fa-duotone.fa-square-rss:after,
.fad.fa-rss-square:after,
.fad.fa-square-rss:after {
  content: '\10f143';
}
.fa-duotone.fa-square-s:after,
.fad.fa-square-s:after {
  content: '\10e27b';
}
.fa-duotone.fa-share-alt-square:after,
.fa-duotone.fa-square-share-nodes:after,
.fad.fa-share-alt-square:after,
.fad.fa-square-share-nodes:after {
  content: '\10f1e1';
}
.fa-duotone.fa-sliders-h-square:after,
.fa-duotone.fa-square-sliders:after,
.fad.fa-sliders-h-square:after,
.fad.fa-square-sliders:after {
  content: '\10f3f0';
}
.fa-duotone.fa-sliders-v-square:after,
.fa-duotone.fa-square-sliders-vertical:after,
.fad.fa-sliders-v-square:after,
.fad.fa-square-sliders-vertical:after {
  content: '\10f3f2';
}
.fa-duotone.fa-square-small:after,
.fad.fa-square-small:after {
  content: '\10e27c';
}
.fa-duotone.fa-square-star:after,
.fad.fa-square-star:after {
  content: '\10e27d';
}
.fa-duotone.fa-square-t:after,
.fad.fa-square-t:after {
  content: '\10e27e';
}
.fa-duotone.fa-box-up:after,
.fa-duotone.fa-square-this-way-up:after,
.fad.fa-box-up:after,
.fad.fa-square-this-way-up:after {
  content: '\10f49f';
}
.fa-duotone.fa-square-u:after,
.fad.fa-square-u:after {
  content: '\10e27f';
}
.fa-duotone.fa-arrow-alt-square-up:after,
.fa-duotone.fa-square-up:after,
.fad.fa-arrow-alt-square-up:after,
.fad.fa-square-up:after {
  content: '\10f353';
}
.fa-duotone.fa-square-up-left:after,
.fad.fa-square-up-left:after {
  content: '\10e280';
}
.fa-duotone.fa-external-link-square-alt:after,
.fa-duotone.fa-square-up-right:after,
.fad.fa-external-link-square-alt:after,
.fad.fa-square-up-right:after {
  content: '\10f360';
}
.fa-duotone.fa-square-user:after,
.fad.fa-square-user:after {
  content: '\10e281';
}
.fa-duotone.fa-square-v:after,
.fad.fa-square-v:after {
  content: '\10e282';
}
.fa-duotone.fa-square-w:after,
.fad.fa-square-w:after {
  content: '\10e283';
}
.fa-duotone.fa-square-x:after,
.fad.fa-square-x:after {
  content: '\10e284';
}
.fa-duotone.fa-square-xmark:after,
.fa-duotone.fa-times-square:after,
.fa-duotone.fa-xmark-square:after,
.fad.fa-square-xmark:after,
.fad.fa-times-square:after,
.fad.fa-xmark-square:after {
  content: '\10f2d3';
}
.fa-duotone.fa-square-y:after,
.fad.fa-square-y:after {
  content: '\10e285';
}
.fa-duotone.fa-square-z:after,
.fad.fa-square-z:after {
  content: '\10e286';
}
.fa-duotone.fa-squirrel:after,
.fad.fa-squirrel:after {
  content: '\10f71a';
}
.fa-duotone.fa-staff:after,
.fad.fa-staff:after {
  content: '\10f71b';
}
.fa-duotone.fa-stairs:after,
.fad.fa-stairs:after {
  content: '\10e287';
}
.fa-duotone.fa-stamp:after,
.fad.fa-stamp:after {
  content: '\10f5bf';
}
.fa-duotone.fa-standard-definition:after,
.fad.fa-standard-definition:after {
  content: '\10e288';
}
.fa-duotone.fa-star:after,
.fad.fa-star:after {
  content: '\10f005';
}
.fa-duotone.fa-star-and-crescent:after,
.fad.fa-star-and-crescent:after {
  content: '\10f699';
}
.fa-duotone.fa-star-christmas:after,
.fad.fa-star-christmas:after {
  content: '\10f7d4';
}
.fa-duotone.fa-star-exclamation:after,
.fad.fa-star-exclamation:after {
  content: '\10f2f3';
}
.fa-duotone.fa-star-half:after,
.fad.fa-star-half:after {
  content: '\10f089';
}
.fa-duotone.fa-star-half-alt:after,
.fa-duotone.fa-star-half-stroke:after,
.fad.fa-star-half-alt:after,
.fad.fa-star-half-stroke:after {
  content: '\10f5c0';
}
.fa-duotone.fa-star-of-david:after,
.fad.fa-star-of-david:after {
  content: '\10f69a';
}
.fa-duotone.fa-star-of-life:after,
.fad.fa-star-of-life:after {
  content: '\10f621';
}
.fa-duotone.fa-star-sharp:after,
.fad.fa-star-sharp:after {
  content: '\10e289';
}
.fa-duotone.fa-star-sharp-half:after,
.fad.fa-star-sharp-half:after {
  content: '\10e28a';
}
.fa-duotone.fa-star-sharp-half-alt:after,
.fa-duotone.fa-star-sharp-half-stroke:after,
.fad.fa-star-sharp-half-alt:after,
.fad.fa-star-sharp-half-stroke:after {
  content: '\10e28b';
}
.fa-duotone.fa-star-shooting:after,
.fad.fa-star-shooting:after {
  content: '\10e036';
}
.fa-duotone.fa-starfighter:after,
.fad.fa-starfighter:after {
  content: '\10e037';
}
.fa-duotone.fa-starfighter-alt:after,
.fa-duotone.fa-starfighter-twin-ion-engine:after,
.fad.fa-starfighter-alt:after,
.fad.fa-starfighter-twin-ion-engine:after {
  content: '\10e038';
}
.fa-duotone.fa-starfighter-alt-advanced:after,
.fa-duotone.fa-starfighter-twin-ion-engine-advanced:after,
.fad.fa-starfighter-alt-advanced:after,
.fad.fa-starfighter-twin-ion-engine-advanced:after {
  content: '\10e28c';
}
.fa-duotone.fa-stars:after,
.fad.fa-stars:after {
  content: '\10f762';
}
.fa-duotone.fa-starship:after,
.fad.fa-starship:after {
  content: '\10e039';
}
.fa-duotone.fa-starship-freighter:after,
.fad.fa-starship-freighter:after {
  content: '\10e03a';
}
.fa-duotone.fa-steak:after,
.fad.fa-steak:after {
  content: '\10f824';
}
.fa-duotone.fa-steering-wheel:after,
.fad.fa-steering-wheel:after {
  content: '\10f622';
}
.fa-duotone.fa-gbp:after,
.fa-duotone.fa-pound-sign:after,
.fa-duotone.fa-sterling-sign:after,
.fad.fa-gbp:after,
.fad.fa-pound-sign:after,
.fad.fa-sterling-sign:after {
  content: '\10f154';
}
.fa-duotone.fa-stethoscope:after,
.fad.fa-stethoscope:after {
  content: '\10f0f1';
}
.fa-duotone.fa-stocking:after,
.fad.fa-stocking:after {
  content: '\10f7d5';
}
.fa-duotone.fa-stomach:after,
.fad.fa-stomach:after {
  content: '\10f623';
}
.fa-duotone.fa-stop:after,
.fad.fa-stop:after {
  content: '\10f04d';
}
.fa-duotone.fa-stopwatch:after,
.fad.fa-stopwatch:after {
  content: '\10f2f2';
}
.fa-duotone.fa-stopwatch-20:after,
.fad.fa-stopwatch-20:after {
  content: '\10e06f';
}
.fa-duotone.fa-store:after,
.fad.fa-store:after {
  content: '\10f54e';
}
.fa-duotone.fa-store-slash:after,
.fad.fa-store-slash:after {
  content: '\10e071';
}
.fa-duotone.fa-street-view:after,
.fad.fa-street-view:after {
  content: '\10f21d';
}
.fa-duotone.fa-stretcher:after,
.fad.fa-stretcher:after {
  content: '\10f825';
}
.fa-duotone.fa-strikethrough:after,
.fad.fa-strikethrough:after {
  content: '\10f0cc';
}
.fa-duotone.fa-stroopwafel:after,
.fad.fa-stroopwafel:after {
  content: '\10f551';
}
.fa-duotone.fa-subscript:after,
.fad.fa-subscript:after {
  content: '\10f12c';
}
.fa-duotone.fa-suitcase:after,
.fad.fa-suitcase:after {
  content: '\10f0f2';
}
.fa-duotone.fa-medkit:after,
.fa-duotone.fa-suitcase-medical:after,
.fad.fa-medkit:after,
.fad.fa-suitcase-medical:after {
  content: '\10f0fa';
}
.fa-duotone.fa-suitcase-rolling:after,
.fad.fa-suitcase-rolling:after {
  content: '\10f5c1';
}
.fa-duotone.fa-sun:after,
.fad.fa-sun:after {
  content: '\10f185';
}
.fa-duotone.fa-sun-alt:after,
.fa-duotone.fa-sun-bright:after,
.fad.fa-sun-alt:after,
.fad.fa-sun-bright:after {
  content: '\10e28d';
}
.fa-duotone.fa-sun-cloud:after,
.fad.fa-sun-cloud:after {
  content: '\10f763';
}
.fa-duotone.fa-sun-dust:after,
.fad.fa-sun-dust:after {
  content: '\10f764';
}
.fa-duotone.fa-sun-haze:after,
.fad.fa-sun-haze:after {
  content: '\10f765';
}
.fa-duotone.fa-sunglasses:after,
.fad.fa-sunglasses:after {
  content: '\10f892';
}
.fa-duotone.fa-sunrise:after,
.fad.fa-sunrise:after {
  content: '\10f766';
}
.fa-duotone.fa-sunset:after,
.fad.fa-sunset:after {
  content: '\10f767';
}
.fa-duotone.fa-superscript:after,
.fad.fa-superscript:after {
  content: '\10f12b';
}
.fa-duotone.fa-swatchbook:after,
.fad.fa-swatchbook:after {
  content: '\10f5c3';
}
.fa-duotone.fa-sword:after,
.fad.fa-sword:after {
  content: '\10f71c';
}
.fa-duotone.fa-sword-laser:after,
.fad.fa-sword-laser:after {
  content: '\10e03b';
}
.fa-duotone.fa-sword-laser-alt:after,
.fad.fa-sword-laser-alt:after {
  content: '\10e03c';
}
.fa-duotone.fa-swords:after,
.fad.fa-swords:after {
  content: '\10f71d';
}
.fa-duotone.fa-swords-laser:after,
.fad.fa-swords-laser:after {
  content: '\10e03d';
}
.fa-duotone.fa-icons-alt:after,
.fa-duotone.fa-symbols:after,
.fad.fa-icons-alt:after,
.fad.fa-symbols:after {
  content: '\10f86e';
}
.fa-duotone.fa-synagogue:after,
.fad.fa-synagogue:after {
  content: '\10f69b';
}
.fa-duotone.fa-syringe:after,
.fad.fa-syringe:after {
  content: '\10f48e';
}
.fa-duotone.fa-t:after,
.fad.fa-t:after {
  content: '\10e28e';
}
.fa-duotone.fa-table:after,
.fad.fa-table:after {
  content: '\10f0ce';
}
.fa-duotone.fa-table-cells:after,
.fa-duotone.fa-th:after,
.fad.fa-table-cells:after,
.fad.fa-th:after {
  content: '\10f00a';
}
.fa-duotone.fa-table-cells-large:after,
.fa-duotone.fa-th-large:after,
.fad.fa-table-cells-large:after,
.fad.fa-th-large:after {
  content: '\10f009';
}
.fa-duotone.fa-columns:after,
.fa-duotone.fa-table-columns:after,
.fad.fa-columns:after,
.fad.fa-table-columns:after {
  content: '\10f0db';
}
.fa-duotone.fa-table-layout:after,
.fad.fa-table-layout:after {
  content: '\10e28f';
}
.fa-duotone.fa-table-list:after,
.fa-duotone.fa-th-list:after,
.fad.fa-table-list:after,
.fad.fa-th-list:after {
  content: '\10f00b';
}
.fa-duotone.fa-table-pivot:after,
.fad.fa-table-pivot:after {
  content: '\10e290';
}
.fa-duotone.fa-rows:after,
.fa-duotone.fa-table-rows:after,
.fad.fa-rows:after,
.fad.fa-table-rows:after {
  content: '\10e291';
}
.fa-duotone.fa-ping-pong-paddle-ball:after,
.fa-duotone.fa-table-tennis-paddle-ball:after,
.fa-duotone.fa-table-tennis:after,
.fad.fa-ping-pong-paddle-ball:after,
.fad.fa-table-tennis-paddle-ball:after,
.fad.fa-table-tennis:after {
  content: '\10f45d';
}
.fa-duotone.fa-table-tree:after,
.fad.fa-table-tree:after {
  content: '\10e292';
}
.fa-duotone.fa-tablet-android:after,
.fa-duotone.fa-tablet:after,
.fad.fa-tablet-android:after,
.fad.fa-tablet:after {
  content: '\10f3fb';
}
.fa-duotone.fa-tablet-button:after,
.fad.fa-tablet-button:after {
  content: '\10f10a';
}
.fa-duotone.fa-tablet-rugged:after,
.fad.fa-tablet-rugged:after {
  content: '\10f48f';
}
.fa-duotone.fa-tablet-android-alt:after,
.fa-duotone.fa-tablet-screen:after,
.fad.fa-tablet-android-alt:after,
.fad.fa-tablet-screen:after {
  content: '\10f3fc';
}
.fa-duotone.fa-tablet-alt:after,
.fa-duotone.fa-tablet-screen-button:after,
.fad.fa-tablet-alt:after,
.fad.fa-tablet-screen-button:after {
  content: '\10f3fa';
}
.fa-duotone.fa-tablets:after,
.fad.fa-tablets:after {
  content: '\10f490';
}
.fa-duotone.fa-digital-tachograph:after,
.fa-duotone.fa-tachograph-digital:after,
.fad.fa-digital-tachograph:after,
.fad.fa-tachograph-digital:after {
  content: '\10f566';
}
.fa-duotone.fa-taco:after,
.fad.fa-taco:after {
  content: '\10f826';
}
.fa-duotone.fa-tag:after,
.fad.fa-tag:after {
  content: '\10f02b';
}
.fa-duotone.fa-tags:after,
.fad.fa-tags:after {
  content: '\10f02c';
}
.fa-duotone.fa-tally-5:after,
.fa-duotone.fa-tally:after,
.fad.fa-tally-5:after,
.fad.fa-tally:after {
  content: '\10f69c';
}
.fa-duotone.fa-tally-1:after,
.fad.fa-tally-1:after {
  content: '\10e293';
}
.fa-duotone.fa-tally-2:after,
.fad.fa-tally-2:after {
  content: '\10e294';
}
.fa-duotone.fa-tally-3:after,
.fad.fa-tally-3:after {
  content: '\10e295';
}
.fa-duotone.fa-tally-4:after,
.fad.fa-tally-4:after {
  content: '\10e296';
}
.fa-duotone.fa-tape:after,
.fad.fa-tape:after {
  content: '\10f4db';
}
.fa-duotone.fa-cab:after,
.fa-duotone.fa-taxi:after,
.fad.fa-cab:after,
.fad.fa-taxi:after {
  content: '\10f1ba';
}
.fa-duotone.fa-taxi-bus:after,
.fad.fa-taxi-bus:after {
  content: '\10e297';
}
.fa-duotone.fa-teeth:after,
.fad.fa-teeth:after {
  content: '\10f62e';
}
.fa-duotone.fa-teeth-open:after,
.fad.fa-teeth-open:after {
  content: '\10f62f';
}
.fa-duotone.fa-telescope:after,
.fad.fa-telescope:after {
  content: '\10e03e';
}
.fa-duotone.fa-temperature-arrow-down:after,
.fa-duotone.fa-temperature-down:after,
.fad.fa-temperature-arrow-down:after,
.fad.fa-temperature-down:after {
  content: '\10e03f';
}
.fa-duotone.fa-temperature-arrow-up:after,
.fa-duotone.fa-temperature-up:after,
.fad.fa-temperature-arrow-up:after,
.fad.fa-temperature-up:after {
  content: '\10e040';
}
.fa-duotone.fa-temperature-0:after,
.fa-duotone.fa-temperature-empty:after,
.fa-duotone.fa-thermometer-0:after,
.fa-duotone.fa-thermometer-empty:after,
.fad.fa-temperature-0:after,
.fad.fa-temperature-empty:after,
.fad.fa-thermometer-0:after,
.fad.fa-thermometer-empty:after {
  content: '\10f2cb';
}
.fa-duotone.fa-temperature-4:after,
.fa-duotone.fa-temperature-full:after,
.fa-duotone.fa-thermometer-4:after,
.fa-duotone.fa-thermometer-full:after,
.fad.fa-temperature-4:after,
.fad.fa-temperature-full:after,
.fad.fa-thermometer-4:after,
.fad.fa-thermometer-full:after {
  content: '\10f2c7';
}
.fa-duotone.fa-temperature-2:after,
.fa-duotone.fa-temperature-half:after,
.fa-duotone.fa-thermometer-2:after,
.fa-duotone.fa-thermometer-half:after,
.fad.fa-temperature-2:after,
.fad.fa-temperature-half:after,
.fad.fa-thermometer-2:after,
.fad.fa-thermometer-half:after {
  content: '\10f2c9';
}
.fa-duotone.fa-temperature-high:after,
.fad.fa-temperature-high:after {
  content: '\10f769';
}
.fa-duotone.fa-temperature-list:after,
.fad.fa-temperature-list:after {
  content: '\10e298';
}
.fa-duotone.fa-temperature-low:after,
.fad.fa-temperature-low:after {
  content: '\10f76b';
}
.fa-duotone.fa-temperature-1:after,
.fa-duotone.fa-temperature-quarter:after,
.fa-duotone.fa-thermometer-1:after,
.fa-duotone.fa-thermometer-quarter:after,
.fad.fa-temperature-1:after,
.fad.fa-temperature-quarter:after,
.fad.fa-thermometer-1:after,
.fad.fa-thermometer-quarter:after {
  content: '\10f2ca';
}
.fa-duotone.fa-temperature-frigid:after,
.fa-duotone.fa-temperature-snow:after,
.fad.fa-temperature-frigid:after,
.fad.fa-temperature-snow:after {
  content: '\10f768';
}
.fa-duotone.fa-temperature-hot:after,
.fa-duotone.fa-temperature-sun:after,
.fad.fa-temperature-hot:after,
.fad.fa-temperature-sun:after {
  content: '\10f76a';
}
.fa-duotone.fa-temperature-3:after,
.fa-duotone.fa-temperature-three-quarters:after,
.fa-duotone.fa-thermometer-3:after,
.fa-duotone.fa-thermometer-three-quarters:after,
.fad.fa-temperature-3:after,
.fad.fa-temperature-three-quarters:after,
.fad.fa-thermometer-3:after,
.fad.fa-thermometer-three-quarters:after {
  content: '\10f2c8';
}
.fa-duotone.fa-tenge-sign:after,
.fa-duotone.fa-tenge:after,
.fad.fa-tenge-sign:after,
.fad.fa-tenge:after {
  content: '\10f7d7';
}
.fa-duotone.fa-tennis-ball:after,
.fad.fa-tennis-ball:after {
  content: '\10f45e';
}
.fa-duotone.fa-terminal:after,
.fad.fa-terminal:after {
  content: '\10f120';
}
.fa-duotone.fa-text:after,
.fad.fa-text:after {
  content: '\10f893';
}
.fa-duotone.fa-text-height:after,
.fad.fa-text-height:after {
  content: '\10f034';
}
.fa-duotone.fa-text-size:after,
.fad.fa-text-size:after {
  content: '\10f894';
}
.fa-duotone.fa-remove-format:after,
.fa-duotone.fa-text-slash:after,
.fad.fa-remove-format:after,
.fad.fa-text-slash:after {
  content: '\10f87d';
}
.fa-duotone.fa-text-width:after,
.fad.fa-text-width:after {
  content: '\10f035';
}
.fa-duotone.fa-thermometer:after,
.fad.fa-thermometer:after {
  content: '\10f491';
}
.fa-duotone.fa-theta:after,
.fad.fa-theta:after {
  content: '\10f69e';
}
.fa-duotone.fa-thumbs-down:after,
.fad.fa-thumbs-down:after {
  content: '\10f165';
}
.fa-duotone.fa-thumbs-up:after,
.fad.fa-thumbs-up:after {
  content: '\10f164';
}
.fa-duotone.fa-thumb-tack:after,
.fa-duotone.fa-thumbtack:after,
.fad.fa-thumb-tack:after,
.fad.fa-thumbtack:after {
  content: '\10f08d';
}
.fa-duotone.fa-ticket:after,
.fad.fa-ticket:after {
  content: '\10f145';
}
.fa-duotone.fa-ticket-airline:after,
.fad.fa-ticket-airline:after {
  content: '\10e299';
}
.fa-duotone.fa-ticket-alt:after,
.fa-duotone.fa-ticket-simple:after,
.fad.fa-ticket-alt:after,
.fad.fa-ticket-simple:after {
  content: '\10f3ff';
}
.fa-duotone.fa-tickets-airline:after,
.fad.fa-tickets-airline:after {
  content: '\10e29a';
}
.fa-duotone.fa-tilde:after,
.fad.fa-tilde:after {
  content: '\10f69f';
}
.fa-duotone.fa-timeline:after,
.fad.fa-timeline:after {
  content: '\10e29b';
}
.fa-duotone.fa-timeline-arrow:after,
.fad.fa-timeline-arrow:after {
  content: '\10e29c';
}
.fa-duotone.fa-timer:after,
.fad.fa-timer:after {
  content: '\10e29d';
}
.fa-duotone.fa-tire:after,
.fad.fa-tire:after {
  content: '\10f631';
}
.fa-duotone.fa-tire-flat:after,
.fad.fa-tire-flat:after {
  content: '\10f632';
}
.fa-duotone.fa-tire-pressure-warning:after,
.fad.fa-tire-pressure-warning:after {
  content: '\10f633';
}
.fa-duotone.fa-tire-rugged:after,
.fad.fa-tire-rugged:after {
  content: '\10f634';
}
.fa-duotone.fa-toggle-off:after,
.fad.fa-toggle-off:after {
  content: '\10f204';
}
.fa-duotone.fa-toggle-on:after,
.fad.fa-toggle-on:after {
  content: '\10f205';
}
.fa-duotone.fa-toilet:after,
.fad.fa-toilet:after {
  content: '\10f7d8';
}
.fa-duotone.fa-toilet-paper:after,
.fad.fa-toilet-paper:after {
  content: '\10f71e';
}
.fa-duotone.fa-toilet-paper-alt:after,
.fa-duotone.fa-toilet-paper-blank:after,
.fad.fa-toilet-paper-alt:after,
.fad.fa-toilet-paper-blank:after {
  content: '\10f71f';
}
.fa-duotone.fa-toilet-paper-blank-under:after,
.fa-duotone.fa-toilet-paper-reverse-alt:after,
.fad.fa-toilet-paper-blank-under:after,
.fad.fa-toilet-paper-reverse-alt:after {
  content: '\10e29e';
}
.fa-duotone.fa-toilet-paper-slash:after,
.fad.fa-toilet-paper-slash:after {
  content: '\10e072';
}
.fa-duotone.fa-toilet-paper-reverse:after,
.fa-duotone.fa-toilet-paper-under:after,
.fad.fa-toilet-paper-reverse:after,
.fad.fa-toilet-paper-under:after {
  content: '\10e29f';
}
.fa-duotone.fa-toilet-paper-reverse-slash:after,
.fa-duotone.fa-toilet-paper-under-slash:after,
.fad.fa-toilet-paper-reverse-slash:after,
.fad.fa-toilet-paper-under-slash:after {
  content: '\10e2a0';
}
.fa-duotone.fa-tombstone:after,
.fad.fa-tombstone:after {
  content: '\10f720';
}
.fa-duotone.fa-tombstone-alt:after,
.fa-duotone.fa-tombstone-blank:after,
.fad.fa-tombstone-alt:after,
.fad.fa-tombstone-blank:after {
  content: '\10f721';
}
.fa-duotone.fa-toolbox:after,
.fad.fa-toolbox:after {
  content: '\10f552';
}
.fa-duotone.fa-tooth:after,
.fad.fa-tooth:after {
  content: '\10f5c9';
}
.fa-duotone.fa-toothbrush:after,
.fad.fa-toothbrush:after {
  content: '\10f635';
}
.fa-duotone.fa-torii-gate:after,
.fad.fa-torii-gate:after {
  content: '\10f6a1';
}
.fa-duotone.fa-tornado:after,
.fad.fa-tornado:after {
  content: '\10f76f';
}
.fa-duotone.fa-broadcast-tower:after,
.fa-duotone.fa-tower-broadcast:after,
.fad.fa-broadcast-tower:after,
.fad.fa-tower-broadcast:after {
  content: '\10f519';
}
.fa-duotone.fa-tower-control:after,
.fad.fa-tower-control:after {
  content: '\10e2a1';
}
.fa-duotone.fa-tractor:after,
.fad.fa-tractor:after {
  content: '\10f722';
}
.fa-duotone.fa-trademark:after,
.fad.fa-trademark:after {
  content: '\10f25c';
}
.fa-duotone.fa-traffic-cone:after,
.fad.fa-traffic-cone:after {
  content: '\10f636';
}
.fa-duotone.fa-traffic-light:after,
.fad.fa-traffic-light:after {
  content: '\10f637';
}
.fa-duotone.fa-traffic-light-go:after,
.fad.fa-traffic-light-go:after {
  content: '\10f638';
}
.fa-duotone.fa-traffic-light-slow:after,
.fad.fa-traffic-light-slow:after {
  content: '\10f639';
}
.fa-duotone.fa-traffic-light-stop:after,
.fad.fa-traffic-light-stop:after {
  content: '\10f63a';
}
.fa-duotone.fa-trailer:after,
.fad.fa-trailer:after {
  content: '\10e041';
}
.fa-duotone.fa-train:after,
.fad.fa-train:after {
  content: '\10f238';
}
.fa-duotone.fa-subway:after,
.fa-duotone.fa-train-subway:after,
.fad.fa-subway:after,
.fad.fa-train-subway:after {
  content: '\10f239';
}
.fa-duotone.fa-subway-tunnel:after,
.fa-duotone.fa-train-subway-tunnel:after,
.fad.fa-subway-tunnel:after,
.fad.fa-train-subway-tunnel:after {
  content: '\10e2a2';
}
.fa-duotone.fa-train-tram:after,
.fa-duotone.fa-tram:after,
.fad.fa-train-tram:after,
.fad.fa-tram:after {
  content: '\10f7da';
}
.fa-duotone.fa-transformer-bolt:after,
.fad.fa-transformer-bolt:after {
  content: '\10e2a3';
}
.fa-duotone.fa-transgender:after,
.fad.fa-transgender:after {
  content: '\10f224';
}
.fa-duotone.fa-transgender-alt:after,
.fad.fa-transgender-alt:after {
  content: '\10f225';
}
.fa-duotone.fa-transporter:after,
.fad.fa-transporter:after {
  content: '\10e042';
}
.fa-duotone.fa-transporter-1:after,
.fad.fa-transporter-1:after {
  content: '\10e043';
}
.fa-duotone.fa-transporter-2:after,
.fad.fa-transporter-2:after {
  content: '\10e044';
}
.fa-duotone.fa-transporter-3:after,
.fad.fa-transporter-3:after {
  content: '\10e045';
}
.fa-duotone.fa-transporter-4:after,
.fad.fa-transporter-4:after {
  content: '\10e2a4';
}
.fa-duotone.fa-transporter-5:after,
.fad.fa-transporter-5:after {
  content: '\10e2a5';
}
.fa-duotone.fa-transporter-6:after,
.fad.fa-transporter-6:after {
  content: '\10e2a6';
}
.fa-duotone.fa-transporter-7:after,
.fad.fa-transporter-7:after {
  content: '\10e2a7';
}
.fa-duotone.fa-transporter-empty:after,
.fad.fa-transporter-empty:after {
  content: '\10e046';
}
.fa-duotone.fa-trash:after,
.fad.fa-trash:after {
  content: '\10f1f8';
}
.fa-duotone.fa-trash-arrow-up:after,
.fa-duotone.fa-trash-restore:after,
.fad.fa-trash-arrow-up:after,
.fad.fa-trash-restore:after {
  content: '\10f829';
}
.fa-duotone.fa-trash-alt:after,
.fa-duotone.fa-trash-can:after,
.fad.fa-trash-alt:after,
.fad.fa-trash-can:after {
  content: '\10f2ed';
}
.fa-duotone.fa-trash-can-arrow-up:after,
.fa-duotone.fa-trash-restore-alt:after,
.fad.fa-trash-can-arrow-up:after,
.fad.fa-trash-restore-alt:after {
  content: '\10f82a';
}
.fa-duotone.fa-trash-can-clock:after,
.fad.fa-trash-can-clock:after {
  content: '\10e2a8';
}
.fa-duotone.fa-trash-can-list:after,
.fad.fa-trash-can-list:after {
  content: '\10e2a9';
}
.fa-duotone.fa-trash-alt-slash:after,
.fa-duotone.fa-trash-can-slash:after,
.fad.fa-trash-alt-slash:after,
.fad.fa-trash-can-slash:after {
  content: '\10e2aa';
}
.fa-duotone.fa-trash-can-arrow-turn-left:after,
.fa-duotone.fa-trash-can-undo:after,
.fa-duotone.fa-trash-undo-alt:after,
.fad.fa-trash-can-arrow-turn-left:after,
.fad.fa-trash-can-undo:after,
.fad.fa-trash-undo-alt:after {
  content: '\10f896';
}
.fa-duotone.fa-trash-clock:after,
.fad.fa-trash-clock:after {
  content: '\10e2ab';
}
.fa-duotone.fa-trash-list:after,
.fad.fa-trash-list:after {
  content: '\10e2ac';
}
.fa-duotone.fa-trash-slash:after,
.fad.fa-trash-slash:after {
  content: '\10e2ad';
}
.fa-duotone.fa-trash-arrow-turn-left:after,
.fa-duotone.fa-trash-undo:after,
.fad.fa-trash-arrow-turn-left:after,
.fad.fa-trash-undo:after {
  content: '\10f895';
}
.fa-duotone.fa-treasure-chest:after,
.fad.fa-treasure-chest:after {
  content: '\10f723';
}
.fa-duotone.fa-tree:after,
.fad.fa-tree:after {
  content: '\10f1bb';
}
.fa-duotone.fa-tree-christmas:after,
.fad.fa-tree-christmas:after {
  content: '\10f7db';
}
.fa-duotone.fa-tree-alt:after,
.fa-duotone.fa-tree-deciduous:after,
.fad.fa-tree-alt:after,
.fad.fa-tree-deciduous:after {
  content: '\10f400';
}
.fa-duotone.fa-tree-decorated:after,
.fad.fa-tree-decorated:after {
  content: '\10f7dc';
}
.fa-duotone.fa-tree-large:after,
.fad.fa-tree-large:after {
  content: '\10f7dd';
}
.fa-duotone.fa-tree-palm:after,
.fad.fa-tree-palm:after {
  content: '\10f82b';
}
.fa-duotone.fa-trees:after,
.fad.fa-trees:after {
  content: '\10f724';
}
.fa-duotone.fa-triangle:after,
.fad.fa-triangle:after {
  content: '\10f2ec';
}
.fa-duotone.fa-exclamation-triangle:after,
.fa-duotone.fa-triangle-exclamation:after,
.fa-duotone.fa-warning:after,
.fad.fa-exclamation-triangle:after,
.fad.fa-triangle-exclamation:after,
.fad.fa-warning:after {
  content: '\10f071';
}
.fa-duotone.fa-triangle-instrument:after,
.fa-duotone.fa-triangle-music:after,
.fad.fa-triangle-instrument:after,
.fad.fa-triangle-music:after {
  content: '\10f8e2';
}
.fa-duotone.fa-construction:after,
.fa-duotone.fa-triangle-person-digging:after,
.fad.fa-construction:after,
.fad.fa-triangle-person-digging:after {
  content: '\10f85d';
}
.fa-duotone.fa-trophy:after,
.fad.fa-trophy:after {
  content: '\10f091';
}
.fa-duotone.fa-trophy-alt:after,
.fa-duotone.fa-trophy-star:after,
.fad.fa-trophy-alt:after,
.fad.fa-trophy-star:after {
  content: '\10f2eb';
}
.fa-duotone.fa-truck:after,
.fad.fa-truck:after {
  content: '\10f0d1';
}
.fa-duotone.fa-shipping-timed:after,
.fa-duotone.fa-truck-clock:after,
.fad.fa-shipping-timed:after,
.fad.fa-truck-clock:after {
  content: '\10f48c';
}
.fa-duotone.fa-truck-container:after,
.fad.fa-truck-container:after {
  content: '\10f4dc';
}
.fa-duotone.fa-truck-container-empty:after,
.fad.fa-truck-container-empty:after {
  content: '\10e2ae';
}
.fa-duotone.fa-shipping-fast:after,
.fa-duotone.fa-truck-fast:after,
.fad.fa-shipping-fast:after,
.fad.fa-truck-fast:after {
  content: '\10f48b';
}
.fa-duotone.fa-truck-flatbed:after,
.fad.fa-truck-flatbed:after {
  content: '\10e2af';
}
.fa-duotone.fa-truck-front:after,
.fad.fa-truck-front:after {
  content: '\10e2b0';
}
.fa-duotone.fa-ambulance:after,
.fa-duotone.fa-truck-medical:after,
.fad.fa-ambulance:after,
.fad.fa-truck-medical:after {
  content: '\10f0f9';
}
.fa-duotone.fa-truck-monster:after,
.fad.fa-truck-monster:after {
  content: '\10f63b';
}
.fa-duotone.fa-truck-moving:after,
.fad.fa-truck-moving:after {
  content: '\10f4df';
}
.fa-duotone.fa-truck-pickup:after,
.fad.fa-truck-pickup:after {
  content: '\10f63c';
}
.fa-duotone.fa-truck-plow:after,
.fad.fa-truck-plow:after {
  content: '\10f7de';
}
.fa-duotone.fa-truck-ramp:after,
.fad.fa-truck-ramp:after {
  content: '\10f4e0';
}
.fa-duotone.fa-truck-loading:after,
.fa-duotone.fa-truck-ramp-box:after,
.fad.fa-truck-loading:after,
.fad.fa-truck-ramp-box:after {
  content: '\10f4de';
}
.fa-duotone.fa-truck-couch:after,
.fa-duotone.fa-truck-ramp-couch:after,
.fad.fa-truck-couch:after,
.fad.fa-truck-ramp-couch:after {
  content: '\10f4dd';
}
.fa-duotone.fa-truck-tow:after,
.fad.fa-truck-tow:after {
  content: '\10e2b1';
}
.fa-duotone.fa-trumpet:after,
.fad.fa-trumpet:after {
  content: '\10f8e3';
}
.fa-duotone.fa-tshirt:after,
.fad.fa-tshirt:after {
  content: '\10f553';
}
.fa-duotone.fa-teletype:after,
.fa-duotone.fa-tty:after,
.fad.fa-teletype:after,
.fad.fa-tty:after {
  content: '\10f1e4';
}
.fa-duotone.fa-teletype-answer:after,
.fa-duotone.fa-tty-answer:after,
.fad.fa-teletype-answer:after,
.fad.fa-tty-answer:after {
  content: '\10e2b2';
}
.fa-duotone.fa-tugrik-sign:after,
.fad.fa-tugrik-sign:after {
  content: '\10e2b3';
}
.fa-duotone.fa-turkey:after,
.fad.fa-turkey:after {
  content: '\10f725';
}
.fa-duotone.fa-try:after,
.fa-duotone.fa-turkish-lira-sign:after,
.fa-duotone.fa-turkish-lira:after,
.fad.fa-try:after,
.fad.fa-turkish-lira-sign:after,
.fad.fa-turkish-lira:after {
  content: '\10e2b4';
}
.fa-duotone.fa-level-down-alt:after,
.fa-duotone.fa-turn-down:after,
.fad.fa-level-down-alt:after,
.fad.fa-turn-down:after {
  content: '\10f3be';
}
.fa-duotone.fa-level-up-alt:after,
.fa-duotone.fa-turn-up:after,
.fad.fa-level-up-alt:after,
.fad.fa-turn-up:after {
  content: '\10f3bf';
}
.fa-duotone.fa-turntable:after,
.fad.fa-turntable:after {
  content: '\10f8e4';
}
.fa-duotone.fa-turtle:after,
.fad.fa-turtle:after {
  content: '\10f726';
}
.fa-duotone.fa-television:after,
.fa-duotone.fa-tv-alt:after,
.fa-duotone.fa-tv:after,
.fad.fa-television:after,
.fad.fa-tv-alt:after,
.fad.fa-tv:after {
  content: '\10f26c';
}
.fa-duotone.fa-tv-music:after,
.fad.fa-tv-music:after {
  content: '\10f8e6';
}
.fa-duotone.fa-tv-retro:after,
.fad.fa-tv-retro:after {
  content: '\10f401';
}
.fa-duotone.fa-typewriter:after,
.fad.fa-typewriter:after {
  content: '\10f8e7';
}
.fa-duotone.fa-u:after,
.fad.fa-u:after {
  content: '\10e2b5';
}
.fa-duotone.fa-ufo:after,
.fad.fa-ufo:after {
  content: '\10e047';
}
.fa-duotone.fa-ufo-beam:after,
.fad.fa-ufo-beam:after {
  content: '\10e048';
}
.fa-duotone.fa-umbrella:after,
.fad.fa-umbrella:after {
  content: '\10f0e9';
}
.fa-duotone.fa-umbrella-beach:after,
.fad.fa-umbrella-beach:after {
  content: '\10f5ca';
}
.fa-duotone.fa-umbrella-alt:after,
.fa-duotone.fa-umbrella-simple:after,
.fad.fa-umbrella-alt:after,
.fad.fa-umbrella-simple:after {
  content: '\10e2b6';
}
.fa-duotone.fa-underline:after,
.fad.fa-underline:after {
  content: '\10f0cd';
}
.fa-duotone.fa-unicorn:after,
.fad.fa-unicorn:after {
  content: '\10f727';
}
.fa-duotone.fa-union:after,
.fad.fa-union:after {
  content: '\10f6a2';
}
.fa-duotone.fa-universal-access:after,
.fad.fa-universal-access:after {
  content: '\10f29a';
}
.fa-duotone.fa-unlock:after,
.fad.fa-unlock:after {
  content: '\10f09c';
}
.fa-duotone.fa-unlock-alt:after,
.fa-duotone.fa-unlock-keyhole:after,
.fad.fa-unlock-alt:after,
.fad.fa-unlock-keyhole:after {
  content: '\10f13e';
}
.fa-duotone.fa-arrow-alt-up:after,
.fa-duotone.fa-up:after,
.fad.fa-arrow-alt-up:after,
.fad.fa-up:after {
  content: '\10f357';
}
.fa-duotone.fa-arrows-alt-v:after,
.fa-duotone.fa-up-down:after,
.fad.fa-arrows-alt-v:after,
.fad.fa-up-down:after {
  content: '\10f338';
}
.fa-duotone.fa-arrows-alt:after,
.fa-duotone.fa-up-down-left-right:after,
.fad.fa-arrows-alt:after,
.fad.fa-up-down-left-right:after {
  content: '\10f0b2';
}
.fa-duotone.fa-arrow-alt-from-bottom:after,
.fa-duotone.fa-up-from-line:after,
.fad.fa-arrow-alt-from-bottom:after,
.fad.fa-up-from-line:after {
  content: '\10f346';
}
.fa-duotone.fa-up-left:after,
.fad.fa-up-left:after {
  content: '\10e2b7';
}
.fa-duotone.fa-long-arrow-alt-up:after,
.fa-duotone.fa-up-long:after,
.fad.fa-long-arrow-alt-up:after,
.fad.fa-up-long:after {
  content: '\10f30c';
}
.fa-duotone.fa-up-right:after,
.fad.fa-up-right:after {
  content: '\10e2b8';
}
.fa-duotone.fa-expand-alt:after,
.fa-duotone.fa-up-right-and-down-left-from-center:after,
.fad.fa-expand-alt:after,
.fad.fa-up-right-and-down-left-from-center:after {
  content: '\10f424';
}
.fa-duotone.fa-external-link-alt:after,
.fa-duotone.fa-up-right-from-square:after,
.fad.fa-external-link-alt:after,
.fad.fa-up-right-from-square:after {
  content: '\10f35d';
}
.fa-duotone.fa-arrow-alt-to-top:after,
.fa-duotone.fa-up-to-line:after,
.fad.fa-arrow-alt-to-top:after,
.fad.fa-up-to-line:after {
  content: '\10f34d';
}
.fa-duotone.fa-upload:after,
.fad.fa-upload:after {
  content: '\10f093';
}
.fa-duotone.fa-usb-drive:after,
.fad.fa-usb-drive:after {
  content: '\10f8e9';
}
.fa-duotone.fa-user:after,
.fad.fa-user:after {
  content: '\10f007';
}
.fa-duotone.fa-user-alien:after,
.fad.fa-user-alien:after {
  content: '\10e04a';
}
.fa-duotone.fa-user-astronaut:after,
.fad.fa-user-astronaut:after {
  content: '\10f4fb';
}
.fa-duotone.fa-user-bounty-hunter:after,
.fad.fa-user-bounty-hunter:after {
  content: '\10e2b9';
}
.fa-duotone.fa-user-check:after,
.fad.fa-user-check:after {
  content: '\10f4fc';
}
.fa-duotone.fa-user-clock:after,
.fad.fa-user-clock:after {
  content: '\10f4fd';
}
.fa-duotone.fa-user-cowboy:after,
.fad.fa-user-cowboy:after {
  content: '\10f8ea';
}
.fa-duotone.fa-user-crown:after,
.fad.fa-user-crown:after {
  content: '\10f6a4';
}
.fa-duotone.fa-user-doctor:after,
.fa-duotone.fa-user-md:after,
.fad.fa-user-doctor:after,
.fad.fa-user-md:after {
  content: '\10f0f0';
}
.fa-duotone.fa-user-doctor-message:after,
.fa-duotone.fa-user-md-chat:after,
.fad.fa-user-doctor-message:after,
.fad.fa-user-md-chat:after {
  content: '\10f82e';
}
.fa-duotone.fa-user-cog:after,
.fa-duotone.fa-user-gear:after,
.fad.fa-user-cog:after,
.fad.fa-user-gear:after {
  content: '\10f4fe';
}
.fa-duotone.fa-user-graduate:after,
.fad.fa-user-graduate:after {
  content: '\10f501';
}
.fa-duotone.fa-user-friends:after,
.fa-duotone.fa-user-group:after,
.fad.fa-user-friends:after,
.fad.fa-user-group:after {
  content: '\10f500';
}
.fa-duotone.fa-user-group-crown:after,
.fa-duotone.fa-users-crown:after,
.fad.fa-user-group-crown:after,
.fad.fa-users-crown:after {
  content: '\10f6a5';
}
.fa-duotone.fa-user-headset:after,
.fad.fa-user-headset:after {
  content: '\10f82d';
}
.fa-duotone.fa-user-construction:after,
.fa-duotone.fa-user-hard-hat:after,
.fa-duotone.fa-user-helmet-safety:after,
.fad.fa-user-construction:after,
.fad.fa-user-hard-hat:after,
.fad.fa-user-helmet-safety:after {
  content: '\10f82c';
}
.fa-duotone.fa-user-injured:after,
.fad.fa-user-injured:after {
  content: '\10f728';
}
.fa-duotone.fa-user-alt:after,
.fa-duotone.fa-user-large:after,
.fad.fa-user-alt:after,
.fad.fa-user-large:after {
  content: '\10f406';
}
.fa-duotone.fa-user-alt-slash:after,
.fa-duotone.fa-user-large-slash:after,
.fad.fa-user-alt-slash:after,
.fad.fa-user-large-slash:after {
  content: '\10f4fa';
}
.fa-duotone.fa-user-lock:after,
.fad.fa-user-lock:after {
  content: '\10f502';
}
.fa-duotone.fa-user-minus:after,
.fad.fa-user-minus:after {
  content: '\10f503';
}
.fa-duotone.fa-user-music:after,
.fad.fa-user-music:after {
  content: '\10f8eb';
}
.fa-duotone.fa-user-ninja:after,
.fad.fa-user-ninja:after {
  content: '\10f504';
}
.fa-duotone.fa-user-nurse:after,
.fad.fa-user-nurse:after {
  content: '\10f82f';
}
.fa-duotone.fa-user-edit:after,
.fa-duotone.fa-user-pen:after,
.fad.fa-user-edit:after,
.fad.fa-user-pen:after {
  content: '\10f4ff';
}
.fa-duotone.fa-user-pilot:after,
.fad.fa-user-pilot:after {
  content: '\10e2ba';
}
.fa-duotone.fa-user-pilot-tie:after,
.fad.fa-user-pilot-tie:after {
  content: '\10e2bb';
}
.fa-duotone.fa-user-plus:after,
.fad.fa-user-plus:after {
  content: '\10f234';
}
.fa-duotone.fa-user-robot:after,
.fad.fa-user-robot:after {
  content: '\10e04b';
}
.fa-duotone.fa-user-secret:after,
.fad.fa-user-secret:after {
  content: '\10f21b';
}
.fa-duotone.fa-user-shakespeare:after,
.fad.fa-user-shakespeare:after {
  content: '\10e2bc';
}
.fa-duotone.fa-user-shield:after,
.fad.fa-user-shield:after {
  content: '\10f505';
}
.fa-duotone.fa-user-slash:after,
.fad.fa-user-slash:after {
  content: '\10f506';
}
.fa-duotone.fa-user-tag:after,
.fad.fa-user-tag:after {
  content: '\10f507';
}
.fa-duotone.fa-user-tie:after,
.fad.fa-user-tie:after {
  content: '\10f508';
}
.fa-duotone.fa-user-unlock:after,
.fad.fa-user-unlock:after {
  content: '\10e058';
}
.fa-duotone.fa-user-visor:after,
.fad.fa-user-visor:after {
  content: '\10e04c';
}
.fa-duotone.fa-user-times:after,
.fa-duotone.fa-user-xmark:after,
.fad.fa-user-times:after,
.fad.fa-user-xmark:after {
  content: '\10f235';
}
.fa-duotone.fa-group:after,
.fa-duotone.fa-users:after,
.fad.fa-group:after,
.fad.fa-users:after {
  content: '\10f0c0';
}
.fa-duotone.fa-users-cog:after,
.fa-duotone.fa-users-gear:after,
.fad.fa-users-cog:after,
.fad.fa-users-gear:after {
  content: '\10f509';
}
.fa-duotone.fa-users-medical:after,
.fad.fa-users-medical:after {
  content: '\10f830';
}
.fa-duotone.fa-users-slash:after,
.fad.fa-users-slash:after {
  content: '\10e073';
}
.fa-duotone.fa-cutlery:after,
.fa-duotone.fa-utensils:after,
.fad.fa-cutlery:after,
.fad.fa-utensils:after {
  content: '\10f2e7';
}
.fa-duotone.fa-utility-pole:after,
.fad.fa-utility-pole:after {
  content: '\10e2bd';
}
.fa-duotone.fa-utility-pole-double:after,
.fad.fa-utility-pole-double:after {
  content: '\10e2be';
}
.fa-duotone.fa-v:after,
.fad.fa-v:after {
  content: '\10e2bf';
}
.fa-duotone.fa-vacuum:after,
.fad.fa-vacuum:after {
  content: '\10e04d';
}
.fa-duotone.fa-vacuum-robot:after,
.fad.fa-vacuum-robot:after {
  content: '\10e04e';
}
.fa-duotone.fa-value-absolute:after,
.fad.fa-value-absolute:after {
  content: '\10f6a6';
}
.fa-duotone.fa-shuttle-van:after,
.fa-duotone.fa-van-shuttle:after,
.fad.fa-shuttle-van:after,
.fad.fa-van-shuttle:after {
  content: '\10f5b6';
}
.fa-duotone.fa-vault:after,
.fad.fa-vault:after {
  content: '\10e2c0';
}
.fa-duotone.fa-vector-circle:after,
.fad.fa-vector-circle:after {
  content: '\10e2c1';
}
.fa-duotone.fa-vector-polygon:after,
.fad.fa-vector-polygon:after {
  content: '\10e2c2';
}
.fa-duotone.fa-vector-square:after,
.fad.fa-vector-square:after {
  content: '\10f5cb';
}
.fa-duotone.fa-venus:after,
.fad.fa-venus:after {
  content: '\10f221';
}
.fa-duotone.fa-venus-double:after,
.fad.fa-venus-double:after {
  content: '\10f226';
}
.fa-duotone.fa-venus-mars:after,
.fad.fa-venus-mars:after {
  content: '\10f228';
}
.fa-duotone.fa-vest:after,
.fad.fa-vest:after {
  content: '\10e085';
}
.fa-duotone.fa-vest-patches:after,
.fad.fa-vest-patches:after {
  content: '\10e086';
}
.fa-duotone.fa-vial:after,
.fad.fa-vial:after {
  content: '\10f492';
}
.fa-duotone.fa-vials:after,
.fad.fa-vials:after {
  content: '\10f493';
}
.fa-duotone.fa-video-camera:after,
.fa-duotone.fa-video:after,
.fad.fa-video-camera:after,
.fad.fa-video:after {
  content: '\10f03d';
}
.fa-duotone.fa-video-arrow-down-left:after,
.fad.fa-video-arrow-down-left:after {
  content: '\10e2c3';
}
.fa-duotone.fa-video-arrow-up-right:after,
.fad.fa-video-arrow-up-right:after {
  content: '\10e2c4';
}
.fa-duotone.fa-video-plus:after,
.fad.fa-video-plus:after {
  content: '\10f4e1';
}
.fa-duotone.fa-video-slash:after,
.fad.fa-video-slash:after {
  content: '\10f4e2';
}
.fa-duotone.fa-vihara:after,
.fad.fa-vihara:after {
  content: '\10f6a7';
}
.fa-duotone.fa-violin:after,
.fad.fa-violin:after {
  content: '\10f8ed';
}
.fa-duotone.fa-virus:after,
.fad.fa-virus:after {
  content: '\10e074';
}
.fa-duotone.fa-virus-slash:after,
.fad.fa-virus-slash:after {
  content: '\10e075';
}
.fa-duotone.fa-viruses:after,
.fad.fa-viruses:after {
  content: '\10e076';
}
.fa-duotone.fa-voicemail:after,
.fad.fa-voicemail:after {
  content: '\10f897';
}
.fa-duotone.fa-volcano:after,
.fad.fa-volcano:after {
  content: '\10f770';
}
.fa-duotone.fa-volleyball-ball:after,
.fad.fa-volleyball-ball:after {
  content: '\10f45f';
}
.fa-duotone.fa-volume-medium:after,
.fa-duotone.fa-volume:after,
.fad.fa-volume-medium:after,
.fad.fa-volume:after {
  content: '\10f6a8';
}
.fa-duotone.fa-volume-high:after,
.fa-duotone.fa-volume-up:after,
.fad.fa-volume-high:after,
.fad.fa-volume-up:after {
  content: '\10f028';
}
.fa-duotone.fa-volume-down:after,
.fa-duotone.fa-volume-low:after,
.fad.fa-volume-down:after,
.fad.fa-volume-low:after {
  content: '\10f027';
}
.fa-duotone.fa-volume-off:after,
.fad.fa-volume-off:after {
  content: '\10f026';
}
.fa-duotone.fa-volume-slash:after,
.fad.fa-volume-slash:after {
  content: '\10f2e2';
}
.fa-duotone.fa-volume-mute:after,
.fa-duotone.fa-volume-times:after,
.fa-duotone.fa-volume-xmark:after,
.fad.fa-volume-mute:after,
.fad.fa-volume-times:after,
.fad.fa-volume-xmark:after {
  content: '\10f6a9';
}
.fa-duotone.fa-vr-cardboard:after,
.fad.fa-vr-cardboard:after {
  content: '\10f729';
}
.fa-duotone.fa-w:after,
.fad.fa-w:after {
  content: '\10e2c5';
}
.fa-duotone.fa-wagon-covered:after,
.fad.fa-wagon-covered:after {
  content: '\10f8ee';
}
.fa-duotone.fa-walker:after,
.fad.fa-walker:after {
  content: '\10f831';
}
.fa-duotone.fa-walkie-talkie:after,
.fad.fa-walkie-talkie:after {
  content: '\10f8ef';
}
.fa-duotone.fa-wallet:after,
.fad.fa-wallet:after {
  content: '\10f555';
}
.fa-duotone.fa-wand:after,
.fad.fa-wand:after {
  content: '\10f72a';
}
.fa-duotone.fa-magic:after,
.fa-duotone.fa-wand-magic:after,
.fad.fa-magic:after,
.fad.fa-wand-magic:after {
  content: '\10f0d0';
}
.fa-duotone.fa-magic-wand-sparkles:after,
.fa-duotone.fa-wand-magic-sparkles:after,
.fad.fa-magic-wand-sparkles:after,
.fad.fa-wand-magic-sparkles:after {
  content: '\10e2c6';
}
.fa-duotone.fa-wand-sparkles:after,
.fad.fa-wand-sparkles:after {
  content: '\10f72b';
}
.fa-duotone.fa-warehouse:after,
.fad.fa-warehouse:after {
  content: '\10f494';
}
.fa-duotone.fa-warehouse-alt:after,
.fa-duotone.fa-warehouse-full:after,
.fad.fa-warehouse-alt:after,
.fad.fa-warehouse-full:after {
  content: '\10f495';
}
.fa-duotone.fa-washer:after,
.fa-duotone.fa-washing-machine:after,
.fad.fa-washer:after,
.fad.fa-washing-machine:after {
  content: '\10f898';
}
.fa-duotone.fa-watch:after,
.fad.fa-watch:after {
  content: '\10f2e1';
}
.fa-duotone.fa-watch-apple:after,
.fad.fa-watch-apple:after {
  content: '\10e2c7';
}
.fa-duotone.fa-watch-calculator:after,
.fad.fa-watch-calculator:after {
  content: '\10f8f0';
}
.fa-duotone.fa-watch-fitness:after,
.fad.fa-watch-fitness:after {
  content: '\10f63e';
}
.fa-duotone.fa-watch-smart:after,
.fad.fa-watch-smart:after {
  content: '\10e2c8';
}
.fa-duotone.fa-water:after,
.fad.fa-water:after {
  content: '\10f773';
}
.fa-duotone.fa-water-arrow-down:after,
.fa-duotone.fa-water-lower:after,
.fad.fa-water-arrow-down:after,
.fad.fa-water-lower:after {
  content: '\10f774';
}
.fa-duotone.fa-water-arrow-up:after,
.fa-duotone.fa-water-rise:after,
.fad.fa-water-arrow-up:after,
.fad.fa-water-rise:after {
  content: '\10f775';
}
.fa-duotone.fa-ladder-water:after,
.fa-duotone.fa-swimming-pool:after,
.fa-duotone.fa-water-ladder:after,
.fad.fa-ladder-water:after,
.fad.fa-swimming-pool:after,
.fad.fa-water-ladder:after {
  content: '\10f5c5';
}
.fa-duotone.fa-heart-rate:after,
.fa-duotone.fa-wave-pulse:after,
.fad.fa-heart-rate:after,
.fad.fa-wave-pulse:after {
  content: '\10f5f8';
}
.fa-duotone.fa-wave-sine:after,
.fad.fa-wave-sine:after {
  content: '\10f899';
}
.fa-duotone.fa-wave-square:after,
.fad.fa-wave-square:after {
  content: '\10f83e';
}
.fa-duotone.fa-wave-triangle:after,
.fad.fa-wave-triangle:after {
  content: '\10f89a';
}
.fa-duotone.fa-waveform:after,
.fad.fa-waveform:after {
  content: '\10f8f1';
}
.fa-duotone.fa-waveform-lines:after,
.fad.fa-waveform-lines:after {
  content: '\10f8f2';
}
.fa-duotone.fa-weight-hanging:after,
.fad.fa-weight-hanging:after {
  content: '\10f5cd';
}
.fa-duotone.fa-weight-scale:after,
.fa-duotone.fa-weight:after,
.fad.fa-weight-scale:after,
.fad.fa-weight:after {
  content: '\10f496';
}
.fa-duotone.fa-whale:after,
.fad.fa-whale:after {
  content: '\10f72c';
}
.fa-duotone.fa-wheat:after,
.fad.fa-wheat:after {
  content: '\10f72d';
}
.fa-duotone.fa-wheat-alt:after,
.fa-duotone.fa-wheat-awn:after,
.fad.fa-wheat-alt:after,
.fad.fa-wheat-awn:after {
  content: '\10e2c9';
}
.fa-duotone.fa-wheelchair:after,
.fad.fa-wheelchair:after {
  content: '\10f193';
}
.fa-duotone.fa-wheelchair-alt:after,
.fa-duotone.fa-wheelchair-move:after,
.fad.fa-wheelchair-alt:after,
.fad.fa-wheelchair-move:after {
  content: '\10e2ca';
}
.fa-duotone.fa-glass-whiskey:after,
.fa-duotone.fa-whiskey-glass:after,
.fad.fa-glass-whiskey:after,
.fad.fa-whiskey-glass:after {
  content: '\10f7a0';
}
.fa-duotone.fa-glass-whiskey-rocks:after,
.fa-duotone.fa-whiskey-glass-ice:after,
.fad.fa-glass-whiskey-rocks:after,
.fad.fa-whiskey-glass-ice:after {
  content: '\10f7a1';
}
.fa-duotone.fa-whistle:after,
.fad.fa-whistle:after {
  content: '\10f460';
}
.fa-duotone.fa-wifi-3:after,
.fa-duotone.fa-wifi-strong:after,
.fa-duotone.fa-wifi:after,
.fad.fa-wifi-3:after,
.fad.fa-wifi-strong:after,
.fad.fa-wifi:after {
  content: '\10f1eb';
}
.fa-duotone.fa-wifi-exclamation:after,
.fad.fa-wifi-exclamation:after {
  content: '\10e2cb';
}
.fa-duotone.fa-wifi-2:after,
.fa-duotone.fa-wifi-fair:after,
.fad.fa-wifi-2:after,
.fad.fa-wifi-fair:after {
  content: '\10f6ab';
}
.fa-duotone.fa-wifi-slash:after,
.fad.fa-wifi-slash:after {
  content: '\10f6ac';
}
.fa-duotone.fa-wifi-1:after,
.fa-duotone.fa-wifi-weak:after,
.fad.fa-wifi-1:after,
.fad.fa-wifi-weak:after {
  content: '\10f6aa';
}
.fa-duotone.fa-wind:after,
.fad.fa-wind:after {
  content: '\10f72e';
}
.fa-duotone.fa-wind-turbine:after,
.fad.fa-wind-turbine:after {
  content: '\10f89b';
}
.fa-duotone.fa-wind-circle-exclamation:after,
.fa-duotone.fa-wind-warning:after,
.fad.fa-wind-circle-exclamation:after,
.fad.fa-wind-warning:after {
  content: '\10f776';
}
.fa-duotone.fa-window:after,
.fad.fa-window:after {
  content: '\10f40e';
}
.fa-duotone.fa-window-alt:after,
.fa-duotone.fa-window-flip:after,
.fad.fa-window-alt:after,
.fad.fa-window-flip:after {
  content: '\10f40f';
}
.fa-duotone.fa-window-frame:after,
.fad.fa-window-frame:after {
  content: '\10e04f';
}
.fa-duotone.fa-window-frame-open:after,
.fad.fa-window-frame-open:after {
  content: '\10e050';
}
.fa-duotone.fa-window-maximize:after,
.fad.fa-window-maximize:after {
  content: '\10f2d0';
}
.fa-duotone.fa-window-minimize:after,
.fad.fa-window-minimize:after {
  content: '\10f2d1';
}
.fa-duotone.fa-window-restore:after,
.fad.fa-window-restore:after {
  content: '\10f2d2';
}
.fa-duotone.fa-windsock:after,
.fad.fa-windsock:after {
  content: '\10f777';
}
.fa-duotone.fa-wine-bottle:after,
.fad.fa-wine-bottle:after {
  content: '\10f72f';
}
.fa-duotone.fa-wine-glass:after,
.fad.fa-wine-glass:after {
  content: '\10f4e3';
}
.fa-duotone.fa-fragile:after,
.fa-duotone.fa-wine-glass-crack:after,
.fad.fa-fragile:after,
.fad.fa-wine-glass-crack:after {
  content: '\10f4bb';
}
.fa-duotone.fa-wine-glass-alt:after,
.fa-duotone.fa-wine-glass-empty:after,
.fad.fa-wine-glass-alt:after,
.fad.fa-wine-glass-empty:after {
  content: '\10f5ce';
}
.fa-duotone.fa-krw:after,
.fa-duotone.fa-won-sign:after,
.fa-duotone.fa-won:after,
.fad.fa-krw:after,
.fad.fa-won-sign:after,
.fad.fa-won:after {
  content: '\10f159';
}
.fa-duotone.fa-wreath:after,
.fad.fa-wreath:after {
  content: '\10f7e2';
}
.fa-duotone.fa-wrench:after,
.fad.fa-wrench:after {
  content: '\10f0ad';
}
.fa-duotone.fa-wrench-simple:after,
.fad.fa-wrench-simple:after {
  content: '\10e2cc';
}
.fa-duotone.fa-x:after,
.fad.fa-x:after {
  content: '\10e2cd';
}
.fa-duotone.fa-x-ray:after,
.fad.fa-x-ray:after {
  content: '\10f497';
}
.fa-duotone.fa-close:after,
.fa-duotone.fa-multiply:after,
.fa-duotone.fa-remove:after,
.fa-duotone.fa-times:after,
.fa-duotone.fa-xmark:after,
.fad.fa-close:after,
.fad.fa-multiply:after,
.fad.fa-remove:after,
.fad.fa-times:after,
.fad.fa-xmark:after {
  content: '\10f00d';
}
.fa-duotone.fa-times-to-slot:after,
.fa-duotone.fa-vote-nay:after,
.fa-duotone.fa-xmark-to-slot:after,
.fad.fa-times-to-slot:after,
.fad.fa-vote-nay:after,
.fad.fa-xmark-to-slot:after {
  content: '\10f771';
}
.fa-duotone.fa-y:after,
.fad.fa-y:after {
  content: '\10e2ce';
}
.fa-duotone.fa-cny:after,
.fa-duotone.fa-jpy:after,
.fa-duotone.fa-rmb:after,
.fa-duotone.fa-yen-sign:after,
.fa-duotone.fa-yen:after,
.fad.fa-cny:after,
.fad.fa-jpy:after,
.fad.fa-rmb:after,
.fad.fa-yen-sign:after,
.fad.fa-yen:after {
  content: '\10f157';
}
.fa-duotone.fa-yin-yang:after,
.fad.fa-yin-yang:after {
  content: '\10f6ad';
}
.fa-duotone.fa-z:after,
.fad.fa-z:after {
  content: '\10e2cf';
}
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(/assets/fonts/fa-light-300.woff2) format('woff2'),
    url(/assets/fonts/fa-light-300.woff) format('woff'),
    url(/assets/fonts/fa-light-300.ttf) format('truetype');
}
.fa-light,
.fal {
  font-weight: 300;
}
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(/assets/fonts/fa-regular-400.woff2) format('woff2'),
    url(/assets/fonts/fa-regular-400.woff) format('woff'),
    url(/assets/fonts/fa-regular-400.ttf) format('truetype');
}
.fa-light,
.fa-regular,
.fal,
.far {
  font-family: 'Font Awesome 6 Pro';
}
.fa-regular,
.far {
  font-weight: 400;
}
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(/assets/fonts/fa-solid-900.woff2) format('woff2'),
    url(/assets/fonts/fa-solid-900.woff) format('woff'),
    url(/assets/fonts/fa-solid-900.ttf) format('truetype');
}
.fa-solid,
.fas {
  font-weight: 900;
}
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url(/assets/fonts/fa-thin-100.woff2) format('woff2'),
    url(/assets/fonts/fa-thin-100.woff) format('woff'),
    url(/assets/fonts/fa-thin-100.ttf) format('truetype');
}
.fa-solid,
.fa-thin,
.fas,
.fat {
  font-family: 'Font Awesome 6 Pro';
}
.fa-thin,
.fat {
  font-weight: 100;
}
