//color

@each $color,
$value in $theme-colors {
  .bg-#{$color}-alt {
    background-color: rgba($value, .12);
  }
}

.bg-white-alt{
  background-color: rgba($white, .12);
}

//Social brand
@each $color,
$value in $brand-colors {
  .brand-#{$color} {
    color: $value;
  }
}

@each $color,
$value in $brand-colors {
  .bg-#{$color}-alt {
    background-color: rgba($value, .12);
  }
}


/*color utility*/
.color {
  position: relative;
  display: block;
  min-height: 50px;
  padding: 1rem 1rem;
}
.color span {
  color: $white;
}
.text-off-white {
  color: rgba(255, 255, 255, 0.8);
}
.border-light {
  border-color: rgba(101, 101, 101, 0.1) !important;
}


.bg-primary h1,
.bg-primary h2,
.bg-primary h3,
.bg-primary h4,
.bg-primary h5,
.bg-primary h6,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-gradient h1,
.bg-gradient h2,
.bg-gradient h3,
.bg-gradient h4,
.bg-gradient h5,
.bg-gradient h6,
.bg-purple h1,
.bg-purple h2,
.bg-purple h3,
.bg-purple h4,
.bg-purple h5,
.bg-purple h6 {
  color: $white;
}
.bg-primary p,
.bg-primary li,
.bg-primary span,
.bg-dark p,
.bg-dark li,
.bg-dark span,
.bg-gradient p,
.bg-gradient li,
.bg-gradient span,
.bg-purple p,
.bg-purple li,
.bg-purple span {
  color: darken($white, 15%)
}
.bg-white h1,
.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white h6,
.bg-light h1,
.bg-light h2,
.bg-light h3,
.bg-light h4,
.bg-light h5,
.bg-light h6 {
  color: initial;
}
.bg-light > .bg-dark h1,
.bg-light .bg-dark h2,
.bg-light .bg-dark h3,
.bg-light .bg-dark h4,
.bg-light .bg-dark h5,
.bg-light .bg-dark h6,
.bg-light > .bg-gradient h1,
.bg-light .bg-gradient h2,
.bg-light .bg-gradient h3,
.bg-light .bg-gradient h4,
.bg-light .bg-gradient h5,
.bg-light .bg-gradient h6 {
  color: $white;
}
.bg-white p,
.bg-white li,
.bg-white span {
  color: $body-color;
}
.text-white-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}
.text-white-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}
.text-white-80 {
  color: rgba(255, 255, 255, 0.8) !important;
}
.text-white-90 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.bg-light{
  .author-info{
    span{
      color: $body-color;
    }
  }
}

h1.ins-heading, h2.ins-heading, h3.ins-heading, h4.ins-heading, h5.ins-heading, h6.ins-heading, .ins-text-secondary {
  color: $ins-secondary;
}

p.ins-text {
  color: $ins-text;
}

h1.mk-heading, h2.mk-heading, h3.mk-heading, h4.mk-heading, h5.mk-heading, h6.mk-heading {
  color: $mk-black;
  line-height: 1.2em;
  &.lh-lg {
    line-height: 1.5em;
  }
}

.crm-bg-light {
  background-color: $crm-bg-light;
}

.crm-bg-yellow {
  background-color: $crm-bg-yellow-light;
}

.crm-bg-light-green {
  background-color: $crm-bg-light-green;
}

.crm-bg-primary-light {
  background-color: rgba($crm-primary, 0.1);
}
.crm-bg-blue-light {
  background-color: rgba($crm-blue, 0.1);
}
.crm-bg-yellow-light {
  background-color: rgba($crm-yellow, 0.1);
}
.text-dg-primary {
  color: $dg-primary-color !important;
}
.heading-dg-color {
  color: $dg-heading-color !important;
}
.text-dg-color {
  color: $dg-text-color !important;
}
.sc-heading-color {
  color: $crm-secondary;
}